import {Button, SaveButton, Toolbar, useTranslate} from "react-admin";
import {useHistory} from "react-router";
import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { UPDATE_TEMPLATE_EMAIL } from "../email/gql";
import { useForm } from 'react-final-form';




const UpdateToolbarEmail = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const form = useForm()
    const cancelButton = () => {
        history.goBack();
    };
    const GetDataTemplate = async () => {
        //console.log('bodyConvert',record);
        console.log('bodyConvert2',form.getState().values);
        const record = form.getState().values
        const subject = form.getState().values.subject
        
        const bodyConvert =  record.virtual_body.toString().replace('<p><br></p>', '<p></p>');
        const data = await apolloClient.mutate({
            mutation: UPDATE_TEMPLATE_EMAIL,
            variables: {
                id: record.id,
                action_key: record.action_key, 
                html_content: bodyConvert, 
                name: record.name,
                status: record.status,
                subject: record.subject
            },
          });
          const {data: {update_email_template}} = data
          return update_email_template;
    }
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
            <SaveButton
                disabled={props.pristine}
                undoable={false}
                label={translate('common.btn_update')}
                style={ props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
                handleSubmitWithRedirect={async () => {
                    const {record} = props;
                    const dataResponse =  await GetDataTemplate()
                    if(dataResponse.status_code && dataResponse.status_code === 200){
                        props.handleSubmitWithRedirect();
                        }
                    }
                  }
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default"/>
        </Toolbar>
    );
};
export default UpdateToolbarEmail;