import React, {useEffect, useState} from 'react';
import {Admin, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {ApolloProvider} from "@apollo/react-hoc";
import buildHasuraProvider from 'ra-data-hasura-graphql';
import authProvider from './authProvider';
import themeReducer from './themeReducer';
import clientReducer from './reducers/clientReducer';
import {Layout, Login} from './layout';
import customRoutes from './routes';
import japanMessages from './i18n/ja';
import enMessages from './i18n/en';
import users from './pages/users';
import template from './pages/email';
import createAuthClient from "./apolloClient";
import createHasuraClient from "./hasuraApolloClient";
import userReducer from "./reducers/userReducer";
import useLocalStorage from "./utils/useLocalStorage";
import {Client, LoginUser, User, InitialStateProps} from './types';
import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import NotFound from './pages/NotFound';
import genre from './pages/genre';
import team from './pages/team';
import account from './pages/account';
import admin from './pages/admin';
import event from './pages/event';
import sposuru_news from './pages/sposuru_news';
import question from './pages/question';
import answer from './pages/answer';
import character from './pages/character';
import team_review from './pages/team_review';
import team_review_list from './pages/team_review_list';
import contact from './pages/contact';
import point_type from './pages/point_type';
import user_point from './pages/user_point';
import point_exchange from './pages/point_exchange';
import genre_sport from './pages/genre_sport';
import event_place from './pages/event_place';
import event_plan from './pages/event_plan';
import teamStaff from './pages/team_staff';
import accountPlace from './pages/account_place';
import teamPlace from './pages/team_place';
import teamDraft from './pages/team_draft';
import accountAdmin from './pages/account_admin';
import teamAdmin from './pages/team_admin';
import area from './pages/area';
import pref from './pages/pref';
import city from './pages/city';
import account_owner from './pages/account_owner';
import user_remove_admin from './pages/user_remove_admin';
import event_application from './pages/event_application';
import team_experiencer from './pages/team_experiencer';
import event_draft from './pages/event_draft';
import event_contact from './pages/event_contact';
import team_contact from './pages/team_contact';
import data_account from './pages/data_account';
import team_trash from './pages/team_trash';
import data_account_trash from './pages/data_account_trash';
import DataProviderContext from "./dataProviderContext";
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { getLocalStorageValue } from './utils/Utils';
import event_trash from './pages/event_trash';
import team_review_trash from './pages/team_review_trash';
import users_trash from './pages/users_trash';
import account_owner_trash from './pages/account_owner_trash';
import account_admin_trash from './pages/account_admin_trash';
import team_admin_trash from './pages/team_admin_trash';
import billing from './pages/billing';
import billing_detail from './pages/billing_detail';
import team_game from './pages/team_game';
import team_game_contact from './pages/team_game_contact';
import team_experiencer_list from './pages/team_experiencer_list';
import team_contact_list from './pages/team_contact_list';
import event_contact_list from './pages/event_contact_list';
import event_application_list from './pages/event_application_list';
import shop from './pages/shop';
import school_gym from './pages/school_gym';
import school_gym_draft from './pages/school_gym_draft';

import shop_draft from './pages/shop_draft';














require('dotenv').config();
const language = localStorage.getItem('language') || 'ja';

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return enMessages;
    }
    return japanMessages;
}, language);

const authClient = createAuthClient();
const hasuraClient = createHasuraClient();

const App = () => {
    const [dataProvider, setDataProvider] = useState();
    const [client] = useLocalStorage<Client>('client', null);    
    const [user] = useLocalStorage<LoginUser>('user', null);
    const [initialState, setInitialState] = useState<InitialStateProps>({
        client,
        user: user as LoginUser
    });

    useEffect(() => {
        //console.log('11111111111111');
        //authProvider.logout(true);
        (async () => {
            if (!dataProvider) {
                const hasuraProvider = await buildHasuraProvider({
                    client: hasuraClient,
                    // introspection: schema
                });

                // @ts-ignore
                setDataProvider(() => hasuraProvider)

                const cUser = getLocalStorageValue<LoginUser>('user', null);
                const initStates = {
                    ...initialState,
                    user: cUser
                };
                setInitialState(initStates);
            }
        })()
    }, [dataProvider]);

    if (!dataProvider) {
        return <Box style={{ width: '100%' }}>
            <LinearProgress />
        </Box>
    }
    
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ApolloProvider client={authClient}>
                <DataProviderContext.Provider value={{dataProvider, setDataProvider}}>
                <Admin
                    title=""
                    dataProvider={dataProvider}
                    customReducers={{ theme: themeReducer, client: clientReducer, user: userReducer }}
                    customRoutes={customRoutes}
                    authProvider={authProvider}
                    loginPage={Login}
                    layout={Layout}
                    initialState={initialState}
                    i18nProvider={i18nProvider}
                    catchAll={NotFound}
                    disableTelemetry
                >
                    <Resource name="data_account" {...data_account} />
                    <Resource name="account" {...account} /> 
                    <Resource name="user_list" {...users} /> 
                    <Resource name="user" {...users} />  
                    <Resource name="genre" {...genre} />    
                    <Resource name="team_list" {...team} />     
                    <Resource name="team" {...team} />    
                    <Resource name="admin" {...admin} /> 
                    <Resource name="template" {...template} />
                    <Resource name="log" /> 
                    <Resource name="event" {...event} />     
                    <Resource name="sposuru_news" {...sposuru_news} />     
                    <Resource name="question" {...question} />    
                    <Resource name="answer" {...answer} />   
                    <Resource name="character" {...character} />      
                    <Resource name="team_review" {...team_review} />    
                    <Resource name="contact" {...contact} />      
                    <Resource name="point_type" {...point_type} />     
                    <Resource name="user_point" {...user_point} />    
                    <Resource name="point_exchange" {...point_exchange} />      
                    <Resource name="genre_sport" {...genre_sport} />      
                    <Resource name="event_place" {...event_place} />    
                    <Resource name="event_plan" {...event_plan} />     
                    <Resource name="team_staff" {...teamStaff} />
                    <Resource name="account_place" {...accountPlace} />
                    <Resource name="team_place_view" {...teamPlace} />
                    <Resource name="team_draft" {...teamDraft} />
                    <Resource name="account_admin" {...accountAdmin} />
                    <Resource name="team_admin" {...teamAdmin} />
                    <Resource name="area" {...area} />
                    <Resource name="pref" {...pref} />
                    <Resource name="city" {...city} />
                    <Resource name="account_owner" {...account_owner} />
                    <Resource name="user_remove_admin" {...user_remove_admin} />
                    <Resource name="event_application" {...event_application} />
                    <Resource name="team_experiencer_list" {...team_experiencer_list} />
                    <Resource name="event_draft" {...event_draft} />
                    <Resource name="event_contact" {...event_contact} />
                    <Resource name="team_contact" {...team_contact} />
                    <Resource name="team_review_list" {...team_review_list} /> 
                    <Resource name="data_account_trash" {...data_account_trash} />   
                    <Resource name="team_trash" {...team_trash} />    
                    <Resource name="event_trash" {...event_trash} />     
                    <Resource name="team_review_trash" {...team_review_trash} /> 
                    <Resource name="user_trash" {...users_trash} />    
                    <Resource name="account_owner_trash" {...account_owner_trash} /> 
                    <Resource name="account_admin_trash" {...account_admin_trash} />     
                    <Resource name="team_admin_trash" {...team_admin_trash} />
                    <Resource name="billing_view" {...billing} />
                    <Resource name="billing_detail" {...billing_detail} />
                    <Resource name="team_game_contact" {...team_game_contact} />
                    <Resource name="team_game" {...team_game} />
                    <Resource name="team_experiencer" {...team_experiencer} />
                    <Resource name="team_contact_list" {...team_contact_list} />
                    <Resource name="event_contact_list" {...event_contact_list} />
                    <Resource name="event_application_list" {...event_application_list} />
                    <Resource name="shop" {...shop} />
                    <Resource name="shop_draft" {...shop_draft} />
                    <Resource name="school_gym" {...school_gym} />
                    <Resource name="school_gym_draft" {...school_gym_draft} />




                </Admin>
                </DataProviderContext.Provider>
            </ApolloProvider>
        </MuiPickersUtilsProvider>
    );
};

// @ts-ignore
export default App;
