export const STATUS = [
    { id: 0, name: "未承認" },
    { id: 1, name: "承認" },
    { id: 2, name: "非承認" },
]
const USER_STATUS = [
    { id: 1, name: "所属中" },
    { id: 2, name: "所属歴あり"},
    { id: 3, name: "体験参加者"},
]
const SCORE = [
    { id: 1, name: 1 },
    { id: 2, name: 2 },
    { id: 3, name: 3 },
    { id: 4, name: 4 },
    { id: 5, name: 5 },
    { id: 6, name: 6 }
]
const DATA = [
    { name: 'id', type: 'textinput', searchField: '', options: [], reference: '',export_name: 'id', nullable: true, editable: false, export: true, import: true },
    { name: 'user_id', type: 'reference', reference: 'user', searchField: 'lastname',export_name: 'lastname', nullable: false, editable: false, export: true, import: true },
    { name: 'user_id', type: 'reference', reference: 'user', searchField: 'firstname',export_name: 'firstname', nullable: false, editable: false, export: true, import: true }, 
    { name: 'nickname', type: 'textinput', searchField: 'nickname',export_name: 'nickname', nullable: false, editable: false, export: true, import: true },
    { name: 'team_id', type: 'reference', reference: 'team', searchField: 'name',export_name: 'team_id', nullable: false, editable: false, export: true, import: true },
    { name: 'user_status', type: 'status', options: USER_STATUS, numberFormat: true,export_name: 'user_status', nullable: false, editable: true, export: true, import: true },
    { name: 'score_1', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_1', nullable: false, editable: true, export: true, import: true },
    { name: 'score_2', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_2', nullable: false, editable: true, export: true, import: true },
    { name: 'score_3', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_3', nullable: false, editable: true, export: true, import: true },
    { name: 'score_4', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_4', nullable: false, editable: true, export: true, import: true },
    { name: 'score_5', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_5', nullable: false, editable: true, export: true, import: true },
    { name: 'score_6', type: 'status', options: SCORE, numberFormat: true,export_name: 'score_6', nullable: false, editable: true, export: true, import: true },

    { name: 'title', type: 'textinput', nullable: true, editable: true,export_name: 'title', export: true, import: true },
    { name: 'review', type: 'textinput',multiline: true, nullable: true,export_name: 'review', editable: true, export: true, import: true },
    { name: 'status', type: 'status', options: STATUS, nullable: false,export_name: 'status', editable: true, export: true, import: true },
    { name: 'note', type: 'textinput',multiline: true, nullable: true,export_name: 'note', editable: true, export: true, import: true },

    { name: 'created', type: 'textinput', nullable: true, editable: false,export_name: 'created', export: true, import: true },
    { name: 'created_by', type: 'hidden', numberFormat: true, nullable: true,export_name: 'created_by', editable: false, export: false, import: true },
]

export default DATA;