import { gql } from '@apollo/client';
import createAuthClient from "../apolloClient";
const fetch = require("node-fetch");

const GET_TOKEN_BY_REFRESH_TOKEN = `
    mutation get_token_by_refresh_token($refresh_token: String!) {
    refresh_token(refresh_token: $refresh_token) {
    error
    token
  }
}
`;
const Providers = {
  getTokenByRefreshToken: async (refreshToken,apolloClient)=>{
    const res = await createAuthClient.mutate({
        mutation: GET_TOKEN_BY_REFRESH_TOKEN,
        variables: { 
          refreshToken,
        }
      });
      return res?.data?.refresh_token
  },
}

export default Providers;

export const executeRefreshToken = async (variables,accessToken) => {
  return fetch(`${process.env.REACT_APP_HASURA}`, {
    headers: { Authentication: `Bearer ${accessToken}`},
    method: 'POST',
    body: JSON.stringify({
      query: GET_TOKEN_BY_REFRESH_TOKEN,
      variables
    })
  })
    .then(response => response.json())
    .then(jsonResponse => {
      console.log(jsonResponse);
      return jsonResponse;
    });
  // const fetchResponse = await fetch(
  //   'http://sposuru-hasura-dev.mk1technology.com/v1/graphql', {
  //     method: 'POST',
  //     headers: reqHeaders |{},
  //     body: JSON.stringify({
  //         query: GET_TOKEN_BY_REFRESH_TOKEN,
  //         variables
  //     })
  // }
  // );
  // return await fetchResponse.json();
}