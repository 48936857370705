import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any) => {
  var data: any = items  
  await fetchRelatedRecords(items, 'account_id', 'account').then((account: any) => {
    const data_account = items.map((item: any) => (
      {
        ...item,
        account_id: account[item.account_id].name,
        payment_method: item.payment_method === 1 ? '銀行振り込み': 'Stripe', 
        paid: item.paid === 0 ? '未払い' : (item.paid  === 1 ? '支払済み' : 'エラー')
      }
    ));
    data = data_account;
  });
  var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  //var fieldName = fields.map((f: any) => f.export_name)
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });
  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }

    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

