import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0
  },
  logotypeContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logotypeImage: {
    width: 165,
    marginBottom: '40px'
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 84,
  },
  customFormContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
    alignItems: "center",
  },
  formContainer: {
    width: "40%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    alignItems: "center",
  },
  form: {
    width: "450px",
    background:"#FFF",
    padding:"50px",
    borderRadius:10,
    boxShadow:"0 0 10px #D9d9f9"   

  },
  tab: {
    fontWeight: 400,
    fontSize: 18
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: '40px'
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: '20px'
  },
  googleButton: {
    marginTop: '40px',
    backgroundColor: "white",
    width: "100%",
    textTransform: "none"
  },
  googleButtonCreating: {
    marginTop: 0
  },
  googleIcon: {
    width: 30,
    marginRight: '20px'
  },
  creatingButtonContainer: {
    marginTop: '20px',
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  createAccountButton: {
    height: 46,
    textTransform: "none"
  },
  formDividerContainer: {
    marginTop: '40px',
    marginBottom: '40px',
    display: "flex",
    alignItems: "center"
  },
  formDividerWord: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
  },
  errorMessage: {
    textAlign: "center"
  },


  formButtons: {
    width: "100%",
    marginTop: '40px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400
  },
  loginLoader: {
    marginLeft: '40px'
  },
  copyright: {
    marginTop: '40px',
    whiteSpace: "nowrap",
  }
}));
