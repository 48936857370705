import { gql } from "@apollo/client";

export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}

`;
export const SOFT_DELETE_ANSWER = gql`
mutation MyMutation($list_answer_id: [Int!]) {
  update_answer(where: {id: {_in: $list_answer_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const REVERT_SOFT_DELETE_ANSWER = gql`
mutation MyMutation($list_answer_id: [Int!]) {
  update_answer(where: {id: {_in: $list_answer_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const DeleteMultiAnswer = async (apolloClient: any,list_answer_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SOFT_DELETE_ANSWER,
      variables: {
        list_answer_id: list_answer_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
export const RecoverMultiAnswer= async (apolloClient: any,list_answer_id: number[]) => {
  let status = 0;
  try{
    let { data: dataRecoverMulti } = await apolloClient.mutate({
      mutation: REVERT_SOFT_DELETE_ANSWER,
      variables: {
        list_answer_id: list_answer_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}