import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    SearchInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import CreateField from '../components/editField';
import exporter from './exporter';
import ReferenceFieldFullName from './ReferenceFieldFullName';
import ButtonApplication from './LinkToApplication';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="message,note,event_name,event_title" alwaysOn />
        </Filter>
    )
};
const AccountReferenceField = (props: any) => {
    return <ReferenceField link={false} source={'account_id'} reference={'account'}  {...props}>
            <TextField source={'name'} />
        </ReferenceField>
}

const EventContactList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const apolloClient = useApolloClient();
    const role = permissions?.role;
    const dataFilter = props.location.search as string;    
    const checkStatus = dataFilter.includes('contact_type%22:1');
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'event_contact';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/event_contact_list';
    },[props]);
    if (role ===undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'event_contact', ExportableFields, translate,fetchRelatedRecords,apolloClient)}
            actions={<ListActions to='event_contact' />}
            empty={<ListEmpty />}
            title={<CreateField titlePage={''} />}
        >
            <CustomizableDatagrid rowClick={(id: any, resource: any, record: any) => `/event_contact/${id}`}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options} = f || {}
                    if (type === 'textinput') {
                        // if (numberFormat) {
                        //     return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        // }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'reference') {
                        if (name === 'user_id') {
                            return  <ReferenceFieldFullName source="user_id" label={translate(`${module}.${name}`)} reference='user'/>
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} link={false}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }
                    if (type === 'date' || type === 'datetime') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    // if (type === 'imagefield') {
                    //     return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    // }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status') {
                        if(name === 'contact_status' && checkStatus === true){
                            return <></>
                        }else {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                        }
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'button_filter') {
                        if (name === 'button_filter_event_contact') {
                            return <ButtonApplication source={" "} />
                        }
                    }
                    if(name == 'account_name'){
                        return <ReferenceField link={false} label={translate(`${module}.account_name`)} source={'event_id'} reference={'event'}>
                            <AccountReferenceField />
                        </ReferenceField>
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default EventContactList;
