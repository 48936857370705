import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
   GET_TEAM_NOT_ACCESS_APPROVE, GET_TEAM_ACCESS_APPROVE, GET_DATA_TEAM_DRAFT,CopyTeamPlaceStaff, COPY_DATA_TEAM_DRAFT_TO_TEAM, ExecuteCopyTeam, SendMailApprove, SendEmailApproveTeamDraftEndUser
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";



const ButtonApprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataApprove: number[] = [];
  var CheckCopy = false;
  var CountCopyFail = 0;
  var CountCopySuccess = 0;
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  const ApproveTeam = async (team_draft_id: number[]) => {
    // get team not access to approve
    let { data: dataNotAccess } = await apolloClient.query({
      query: GET_TEAM_NOT_ACCESS_APPROVE,
      variables: {
        list_team_id: team_draft_id
      }
    });
    // get team not access to approve
    let { data: dataAccess } = await apolloClient.query({
      query: GET_TEAM_ACCESS_APPROVE,
      variables: {
        list_team_id: team_draft_id
      }
    });
    if (dataAccess.team_draft.length > 0) {
      dataAccess.team_draft.map((x: any) => {
        dataApprove.push(x.id)
      })
    } else {
      return {
        countSuccess: 0,
        countFail: dataNotAccess.team_draft.length
      };
    }
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
    for (let index = 0; index < dataApprove.length; index++) {
      const team_draft_id = dataApprove[index];
      console.log('team_draft_id', team_draft_id);
      let { data: dataTeamDraft } = await apolloClient.query({
        query: GET_DATA_TEAM_DRAFT,
        variables: {
          team_draft_id: team_draft_id
        }
      });
      if(dataTeamDraft.team_draft[0].account_id){
        const sendMailApprove = await SendMailApprove(apolloClient,team_draft_id);
        console.log('sendMailApprove',sendMailApprove);
      }
      const dataCopy = await ExecuteCopyTeam(dataTeamDraft.team_draft[0], apolloClient, user_id);
      const dataTeamStaff = dataTeamDraft.team_draft[0].team_staff_drafts;
      const dataTeamplace = dataTeamDraft.team_draft[0].team_place_drafts;
      const dataInsertPlaceStaff = await CopyTeamPlaceStaff(apolloClient,dataTeamplace,dataTeamStaff,dataTeamDraft.team_draft[0].team_id);
      if (dataCopy.update_team.affected_rows > 0 && dataCopy.delete_team_draft_by_pk.id) {
        CountCopySuccess++;
      } else {
        CountCopyFail++
      }
    }
    let dataReturn = {
      countFail: dataNotAccess.team_draft.length,
      countSuccess: dataAccess.team_draft.length,
      CountCopySuccess,
      CountCopyFail
    }
    return dataReturn;
  }
  const handleClick = async () => {
    const dataSendEmail = await SendEmailApproveTeamDraftEndUser(apolloClient,selectedIds);
    const data = await ApproveTeam(selectedIds);
    console.log('dataSendEmail',dataSendEmail);
    
    console.log('data', data);
    if (data.countSuccess > 0) {
      //notify(translate('common.msg_approved'))
      notify(`${data.countSuccess}チームが承認されました。, ${data.countFail} チームが承認されませんでした`)
      refresh();
      unselectAll('team_draft');

    } else {
      notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning')
    }
  }

  return <Button
    disabled={isConverting || props.disabled}
    //variant="contained"
    startIcon={<CheckCircle />}
    color="secondary"
    onClick={handleClick}>
    {translate('account.approve')}

  </Button>
}

ButtonApprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonApprove.defaultProps = {
  selectedIds: []
}

export default ButtonApprove;