import * as React from 'react';
import {useState, useContext} from 'react';
import PropTypes from 'prop-types';
import {Field, withTypes} from 'react-final-form';
import Button from '@material-ui/core/Button';
import {createMuiTheme} from '@material-ui/core/styles';
import {CircularProgress, Fade} from '@material-ui/core'
import {ThemeProvider} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {Notification} from 'react-admin';
import {useNotify, useRedirect, useTranslate} from 'ra-core';
import {lightTheme} from '../../themes/themes';
import {renderInput} from '../../utils/renderInput';
import useStyles from './style'
import { useMutation} from "@apollo/client";
import {login_admin_sposuru as LoginResponse, loginVariables} from "../../generated/login";
import {updateUser} from "../../configuration/actions";
import {useDispatch} from "react-redux";
import {signup, signupVariables} from "../../generated/signup";
import {forgotPassword, forgotPasswordVariables} from "../../generated/forgotPassword";
import authProvider from "../../authProvider";
import { useHistory } from "react-router";
import dataProviderContext from "../../dataProviderContext";
import { REGISTER_USER, FORGOT_PASSWORD, LOGIN_USER } from './gql';

interface FormValues {
    email: string;
    password: string;
}

interface FormSignUpValues {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword?: string;
}

interface FormSignUpError {
    firstName?: string;
    lastName?: string;
    confirmPassword?: string;
    email?: string;
    password?: string;
}

interface FormValuesError {
    email?: string;
    password?: string;
}

interface FormForgotValues {
    email: string;
}

interface FormForgotError {
    email?: string;
}


const { Form: LoginForm } = withTypes<FormValues>();
const { Form: ForgotForm } = withTypes<FormForgotValues>();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [isForgot, setIsForgot] = useState(false);
    const [activeTabId, setActiveTabId] = useState(0);
    const [error] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const [login] = useMutation<LoginResponse, loginVariables>(LOGIN_USER);
    const [signUp] = useMutation<signup, signupVariables>(REGISTER_USER);
    const [forgot] = useMutation<forgotPassword, forgotPasswordVariables>(FORGOT_PASSWORD);

    const dispatch = useDispatch();
    const history = useHistory();
    const { setDataProvider } = useContext(dataProviderContext);

    // Handle login request
    const handleSubmit = (auth: FormValues) => {
        setLoading(true);

        login({
            variables: {
                ...auth
            }
        }).then(({data}) => {
            if (data !== null && data !== undefined) {
                const { login_admin_sposuru: userLogin } = data;
                setLoading(false);
                if (!userLogin.error_code) {
                    dispatch(updateUser({id: userLogin.id, email: userLogin.email}));
                    authProvider.login(userLogin).then((ret: any) => {
                        // update dataProvider
                        setDataProvider(null);

                        history.push("/data_account");
                        //window.location.reload()
                        return ret;
                    });
                    // sposuruLogin(userLogin).catch(()=>{
                    //     console.log("Invalid email or password");
                    // });
                } else {
                    notify(translate('login.error_password'), 'warning');
                    //notify(userLogin.error, 'warning');
                }
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    // Handle signUp request
    const handleSignUp = async (values: FormSignUpValues) => {
        delete values.confirmPassword;
        setLoading(true);
        const { data } = await signUp({
            variables: {
                user: {
                    ...values,
                    groupId: 1,
                }
            }
        });
        setLoading(false);
        if (data) {
            if (data.signup.__typename === 'AuthPayload') {
                notify(translate('common.ms_register_success'), 'info');
                setActiveTabId(0);
            } else {
                notify(data.signup.message, 'warning');
            }
        }
    };

    // Handle forgot password api
    const handleForgotPassword = async (values: FormForgotValues) => {
        setLoading(true);
        const { data } = await forgot({
            variables: {
                ...values,
            }
        });
        setLoading(false);
        if (data) {
            if (data.forgotPassword.__typename === 'ActionResult') {
                notify(translate('forgot_password.mgs_recover_password'), 'info');
                setActiveTabId(0);
                setIsForgot(false);
            } else {
                notify(data.forgotPassword.message, 'warning');
            }
        }
    };

    const validate = (values: FormValues) => {
        const errors: FormValuesError = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const validateForgot = (values: FormForgotValues) => {
        const errors: FormForgotError = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        return errors;
    };

    const validateSignUp = (values: FormSignUpValues) => {
        const errors: FormSignUpError = {};
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }else  if (!pattern.test(values.email)) {
            errors.email = translate('common.emailValidate');
          }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }else if (values.password.length < 6) {
            errors.password = translate('common.minLenght');
        }else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = translate('common.msg_confirm_not_match');
        }
        
        return errors;
    };

    return (
        <div className={classes.main}>
            <div
                className={
                    !isForgot
                        ? classes.formContainer
                        : classes.customFormContainer
                }
            >
                <div className={classes.form}>
                    {isForgot ? (
                        <div>
                            <Typography component="h3" className={classes.titleForm}>
                                {translate('forgot_password.title')}
                            </Typography>
                            <Typography
                                component="div"
                                style={{
                                    marginBottom: 20,
                                }}
                            >
                                {translate('forgot_password.description')}
                            </Typography>
                            <ForgotForm
                                onSubmit={handleForgotPassword}
                                validate={validateForgot}
                                render={({ handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            autoFocus
                                            name="email"
                                            // @ts-ignore
                                            component={renderInput}
                                            variant="outlined"
                                            label={translate('login.email_address')}
                                            disabled={loading}
                                            style={{ marginBottom: 35 }}
                                        />

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            disabled={loading}
                                            fullWidth
                                            size="large" 
                                        >
                                            {loading && (
                                                <CircularProgress
                                                    size={25}
                                                    thickness={2}
                                                />
                                            )}
                                            {translate('forgot_password.btn_send')}
                                        </Button>
                                    </form>
                                )}
                            />
                            <Button
                                color="primary"
                                size="large"
                                onClick={() => setIsForgot(!isForgot)}
                                className={classes.forgetButton}
                            >
                                {translate('forgot_password.back_login')}
                            </Button>
                        </div>
                    ) : (
                        <React.Fragment>
                            {activeTabId === 0 && (
                                <React.Fragment>
                                    <Fade
                                        in={error}
                                        style={
                                            !error
                                                ? { display: 'none' }
                                                : { display: 'inline-block' }
                                        }
                                    >
                                        <Typography
                                            color="secondary"
                                            className={classes.errorMessage}
                                            style={{ marginBottom: 35 }}
                                        >
                                            {translate('login.error_password')}
                                        </Typography>
                                    </Fade>
                                    <div className={classes.topBlock}>
                                        <img className={classes.logo} src={"https://d1bakykxa6v7sk.cloudfront.net/logo.png"} alt="Logo"></img>
                                        <Typography component="p" className={classes.appTitle}>
                                            {translate('appTitle')}
                                        </Typography>
                                    </div>
                                    <LoginForm
                                        onSubmit={handleSubmit}
                                        validate={validate}
                                        render={({ handleSubmit }) => (
                                            <form onSubmit={handleSubmit} noValidate>
                                                <Field
                                                    autoFocus
                                                    name="email"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('login.email_address')}
                                                    disabled={loading}
                                                    style={{ marginBottom: 35 }}
                                                />
                                                <Field
                                                    name="password"
                                                    // @ts-ignore
                                                    component={renderInput}
                                                    variant="outlined"
                                                    label={translate('ra.auth.password')}
                                                    type="password"
                                                    disabled={loading}
                                                    style={{ marginBottom: 35 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    disabled={loading}
                                                    fullWidth
                                                    size="large" 
                                                >
                                                    {loading && (
                                                        <CircularProgress
                                                            size={25}
                                                            thickness={2}
                                                        />
                                                    )}
                                                    {translate('login.title_login')}
                                                </Button>
                                            </form>
                                        )}
                                    />
                                    <Button
                                        color="primary"
                                        size="large"
                                        onClick={() => setIsForgot(!isForgot)}
                                        className={classes.forgetButton}
                                    >
                                        {translate('login.forgot_password')}
                                    </Button>
                                </React.Fragment>
                            )}
                            <Notification />
                        </React.Fragment>
                    )
                    }

                </div>
            </div>
        </div>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
