import * as React from 'react';
import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import {
  signedDownloadQuery,
  SignedDownloadVariables
} from '../../types';
import { useApolloClient } from "@apollo/client";
import axios from 'axios';
import moment from 'moment';
import {
  DISAPPROVE_EVENT,
   GET_EVENT_ACCESS_DISAPPROVE, GET_EVENT_NOT_ACCESS_DISAPPROVE
} from './gql';
import 'moment/locale/ja';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import { RemoveCircle } from '@material-ui/icons';
import jwt_decode from "jwt-decode";
import { Confirm } from 'react-admin';


const ButtonDisapprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();

  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataDispprove: number[] = [];
  var dataDispproveSendMail: number[] = [];
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  const DisapproveEvent = async (event_id: number[]) => {
    let { data: dataNotAccess } = await apolloClient.query({
      query: GET_EVENT_NOT_ACCESS_DISAPPROVE,
      variables: {
        list_event_id: event_id
      }
    });
    let { data: dataAccess } = await apolloClient.query({
      query: GET_EVENT_ACCESS_DISAPPROVE,
      variables: {
        list_event_id: event_id
      }
    });
    if (dataAccess.event.length > 0) {
      //setCountSuccess(dataAccess.team.length);
      dataAccess.event.map((x: any) => {
        dataDispprove.push(x.id)
        dataDispproveSendMail.push(x.id);
        // if (x.account_id === 0 || !x.account_id) {
        //   dataDispproveSendMail.push(x.id);
        // }
      })
    } else {
      return {
        countSuccess: 0,
        countFail: dataNotAccess.event.length
      };
    }
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
    const { data } = await apolloClient.mutate({
      mutation: DISAPPROVE_EVENT,
      variables: {
        list_event_id: dataDispprove,
        varified_by: parseInt(user_id)
      }
    });
    let dataReturn = {
      countFail: dataNotAccess.event.length,
      countSuccess: dataAccess.event.length,
    }
    // send mail     
    // const { data: dataSendMail } = await apolloClient.mutate({
    //   mutation: SEND_MAIL_DISAPPROVE_TEAM,
    //   variables: {
    //     list_team: dataDispproveSendMail
    //   }
    // });
    // console.log('dataSendMail', dataSendMail);
    return dataReturn;
  }
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    const data = await DisapproveEvent(selectedIds);    
    if (data.countSuccess > 0) {
      //notify(translate('common.msg_approved'))
      // notify(`${data.countSuccess}非承認にしました`)
      notify(`非承認にしました。`)
      unselectAll('event');
      refresh();
    } else {
      notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning')
      setOpen(false)
      unselectAll('event');

    }
  }
  return (
    <Fragment>
      <Button
        disabled={isConverting || props.disabled}
        //variant="contained"
        startIcon={<RemoveCircle />}
        color="secondary"
        onClick={handleClick}>
        {translate('team.disapprove')}
      </Button>
      <Confirm
        isOpen={open}
        title={translate('team.msg_title_disapprove')}
        content={translate('team.msg_disapprove_message')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        cancel={translate('common.cancel_text_disapprove_team')}
        confirm= {translate('common.confirm_text_disapprove_team')}
      />
    </Fragment>
  )
}

ButtonDisapprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonDisapprove.defaultProps = {
  selectedIds: []
}

export default ButtonDisapprove;