import gql from 'graphql-tag';

export const REGISTER_USER = gql`
    mutation signup($user: UserCreateInput!) {
        signup(userCreateInput: $user) {
            ... on AuthPayload {
                token
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!) {
        forgotPassword(email: $email) {
            ... on ActionResult {
                success
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

export const LOGIN_USER = gql`
    mutation login_admin_sposuru($email: String!, $password: String!) {
        login_admin_sposuru(email: $email, password: $password) {
            email
            id
            token
            tokenExpiry
            error_code
            refreshToken
            status_code
            error_message
            permission_all
            permission_approve
            permission_create_edit
            permission_genre
            permission_payment
            permission_view
        }
    }
`;
