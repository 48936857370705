import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';

const SectionTitle = ({ label,id }: { label: string, id:number }) => {
  const translate = useTranslate();

  if(id){
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  }else{
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  var isEditPage = editForm === true ? true : false;
  if(editForm){
    var {record: {id}} = props;
  }
  return <Container>
    
    <Grid container justify='center' style={{ width: '100%'}} spacing={2}>
    
    <Grid  style={{ width: '50%'}} spacing={2} >
    <Grid  style={{ width: '100%'}} >
    <SectionTitle label={editForm ? "menu.genre.page_edit" : "menu.genre.page_create"} id  = {editForm? id : ''} />
    <Separator />
    </Grid>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'created' && isEditPage === false) {
          return <></>
        }
        return <Grid item xs={12} key={idx}>
          <InputRenderer isEditPage={isEditPage} source='genre' data={d} />
        </Grid>
      })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;