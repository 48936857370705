import { gql } from "@apollo/client";

export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;

export const APPROVE_EVENT = gql`
mutation MyMutation($list_event_id: [Int!], $approved_at: timestamp ) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:2, approved_at: $approved_at}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_EVENT_APPROVE_3 = gql`
query MyQuery($list_event_id: [Int!], $date_now: date) {
  event(where: {valid_from: {_gt: $date_now}, id: {_in: $list_event_id}}) {
    id
    valid_from
  }
}
`;
export const GET_EVENT_APPROVE_4 = gql`
query MyQuery($list_event_id: [Int!] , $date_now: date) {
  event(where: {valid_from: {_lte: $date_now}, id: {_in: $list_event_id}, valid_to: {_gte: $date_now}}) {
    id
    valid_from
  }
}
`;
export const GET_EVENT_APPROVE_ERROR = gql`
query MyQuery($list_event_id: [Int!], $date_now: date) {
  event(where: {id: {_in: $list_event_id}, valid_to: {_lt: $date_now}}) {
    id
    valid_from
  }
}
`;
export const APPROVE_EVENT_WAITING = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const APPROVE_EVENT_OPENNING = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:4}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const APPROVE_EVENT_OUT_OF_DATE = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:0}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_EVENT_NOT_ACCESS_DISAPPROVE = gql`
query Myquery($list_event_id: [Int!]) {
    event(where: {id: {_in: $list_event_id}, status: {_nin: [2]}}) {
      id
    }
}
`;
export const GET_EVENT_ACCESS_DISAPPROVE = gql`
query Myquery($list_event_id: [Int!]) {
    event(where: {id: {_in: $list_event_id}, status: {_in: [2]}}) {
      id
    }
}
`;
export const DISAPPROVE_EVENT = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:6}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_0 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:0}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_1 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:1}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_2 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:2}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_3 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_4 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:4}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_5 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:5}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const RECOVER_EVENT = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {is_soft_deleted:false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_6 = gql`
mutation MyMutation($list_event_id: [Int!],$varified_by: Int) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:6}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GetEventApproveStatus3 = async (apolloClient: any, list_event_id: number[], dateNow: string) => {
  let list_event_id_3 = [];
  let { data: dataEventStatus3 } = await apolloClient.query({
    query: GET_EVENT_APPROVE_3,
    variables: {
      list_event_id: list_event_id,
      date_now: dateNow
    }
  });
  if(dataEventStatus3){
    const dataEvent = dataEventStatus3.event;
    for (let index = 0; index < dataEvent.length; index++) {
      const event = dataEvent[index];
      list_event_id_3.push(event.id);
    }
  }
  return list_event_id_3;
}
export const GetEventApproveStatus4 = async (apolloClient: any, list_event_id: number[], dateNow: string) => {
  let list_event_id_4 = [];
  let { data: dataEventStatus4 } = await apolloClient.query({
    query: GET_EVENT_APPROVE_4,
    variables: {
      list_event_id: list_event_id,
      date_now: dateNow
    }
  });
  if(dataEventStatus4.event.length > 0){
    const dataEvent = dataEventStatus4.event;
    for (let index = 0; index < dataEvent.length; index++) {
      const event = dataEvent[index];
      list_event_id_4.push(event.id);
    }
  }
  return list_event_id_4;
}
export const GetEventApproveError = async (apolloClient: any, list_event_id: number[], dateNow: string) => {
  let list_event_id_error = [];
  let { data: dataEventError } = await apolloClient.query({
    query: GET_EVENT_APPROVE_ERROR,
    variables: {
      list_event_id: list_event_id,
      date_now: dateNow
    }
  });
  if(dataEventError){
    const dataEvent = dataEventError.event;
    for (let index = 0; index < dataEvent.length; index++) {
      const event = dataEvent[index];
      list_event_id_error.push(event.id);
    }
  }
  return list_event_id_error;
}
export const GetAllGenreSport = async (dataProvider: any, genre_id: number) => {
  let data_genre_sport = await dataProvider.getList("genre_sport", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: {genre_id: genre_id},
  });
  let ListData = [];
  const {data} = data_genre_sport;
  for(let item = 0; item < data.length; item++){
    ListData.push({id: data[item].id, name: data[item].secondary_name})
  }
  return ListData;
};