import * as React from "react";
import PropTypes from 'prop-types';
import {
    ReferenceField,
} from 'react-admin';
const CustomFieldName = (props: any) => {
    const { record, event } = props;
    const { record: recordContact } = event;
    if (recordContact.parent_flg) {
        return <span> {record['lastname'] + ' ' + record['firstname']} (保護者) </span> ;
    } else {
        return <></>
    }
}
const CustomReferField = (props: any) => {
    return <> <ReferenceField {...props} link={() => `/user/${props.record.user_id}`}>
        <CustomFieldName event={props} />
    </ReferenceField>
    </>

}

CustomReferField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
    reference: PropTypes.string,
};

export default CustomReferField;