const DATA = [
{ name: 'id', type: 'textinput', searchField: '', options:[], reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'account_id', type: 'reference', reference: 'account',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'post_code', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'pref_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'city_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'address', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'building', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'area_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'search_pref_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'search_city_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'line1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
{ name: 'created_by', type: 'hidden', numberFormat:true,nullable: true, editable: false, export: false, import: true },
]

export default DATA;