import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  SimpleForm,
  useTranslate,
} from 'react-admin';
import EditField from '../components/editField';
import UpdateToolbar from "../components/UpdateToolbarRedirect";
import NMasterFrom from './Form';
import { Tabs, Tab } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';

const EventEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div>

      <TabPanel value={value} index={0}>
        <Edit
          {...props}
          successMessage="common.msg_update_success"
          undoable={false}
          className="formContent"
          title={<EditField titlePage={translate('menu.event_application.edit')} />}>
          <SimpleForm redirect="list" toolbar={<UpdateToolbar redirect_url={'event_application_list'}  />} className="form">
            <FormDataConsumer>
              {(formDataProps: any) => (
                <NMasterFrom {...formDataProps} editForm={true} />
              )}
            </FormDataConsumer>
          </SimpleForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditHistory {...props} />
      </TabPanel>
    </div>
  );
};

export default EventEdit;
