import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  var isEditPage = editForm === true ? true : false;
  return <Container>
    <Grid container justify='center' style={{ width: '100%' }} spacing={2}>

      <Grid style={{ width: '50%' }} container justify='center' spacing={2} >
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.account_admin.edit" : "menu.account_admin.add"} />
          <Separator />
        </Grid>
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }

          return <Grid item xs={12} key={idx}>
            <InputRenderer source='account_admin' data={d} />
          </Grid>

        })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;