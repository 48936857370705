import { gql } from "@apollo/client";
export const FETCH_ALL_TEAM = gql`
query MyQuery {
  team {
    id
    account {
      name
    }
  }
}
`;
export const FETCH_ALL_TEAM_BY_TEAM_CONTACT = gql`
query MyQuery {
  team_contact {
    team {
      name
      account {
        name
        source
      }
    }
    id
  }
}

`;
export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;
export const GET_ALL_TEAM_ACCOUNT = async (apolloClient: any) => {
  let { data: {team_contact} } = await apolloClient.query({
    query: FETCH_ALL_TEAM_BY_TEAM_CONTACT,
    variables: {
    }
  });
  return team_contact;
}