import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
  APPROVE_SCHOOL_GYM, GET_TEAM_NOT_ACCESS_APPROVE, GET_TEAM_ACCESS_APPROVE
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";


const ButtonApprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataApprove: number[] = [];
  var dataApproveSendMail: number[] = [];
  var dataApproveSendMailAdmin: number[] = [];

  //const approved_at = moment().utcOffset('+0900');
  const approved_at = new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"});

  
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  

  const ApproveTeam = async (team_id: number[]) => {
  let {data: dataNotAccess} = await apolloClient.query({
      query: GET_TEAM_NOT_ACCESS_APPROVE,
      variables : {
        list_team_id: team_id
      }
    });
  let {data: dataAccess} = await apolloClient.query({
      query: GET_TEAM_ACCESS_APPROVE,
      variables : {
        list_team_id: team_id
      }
  });
  
  if (dataAccess.team.length > 0) {
    //setCountSuccess(dataAccess.team.length);
  }else{
    return {
      countSuccess: 0, 
      countFail: dataNotAccess.team.length
    };
  }
  //setCountFail(dataNotAccess.team.length);
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
        const {data} = await apolloClient.mutate({
            mutation: APPROVE_SCHOOL_GYM,
            variables : {
              list_team_id: dataApprove,
              varified_by: parseInt(user_id), 
              approved_at: approved_at
            }
        });
        let dataReturn = {
          countFail: dataNotAccess.team.length, 
          countSuccess: dataAccess.team.length, 
        }
        console.log('dataApproveSendMail',dataApproveSendMail);
    //   const {data: dataSendMailAdmin} = await apolloClient.mutate({
    //     mutation: SEND_MAIL_APPROVE_TEAM_BY_ADMIN,
    //     variables : {
    //       list_team: dataApproveSendMailAdmin
    //     }
    // });
      
      //console.log('dataSendMail',dataSendMail);
      //console.log('dataSendMailAdmin',dataSendMailAdmin);
      //console.log('dataApproveSendMailAdmin',dataApproveSendMailAdmin);
      return dataReturn;
  }
  const handleClick = async () => {
        const data = await ApproveTeam(selectedIds);
        
        if(data.countSuccess > 0){
            //notify(translate('common.msg_approved'))
            notify(`${data.countSuccess}チームが承認されました。, ${data.countFail} チームが承認されませんでした`)
            unselectAll('team_list');
            refresh();
        }else{
            notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning')
        }  
}
  


  return <Button
    disabled={isConverting || props.disabled}
    //variant="contained"
    startIcon = {<CheckCircle/>}
    color="inherit"
    onClick={handleClick}>
    {translate('account.approve')}
    
  </Button>
}

ButtonApprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonApprove.defaultProps = {
  selectedIds: []
}

export default ButtonApprove;