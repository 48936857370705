import * as React from 'react';
import {Fragment, useState} from 'react';
import Refresh from '@material-ui/icons/Refresh';
import {
    Button,
    Confirm,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
} from 'react-admin';
import { RecoverMultiAnswer } from './gql';
import { useApolloClient } from "@apollo/client";


const BulkDeleteButtonAction = (props :any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const apolloClient = useApolloClient();
    const translate = useTranslate();
    
    const {
        resource,
    } = useListContext();    
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const recoverMany = async () => {
        const Response = await RecoverMultiAnswer(apolloClient,selectedIds);
        if (Response === 1) {
            refresh();
            notify(translate('common.msg_restore'));
            unselectAll(resource);
        }else if (Response === 0) {
            notify(translate('common.msg_delete_fail'), 'warning')
        }
    }
    const handleConfirm = async () => {
        await recoverMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate('common.btn_recover')} onClick={handleClick} startIcon={<Refresh />} style={{color:'#FF8C00'}}/>
            <Confirm
                isOpen={open}
                //loading={loading}
                title={translate('common.msg_title_recover')}
                content={translate('common.msg_recover_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButtonAction;