import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { GET_ALL_EVENT_ACCOUNT } from '../event_contact/gql';

const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any, apolloClient: any) => {
  var data: any = items
  const dataevent = await GET_ALL_EVENT_ACCOUNT(apolloClient);
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
   data = data.map((item: any) => {
    
    const dataFilter = dataevent.filter((x: any) => x.id === item.event_id);
    let account_name = '';
    if (dataFilter[0]) {
      account_name = (dataFilter[0].account && dataFilter[0].account.name) ? dataFilter[0].account.name : '';
    }
    let dob = new Date(item.birthday);  
    let month_diff = Date.now() - dob.getTime();  
    let age_dt = new Date(month_diff);  
    let year = age_dt.getUTCFullYear(); 
    let age = Math.abs(year - 1970);
    return {
      ...item, 
      account_name: account_name, 
      age:age
    }

  })
  await fetchRelatedRecords(data, 'user_id', 'user').then((user: any) => {
    const data_user = data.map((item: any) => (
      {
        ...item,
        user_id: user[item.user_id] ? user[item.user_id].id : '',
        user_phone: user[item.user_id] ? user[item.user_id].phone : '',
        user_email: user[item.user_id] ? user[item.user_id].email : '',
        user_name: user[item.user_id]? user[item.user_id].lastname +  ' ' +user[item.user_id].firstname : ''

      }
    ));
    data = data_user;
  });
  var fieldName = fields.map((f: any) => f.name)
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }
    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

