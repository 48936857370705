import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { GET_ALL_TEAM_ACCOUNT } from './gql';

const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any, apolloClient: any) => {
  const datateam = await GET_ALL_TEAM_ACCOUNT(apolloClient);
  let dataExport: any = items;
  await fetchRelatedRecords(items, 'user_id', 'user').then((user: any) => {
    const data_user = items.map((item: any) => {
      var dob = new Date(user[item.user_id].birthday);
      var month_diff = Date.now() - dob.getTime();
      var age_dt = new Date(month_diff);
      var year = age_dt.getUTCFullYear();
      var age = Math.abs(year - 1970);
      const dataItem =
      {
        ...item,
        age: age,
        gender: user[item.user_id].gender == 0 ? '不明' : (user[item.user_id].gender == 1 ? '男性' : (user[item.user_id].gender == 2 ? '女性' : '未選択')),
        birthday: user[item.user_id].birthday,
      }
      return dataItem;

    });
    dataExport = data_user;
  });
  let data: any = dataExport.map((item: any) => {

    const dataFilter = datateam.filter((x: any) => x.id === item.team_id);
    let account_name = '';
    let team_name = '';
    if (dataFilter[0]) {
      account_name = (dataFilter[0].account && dataFilter[0].account.name) ? dataFilter[0].account.name : '';
      team_name = dataFilter[0].name
    }
    return {
      ...item, 
      account_name: account_name,
      team_name: team_name
    }

  })
  await fetchRelatedRecords(data, 'user_id', 'user').then((user: any) => {
    const data_user = data.map((item: any) => (
      {
        ...item,
        user_phone: user[item.user_id].phone,
        user_email: user[item.user_id].email,
        user_name: user[item.user_id].lastname +  ' ' +user[item.user_id].firstname, 
        gender: user[item.user_id].gender == 0 ? '不明' : (user[item.user_id].gender == 1 ? '男性' : (user[item.user_id].gender == 2 ? '女性' : '未選択')),
        birthday: user[item.user_id].birthday
      }
    ));
    data = data_user;
  });
 
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.name)
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }
    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};
const GetAge = async (birthday: any) => {
  let dob = new Date(birthday);
  let month_diff = Date.now() - dob.getTime();
  let age_dt = new Date(month_diff);
  let year = age_dt.getUTCFullYear();
  let age = Math.abs(year - 1970);
}

export default exporter;



