import * as React from "react";
import PropTypes from 'prop-types';
import {
  ReferenceField,
} from 'react-admin';

const CustomField = (props: any) => {
  const { record } = props;
  const gender = record['gender'];
  let gender_text = '';
  switch (gender) {
    case 0:
      gender_text = '不明'
      break;
    case 1:
      gender_text = '男性'
      break;
    case 2:
      gender_text = '女性'
      break;
    case 3:
      gender_text = '未選択'
      break;
    default:
      break;
  }
  return <span>{(gender_text)} </span>;
}
const ReferenceFieldGender = (props: any) => {
  return (
    <>
        <CustomField {...props} />
    </>)
}
ReferenceFieldGender.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default ReferenceFieldGender;