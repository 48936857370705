import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';
 
const CustomField = (props: any) => {
  const { record } = props;
  const {searchField} = props
  const data = record[searchField]
  return <span>{(record[searchField])}</span>;
}

const CustomReferField = (props: any) => {     
    const {searchField} = props 
    const {record} =  props;
    if (!record.account_id) {
        return <ReferenceField  {...props} source="created_by" reference="user" link={() => `/user/${props.record.created_by}`}  >
          <CustomField searchField = {searchField} />
      </ReferenceField>
    }else{
        return <ReferenceField  {...props}>
        <ReferenceField  source="created_by" reference="user" link={() => `/account_owner/${props.record.created_by}`}>
          <CustomField searchField = {searchField} />
        </ReferenceField>
      </ReferenceField>
    }
 
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
  searchField: PropTypes.string
};

export default CustomReferField;