import {Client, LoginUser, ThemeName, User} from '../types';

export const CHANGE_THEME = 'CHANGE_THEME';

export const changeTheme = (theme: ThemeName) => ({
    type: CHANGE_THEME,
    payload: theme,
});


export const UPDATE_CLIENT = 'UPDATE_CLIENT';

export const updateClient = (client: Client) => ({
    type: UPDATE_CLIENT,
    payload: client,
});

export const UPDATE_USER = 'UPDATE_USER';

export const updateUser = (user: LoginUser) => ({
    type: UPDATE_USER,
    payload: user,
});