import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    SearchInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import exporter from './exporter';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { STATUS } from './dataset';
import { NumberField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import { NumberInput } from 'react-admin';
import { DateField } from 'react-admin';
import FullNameField from './FullnameField'
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import ReferenceFieldAge from '../event_application/ReferenceFieldAge';
import ReferenceContactType from './ReferenceFieldContactType';
import { SelectInput } from 'react-admin';




const ExportableFields = Fields.filter(d => d.export);

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="lastname,firstname,firstname_kana,lastname_kana,account_name,team_name,note" alwaysOn />
            <SelectInput source="status" label={translate('team_experiencer.status')} choices = {STATUS} alwaysOn />

        </Filter>
    )
};

const CustomDataShow = (props: any) => {
    const { record } = props;
    if (props.type === 'monthly_fee') {
        if (record['monthly_fee_between'] == false) {
            if(!record['monthly_fee_from']){
                return <span> 0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 </span>
        }
        if (record['monthly_fee_between'] == true && !record['monthly_fee_to']) {
            if(!record['monthly_fee_from']){
            return <span>0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 ~ </span>
        }
        if (record['monthly_fee_between'] == true && record['monthly_fee_to']) {
            return <span> {record['monthly_fee_from']} ~ {record['monthly_fee_to']} 円  </span>

        }
    }
    if(props.type === 'fee'){
        const monthly_text = record['monthly_fee'] == 0 ? 'なし' : 'あり'; 
            return <span> {monthly_text}</span>
    }
    return <TextField source={props.type} />
}
const TeamReferenceFieldFee = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <CustomDataShow />
    </ReferenceField>
}
const AccountReferenceField = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <ReferenceField source={'account_id'} reference={'account'} link={false}>
            <TextField source={'name'} />
        </ReferenceField>
    </ReferenceField>
}

const AccountReferenceFieldPhone = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <ReferenceField source={'account_id'} reference={'account'} link={false}>
            <TextField source={'contact_phone'} />
        </ReferenceField>
    </ReferenceField>
}
const AccountReferenceFieldEmail = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <ReferenceField source={'account_id'} reference={'account'} link={false}>
            <TextField source={'contact_email'} />
        </ReferenceField>
    </ReferenceField>
}

const StaffList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const apolloClient = useApolloClient();
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit' : '';
    const module = 'team_experiencer';
    const history = useHistory();
    useEffect(() => {
        history.location.pathname = '/team_experiencer_list';
    }, [props]);
    if (role === undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'team_experiencer', ExportableFields, translate, fetchRelatedRecords, apolloClient)}
            actions={<ListActions to='team_experiencer' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            <CustomizableDatagrid rowClick={(id: any, resource: any, record: any) => `/team_experiencer/${id}`}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput' && name !== 'created') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        if (name == 'lastname' || name === 'firstname') {
                            return <></>
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'reference' && name === 'team_contact_name') {
                        // return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={name} reference={reference}>
                        //     <ReferenceEventContact label={translate(`${module}.${name}`)} source={'user_id'} reference={'user'} />
                        // </ReferenceField>
                        return <FullNameField label={translate(`${module}.${name}`)} source={name} reference={reference} />
                    }
                    
                    if (type === 'deepReference' && name === 'team_name') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'account_name' && name === 'account_name') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'account_source' && name === 'account_source') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if ((type === 'monthly_fee' || type === 'monthly_fee_from' || type === 'monthly_fee_to' || type === 'monthly_fee_between') && name === 'team_contact_id') {
                        return <ReferenceField link={false} label={translate(`team_experiencer.${type}`)} source={name} reference={reference}>
                            <TeamReferenceFieldFee type={type} />
                        </ReferenceField>
                    }
                    if (type === 'contact_type') {
                        return  <ReferenceContactType
                        source={name}
                        label={translate(`${module}.${name}`)}
                    />
                    }
                    if ((type === 'fee') && name === 'team_contact_id') {
                        return <ReferenceField link={false} label={translate(`team.${'monthly_fee'}`)} source={name} reference={reference}>
                            <TeamReferenceFieldFee type={type} />
                        </ReferenceField>
                    }
                   
                    if (type === 'age') {
                        return <ReferenceFieldAge
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'account_name') {
                        return <ReferenceField link={false} label={translate(`${module}.account_name`)} source={'team_contact_id'} reference={'team_contact'}>
                            <AccountReferenceField />
                        </ReferenceField>
                    }

                    if (type === 'reference') {
                        return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={name} reference={reference}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (name === 'created_by_phone') {
                        return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={'created_by'} reference={'user'}>
                            <TextField source={'phone'} />
                        </ReferenceField>

                    }
                    if (name === 'created_by_email') {
                        return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={'created_by'} reference={'user'}>
                            <TextField source={'email'} />
                        </ReferenceField>

                    }
                    if (name === 'account_phone') {
                        return <ReferenceField link={false} label={translate(`${module}.account_phone`)} source={'team_contact_id'} reference={'team_contact'}>
                            <AccountReferenceFieldPhone />
                        </ReferenceField>
                    }
                    if (name === 'account_email') {
                        return <ReferenceField link={false} label={translate(`${module}.account_email`)} source={'team_contact_id'} reference={'team_contact'}>
                            <AccountReferenceFieldEmail />
                        </ReferenceField>
                    }
                    if (type === 'date') {
                        if (name === 'birthday') {
                            return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />

                        }
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default StaffList;
