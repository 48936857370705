import * as React from "react";
import {FC} from "react";
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    email,
    FormTab,
    minLength,
    PasswordInput,
    ReferenceInput,
    required,
    SelectInput,
    TabbedForm,
    TextInput,
    useNotify,
    usePermissions,
    useTranslate,
} from "react-admin";
import {Box, Typography, Container} from "@material-ui/core";
import {CreateComponentProps, PermissionsType} from "../../types";
import CreateToolbar from "../components/CreateToolbar";
import {buildRoleOptions} from "../../utils/Utils";

export const validatePasswords = ({
   password,
   confirm_password,
}: {
   password: string;
   confirm_password: string;
}) => {
   const errors = {} as any;

   if (password && confirm_password && password !== confirm_password) {
      errors.confirm_password = ["common.msg_confirm_not_match"];
   }

   return errors;
};

const UserCreate: FC<CreateComponentProps> = (props) => {
   const translate = useTranslate();
   const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
   const notify = useNotify();
   const transform = (data: any) => {
      delete data.confirm_password;
      return data;
   };

   return (
      <div className="formContent">
         <Container maxWidth="sm">
            <SectionTitle label="user_list.create_new" />
            <Create
               transform={transform}
               onSuccess={() => {
                  notify(`${translate('common.msg_add_success')}`, 'info');
               }}
               title={translate("menu.user.user_add")}
               undoable={false}
               {...props}
            >
               {/* <Container maxWidth="sm"> */}
                  <TabbedForm style={{ width: '100%' }} className="form" validate={validatePasswords}
                     toolbar={<CreateToolbar />}>
                     <FormTab label={translate("user_list.tab_profile")} style={{ width: '50%', maxWidth: 'inherit' }}>

                        <Separator />
                        {role ==="admin" && (
                           <ReferenceInput
                              autoFocus
                              source="client_id"
                              validate={requiredValidate}
                              reference="client"
                              fullWidth
                              variant="outlined"
                              label={translate("bill.client_id")}
                              filterToQuery={(searchText: any) => ({ name: searchText })}
                           >
                              <AutocompleteInput optionText="name" />
                           </ReferenceInput>
                        )}
                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                           }}
                        >
                           <TextInput
                              autoFocus
                              source="last_name"
                              validate={requiredValidate}
                              fullWidth
                              label={translate("user_list.last_name")}
                              variant="outlined"
                           />
                           <TextInput
                              source="first_name"
                              validate={requiredValidate}
                              fullWidth
                              label={translate("user_list.first_name")}
                              variant="outlined"
                              style={{ marginLeft: "10px" }}
                           />
                        </Box>
                        <Box
                           style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                           }}
                        >
                           <TextInput
                              source="last_name_kana"
                              fullWidth
                              label={translate("user_list.last_name_kana")}
                              variant="outlined"
                           />
                           <TextInput
                              source="first_name_kana"
                              fullWidth
                              style={{ marginLeft: "10px" }}
                              label={translate("user_list.first_name_kana")}
                              variant="outlined"
                           />
                        </Box>
                        <TextInput
                           type="email"
                           source="email"
                           validation={{ email: true }}
                           validate={[required(), email()]}
                           fullWidth
                           label={translate("user_list.email")}
                           variant="outlined"
                        />

                        <ReferenceInput
                           source="group_id"
                           reference="user_group"
                           validate={requiredValidate}
                           fullWidth
                           variant="outlined"
                           label={translate("user_list.group")}
                        >
                           <SelectInput source="name" />
                        </ReferenceInput>
                        <TextInput
                           source="title"
                           resource="users"
                           fullWidth
                           variant="outlined"
                           label={translate("user_list.title")}
                           options={{autoComplete: "new-password"}}
                        />
                        {(role ==='admin' || role ==='client_admin') && (
                           <SelectInput
                              source="role"
                              label={translate('role.title')}
                              fullWidth
                              validate={[required()]}
                              variant="outlined"
                              choices={buildRoleOptions(permissions, translate)}
                           />)}
                        <SelectInput
                           source="status"
                           label={translate("common.status")}
                           fullWidth
                           variant="outlined"
                           choices={[
                              { id: "0", name: translate("common.status_inactive") },
                              { id: "1", name: translate("common.status_active") },
                           ]}
                        />
                        {(role ==="admin" || role ==="client_admin") && (
                           <Box style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              width: "100%",
                           }}>
                              <BooleanInput
                                 label={translate("user_list.email_verified")}
                                 source="email_verified"
                              />
                           </Box>
                        )}
                        {role ==="admin" && (
                           <Box display={{ md: "flex", lg: "flex" }}>
                              <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                                 <BooleanInput
                                    label={translate("user_list.is_admin")}
                                    source="is_admin"
                                 />
                              </Box>
                           </Box>
                        )}
                     </FormTab>
                     <FormTab label={translate("user_list.tab_password")} path="password" style={{ width: '50%', maxWidth: 'inherit' }}>
                        <Separator />
                        {(role ==="admin" || role ==="client_admin") &&
                           <PasswordInput
                              source="password"
                              fullWidth
                              validate={[required(), minLength(6)]}
                              variant="outlined"
                              label={translate("user_list.password")}
                              inputProps={{ autoComplete: 'newsss_password' }}
                           />}
                        <PasswordInput
                           source="confirm_password"
                           fullWidth
                           validate={[required(), minLength(6)]}
                           variant="outlined"
                           label={translate("user_list.confirm_password")}
                           inputProps={{ autoComplete: 'confirm_password' }}
                        />
                     </FormTab>
                  </TabbedForm>
            </Create>
         </Container>
      </div>
   );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
   const translate = useTranslate();

   return (
      <Typography variant="h6" gutterBottom style={{ paddingTop: '50px', maxWidth: '600px', margin: 'auto' }}>
         {translate(label)}
      </Typography>
   );
};

const Separator = () => <Box pt="3em" />;

export default UserCreate;
