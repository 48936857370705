import PersonIcon from '@material-ui/icons/Person';

import List from './TeamList';
import Create from './TeamCreate';
import Edit from './TeamEdit';

export default {
    list: List,
    create: Create,
    edit: Edit,
    icon: PersonIcon,
};
