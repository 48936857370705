import * as React from 'react';
import {FC, Fragment, useEffect} from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps, PermissionsType} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import { ChipField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import StatusField from './StatusField';
import {STATUS} from './dataset'
import ButtonApprove from './ButtonApprove';
import ButtonDisapprove from './ButtonDisapprove';
import ButtonStatus0 from './ButtonStatus0';
import ButtonStatus1 from './ButtonStatus1';
import ButtonStatus2 from './ButtonStatus2';
import ButtonStatus5 from './ButtonStatus5';
import ButtonStatus3 from './ButtonStatus3';
import ButtonStatus4 from './ButtonStatus4';
import ButtonStatus6 from './ButtonStatus6';



import exporter from './exporter';
import { useHistory } from 'react-router';


const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    const {filterValues: {status}} = props as any
    return (
        <Fragment>
            {(status !== 0 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus0 {...props}/>}
            {(status !== 1 && (permission?.permission_approve  === true || permission?.permission_all  === true))&&  <ButtonStatus1 {...props}/>}
            {(status !== 2 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus2 {...props}/>}
            {(status !== 3 && status !== 2 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus3 {...props}/>}
            {(status !== 4 && status !== 2 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus4 {...props}/>}
            {(status !== 5 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus5 {...props}/>}
            {(status !== 6 && status !== 2 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonStatus6 {...props}/>}
            
            {(status === 2 && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonApprove {...props}/>}
            {((status === 2 || status === undefined) && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonDisapprove {...props}/>}
            {(role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="note,title,sub_title,video_url,access,capacity,capacity_note,description,point,covid_note,title1,body1,title2,body2,title3,body3,title4,body4,organizer_name,organizer_pref,organizer_city,organizer_address1,organizer_address2,additional_note,application_method,application_method_note,is_free,admission_fee,payment_method,fee_note,cancel_policy,data1_url,data2_url,data3_url,schedule_time,organizer_post_code,comment,disability,ladies,genre_other,genre_sport_other" alwaysOn />
            <SelectInput choices={STATUS} source="status" label={translate('event.status')} alwaysOn/>
        </Filter>
    )
};
const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'event';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/event';
    },[props]);
    return (
        <List
            {...props}
            filters={<TeamFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            filterDefaultValues={{ status: 2, is_soft_deleted: false}}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'event', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='team' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            <CustomizableDatagrid rowClick={"edit"}>
        
            {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference' && reference == 'account') {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} link={false} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                    }
                    if (type === 'date' || type === 'datetime') {
                        if(name === 'valid_from' || name === 'valid_to' || name === 'date_from' || name === 'date_to'){
                            return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                        }
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        // return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                        return <></>
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                            return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status' && name === 'status') {
                        return <StatusField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'status' && name === 'attention') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default UserList;
