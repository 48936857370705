import { TranslationMessages } from 'ra-core';
import japaneseMessages from '@bicstone/ra-language-japanese';

const customJapanMessages: TranslationMessages = {
    ...japaneseMessages,
    app_name: "スポスル",
    appTitle: "運営管理ログイン",
    menu: {
        mainMenuInvoice: "メニュー",
        user: {
            user_menu: "ユーザー",
            user_list: "一覧",
            user_add: "追加",
            user_import: 'インポート',
            appbar_title: '',
            page_create: 'ユーザー新規追加',
            page_edit: ''
        },
        genre: {
            genre_menu: "ジャンル管理",
            genre_list: "一覧",
            genre_add: "追加",
            genre_import: 'インポート',
            page_create: 'ジャンル新規登録',
            page_edit: 'ジャンル'
        },
        team: {
            team_menu: "チーム管理",
            team_list: "チーム一覧",
            team_add: "チームの追加",
            team_edit: "チームの編集",
            edit_page: 'チーム'

        },
        account: {
            account_menu: "団体管理",
            account_list: "一覧",
            account_add_new: "追加",
            account_edit: "編集",
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: '団体'
        },
        admin: {
            admin_menu: "システム管理者",
            admin_list: "一覧",
            admin_add_new: "追加",
            admin_edit: "編集"
        },
        settings: {
            setting_client: '設定',
            change_password: 'パスワード変更',
            profile: "プロファイル"
        },
        email_template: {
            email_template_menu: "テンプレートメニュー",
            email_template_list: "一覧",
            email_template_add: "追加",
            email_email_edit: "編集",
            create_new: 'テンプレートメニュー新規登録',
            page_edit: 'テンプレートメニュー'
        },
        event: {
            event_menu: "イベント情報",
            event_list: "イベント情報一覧",
            event_add: "追加",
            event_edit: "編集",
            create_new: 'イベント情報新規登録',
            page_edit: 'イベント情報'
        },
        event_place: {
            event_place_menu: "イベント会場",
            event_place_list: "イベント会場一覧",
            event_place_add: "追加",
            event_place_edit: "編集",
            page_create: 'イイベント会場新規登録',
            page_edit: 'イベント会場'
        },
        event_plan: {
            event_plan_menu: "イベントプラン",
            event_plan_list: "イベントプラン一覧",
            event_plan_add: "追加",
            event_plan_edit: "編集",
            page_create: 'イベントプラン新規登録',
            page_edit: 'イベントプラン'
        },
        sposuru_news: {
            menu: "スポスルからのお知らせ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            appbar_title_create: '',
            appbar_title_edit: 'スポスルからのお知らせ新規追加',
            page_create: 'スポスルからのお知らせ',
            page_edit: 'スポスルからのお知らせ'
        },
        question: {
            menu: "質問データ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '質問データ',
            edit_page: '質問データ'
        },
        genre_sport: {
            menu: "ジャンルスポーツ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            page_create: 'ジャンルスポーツ',
            page_edit: 'ジャンルスポーツ'
        },
        answer: {
            menu: "回答データ",
            list: "一覧",
            add: "追加",
            edit: "編集"
        },
        character: {
            menu: "特徴",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '特徴新規登録',
            edit_page: '特徴編集'
        },
        team_review: {
            menu: "口コミデータ",
            list: "一覧",
            add: "追加",
            edit: "編集", 
            add_page: '口コミデータ',
            edit_page: '口コミデータ'
        },
        contact: {
            menu: "問い合わせデータ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '問い合わせ新規登録',
            edit_page: '問い合わせ'
        },
        point_type: {
            menu: "ポイント設定",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: 'ポイント設定せ新規登録',
            edit_page: 'ポイント設定'
        },
        user_point: {
            menu: "ユーザーポイント",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: 'ユーザーポイント新規登録',
            edit_page: 'ユーザーポイント'
        },
        point_exchange: {
            menu: "ポイント交換商品",
            list: "一覧",
            add: "追加",
            edit: "編集",
            page_create: 'ポイント交換商品新規登録',
            page_edit: 'ポイント交換商品'
        },
        team_staff: {
            menu: "",
            list: "スタッフ一覧",
            add: "スタッフの追加",
            edit: "スタッフの編集",
            edit_page: 'スタッフ', 
            add_page: 'スタッフ', 
        },
        account_place: {
            menu: "",
            list: "練習場所一覧",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        team_place: {
            menu: "",
            list: "練習場所一覧",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
    },
    login: {
        title_login: "ログイン",
        title_new_user: "新規ユーザー",
        forgot_password: "パスワードを忘れた場合",
        full_name: "フルネーム",
        email_address: "メールアドレス",
        password: "パスワード",
        create_acount: "アカウントを追加する",
        error_password: "ログイン情報かパスワードに誤りがあります :(",
        success_login: "ログインできました"
    },
    forgot_password: {
        title: "パスワードを忘れた場合",
        description: "認証コードを受け取るために、下記の連絡先メールアドレスを入力してください",
        email: "メールアドレス",
        back_login: "ログインへ",
        btn_send: "送信する",
        mgs_recover_password: "パスワードを忘れました。次のステップについては、メールを確認してください",
        mgs_recover_success: 'パスワードの変更に成功しました。 ',
    },
    activeUser: {
        title: "有効ユーザー",
        success: "アカウントを有効化しました。",
        error: "このユーザーを有効にしているときにエラーが発生しました。管理者に連絡してください",
        btn_login: "ログインへ"
    },
    reset: {
        success: "パスワードをリセットしました。",
        error: "パスワードのリセットに失敗しました。",
        password: "パスワード",
        reset: "パスワードを再設定する",
        back_to_login: "ログインに戻る",
        confirm_password: "パスワード（再確認用）"
    },
    copyright: "© 2021 Sposuru",
    user_list: {
        id: "ID",
        firstname: "名",
        lastname: "姓",
        firstname_kana: "メイ",
        lastname_kana: "セイ",
        post_code: '郵便番号',
        pref: '都道府県',
        city: '市区町村',
        address: '住所',
        phone: '電話番号',
        email: "メールアドレス",
        password: "パスワード",
        confirm_password: 'パスワード（確認）',
        image_url: 'プロフィール画像',
        nickname: 'ニックネーム',
        birthday: '生年月日',
        gender: "性別",
        status: 'ステータス',
        hash: 'メールアドレス確認キー',
        varified: 'メールアドレス確認日',
        created: '作成日',
        created_by: '作成者',
        note: '備考',
        update_user_title: 'ユーザーの編集',
        tab_profile: 'プロフィール',
        tab_password: 'パスワード',
        create_new: '新規登録',
    },
    genre: {
        id: "ID",
        name: "大ジャンル",
        created: "作成日",
        created_by: "作成者",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        genre_add: "追加",
        mobile_image_url: "モバイル用",
        english_name: "英語名"
    },
    genre_sport: {
        id: "ID",
        secondary_name: "中ジャンル",
        genre_id: "大ジャンル",
        created: "作成日",
        created_by: "作成者",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        genre_add: "追加",
        mobile_image_url: "モバイル用",
        secondary_english_name: "英語名"
    },
    team: {
        id: "ID",
        name: "チーム・スクール名",
        name_kana: "チーム名フリガナ",
        account_id: "団体",
        president_name: "代表者責任者名",
        philosophy: "チーム理念・指導方針",
        achievements: "主な過去の実績",
        logo_url: "チームロゴ",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像2",
        image7_url: "画像3",
        video_url: "動画あり(TOPページ用）",
        category: "カテゴリ",
        sub_category: "サブカテゴリ",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        age: "対象年代",
        practice_days: "練習曜日",
        practice_datetime: "練習日・練習時間",
        style: "スタイル",
        trial_type: "体験",
        visit_ok: "見学OKフラグ",
        trial_description: "体験・募集について",
        enrolled: "在籍人数",
        for_parents: "保護者の方にお願いする事",
        admission_fee: "入会金",
        annual_fee: "年会費",
        monthly_fee: "月謝あり・なし",
        monthly_fee_from: "月謝：下限",
        monthly_fee_to: "月謝：上限",
        things_to_prepare: "入会後ご用意いただきたいもの",
        fee_description: "費用に関する説明",
        initial_fee: "入会時に掛かる初期費用の目安",
        gear: "初回購入品と金額のJSON配列",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        keyword: "",
        attention: "おすすめフラグ",
        publish_status: "掲載ステータス",
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        disability: "障害者歓迎",
        ladies: "レディースのみ",
        team_tab_1: '初期設定情報',
        team_tab_2: '基本情報',
        team_tab_3: 'チーム・スクールの画像・特徴',
        team_tab_4: '特徴',
        team_tab_5: '募集・練習体験・セレクション',
        team_tab_6: '費用について',
        appeal_title: 'アピールタイトル',
        appeal_body: 'アピール本文',
        keyword1: 'タグ種別１',
        keyword2: 'タグ種別２',
        keyword3: 'タグ種別３',
        keyword4: 'タグ種別４',
        enroll_description: '在籍人数の詳細',
        placeholder_style_description_1: '例：だらだらと練習はしません。そのため、練習が厳しく感じるかもしれませんが、 楽しむことが大前提という意識で指導しております。',
        placeholder_style_description_2: '例：日数が少ない分、1日の練習量を多めに確保しております。',
        placeholder_style_description_3: '例：練習試合においては全選手、出場機会があるように心がけておりますが、 公式戦は勝ちにこだわるためシビアに選手選考を行います。',
        placeholder_style_description_4: '例：20代～50代が在籍しており、年齢層は幅広いです。',
        placeholder_style_description_5: '例：基本技術はとても大事にしておりますが、どちらかと言えば戦術重視のチームと 言えます。',
        placeholder_style_description_6: '例：男女比率はほぼ均等で、試合も男女混合で臨んでいます。',
        placeholder_style_description_7: '例：勝ち負けよりも、いかに楽しむかが大切です。小学生のうちは勝つことはそこまで 重要でないと考えております。',
        placeholder_style_description_8: '例：入会する前は、ほとんどの方が未経験です。ですので、未経験の方も安心して ください。',
        style_choice_description_1: '説明',
        style_choice_description_2: '説明',
        style_choice_description_3: '説明',
        style_choice_description_4: '説明',
        style_choice_description_5: '説明',
        style_choice_description_6: '説明',
        style_choice_description_7: '説明',
        style_choice_description_8: '説明',
        age_1: '',
        age_2: '',
        age_3: '',
        age_4: '',
        age_5: '',
        age_6: '',
        age_7: '',
        age_8: '',
        age_9: '',
        age_10: '',
        age_11: '',
        age_12: '',
        age_open_1: '',
        age_open_2: '',
        age_open_3: '',
        age_open_4: '',
        age_open_5: '',
        age_open_6: '',
        age_open_7: '',
        age_open_8: '',
        age_open_9: '',
        age_open_10: '',
        age_open_11: '',
        age_open_12: '',
        radio_value_1: '額',
        radio_value_2: '額',
        radio_value_3_from: 'からの量',
        radio_value_3_to: '匹敵する',
        gear_name_1: '初期購入品1',
        gear_name_2: '初期購入品2',
        gear_name_3: '初期購入品3',
        gear_name_4: '初期購入品4',
        gear_name_5: '初期購入品5',
        gear_name_6: '初期購入品6',
        gear_name_7: '初期購入品7',
        gear_name_8: '初期購入品8',
        gear_name_9: '初期購入品9',
        gear_name_10: '初期購入品10',
        gear_price_1: '金額1',
        gear_price_2: '金額2',
        gear_price_3: '金額3',
        gear_price_4: '金額4',
        gear_price_5: '金額5',
        gear_price_6: '金額6',
        gear_price_7: '金額7',
        gear_price_8: '金額8',
        gear_price_9: '金額9',
        gear_price_10: '金額10'
    },
    account: {
        id: "ID",
        name: "企業名/団体名",
        name_kana: "企業名/団体名フリガナ",
        representative_first_name: "代表者名",
        representative_last_name: "代表者姓",
        representative_firstname_kana: "代表者名フリガナ",
        representative_lastname_kana: "代表者姓フリガナ",
        contact_first_name: "担当者名",
        contact_last_name: "担当者姓",
        contact_firstname_kana: "担当者名フリガナ",
        contact_lastname_kana: "担当者姓フリガナ",
        contact_email: "Eメールアドレス",
        post_code: "郵便番号",
        pref: "都道府県",
        city: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        contact_phone: "連絡先電話番号",
        business_contents: "事業内容",
        affiliation_association: "所属協会",
        affiliation_association_kana: "所属協会フリガナ",
        sports: "取り扱いスポーツ",
        status: "ステータス",
        varified: "承認日",
        varified_by: "承認者",
        created: "作成日",
        created_by: "作成者",
        note: "備考",
        create_new: '新規登録',
        approve: '承認する'
    },
    admin: {
        id: "ID",
        email: "Eメールアドレス",
        status: 'ステータス',
        created: '作成日',
        created_by: '作成者'
    },
    event: {
        id: "イベントID",
        account_id: "アカウント",
        attention: "注目",
        video_url: "動画あり(TOPページ用）",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像6",
        image7_url: "画像7",
        image8_url: "画像8",
        image9_url: "画像9",
        access: "アクセスに関する備考",
        capacity: "定員数",
        capacity_note: "定員数備考",
        is_group: "参加形式",
        age_group: "対象年代",
        disability: "障害者歓迎フラグ",
        ladies: "レディースのみ",
        genre_id: "スポーツジャンル",
        genre_sport_id: "スポーツジャンル＞スポーツ",
        title: "イベントタイトル",
        sub_title: "イベントサブタイトル",
        schedule_time: "イベント時間",
        description: "イベント説明",
        point: "イベントのポイント",
        covid_note: "コロナ対策情報",
        image10_url: "画像10",
        title1: "タイトル",
        body1: "本文",
        image11_url: "画像11",
        title2: "タイトル",
        body2: "本文",
        image12_url: "画像12",
        title3: "タイトル",
        body3: "本文",
        image13_url: "画像13",
        title4: "タイトル",
        body4: "本文",
        organizer_name: "主催者名",
        organizer_post_code: "主催者郵便番号",
        organizer_pref: "主催者住所都道府県",
        organizer_city: "主催者住所市区町村",
        organizer_address1: "主催者住所市区町村以下",
        organizer_address2: "主催者住所住所その他",
        additional_note: "参加者など",
        comment: "イベント担当者からのコメント",
        application_method: "お申し込み方法",
        application_method_note: "お申し込み方法備考",
        is_free: "無料",
        admission_fee: "参加費用金額",
        payment_method: "お支払い方法等",
        fee_note: "費用に関する情報",
        cancel_policy: "キャンセルの場合の情報",
        data1_url: "イベントチラシデータアップロード1",
        data2_url: "イベントチラシデータアップロード2",
        data3_url: "イベントチラシデータアップロード3",
        accept_contact: "受け付けない",
        valid_from: "公開期間設定 開始日",
        valid_to: "公開期間設定 終了日",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        type: "イベント種別",
        level: "レベル・経験値",
        preview_key: "UUID",
        preview: "下書き用のデータ",
        event_tab_1: '初期設定情報',
        event_tab_2: 'イベント情報',
        event_tab_3: 'イベントのポイント',
        event_tab_4: '参加費用・要項',
        event_tab_5: '主催者情報',
        event_tab_6: '注意事項など',
    },
    sposuru_news: {
        id: "ID",
        title: "題名",
        content: '本文',
        publish: '公開',
        note: '備考',
        created: '作成日',
        created_by: '作成者'
    },
    common: {
        export: 'エクスポート',
        btn_update: '保存する',
        btn_cancel: 'キャンセル',
        btn_save: '保存',
        status: 'ステータス',
        msg_confirm_not_match: 'マッサージが一致しないことを確認する',
        unknow: '不明',
        male: '男性',
        female: '女性',
        gender: '性別',
        btn_create: '登録する',
        btn_add: "追加",
        msg_delete: 'アイテムが正常に削除されました',
        msg_delete_user: "アイテムの削除は成功しました",
        btn_disagree: "同意しない",
        btn_agree: "同意する",
        btn_delete: '削除する',
        lbl_search: "検索",
        msg_add_success: "アイテムの追加に成功しました",
        msg_update_success: "更新しました。",
        msg_no_record: "指定された検索条件に一致する結果は見つかりませんでした",
        ms_active_error: "このリンクは有効化されました、または期限が切れています。しばらくしてからもう一度お試しください。",
        ms_register_success: "このアカウントは正常に登録されました。アカウントを有効にするために、メールをご確認ください",
        ms_active_success: "このアカウントは正常に有効化されました。ログインボタンをクリックしてシステムにログインしてください",
        msg_login_failed: "メールアドレスまたはパスワードが正しくありません。",
        configuration: 'アプリケーション設定',
        configClient: 'クライアント設定',
        theme_light: 'Light',
        theme_dark: 'Dark',
        language: '言語',
        theme: 'テーマ',
        minute: "分",
        hours: "時間",
        required: "required",
        msg_delete_confirm: '削除してもよろしいですか。',
        msg_delete_fail: '削除されませんでした',
        msg_title_delete: '削除の確認',
        status_active: "有効",
        status_inactive: "無効",
        could_not: "作成できませんでした：",
        no_record: "レコードがありません。",
        import: "新しいレコードを追加するか。またはファイルからインポートしてください。",
        btn_import: "インポート",
        minLenght: '6文字以上である必要があります',
        emailValidate: "メールアドレスである必要があります",
        btn_ok: 'OK',
        created: '登録日',
        created_by: '登録者',
        actions: '操作',
        edit_history: '修正履歴',
        generate_pdf: 'ファイル生成',
        new_registration: '新規登録',
        batch_update_confirm_title: '確認',
        batch_update_confirm_content: '件のレコードが更新されます。',
        batch_update_confirmation: '実行してもよろしいですか。',
        msg_approved: '承認しました。',
        edit: '編集',
        draft: '未掲載',
        waiting_approve: '申請中',
        approved: '掲載中',
        end_event: '終了',
        draft_event: '作成中',
        waiting_approve_event: '公開待ち',
        approved_event: '公開中',
        pending_event: '受付停止中',
        imageUploadPlaceholder: '画像を選択してください。', 
        msg_delete_permanently: 'アイテムが正常に処理されました',

    },
    changePassword: {
        title: 'パスワード変更',
        error: 'パスワード変更エラー',
        oldPassword: '現在のパスワード',
        new_password: '新しいパスワード',
        confirm_password: '新しいパスワードの確認',
        btn_change_password: '変更する',
        msg_complete: "パスワードの変更に成功しました。 ログアウトして新しいパスワードを使用してください"
    },
    email_template: {
        id: "ID",
        action_key: "アクションキー",
        name: "名称",
        sendgrid_template_id: "sendgridのテンプレートID",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        title_add: "追加",
        body: "コンテンツ",
        params: "パラメータ",
        subject: '主題',
        virtual_body: '仮想体'

    },
    question: {
        id: "ID",
        user_id: "ユーザーID",
        category: "投稿カテゴリ",
        title: "タイトル",
        question: "内容",
        approved: "承認日",
        approved_by: "承認者ID",
        note: "備考",
        violation: "違反報告",
        nickname: "ニックネーム",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        created: "作成日",
        created_by: "作成者ID",

    },
    answer: {
        id: "ID",
        question_id: "Question",
        answer: "回答本文",
        answered_by: "回答ユーザID",
        answered_nickname: "回答ユーザのニックネーム",
        note: "備考",
        good: "参考になった",
        violation: "違反報告",
        created: "作成日",
        created_by: "作成者ID",

    },
    character: {
        id: "特徴",
        type: "種別",
        name: "名前",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        created: "作成日",
        created_by: "作成者ID",
        mobile_image_url: "モバイル用"

    },
    team_review: {
        id: "レビューID",
        user_id: "ユーザーID",
        team_id: "チームID",
        user_status: "所属・体験ステータス",
        score_1: "評価1",
        score_2: "評価2",
        score_3: "評価3",
        score_4: "評価4",
        score_5: "評価5",
        score_6: "評価6",
        title: "クチコミタイトル",
        review: "クチコミテキスト",
        status: "管理ステータス",
        note: "備考",
        created: "作成日",
        created_by: "作成者ID",

    },
    contact: {
        id: "問合せID",
        category: "お問い合わせのカテゴリー",
        contact: "問合せ内容",
        lastname: "姓",
        firstname: "名",
        phone: "連絡先電話番号",
        email: "メールアドレス",
        note: "備考",
        user_id: "user_id",
        contact_user: "担当者",
        company_name: "会社名",
        corporate_name: "団体名",
        created: "作成日"

    },
    point_type: {
        id: "ポイントID",
        type: "ポイント種別",
        amount: "獲得できるポイント数",
        created: "作成日",
        created_by: "作成者",
        note: '備考'
    },
    user_point: {
        id: "ID",
        user_id: "ユーザー",
        point_type_id: "ポイント種別",
        point_received: "ポイントゲット日時",
        amount: "ポイント数",
        created: "作成日",
        created_by: "作成者ID",
        user: 'ユーザー',
        point_exchange_id: '利用ポイント',
        point_exchange_status: '利用ステータス',
        note: '備考',

    },
    point_exchange: {
        id: "ID",
        type: "交換カテゴリ",
        image_url: "商品画像",
        title: "商品名",
        description: "商品説明文",
        amount: "交換ポイント",
        status: "ステータス",
        note: "備考",
        created: "作成日",
        created_by: "作成者ID",
        item_name: '商品名'
    },
    event_place: {
        id: "ID",
        event_id: "イベント",
        date_from: "開催日(開始)",
        date_to: "開催日(終了)",
        time: "時間",
        time_description: "時間詳細",
        name: "開催地名称",
        area: "開催地エリア",
        area_name: "エリア名",
        pref: "都道府県",
        pref_name: "都道府県名",
        city: "市区町村",
        city_name: "市区町村名",
        venue_post_code: "開催地郵便番号",
        venue_pref: "開催地都道府県",
        venue_city: "開催地市区町村",
        venue_address1: "開催地市区町村以下",
        venue_address2: "開催地住所その他",
        venue_line_cd1: "開催地最寄り駅1：沿線",
        venue_line1: "venue_line1",
        venue_station_cd1: "開催地最寄り駅1：駅",
        venue_station1: "venue_station1",
        venue_walk1: "開催地最寄り駅1：徒歩分数",
        venue_line_cd2: "開催地最寄り駅2：沿線",
        venue_line2: "venue_line2",
        venue_station_cd2: "開催地最寄り駅2：駅",
        venue_station2: "venue_station2",
        venue_walk2: "開催地最寄り駅2：徒歩分数",
        venue_line_cd3: "開催地最寄り駅3：沿線",
        venue_line3: "開催地最寄り駅3：沿線",
        venue_station_cd3: "開催地最寄り駅3：駅",
        venue_station3: "venue_station3",
        venue_walk3: "開催地最寄り駅3：徒歩分数",
        created: "作成日",
        created_by: "作成者",
        note: "備考",
        gen_code_button: "住所を自動入力"
    },
    event_plan: {
        id: "ID",
        event_id: "イベント",
        name: "プラン名",
        description: "説明",
        price: "金額",
        applicants: "募集人数",
        note: "備考",
        created_by: "作成者ID",
        created: "作成日"

    },
    team_staff: {
        id: "ID",
        user_id: "ユーザーID",
        team_id: "チーム",
        image_url: "プロフィール画像",
        name: "名前",
        email: "メールアドレス",
        title: "役職",
        comment: "コメント",
        note: "備考",
        csv_fields: {
            id: "ID",
            user_id: "ユーザーID",
            team_id: "チーム",
            image_url: "プロフィール画像",
            name: "名前",
            email: "メールアドレス",
            title: "役職",
            comment: "コメント",
            note: "備考",
            created_by: "作成者ID",
            created: "作成日"
        }
    },
    account_place: {
        id: "ID",
        account_id: "団体名",
        team_id: "チーム",
        name: "練習場所名称",
        post_code: "郵便番号",
        pref_name: "都道府県",
        city_name: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        area: "エリア＞都道府県＞市区町村",
        area_name: "",
        pref: "エリア＞都道府県＞市区町村",
        search_pref_name: "",
        city: "エリア＞都道府県＞市区町村",
        search_city_name: "",
        line1: "路線名",
        line_cd1: "路線コード",
        station1: "駅名",
        station_cd1: "駅コード",
        walk1: "徒歩",
        line2: "路線名",
        line_cd2: "路線コード",
        station2: "駅名",
        station_cd2: "駅コード",
        walk2: "徒歩",
        line3: "路線名",
        line_cd3: "路線コード",
        station3: "駅名",
        station_cd3: "駅コード",
        walk3: "徒歩",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        csv_fields: {
            id: "ID",
            account_id: "団体ID",
            team_id: "チームID",
            name: "練習場所名称",
            post_code: "郵便番号",
            pref_name: "都道府県",
            city_name: "市区町村",
            address: "住所",
            building: "建物名・部屋番号",
            area: "エリア＞都道府県＞市区町村",
            area_name: "",
            pref: "エリア＞都道府県＞市区町村",
            search_pref_name: "",
            city: "エリア＞都道府県＞市区町村",
            search_city_name: "",
            line1: "路線名",
            line_cd1: "路線コード",
            station1: "駅名",
            station_cd1: "駅コード",
            walk1: "徒歩",
            line2: "路線名",
            line_cd2: "路線コード",
            station2: "駅名",
            station_cd2: "駅コード",
            walk2: "徒歩",
            line3: "路線名",
            line_cd3: "路線コード",
            station3: "駅名",
            station_cd3: "駅コード",
            walk3: "徒歩",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
        }
    }
};

export default customJapanMessages;
