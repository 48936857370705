import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';

const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any) => {
  var data: any = items
  await fetchRelatedRecords(items, 'team_id', 'team').then((team: any) => {
    const data_team = items.map((item: any) => (
      {
        ...item,
        team_id: team[item.team_id].name,
      }
    ));
    data = data_team;
  });
  await fetchRelatedRecords(data, 'user_id', 'user').then((user: any) => {
    const data_user = data.map((item: any) => (
      {
        ...item,
        lastname: user[item.user_id].lastname,
        firstname: user[item.user_id].firstname,

      }
    ));
    data = data_user;
  });
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.export_name)
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }
    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

