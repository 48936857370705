
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],reference: '', nullable: false, editable: false, export: true },
  { name: 'event_id',  type: 'reference',reference: 'event',searchField: 'title', nullable: false, export: true },
  { name: 'name', type: 'textinput', nullable: false, editable: true, export: true },
  { name: 'description', type: 'textinput',multiline: true, nullable: false, editable: true, export: true },
  { name: 'price', type: 'textinput',numberFormat: true, nullable: false, editable: true, export: true },
  { name: 'applicants', type: 'textinput',numberFormat: true, nullable: true, editable: true, export: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true },
  { name: 'note', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },
]
export default DATA;