import * as React from 'react';
import { FC, Fragment,useEffect,useState } from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    usePermissions,
    useTranslate,
} from 'react-admin';

import ListActions from '../components/actions';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { PUBLISH_STATUS, ACCOUNT_SEARCH, IS_SOFT_DELETED } from './dataset';
import { GET_SHOP } from './gql';

import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import StatusField from './StatusField';
// import ButtonApprove from './ButtonApprove';
// import ButtonDisapprove from './ButtonDisapprove';
import BulkDeleteButton from './actionsBulkDelete';


import ChipFieldJsonb from '../components/ChipFieldJsonb';
import UserReferenceField from './ReferenceFieldUser'
import exporter from './exporter';
import AccountCreateField from './AccountCreateField';
import { } from 'react-admin';
import { Box, Button, CircularProgress, FormControl, Paper } from '@material-ui/core';
import { ReferenceField } from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router';



const ExportableFields = Fields.filter(d => d.export);

const UserBulkActionButtons = (props: any) => {
    const apolloClient = useApolloClient();
    const { permissions } = usePermissions();
    const { filterValues: { publish_status, name } } = props as any
    const { selectedIds } = props as any
    const [isButtonCopy, setIsButtonCopy] = useState(false);
    console.log('selectedIds', selectedIds);
    console.log('props', props);
    const permission = permissions?.list_permission as PermissionsType;
    const role = permissions?.role;
    //var isButtonCopy = false;
    if (selectedIds.length === 1) {
        let data = apolloClient.query({
            query: GET_SHOP,
            variables: {
                school_gym_draft_id: selectedIds[0]
            }
        }).then(dataSchoolGym => {
            const { data: { school_gym_draft } } = dataSchoolGym;
            if (!school_gym_draft[0].shop_id) {
                setIsButtonCopy(true);
            }else{
                setIsButtonCopy(false);
            }
        });
    }
    return (
        <Fragment>
{/*           
            {((publish_status === 1 || publish_status === undefined) && (permission?.permission_approve === true || permission?.permission_all === true)) && <ButtonApprove {...props} />}
            {((publish_status === 1 || publish_status === undefined) && (permission?.permission_approve === true || permission?.permission_all === true)) && <ButtonDisapprove {...props} />} */}
            {/* {((isButtonCopy === false && (publish_status === 1 || publish_status === undefined)) && (permission?.permission_approve === true || permission?.permission_all === true)) && <ButtonUnpublish {...props} />} */}
            {/* {(isButtonCopy === true && selectedIds.length === 1) && <BulkCopyTeamReviewButton {...props} />} */}
            {(publish_status === 1 || publish_status === undefined) && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>

            <SearchInput source="name" alwaysOn />
            <SelectInput choices={PUBLISH_STATUS} source="publish_status" label={translate('team.publish_status')} alwaysOn />
            <SelectInput
                allowEmpty={false}
                choices={ACCOUNT_SEARCH}
                parse={(val: number) => val === 0 ? null : true}
                format={(val: any) => val ? 1 : 0}
                source="account_id@_is_null"
                label={translate('team.is_frontend_created_search')} alwaysOn />
        </Filter>

    )
};

const SchoolGymList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/school_gym_draft';
    },[props]);
    const apolloClient = useApolloClient();
    const module = 'school_gym_draft';
    if (role ===undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            filterDefaultValues={{ publish_status: 1}}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'school_gym_draft', ExportableFields, translate, fetchRelatedRecords,apolloClient)}
            actions={<ListActions to='school_gym_draft' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            <CustomizableDatagrid rowClick={edit}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat,isShow } = f || {}
                    if(isShow == false){
                        return <></>
                    }
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'status') {
                        return <StatusField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'reference') {
                        if (searchField === 'lastname' ||searchField === 'firstname' ||searchField === 'email' ||searchField === 'phone' ) {
                            return <UserReferenceField searchField  = {searchField} label={translate(`user_list.${searchField}`)} source={name} reference={reference} key={idx} />
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }
                    if (type === 'date' || type === 'datetime') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }

                    if (type === 'mutipleChoiceInput') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'is_frontend_created') {
                        return <AccountCreateField
                            source={name}
                            label={translate(`${module}.${type}`)}
                        />
                    }

                    return <></>
                })}
            </CustomizableDatagrid>
        </List>
    );
};

export default SchoolGymList;
