import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
  APPROVE_TEAM_REVIEW,GET_TEAM_REVIEW_NOT_ACCESS_APPROVE,GET_TEAM_REVIEW_ACCESS_APPROVE,SEND_MAIL_APPROVE_TEAM_REVIEW,INSERT_USER_POINT
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";
import { GetAmountPoint } from '../team/gql';
const ButtonApprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataApprove: number[] = [];
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;

  const UpdateUserPoint = async (created_by: number) => {
    const dataPointType = await GetAmountPoint(apolloClient,1);

    let {data: dataUserPoint} = await apolloClient.mutate({
      mutation: INSERT_USER_POINT,
      variables : {
        user_id: created_by, 
        amount: dataPointType[0].amount
      }
    });
  }
  const ApproveTeamReview = async (team_review_id: number[]) => {
    // data team review not access approve

  let {data: dataNotAccess} = await apolloClient.query({
      query: GET_TEAM_REVIEW_NOT_ACCESS_APPROVE,
      variables : {
        list_team_review_id: team_review_id
      } 
    });
    // data team review access approve
  let {data: dataAccess} = await apolloClient.query({
      query: GET_TEAM_REVIEW_ACCESS_APPROVE,
      variables : {
        list_team_review_id: team_review_id
      }
  });
  if (dataAccess.team_review.length > 0) {
    //setCountSuccess(dataAccess.team_review.length);
    dataAccess.team_review.map((x:any) => {
      UpdateUserPoint(x.created_by);
      dataApprove.push(x.id);
    })
  }else{
    return {
      countSuccess: 0, 
      countFail: dataNotAccess.team_review.length
    };
  }
  //setCountFail(dataNotAccess.team_review.length);
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
        const {data} = await apolloClient.mutate({
            mutation: APPROVE_TEAM_REVIEW,
            variables : {
              list_team_review_id: dataApprove,
              varified_by: parseInt(user_id)
            }
        });
        let dataReturn = {
          countFail: dataNotAccess.team_review.length, 
          countSuccess: dataAccess.team_review.length, 
        }        
        //send mail
        const {data: dataSendMail} = await apolloClient.mutate({
          mutation: SEND_MAIL_APPROVE_TEAM_REVIEW,
          variables : {
            list_team_review: dataApprove
          }
      });
        console.log('dataSendMail',dataSendMail);
        return dataReturn;
  }
  const handleClick = async () => {
        const data = await ApproveTeamReview(selectedIds);
        if(data.countSuccess > 0){
            //notify(translate('common.msg_approved'))
            notify(`${data.countSuccess}承認しました。, ${data.countFail} 承認エラーが発生しました。`)
            unselectAll('team_review');
            refresh();
        }else{
            notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning')
        }  
}
  
  return <Button
    disabled={isConverting || props.disabled}
    //variant="contained"
    startIcon = {<CheckCircle/>}
    color="inherit"
    onClick={handleClick}>
    {translate('account.approve')}
    
  </Button>
}

ButtonApprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonApprove.defaultProps = {
  selectedIds: []
}

export default ButtonApprove;