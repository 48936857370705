import moment from 'moment';
const PAYMENT_METHOD = [
    { id: 0, name: '銀行振り込み' },
    { id: 1, name: 'Stripe' },
]
export const MONTHS = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
    { id: 3, name: '3' },
    { id: 4, name: '4' },
    { id: 5, name: '5' },
    { id: 6, name: '6' },
    { id: 7, name: '7' },
    { id: 8, name: '8' },
    { id: 9, name: '9' },
    { id: 10, name: '10' },
    { id: 11, name: '11' },
    { id: 12, name: '12' }
]
export const YEARS = [ 1, 0, -1, -2, -3, -4 , -5, -6, -7].map(num => ({ id: moment().year() - num, name: `${moment().year() - num}` }))
//export const YEARS_ALL = YEARS.push({id: 0, name: '全て'});
export const PAID = [
    {id: 0, name: '未払い'},
    {id: 1, name: '支払済み'},
    {id: -1, name: 'エラー'},
  ]
export const DATA = [
    { name: 'id', type: 'textinput', searchField: '', options: [], reference: '', export_name: 'id', nullable: true, editable: false, export: true, import: true, show: true },
    { name: 'account_name', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'email_owner', type: 'textinput', nullable: true, editable: true, export: true, import: true, show: true },

    { name: 'team_experiencer_name', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'status_updated_at', type: 'date', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true  },
    { name: 'join_date', type: 'date', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true  },

    { name: 'year', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'month', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'amount_after_discount', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'tax_amount_after_discount', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'paid', type: 'status', options: PAID, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'discount', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'paid_date', type: 'date', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'payment_method', type: 'status', options: PAYMENT_METHOD, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'billing_detail_product', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'billing_detail_unit', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'billing_detail_discount', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'payment_method_update_from', type: 'date', nullable: true, editable: true, export: true, import: true, show: true },
    
    { name: 'issue_date', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'account_pref', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'account_city', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'account_address', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: false },
    { name: 'due_date', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'deposit_amount', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'note_billing', type: 'textinput', numberFormat: false, nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'action' },
    { name: 'account_source', type: 'textinput', nullable: true, editable: true, export: true, import: true, show: true },
    { name: 'account_created', type: 'date',  nullable: true, editable: true, export: true, import: true, show: true },
    
]
export default DATA;