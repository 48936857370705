import { gql } from "@apollo/client";
export const GET_TEMPLATE_EMAIL = gql`
mutation MyMutation($id: Int!) {
    get_email_template(id: $id ) {
      action_key
      avatar
      error_message
      error_code
      html_content
      name
      status_code
      plain_content
      subject
    }
  }
`;
export const UPDATE_TEMPLATE_EMAIL = gql`
mutation MyMutation($action_key: String, $html_content: String, $name: String, $id: Int, $status: Int, $subject: String) {
  update_email_template(action_key: $action_key, html_content: $html_content, name: $name, id: $id, status: $status, subject: $subject) {
    error_code
    error_message
    status_code
  }
}
`;
export const CREATE_TEMPLATE_EMAIL = gql`
mutation MyMutation( $html_content: String, $name: String, $subject: String) {
  create_email_template(html_content: $html_content, name: $name,subject: $subject) {
    error_code
    error_message
    status_code
    template_id
  }
}
`;