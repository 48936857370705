import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();
  if(id){
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  }else{
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)} 新規追加
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  if(editForm){
    var {record: {id}} = props;
  }
  return <Container style={{justifyContent: 'center'}}>
    
    <Grid  style={{ width: '100%'}}  container justify='center' spacing={2}> 
      <Grid  style={{ width: '50%'}} container justify='center' spacing={2} >
      <Grid  style={{ width: '100%'}} >
      <SectionTitle  label={editForm ? "menu.account.page_edit" : "menu.account.page_create"} id = {editForm? id : ''}  />
      <Separator />
      </Grid>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if(d.isName){
          return <Grid item xs={6} key={idx}>
          <InputRenderer source='account' data={d} />
        </Grid>
        }else{
          return <Grid item xs={12} key={idx}>
          <InputRenderer source='account' data={d} />
        </Grid>
        }

      })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;