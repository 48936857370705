import {Reducer} from 'redux';
import {UPDATE_CLIENT, updateClient} from '../configuration/actions';
import {Client} from '../types';

type State = Client | null;

type Action =
    | ReturnType<typeof updateClient>
    | { type: 'OTHER_ACTION'; payload?: any };

const clientReducer: Reducer<State, Action> = (
    previousState = null,
    action
) => {
    if (action.type === UPDATE_CLIENT) {
        return action.payload;
    }
    return previousState;
};

export default clientReducer;
