import * as React from 'react';
import { Fragment, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
} from 'react-admin';
import { useApolloClient } from '@apollo/client'
import {
    DELETE_MANY_ACCOUNT, SOFT_DELETE_MANY_ACCOUNT,

} from './gql';

const BulkDeleteButtonAction = (props: any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const [loading, setLoading] = useState<Boolean>(false);
    const {
        resource,
    } = useListContext();

    // const [deleteMany, { loading }] = useDeleteMany(
    //     resource,
    //     selectedIds,
    //     {
    //         onSuccess: () => {
    //             refresh();
    //             notify(translate('common.msg_delete'));
    //             unselectAll(resource);
    //         },
    //         onFailure: () => notify(translate('common.msg_delete_fail'), 'warning'),
    //     }
    // );
    const deleteMany = async () => {
        setLoading(true);
        const { data, errors } = await apolloClient.mutate({
            mutation: SOFT_DELETE_MANY_ACCOUNT,
            variables: {
                list_account_id: selectedIds
            }
        });
        setLoading(false);
        if (data.update_account.affected_rows > 0) {            
            refresh();
            notify(translate('common.msg_delete'));
            unselectAll(resource);
        }
        if (errors) {
            notify(translate('common.msg_delete_fail'), 'warning')
        }
    }
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        await deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate('common.btn_delete')} onClick={handleClick} startIcon={<DeleteIcon />} style={{ color: '#f44336' }} />
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('common.msg_title_delete')}
                content={translate('common.msg_delete_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButtonAction;