import { TranslationMessages } from 'ra-core';
import japaneseMessages from '@bicstone/ra-language-japanese';

const customJapanMessages: TranslationMessages = {
    ...japaneseMessages,
    app_name: "スポスル",
    appTitle: "運営管理ログイン",
    menu: {
        mainMenuInvoice: "メニュー",
        user: {
            user_menu: "会員",
            user_list: "一覧",
            user_add: "追加",
            user_import: 'インポート',
            appbar_title: '',
            page_create: 'ユーザー新規追加',
            page_edit: '',
            trash: '会員'
        },
        genre: {
            genre_menu: "ジャンル管理",
            genre_list: "一覧",
            genre_add: "追加",
            genre_import: 'インポート',
            page_create: 'ジャンル新規登録',
            page_edit: 'ジャンル'
        },
        team: {
            team_menu: "チーム管理",
            team_list: "チーム一覧",
            team_add: "チームの追加",
            team_edit: "チームの編集",
            edit_page: 'チーム',
            trash: 'チーム'

        },
        account: {
            account_menu: "団体",
            account_list: "一覧",
            account_add_new: "追加",
            account_edit: "編集",
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: '団体',
            trash: '団体'
        },
        admin: {
            admin_menu: "システム管理者",
            admin_list: "一覧",
            admin_add_new: "追加",
            admin_edit: "編集"
        },
        settings: {
            setting_client: '設定',
            change_password: 'パスワード変更',
            profile: "プロファイル"
        },
        email_template: {
            email_template_menu: "メールテンプレート",
            email_template_list: "一覧",
            email_template_add: "追加",
            email_email_edit: "編集",
            create_new: 'メールテンプレート新規登録',
            page_edit: 'メールテンプレート'
        },
        event: {
            event_menu: "イベント情報",
            event_list: "イベント情報一覧",
            event_add: "追加",
            event_edit: "編集",
            create_new: 'イベント情報新規登録',
            page_edit: 'イベント情報',
            trash: 'イベント'
        },
        event_draft: {
            event_draft_list: "公開・申請中のイベント",
            event_draft_add: "追加",
            event_draft_edit: "編集",
            create_new: 'イベント情報新規登録',
            page_edit: 'イベント情報'
        },
        event_place: {
            event_place_menu: "イベント会場",
            event_place_list: "イベント会場一覧",
            event_place_add: "追加",
            event_place_edit: "編集",
            page_create: 'イイベント会場新規登録',
            page_edit: 'イベント会場'
        },
        event_plan: {
            event_plan_menu: "イベントプラン",
            event_plan_list: "イベントプラン一覧",
            event_plan_add: "追加",
            event_plan_edit: "編集",
            page_create: 'イベントプラン新規登録',
            page_edit: 'イベントプラン'
        },
        sposuru_news: {
            menu: "スポスルからのお知らせ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            appbar_title_create: '',
            appbar_title_edit: 'スポスルからのお知らせ新規追加',
            page_create: 'スポスルからのお知らせ',
            page_edit: 'スポスルからのお知らせ'
        },
        question: {
            menu: "質問",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '質問',
            edit_page: '質問',
            trash: '質問'
        },
        genre_sport: {
            menu: "ジャンルスポーツ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            page_create: 'ジャンルスポーツ',
            page_edit: 'ジャンルスポーツ'
        },
        answer: {
            menu: "回答",
            list: "一覧",
            add: "追加",
            edit: "編集",
            trash: '回答'
        },
        character: {
            menu: "特徴",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '特徴新規登録',
            edit_page: '特徴編集'
        },
        team_review: {
            menu: "クチコミ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: 'クチコミ',
            edit_page: 'クチコミ',
            trash: 'チームクチコミ'
        },
        contact: {
            menu: "問い合わせデータ",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: '問い合わせ新規登録',
            edit_page: '問い合わせ'
        },
        point_type: {
            menu: "ポイント設定",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: 'ポイント設定せ新規登録',
            edit_page: 'ポイント設定'
        },
        user_point: {
            menu: "ユーザーポイント",
            list: "一覧",
            add: "追加",
            edit: "編集",
            add_page: 'ユーザーポイント新規登録',
            edit_page: 'ユーザーポイント'
        },
        point_exchange: {
            menu: "ポイント交換商品",
            list: "一覧",
            add: "追加",
            edit: "編集",
            page_create: 'ポイント交換商品新規登録',
            page_edit: 'ポイント交換商品'
        },
        team_staff: {
            menu: "",
            list: "スタッフ一覧",
            add: "スタッフの追加",
            edit: "スタッフの編集",
            edit_page: 'スタッフ',
            add_page: 'スタッフ',
        },
        account_place: {
            menu: "",
            list: "団体の活動拠点一覧",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        team_place: {
            menu: "",
            list: "主な練習場所一覧",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        team_draft: {
            menu: "",
            list: "公開・更新中のチーム",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        account_admin: {
            menu: "団体管理者",
            list: "団体管理者一覧",
            add: "団体管理者一の追加",
            edit: "練団体管理者一の編集",
            trash: '団体管理者'
        },
        team_admin: {
            menu: "チーム管理者",
            list: "チーム管理者用一覧",
            add: "チーム管理者用一の追加",
            edit: "チーム管理者用一の編集",
            trash: 'チーム管理者'
        },
        area: {
            menu: "チーム管理者",
            list: "チーム管理者用一覧",
            add: "チーム管理者用一の追加",
            edit_page: "エリアデータ一の編集",
        },
        city: {
            menu: "チーム管理者",
            list: "チーム管理者用一覧",
            add: "チーム管理者用一の追加",
            edit_page: "市町村データ一の編集",
        },
        pref: {
            menu: "チーム管理者",
            list: "チーム管理者用一覧",
            add: "チーム管理者用一の追加",
            edit_page: "都道府県データ一の編集",
        },
        account_owner: {
            menu: "団体オーナー",
            list: "一覧",
            add: "団体オーナー用一の追加",
            edit_page: "団体オーナーデータ一の編集",
            trash: '団体オーナー'
        },
        user_remove_admin: {
            menu: "どこにも所属しない管理者",
            list: "一覧",
            add: "団体オーナー用一の追加",
            edit_page: "団体オーナーデータ一の編集",
        },
        event_application: {
            event_application_list: 'イベント一覧',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            edit_page: ''
        },
        event_contact: {
            event_contact_list: 'イベントお問い合わせ',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: ''
        },
        team_experiencer: {
            team_experiencer_list: 'チーム一覧',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            edit_page: ''
        },
        team_contact: {
            list: 'チームお問い合わせ',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: '',

        },
        permission: {
            menu: '管理者のロール',
            list: 'ロール一覧',
            draft: 'システム管理者'
        },
        trash: {
            menu: 'ゴミ箱'
        },
        menuContactEventTeam: {
            menu: 'お問い合わせ一覧',
            team_contact_type_1: 'チームお問い合わせ',
            event_contact_type_2: 'イベントお問い合わせ',
        },
        menuExperiencerDetail: {
            menu: '申込み一覧',
            team_contact_type_2: 'チーム申込み一覧',
            team_experiencer_list: 'チーム体験者一覧',
            event_contact_type_1: 'イベント申込み一覧',
            event_application_list: 'イベント参加者一覧',
        },
        billing: {
            menu: '請求履歴',
            billing_list: ' ',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            edit: '',
            list: "一覧",
            add: "請求履歴の追加"
        },
        billing_detail: {
            menu: '請求履歴詳細',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            edit: '',
            list: "一覧",
            add: "請求履歴詳細の追加",
        },
        team_game: {
            menu: '練習試合',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: '',
            list: "練習試合一覧",
            add: "請求履歴詳細の追加",
        },
        team_game_contact: {
            menu: 'つながり機能',
            appbar_title_create: '',
            appbar_title_edit: '団体新規追加',
            page_create: '団体',
            page_edit: '',
            list_null: "練習試合申込み一覧",
            list_not_null: "つながり申請一覧",
            add: "請求履歴詳細の追加",
        },
        shop: {
            shop_menu: "店舗一覧",
            list: "店舗一覧",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        shop_draft: {
            menu: "",
            list: "公開・更新中の店舗",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        school_gym: {
            menu: "",
            list: "スクール・ジム一覧へ",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
        join_reported: {
            menu: "入会報告一覧",
            submenu_1: "入会報告",
            submenu_2: "入会しない報告",
        },
        school_gym_draft: {
            menu: "",
            list: "公開・更新中のスクール",
            add: "練習場所一の追加",
            edit: "練習場所一の編集",
        },
    },
    login: {
        title_login: "ログイン",
        title_new_user: "新規ユーザー",
        forgot_password: "パスワードを忘れた場合",
        full_name: "フルネーム",
        email_address: "メールアドレス",
        password: "パスワード",
        create_acount: "アカウントを追加する",
        error_password: "ログイン情報かパスワードに誤りがあります :(",
        success_login: "ログインできました"
    },
    forgot_password: {
        title: "パスワードを忘れた場合",
        description: "認証コードを受け取るために、下記の連絡先メールアドレスを入力してください",
        email: "メールアドレス",
        back_login: "ログインへ",
        btn_send: "送信する",
        mgs_recover_password: "パスワードを忘れました。次のステップについては、メールを確認してください",
        mgs_recover_success: 'パスワードの変更に成功しました。 ',
    },
    activeUser: {
        title: "有効ユーザー",
        success: "アカウントを有効化しました。",
        error: "このユーザーを有効にしているときにエラーが発生しました。管理者に連絡してください",
        btn_login: "ログインへ"
    },
    reset: {
        success: "パスワードをリセットしました。",
        error: "パスワードのリセットに失敗しました。",
        password: "パスワード",
        reset: "パスワードを再設定する",
        back_to_login: "ログインに戻る",
        confirm_password: "パスワード（再確認用）"
    },
    copyright: "© 2021 Sposuru",
    user_list: {
        id: "ID",
        firstname: "名",
        lastname: "姓",
        firstname_kana: "メイ",
        lastname_kana: "セイ",
        post_code: '郵便番号',
        pref: '都道府県',
        city: '市区町村',
        address: '住所',
        phone: '電話番号',
        email: "メールアドレス",
        password: "パスワード",
        confirm_password: 'パスワード（確認）',
        image_url: 'プロフィール画像',
        nickname: 'ニックネーム',
        birthday: '生年月日',
        gender: "性別",
        status: 'ステータス',
        hash: 'メールアドレス確認キー',
        varified: 'メールアドレス確認日',
        created: '作成日',
        created_by: '作成者',
        note: '備考',
        update_user_title: 'ユーザーの編集',
        tab_profile: 'プロフィール',
        tab_password: 'パスワード',
        create_new: '新規登録',
        account_id: '団体',
        created_email: 'メールアドレス登録日',
        favorite_genre_sport_id1: '好きなスポーツ1',
        favorite_genre_sport_id2: '好きなスポーツ2',
        favorite_genre_sport_id3: '好きなスポーツ3',
        favorite_genre_sport_id4: '好きなスポーツ4',
        favorite_genre_sport_id5: '好きなスポーツ5',
        list_genre: '好きなスポーツ',
        amount: 'ポイント集計結果',
        age: '年齢'
    },
    genre: {
        id: "ID",
        name: "大ジャンル",
        created: "作成日",
        created_by: "作成者",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        genre_add: "追加",
        mobile_image_url: "モバイル用",
        english_name: "英語名",
        icon_tag: "アイコン タグ"
    },
    account_admin: {
        id: "ID",
        account_id: "団体",
        user_id: "ユーザー",
        note: "備考",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者",
        email: "メールアドレス",
        user_comment: "メモ"
    },
    billing: {
        id: 'ID',
        account_name: '団体',
        team_experiencer_name: '入会者',
        status_updated_at: 'ステータス変更日',
        year: '請求年',
        month: '請求月',
        paid: '支払ステータス',
        discount: '割引',
        //issue_date: '請求日',
        paid_date: '支払日',
        payment_method: '支払方法',
        //payment_reference: 'payment_reference',
        billing_detail_product: '申し込みID',
        billing_detail_unit: '個数',
        billing_detail_amount: '合計',
        tax_amount: '消費税',
        //billing_detail_percentage: 'billing_detail_percentage',
        billing_detail_discount: 'ディスカウント',
        issue_date: '請求日',
        account_pref: '都道府県',
        account_city: '市区町村',
        account_address: '住所',
        due_date: '支払い期限',
        deposit_amount: '入金額',
        deposit_date: '入金日',
        note_billing: '備考',
        account_source: '流入経路',
        account_created: '団体登録日',
        join_date: '入会報告日',
        payment_method_update_from: '新支払い方法適用日',
        method_update_from: '適用開始日',
        method_update_to: '適用終了日', 
        email_owner: "団体オーナーメールアドレス",
        amount_after_discount: '合計',
        tax_amount_after_discount: '消費税'
    },
    billing_view: {
        id: 'ID',
        account_name: '団体',
        year: '請求年',
        month: '請求月',
        total_amount: '合計',
        tax_amount: '消費税',
        paid: '支払ステータス',
        discount: '割引',
        paid_date: '支払日',
        payment_method: '支払方法',
        billing_detail_product: '申し込みID',
        billing_detail_unit: '個数',
        billing_detail_amount: '合計',
        billing_detail_tax: '消費税',
        billing_detail_discount: 'ディスカウント',
        account_address: '住所',
        account_pref: '都道府県',
        account_city: '市区町村',
        action: '',
        due_date: '支払い期限',
        receipt_filepath: '領収書S3のパス',
        receipt_filename: '領収書ファイル名',
        deposit_amount: '入金額',
        deposit_date: '入金日',
        issue_date: "請求日",
        note_billing: '備考',
        team_experiencer_name: '入会者', 
        status_updated_at: 'ステータス変更日', 
        join_date: '入会報告日',
        note: '備考',
        amount_after_discount: '合計',
        tax_amount_after_discount: '消費税'
    },
    billing_detail: {
        id: "ID",
        billing_id: "請求書ID",
        product: "申し込みID",
        unit: "個数",
        amount: "合計",
        tax: "消費税",
        percentage: "パーセンテージ",
        discount: "ディスカウント",
        stripe_product_id: "ストライプの商品ID",
        created_at: "作成日",
        updated_at: "更新日",
        created_by: "作成ユーザー",
    },
    team_admin: {
        id: "ID",
        team_id: "チーム",
        user_id: "ユーザー",
        permissions: "権限データ",
        note: "備考",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者",
        email: "メールアドレス"
    },
    genre_sport: {
        id: "ID",
        secondary_name: "中ジャンル",
        genre_id: "大ジャンル",
        created: "作成日",
        created_by: "作成者",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        genre_add: "追加",
        mobile_image_url: "モバイル用",
        secondary_english_name: "英語名"
    },
    team_list: {
        modal_copy_title: 'マージする対象の団体チームを選択してください。',
    },
    team: {
        id: "ID",
        name: "チーム・スクール名",
        name_kana: "チーム名フリガナ",
        account_id: "ユーザー",
        president_name: "代表者責任者名",
        philosophy: "チーム理念・指導方針",
        achievements: "主な過去の実績",
        logo_url: "チームロゴ",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像2",
        image7_url: "画像3",
        video_url: "動画あり(TOPページ用）",
        category: "カテゴリ",
        sub_category: "サブカテゴリ",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        age: "対象年代",
        practice_days: "練習曜日",
        practice_datetime: "練習日・練習時間",
        style: "スタイル",
        trial_type: "体験",
        visit_ok: "見学OKフラグ",
        trial_description: "体験・募集について",
        enrolled: "在籍人数",
        for_parents: "保護者の方にお願いする事",
        admission_fee: "入会金",
        annual_fee: "年会費",
        monthly_fee: "月謝あり・なし",
        monthly_fee_from: "月謝：下限",
        monthly_fee_to: "月謝：上限",
        things_to_prepare: "入会後ご用意いただきたいもの",
        fee_description: "費用に関する説明",
        initial_fee: "入会時に掛かる初期費用目安",
        gear: "初回購入品と金額のJSON配列",
        created: "作成日",
        created_by: "ユーザーID",
        note: "備考",
        keyword: "",
        attention: "おすすめフラグ",
        publish_status: "掲載ステータス",
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        disability: "障害者歓迎",
        ladies: "レディース",
        team_tab_1: '初期設定情報',
        team_tab_2: '基本情報',
        team_tab_3: 'チーム・スクールの画像・特徴',
        team_tab_4: '特徴',
        team_tab_5: '募集・練習体験・セレクション',
        team_tab_6: '費用について',
        appeal_title: 'アピールタイトル',
        appeal_body: 'アピール本文',
        keyword1: 'タグ種別１',
        keyword2: 'タグ種別２',
        keyword3: 'タグ種別３',
        keyword4: 'タグ種別４',
        enroll_description: '在籍人数の詳細',
        placeholder_style_description_1: '例：だらだらと練習はしません。そのため、練習が厳しく感じるかもしれませんが、 楽しむことが大前提という意識で指導しております。',
        placeholder_style_description_2: '例：日数が少ない分、1日の練習量を多めに確保しております。',
        placeholder_style_description_3: '例：練習試合においては全選手、出場機会があるように心がけておりますが、 公式戦は勝ちにこだわるためシビアに選手選考を行います。',
        placeholder_style_description_4: '例：20代～50代が在籍しており、年齢層は幅広いです。',
        placeholder_style_description_5: '例：基本技術はとても大事にしておりますが、どちらかと言えば戦術重視のチームと 言えます。',
        placeholder_style_description_6: '例：男女比率はほぼ均等で、試合も男女混合で臨んでいます。',
        placeholder_style_description_7: '例：勝ち負けよりも、いかに楽しむかが大切です。小学生のうちは勝つことはそこまで 重要でないと考えております。',
        placeholder_style_description_8: '例：入会する前は、ほとんどの方が未経験です。ですので、未経験の方も安心して ください。',
        style_choice_description_1: '説明',
        style_choice_description_2: '説明',
        style_choice_description_3: '説明',
        style_choice_description_4: '説明',
        style_choice_description_5: '説明',
        style_choice_description_6: '説明',
        style_choice_description_7: '説明',
        style_choice_description_8: '説明',
        age_1: '',
        age_2: '',
        age_3: '',
        age_4: '',
        age_5: '',
        age_6: '',
        age_7: '',
        age_8: '',
        age_9: '',
        age_10: '',
        age_11: '',
        age_12: '',
        age_open_1: '',
        age_open_2: '',
        age_open_3: '',
        age_open_4: '',
        age_open_5: '',
        age_open_6: '',
        age_open_7: '',
        age_open_8: '',
        age_open_9: '',
        age_open_10: '',
        age_open_11: '',
        age_open_12: '',
        radio_value_1: '額',
        radio_value_2: '額',
        radio_value_3_from: 'からの量',
        radio_value_3_to: '匹敵する',
        gear_name_1: '初期購入品1',
        gear_name_2: '初期購入品2',
        gear_name_3: '初期購入品3',
        gear_name_4: '初期購入品4',
        gear_name_5: '初期購入品5',
        gear_name_6: '初期購入品6',
        gear_name_7: '初期購入品7',
        gear_name_8: '初期購入品8',
        gear_name_9: '初期購入品9',
        gear_name_10: '初期購入品10',
        gear_price_1: '金額1',
        gear_price_2: '金額2',
        gear_price_3: '金額3',
        gear_price_4: '金額4',
        gear_price_5: '金額5',
        gear_price_6: '金額6',
        gear_price_7: '金額7',
        gear_price_8: '金額8',
        gear_price_9: '金額9',
        gear_price_10: '金額10',
        is_frontend_created: 'ユーザー',
        is_frontend_created_search: '口コミのフィルタ',
        disapprove: '非承認する',
        msg_title_disapprove: '非承認の確認',
        msg_disapprove_message: '本当に、非承認に変更してよろしいでしょうか？',
        unpublish: '非公開にする(メール送信)',
        modal_copy_title: 'マージする対象の団体チームを選択してください。',
        copy_field: 'プルダウン',
        button_status_0: '作成中にする',
        button_status_1: '申請中にする',
        button_status_2: '公開中にする',
        button_status_3: '非公開にする',
        button_status_4: '非承認にする',
        approved_at: '承認日',
        account_name: '団体',
        team_id: 'チーム・スクール名',
        account_lastname: '姓',
        account_firstname: '名',
        account_email: 'メールアドレス',
        account_source: '流入経路',
        show_account_info: '運営者情報の表示',
        payable_rate: '支払いパーセンテージ',
        review_count: 'クチコミ数',
        team_place_name_1: '主な練習場所１',
        team_place_name_2: '主な練習場所２',
        team_place_name_3: '主な練習場所３',
        updated: '更新日',
        request_date: '申請日',
        monthly_fee_between: '下限・上限あり',
        approve_at: '承認日',
        team_connection: 'つながり設定',
        list_staff: "スタッフ"
    },
   
    team_draft: {
        id: "ID",
        name: "チーム・スクール名",
        name_kana: "チーム名フリガナ",
        account_id: "団体",
        president_name: "代表者責任者名",
        philosophy: "チーム理念・指導方針",
        achievements: "主な過去の実績",
        logo_url: "チームロゴ",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像2",
        image7_url: "画像3",
        video_url: "動画あり(TOPページ用）",
        category: "カテゴリ",
        sub_category: "サブカテゴリ",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        age: "対象年代",
        practice_days: "練習曜日",
        practice_datetime: "練習日・練習時間",
        style: "スタイル",
        trial_type: "体験",
        visit_ok: "見学OKフラグ",
        trial_description: "体験・募集について",
        enrolled: "在籍人数",
        for_parents: "保護者の方にお願いする事",
        admission_fee: "入会金",
        annual_fee: "年会費",
        monthly_fee: "月謝あり・なし",
        monthly_fee_from: "月謝：下限",
        monthly_fee_to: "月謝：上限",
        things_to_prepare: "入会後ご用意いただきたいもの",
        fee_description: "費用に関する説明",
        initial_fee: "入会時に掛かる初期費用の目安",
        gear: "初回購入品と金額のJSON配列",
        created: "作成日",
        created_by: "ユーザーID",
        note: "備考",
        keyword: "",
        attention: "おすすめフラグ",
        publish_status: "掲載ステータス",
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        disability: "障害者歓迎",
        ladies: "レディース",
        team_tab_1: '初期設定情報',
        team_tab_2: '基本情報',
        team_tab_3: 'チーム・スクールの画像・特徴',
        team_tab_4: '特徴',
        team_tab_5: '募集・練習体験・セレクション',
        team_tab_6: '費用について',
        appeal_title: 'アピールタイトル',
        appeal_body: 'アピール本文',
        keyword1: 'タグ種別１',
        keyword2: 'タグ種別２',
        keyword3: 'タグ種別３',
        keyword4: 'タグ種別４',
        enroll_description: '在籍人数の詳細',
        placeholder_style_description_1: '例：だらだらと練習はしません。そのため、練習が厳しく感じるかもしれませんが、 楽しむことが大前提という意識で指導しております。',
        placeholder_style_description_2: '例：日数が少ない分、1日の練習量を多めに確保しております。',
        placeholder_style_description_3: '例：練習試合においては全選手、出場機会があるように心がけておりますが、 公式戦は勝ちにこだわるためシビアに選手選考を行います。',
        placeholder_style_description_4: '例：20代～50代が在籍しており、年齢層は幅広いです。',
        placeholder_style_description_5: '例：基本技術はとても大事にしておりますが、どちらかと言えば戦術重視のチームと 言えます。',
        placeholder_style_description_6: '例：男女比率はほぼ均等で、試合も男女混合で臨んでいます。',
        placeholder_style_description_7: '例：勝ち負けよりも、いかに楽しむかが大切です。小学生のうちは勝つことはそこまで 重要でないと考えております。',
        placeholder_style_description_8: '例：入会する前は、ほとんどの方が未経験です。ですので、未経験の方も安心して ください。',
        style_choice_description_1: '説明',
        style_choice_description_2: '説明',
        style_choice_description_3: '説明',
        style_choice_description_4: '説明',
        style_choice_description_5: '説明',
        style_choice_description_6: '説明',
        style_choice_description_7: '説明',
        style_choice_description_8: '説明',
        age_1: '',
        age_2: '',
        age_3: '',
        age_4: '',
        age_5: '',
        age_6: '',
        age_7: '',
        age_8: '',
        age_9: '',
        age_10: '',
        age_11: '',
        age_12: '',
        age_open_1: '',
        age_open_2: '',
        age_open_3: '',
        age_open_4: '',
        age_open_5: '',
        age_open_6: '',
        age_open_7: '',
        age_open_8: '',
        age_open_9: '',
        age_open_10: '',
        age_open_11: '',
        age_open_12: '',
        radio_value_1: '額',
        radio_value_2: '額',
        radio_value_3_from: 'からの量',
        radio_value_3_to: '匹敵する',
        gear_name_1: '初期購入品1',
        gear_name_2: '初期購入品2',
        gear_name_3: '初期購入品3',
        gear_name_4: '初期購入品4',
        gear_name_5: '初期購入品5',
        gear_name_6: '初期購入品6',
        gear_name_7: '初期購入品7',
        gear_name_8: '初期購入品8',
        gear_name_9: '初期購入品9',
        gear_name_10: '初期購入品10',
        gear_price_1: '金額1',
        gear_price_2: '金額2',
        gear_price_3: '金額3',
        gear_price_4: '金額4',
        gear_price_5: '金額5',
        gear_price_6: '金額6',
        gear_price_7: '金額7',
        gear_price_8: '金額8',
        gear_price_9: '金額9',
        gear_price_10: '金額10',
        is_frontend_created: 'ユーザー',
        is_frontend_created_search: '口コミのフィルタ',
        disapprove: '非承認する',
        msg_title_disapprove: '非承認の確認',
        msg_disapprove_message: '本当に、非承認に変更してよろしいでしょうか？',
        show_account_info: '運営者情報の表示',
        payable_rate: '支払いパーセンテージ',
        unpublish: '非公開にする(メール送信)',
        request_date: '申請日',
        approve_at: '承認日',
        team_connection: 'つながり設定'
    },
    account: {
        id: "ID",
        name: "企業名/団体名",
        name_kana: "企業名/団体名フリガナ",
        representative_first_name: "代表者名",
        representative_last_name: "代表者姓",
        representative_firstname_kana: "代表者名フリガナ",
        representative_lastname_kana: "代表者姓フリガナ",
        contact_first_name: "担当者名",
        contact_last_name: "担当者姓",
        contact_firstname_kana: "担当者名フリガナ",
        contact_lastname_kana: "担当者姓フリガナ",
        contact_email: "Eメールアドレス",
        post_code: "郵便番号",
        pref: "都道府県",
        city: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        contact_phone: "連絡先電話番号",
        business_contents: "事業内容",
        affiliation_association: "所属協会",
        affiliation_association_kana: "所属協会フリガナ",
        sports: "取り扱いスポーツ",
        status: "ステータス",
        varified: "承認日",
        varified_by: "承認者",
        created: "作成日",
        created_by: "作成者",
        note: "備考",
        create_new: '新規登録',
        approve: '承認する',
        email: '承認者',
        account_id: '企業名/団体名',
        no_of_event: 'イベント数',
        no_of_team: '公開中のチーム数',
        no_of_team_open: '作成中のチーム数',
        list_team: 'チーム一覧',
        list_event: 'イベント一覧',
        source: '流入経路',
        fee_notice_flg: '1人目無料フラグ',
        no_of_team_unpublish: '非公開中のチーム数',
        user_comment: "メモ",
        payment_method_update_at: "支払い方法変更日",
        payment_method_update_from: "適用日",

        payable_rate: '支払いパーセンテージ', 
        account_name_history: '団体名変更履歴',
        history_name: '旧団体名',
        history_name_modify: '変更時間',

    },
    admin: {
        id: "ID",
        email: "Eメールアドレス",
        status: 'ステータス',
        created: '作成日',
        created_by: '作成者',
        password: "パスワード",
        create_title: '新しい管理者を追加'
    },
    event: {
        id: "ID",
        account_id: "団体名",
        attention: "注目",
        video_url: "動画あり(TOPページ用）",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像6",
        image7_url: "画像7",
        image8_url: "画像8",
        image9_url: "画像9",
        access: "アクセスに関する備考",
        capacity: "定員数",
        capacity_note: "定員数備考",
        is_group: "参加形式",
        age_group: "対象年代",
        disability: "障害者歓迎フラグ",
        ladies: "レディース",
        genre_id: "スポーツジャンル",
        genre_sport_id: "スポーツジャンル＞スポーツ",
        title: "イベントタイトル",
        sub_title: "イベントサブタイトル",
        schedule_time: "イベント時間",
        description: "イベント説明",
        point: "イベントのポイント",
        covid_note: "コロナ対策情報",
        image10_url: "画像10",
        title1: "タイトル",
        body1: "本文",
        image11_url: "画像11",
        title2: "タイトル",
        body2: "本文",
        image12_url: "画像12",
        title3: "タイトル",
        body3: "本文",
        image13_url: "画像13",
        title4: "タイトル",
        body4: "本文",
        organizer_name: "主催者名",
        organizer_post_code: "主催者郵便番号",
        organizer_pref: "主催者住所都道府県",
        organizer_city: "主催者住所市区町村",
        organizer_address1: "主催者住所市区町村以下",
        organizer_address2: "主催者住所住所その他",
        additional_note: "参加者など",
        comment: "イベント担当者からのコメント",
        application_method: "お申し込み方法",
        application_method_note: "お申し込み方法備考",
        is_free: "無料",
        admission_fee: "参加費用金額",
        payment_method: "お支払い方法等",
        fee_note: "費用に関する情報",
        cancel_policy: "キャンセルの場合の情報",
        data1_url: "イベントチラシデータアップロード1",
        data2_url: "イベントチラシデータアップロード2",
        data3_url: "イベントチラシデータアップロード3",
        accept_contact: "受け付けない",
        valid_from: "公開期間設定 開始日",
        valid_to: "公開期間設定 終了日",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        type: "イベント種別",
        level: "レベル・経験値",
        preview_key: "UUID",
        preview: "下書き用のデータ",
        event_tab_1: '初期設定情報',
        event_tab_2: 'イベント情報',
        event_tab_3: 'イベントのポイント',
        event_tab_4: '参加費用・要項',
        event_tab_5: '主催者情報',
        event_tab_6: '注意事項など',
        button_status_0: '完了にする',
        button_status_1: '作成中にする',
        button_status_2: '申請中の確認',
        button_status_3: '公開予定にする',
        button_status_4: '公開中にする',
        button_status_5: '受付停止中にする',
        button_status_6: '非承認にする',
        approved_at: '承認日',
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        date_from: "開催日(開始)",
        date_to: "開催日(終了)",
        request_date: '申請日'
    },
    event_draft: {
        id: "ID",
        account_id: "団体名",
        event_id: '開催地名称',
        attention: "注目",
        video_url: "動画あり(TOPページ用）",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像6URL",
        image7_url: "画像7URL",
        image8_url: "画像8URL",
        image9_url: "画像9URL",
        access: "アクセスに関する備考",
        capacity: "定員数",
        capacity_note: "定員数備考",
        is_group: "参加形式",
        age_group: "対象年代",
        disability: "障害者歓迎フラグ",
        ladies: "レディース",
        genre_id: "スポーツジャンル",
        genre_sport_id: "スポーツジャンル＞スポーツ",
        title: "イベントタイトル",
        sub_title: "イベントサブタイトル",
        schedule_time: "イベント時間",
        description: "イベント説明",
        point: "イベントのポイント",
        covid_note: "コロナ対策情報",
        image10_url: "画像10",
        title1: "タイトル",
        body1: "本文",
        image11_url: "画像11",
        title2: "タイトル",
        body2: "本文",
        image12_url: "画像12",
        title3: "タイトル",
        body3: "本文",
        image13_url: "画像13",
        title4: "タイトル",
        body4: "本文",
        organizer_name: "主催者名",
        organizer_post_code: "主催者郵便番号",
        organizer_pref: "主催者住所都道府県",
        organizer_city: "主催者住所市区町村",
        organizer_address1: "主催者住所市区町村以下",
        organizer_address2: "主催者住所住所その他",
        additional_note: "参加者など",
        comment: "イベント担当者からのコメント",
        application_method: "お申し込み方法",
        application_method_note: "お申し込み方法備考",
        is_free: "無料",
        admission_fee: "参加費用金額",
        payment_method: "お支払い方法等",
        fee_note: "費用に関する情報",
        cancel_policy: "キャンセルの場合の情報",
        data1_url: "イベントチラシデータアップロード1",
        data2_url: "イベントチラシデータアップロード2",
        data3_url: "イベントチラシデータアップロード3",
        accept_contact: "受け付けない",
        valid_from: "公開期間設定 開始日",
        valid_to: "公開期間設定 終了日",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        type: "イベント種別",
        level: "レベル・経験値",
        preview_key: "UUID",
        preview: "下書き用のデータ",
        event_tab_1: '初期設定情報',
        event_tab_2: 'イベント情報',
        event_tab_3: 'イベントのポイント',
        event_tab_4: '参加費用・要項',
        event_tab_5: '主催者情報',
        event_tab_6: '注意事項など',
        request_date: '申請日',
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        date_from: "開催日(開始)",
        date_to: "開催日(終了)",

    },
    sposuru_news: {
        id: "ID",
        title: "題名",
        content: '本文',
        publish: '公開',
        note: '備考',
        created: '作成日',
        created_by: '作成者',
        publish_date: '公開日',
        target_user: 'マイページ',
        target_admin: 'チーム管理画面',
        target_app: 'アプリ'
    },
    common: {
        export: 'エクスポート',
        btn_update: '保存する',
        btn_cancel: 'キャンセル',
        btn_save: '保存',
        status: 'ステータス',
        msg_confirm_not_match: 'マッサージが一致しないことを確認する',
        unknow: '不明',
        male: '男性',
        female: '女性',
        gender: '性別',
        btn_create: '登録する',
        btn_add: "追加",
        msg_delete: 'アイテムが正常に削除されました。',
        msg_delete_user: "アイテムの削除は成功しました",
        btn_disagree: "同意しない",
        btn_agree: "同意する",
        btn_delete: '削除する',
        lbl_search: "検索",
        msg_add_success: "アイテムの追加に成功しました",
        msg_update_success: "更新しました。",
        msg_update_error: "更新に失敗しました。",
        msg_no_record: "指定された検索条件に一致する結果は見つかりませんでした",
        ms_active_error: "このリンクは有効化されました、または期限が切れています。しばらくしてからもう一度お試しください。",
        ms_register_success: "このアカウントは正常に登録されました。アカウントを有効にするために、メールをご確認ください",
        ms_active_success: "このアカウントは正常に有効化されました。ログインボタンをクリックしてシステムにログインしてください",
        msg_login_failed: "メールアドレスまたはパスワードが正しくありません。",
        configuration: 'アプリケーション設定',
        configClient: 'クライアント設定',
        theme_light: 'Light',
        theme_dark: 'Dark',
        language: '言語',
        theme: 'テーマ',
        minute: "分",
        hours: "時間",
        required: "required",
        msg_delete_confirm: '削除してもよろしいですか。',
        msg_recover_confirm: '元に戻してもよろしいですか。',
        msg_delete_fail: '削除されませんでした',
        msg_delete_fail_genre: 'チームまたはイベントで利用されているため、削除できません',
        msg_title_delete: '削除の確認',
        msg_title_recover: '回復の確認',
        status_active: "有効",
        status_inactive: "無効",
        could_not: "作成できませんでした：",
        no_record: "レコードがありません。",
        import: "新しいレコードを追加するか。またはファイルからインポートしてください。",
        btn_import: "インポート",
        minLenght: '6文字以上である必要があります',
        emailValidate: "メールアドレスである必要があります",
        btn_ok: 'OK',
        created: '登録日',
        created_by: '登録者',
        actions: '操作',
        edit_history: '修正履歴',
        generate_pdf: 'ファイル生成',
        new_registration: '新規登録',
        batch_update_confirm_title: '確認',
        batch_update_confirm_content: '件のレコードが更新されます。',
        batch_update_confirmation: '実行してもよろしいですか。',
        msg_approved: '承認しました。',
        edit: '編集',
        draft: '未掲載',
        waiting_approve: '申請中',
        approved: '掲載中',
        end_event: '終了',
        draft_event: '作成中',
        waiting_approve_event: '公開待ち',
        approved_event: '公開中',
        pending_event: '受付停止中',
        imageUploadPlaceholder: '画像を選択してください。',
        publishing: '掲載中',
        modify_after_publish: '掲載後変更中',
        waiting_approve_after_modify: '変更後申請中',
        cancel_text_disapprove_team: 'いいえ',
        confirm_text_disapprove_team: 'はい',
        msg_title_unpublish: '非公開の確認',
        msg_unpublish_message: '本当に、非公開に変更してよろしいでしょうか？',
        valid: '無効',
        invalid: '有効',
        waiting_mail: 'メール認証待ち',
        confirm_button_copy: 'マージする',
        btn_copy_team_review: '団体のチームにマージする',
        search_team_name: 'チーム・スクール名で検索',
        contact_user_1: 'ユーザー',
        contact_user_5: 'スポーツ団体・組織',
        contact_user_10: 'その他',
        msg_title_copy: 'マージの確認',
        msg_copy_confirm: 'マージしてもよいですか？',
        status_event_0: '終了',
        status_event_1: '作成中',
        status_event_2: '申請中',
        status_event_3: '公開予定',
        status_event_4: ' 公開中',
        status_event_5: '受付停止中',
        status_event_6: '非承認',
        status_event_draft_3: '非承認',
        no_choice: '未選択',
        register_success: '作成しました。',
        register_error: 'エラーが発生しました。',
        register_error_exist: 'ゴミ箱にあるアカウントですので追加できません。\n他のメールアドレスを追加してください。',
        register_error_exist_not_delete: '既に存在しているアカウントですので追加できません。\n他のメールアドレスを追加してください',
        update_permission_success: 'データが保存されました。',
        update_permission_fail: 'エラーが発生しました。',
        delete_success: '削除しました。',
        delete_error: 'エラーが発生しました。',
        modal_reset_title: 'パスワード初期化',
        reset_password: '新しいパスワード',
        reset_password_confirm: 'パスワードを再入力してください',
        reset_pass_confirm_btn: 'OK',
        reset_pass_success: 'パスワードの設定が成功しました',
        reset_pass_error: 'パスワードを入力してください。',
        btn_recover: '元に戻す',
        restore_success: 'データが復元されました。',
        msg_delete_permanently: 'アイテムが正常に削除されました。',
        msg_restore: 'アイテムが正常に処理されました',
        restore_success_permission: 'アイテムが正常に処理されました。',
        update_billing_success: '請求が正常に更新されました。',
        must_be_number: '数字を入力する必要があります。',
        gen_receipt_success: '領収書が生成されました。',
        gen_receipt_error: 'エラーが発生されました。',
        actions_message: 'メッセージ履歴', 
        msg_update_multi_team_fail: '保存されませんでした',
        msg_update_multi_team_success: 'アイテムが正常に保存されました',
        update_invoice: "変更の確認", 
        confirm_button_update_invoice: "確認", 
        status_event_7: '非公開',
    },
    changePassword: {
        title: 'パスワード変更',
        error: 'パスワード変更エラー',
        oldPassword: '現在のパスワード',
        new_password: '新しいパスワード',
        confirm_password: '新しいパスワードの確認',
        btn_change_password: '変更する',
        msg_complete: "パスワードの変更に成功しました。 ログアウトして新しいパスワードを使用してください"
    },
    email_template: {
        id: "ID",
        action_key: "アクションキー",
        name: "名称",
        sendgrid_template_id: "sendgridのテンプレートID",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        title_add: "追加",
        body: "本文",
        params: "パラメータ",
        subject: '主題',
        virtual_body: '仮想体'
    },
    question: {
        id: "ID",
        user_id: "ユーザーID",
        category: "投稿カテゴリ",
        title: "タイトル",
        question: "本文",
        approved: "承認日",
        approved_by: "承認者ID",
        note: "備考",
        violation: "違反報告",
        nickname: "投稿者ニックネーム",
        genre_id: "スポーツ",
        genre_sport_id: "スポーツジャンル",
        created: "作成日",
        created_by: "作成者ID",
        search_title: "タイトル / 本文",
        firstname: "本名(名)",
        lastname: "本名(姓)",
    },
    answer: {
        id: "ID",
        question_id: "質問",
        answer: "回答本文",
        answered_by: "ユーザーID",
        answered_nickname: "投稿者ニックネーム",
        note: "備考",
        good: "参考になった",
        violation: "違反報告",
        created: "作成日",
        created_by: "作成者ID",
        firstname: "本名(名)",
        lastname: "本名(姓)",

    },
    character: {
        id: "ID",
        type: "種別",
        name: "名前",
        image_url: "画像URL",
        note: "備考",
        seq: "表示順",
        created: "作成日",
        created_by: "作成者ID",
        mobile_image_url: "モバイル用",
        icon_tag: "アイコン タグ"

    },
    team_review: {
        id: "ID",
        user_id: "投稿者ニックネーム",
        team_id: "チーム",
        user_status: "所属・体験ステータス",
        score_1: "指導",
        score_2: "練習環境",
        score_3: "練習量",
        score_4: "雰囲気",
        score_5: "アクセス",
        score_6: "費用",
        title: "クチコミ",
        review: "クチコミテキスト",
        status: "管理ステータス",
        note: "備考",
        created: "作成日",
        created_by: "作成者ID",
        disapprove: '非承認する',
        firstname: "本名(名)",
        lastname: "本名(姓)",
        nickname: '投稿者ニックネーム', 
        fullname: 'ユーザー'
    },
    contact: {
        id: "ID",
        category: "お問い合わせのカテゴリー",
        contact: "問合せ内容",
        lastname: "姓",
        firstname: "名",
        phone: "連絡先電話番号",
        email: "メールアドレス",
        note: "備考",
        user_id: "ユーザーID",
        contact_user: "ユーザー種別",
        company_name: "会社名",
        corporate_name: "団体名",
        created: "作成日"
    },
    point_type: {
        id: "ID",
        type: "ポイント種別",
        amount: "獲得できるポイント数",
        created: "作成日",
        created_by: "作成者",
        note: 'ポイント種別',
        user_comment: 'メモ',
        admin_comment: '備考',

    },
    user_point: {
        id: "ID",
        user_id: "ユーザー",
        point_type_id: "ポイント種別",
        point_received: "ポイントゲット日時",
        amount: "ポイント数",
        created: "作成日",
        created_by: "作成者ID",
        user: 'ユーザー',
        point_exchange_id: '利用ポイント',
        point_exchange_status: '利用ステータス',
        note: '備考',
        firstname: '本名（姓）',
        lastname: '本名（名）',
        email: 'メールアドレス',
        phone: '電話番号',
        title: '利用ポイント',
        id_of_user: 'ユーザーID'
    },
    point_exchange: {
        id: "ID",
        type: "交換カテゴリ",
        image_url: "商品画像",
        title: "商品名",
        description: "商品説明文",
        amount: "交換ポイント",
        status: "ステータス",
        note: "備考",
        created: "作成日",
        created_by: "作成者ID",
        item_name: '商品名',
        display_order: '表示順'
    },
    event_place: {
        id: "ID",
        event_id: "イベント",
        date_from: "開催日(開始)",
        date_to: "開催日(終了)",
        time: "時間",
        time_description: "時間詳細",
        name: "開催地名称",
        area: "開催地エリア",
        area_name: "エリア名",
        pref: "都道府県",
        pref_name: "都道府県名",
        city: "市区町村",
        city_name: "市区町村名",
        venue_post_code: "開催地郵便番号",
        venue_pref: "開催地都道府県",
        venue_city: "開催地市区町村",
        venue_address1: "開催地市区町村以下",
        venue_address2: "開催地住所その他",
        venue_line_cd1: "開催地最寄り駅1：沿線",
        venue_line1: "venue_line1",
        venue_station_cd1: "開催地最寄り駅1：駅",
        venue_station1: "venue_station1",
        venue_walk1: "開催地最寄り駅1：徒歩分数",
        venue_line_cd2: "開催地最寄り駅2：沿線",
        venue_line2: "venue_line2",
        venue_station_cd2: "開催地最寄り駅2：駅",
        venue_station2: "venue_station2",
        venue_walk2: "開催地最寄り駅2：徒歩分数",
        venue_line_cd3: "開催地最寄り駅3：沿線",
        venue_line3: "開催地最寄り駅3：沿線",
        venue_station_cd3: "開催地最寄り駅3：駅",
        venue_station3: "venue_station3",
        venue_walk3: "開催地最寄り駅3：徒歩分数",
        created: "作成日",
        created_by: "作成者",
        note: "備考",
        gen_code_button: "住所を自動入力"
    },
    event_plan: {
        id: "ID",
        event_id: "イベント",
        name: "プラン名",
        description: "説明",
        price: "金額",
        applicants: "募集人数",
        note: "備考",
        created_by: "作成者ID",
        created: "作成日"

    },
    area: {
        id: "ID",
        name: "名称",
        name_rome: "ローマ字表記"

    },
    pref: {
        id: "ID",
        area_id: "エリアID",
        name: "名称",
        name_rome: "ローマ字表記",
        seq: "表示順"
    },
    city: {
        id: "ID",
        pref_id: "都道府県ID",
        name: "名称",
        name_rome: "ローマ字表記"
    },
    team_staff: {
        id: "ID",
        user_id: "ユーザーID",
        team_id: "チーム",
        image_url: "プロフィール画像",
        name: "名前",
        email: "メールアドレス",
        title: "役職",
        comment: "コメント",
        note: "備考",
        csv_fields: {
            id: "ID",
            user_id: "ユーザーID",
            team_id: "チーム",
            image_url: "プロフィール画像",
            name: "名前",
            email: "メールアドレス",
            title: "役職",
            comment: "コメント",
            note: "備考",
            created_by: "作成者ID",
            created: "作成日"
        }
    },
    event_application: {
        id: "ID",
        user_id: "ユーザーID",
        event_id: "イベントID",
        event_contact_id: "申込者",
        same_user_id: "申込み本人かどうか",
        event_plan_id: "プランID",
        paid: "支払い金額",
        status: "ステータス",
        firstname: "名",
        lastname: "姓",
        firstname_kana: "メイ",
        lastname_kana: "セイ",
        gender: "性別",
        birthday: "生年月日",
        already_in_sports: "団体所属フラグ",
        created: "申込日",
        created_by: "作成者ID",
        note: "備考",
        status_updated_at: "ステータスの変更日",
        account_name: '団体名',
        user_name: 'ユーザー名',
        user_phone: 'TEL',
        user_email: 'メールアドレス',
        age: '年齢',
        csv_fields: {
            id: "ID",
            user_id: "ユーザーID",
            event_id: "イベントID",
            event_contact_id: "イベント問合せID",
            same_user_id: "申込み本人かどうか",
            event_plan_id: "プランID",
            paid: "支払い金額",
            status: "ステータス",
            firstname: "名",
            lastname: "姓",
            firstname_kana: "メイ",
            lastname_kana: "セイ",
            gender: "性別",
            birthday: "生年月日",
            already_in_sports: "団体所属フラグ",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
            status_updated_at: "ステータスの変更日",
            account_name: '団体名',
            user_name: 'ユーザー名',
            user_phone: 'TEL',
            user_email: 'メールアドレス',
            age: '年齢',
        }
    },
    team_experiencer: {
        id: "ID",
        user_id: "ユーザーID",
        team_contact_name: "体験者",
        team: 'チーム',
        same_user_id: "申込み本人かどうか",
        contact_type: '募集フラグ',
        status: "ステータス",
        firstname: "名",
        lastname: "姓",
        firstname_kana: "メイ",
        lastname_kana: "セイ",
        gender: "性別",
        birthday: "生年月日",
        already_in_sports: "団体所属フラグ",
        created: "申込日",
        created_by: "作成者ID",
        note: "備考",
        amount: "請求金額",
        join_date: "入会報告日",
        account_name: '団体名',
        user_name: 'ユーザー名',
        team_name: 'チーム名',
        user_phone: 'TEL',
        user_email: 'メールアドレス',
        monthly_fee: '月謝金額',
        age: '年齢',
        account_source: '流入経路',
        created_by_phone: '申込者電話番号',
        created_by_email: '申込者メールアドレス',
        account_email: '団体メールアドレス',
        account_phone: '団体電話番号',
        user_comment: "メモ",
        flag_disable: '完了フラグ',
        team_contact_message: 'メッセージ本文',
        account_owner_name: "団体オーナー",
        account_owner_email: "団体オーナーメールアドレス",
        fullname: "体験者名",
        status_updated_at: "ステータス変更日",
        representative_last_name : '代表者姓',
        representative_first_name : '代表者名',
        is_user_deleted: '退会',
        report_admin_approve_status : '承認ステータス',
        report_admin_decline_reason : '非認理由',
        payable_rate : '支払いパーセンテージ',
        user_fullname: "申込者",
        created_from: "申込日から",
        created_to: "申込日まで",
        csv_fields: {
            id: "ID",
            user_id: "ユーザーID",
            event_id: "イベントID",
            event_contact_id: "イベント問合せID",
            same_user_id: "申込み本人かどうか",
            event_plan_id: "プランID",
            paid: "支払い金額",
            status: "ステータス",
            firstname: "名",
            lastname: "姓",
            firstname_kana: "メイ",
            lastname_kana: "セイ",
            gender: "性別",
            birthday: "生年月日",
            already_in_sports: "団体所属フラグ",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
            join_date: "入会報告日",
            account_name: '団体名',
            age: '年齢',
            user_name: '申込者名',
            team_name: 'チーム名',
            user_phone: '申込者TEL',
            user_email: '申込者メールアドレス',
            monthly_fee: '月謝金額',
            account_source: '流入経路',
            user_comment: "メモ",
            team_contact_message: 'メッセージ本文',
            account_owner_name: "団体オーナー",
            account_owner_email: "団体オーナーメールアドレス",
            status_updated_at: "ステータス変更日",
            is_user_deleted: '退会',
            report_admin_approve_status : '承認ステータス',
            report_admin_decline_reason : '非認理由',
            payable_rate : '支払いパーセンテージ',
            user_fullname: "申込者"
        }
    },
    team_contact: {
        id: 'ID',
        user_id: 'ユーザー',
        team_id: 'チーム',
        contact_type: '募集フラグ',
        parent_flg: '保護者フラグ',
        contact_status: 'ステータス',
        phone: '電話番号',
        already_in_sports: '団体所属フラグ',
        message: 'メッセージ本文',
        created: '作成日',
        created_by: '作成者ID',
        note: '備考',
        attention_flg: 'オススメフラグ',
        btn_link_to_experiencer: '体験者一覧',
        account_name: '団体名',
        user_name: 'ユーザー名',
        team_name: 'チーム名',
        user_phone: 'TEL',
        user_email: 'メールアドレス',
        gender: '性別',
        birthday: '生年月日',
        age: '年齢',
        user_comment: "メモ",
        fullname: "ユーザー",
        created_by_phone: '申込者電話番号',
        created_by_email: '申込者メールアドレス',
        account_email: '団体メールアドレス',
        account_phone: '団体電話番号',
        flag_disable: '完了フラグ',
        status_updated_at: "ステータス変更日",
        representative_last_name : '代表者姓',
        representative_first_name : '代表者名',
        is_user_deleted: '退会',
        payable_rate : '支払いパーセンテージ',
        created_from: "申込日から",
        created_to: "申込日まで",
    },
    event_contact: {
        id: 'ID',
        user_id: 'ユーザー',
        event_id: 'イベント',
        contact_type: '募集フラグ',
        parent_flg: '保護者フラグ',
        contact_status: 'ステータス',
        message: 'メッセージ本文',
        created: '作成日',
        created_by: '作成者ID',
        note: '備考(スポスル)',
        attention_flg: 'オススメフラグ',
        event_contact_category: 'お問い合わせカテゴリ',
        btn_link_to_event_application: '体験者一覧',
        account_name: '団体名',
        user_name: 'ユーザー名',
        user_phone: 'TEL',
        user_email: 'メールアドレス'
    },
    account_place: {
        id: "ID",
        account_id: "団体名",
        team_id: "チーム",
        name: "練習場所名称",
        post_code: "郵便番号",
        pref_name: "都道府県",
        city_name: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        area: "エリア＞都道府県＞市区町村",
        area_name: "",
        pref: "エリア＞都道府県＞市区町村",
        search_pref_name: "",
        city: "エリア＞都道府県＞市区町村",
        search_city_name: "",
        line1: "路線名",
        line_cd1: "路線コード",
        station1: "駅名",
        station_cd1: "駅コード",
        walk1: "徒歩",
        line2: "路線名",
        line_cd2: "路線コード",
        station2: "駅名",
        station_cd2: "駅コード",
        walk2: "徒歩",
        line3: "路線名",
        line_cd3: "路線コード",
        station3: "駅名",
        station_cd3: "駅コード",
        walk3: "徒歩",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        text_detail_line1: '路線１',
        text_detail_line2: '路線２',
        text_detail_line3: '路線３',
        csv_fields: {
            id: "ID",
            account_id: "団体名",
            team_id: "チーム",
            name: "練習場所名称",
            post_code: "郵便番号",
            pref_name: "都道府県",
            city_name: "市区町村",
            address: "住所",
            building: "建物名・部屋番号",
            area: "エリア＞都道府県＞市区町村",
            area_name: "",
            pref: "エリア＞都道府県＞市区町村",
            search_pref_name: "",
            city: "エリア＞都道府県＞市区町村",
            search_city_name: "",
            line1: "路線名",
            line_cd1: "路線コード",
            station1: "駅名",
            station_cd1: "駅コード",
            walk1: "徒歩",
            line2: "路線名",
            line_cd2: "路線コード",
            station2: "駅名",
            station_cd2: "駅コード",
            walk2: "徒歩",
            line3: "路線名",
            line_cd3: "路線コード",
            station3: "駅名",
            station_cd3: "駅コード",
            walk3: "徒歩",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
            text_detail_line1: '路線１',
            text_detail_line2: '路線２',
            text_detail_line3: '路線３',
        }
    },
    team_game: {
        id: "ID",
        team_id: "チームID",
        publish_start_date: "公開期間開始日",
        publish_end_date: "公開期間終了日",
        genre_id: "ジャンル",
        sport_genre_id: "スポーツジャンル",
        game_date: "試合日",
        game_start_time: "試合開始時間",
        game_end_time: "試合終了時間",
        title: "タイトル",
        body: "詳細",
        post_code: "郵便番号",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        area_name: "エリア名",
        pref_name: "都道府県名",
        city_name: "市区町村名",
        address: "住所",
        building: "建物名・部屋番号",
        place: "場所",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        male: "男性",
        female: "女性",
        age: "対象年代",
        account_name: '団体名',
        csv_fields: {
            id: "ID",
            team_id: "チームID",
            publish_start_date: "公開期間開始日",
            publish_end_date: "公開期間終了日",
            genre_id: "ジャンル",
            sport_genre_id: "スポーツジャンル",
            game_date: "試合日",
            game_start_time: "試合開始時間",
            game_end_time: "試合終了時間",
            title: "タイトル",
            body: "詳細",
            post_code: "郵便番号",
            area: "エリア",
            pref: "都道府県",
            city: "市区町村",
            area_name: "エリア名",
            pref_name: "都道府県名",
            city_name: "市区町村名",
            address: "住所",
            building: "建物名・部屋番号",
            place: "場所",
            status: "ステータス",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
            male: "男性",
            female: "女性",
            age: "対象年代",
            account_name: '団体名',

        }
    },
    team_game_contact: {
        id: "ID",
        from_team_id: "リクエストチームID",
        account_name_from: 'リクエスト団体名',
        account_name_to: '受理団体名',
        to_team_id: "受理チーム",
        team_game_id: "練習試合",
        message: "メッセージ本文",
        connection_type: "経由",
        status: "ステータス",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        csv_fields: {
            id: "ID",
            from_team_id: "リクエストチームID",
            account_name_from: 'リクエスト団体名',
            account_name_to: '受理団体名',
            to_team_id: "受理チームID",
            team_game_id: "練習試合ID",
            message: "メッセージ本文",
            connection_type: "経由",
            status: "ステータス",
            created: "作成日",
            created_by: "作成者ID",
            note: "備考",
        }
    },
    shop: {
        id: "ID",
        name: "店舗・施設名",
        account_id: "アカウントID",
        manager_name: "責任者名",
        post_code: "郵便番号",
        pref_name: "都道府県",
        city_name: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        area: "エリア＞都道府県＞市区町村",
        pref: "エリア＞都道府県＞市区町村",
        city: "エリア＞都道府県＞市区町村",
        line1: "路線名",
        line_cd1: "路線コード",
        station1: "駅名",
        station_cd1: "駅コード",
        walk1: "徒歩",
        line2: "路線名",
        line_cd2: "路線コード",
        station2: "駅名",
        station_cd2: "駅コード",
        walk2: "徒歩",
        line3: "路線名",
        line_cd3: "路線コード",
        station3: "駅名",
        station_cd3: "駅コード",
        walk3: "徒歩",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        publish_status: "掲載ステータス",
        varified: "承認日",
        varified_by: "承認者ID",
        walk_bus_car: "徒歩・バス・車",
        user_comment: "メモ",
    },
    shop_draft: {
        id: "ID",
        name: "店舗・施設名",
        account_id: "アカウントID",
        manager_name: "責任者名",
        post_code: "郵便番号",
        pref_name: "都道府県",
        city_name: "市区町村",
        address: "住所",
        building: "建物名・部屋番号",
        area: "エリア＞都道府県＞市区町村",
        pref: "エリア＞都道府県＞市区町村",
        city: "エリア＞都道府県＞市区町村",
        line1: "路線名",
        line_cd1: "路線コード",
        station1: "駅名",
        station_cd1: "駅コード",
        walk1: "徒歩",
        line2: "路線名",
        line_cd2: "路線コード",
        station2: "駅名",
        station_cd2: "駅コード",
        walk2: "徒歩",
        line3: "路線名",
        line_cd3: "路線コード",
        station3: "駅名",
        station_cd3: "駅コード",
        walk3: "徒歩",
        created: "作成日",
        created_by: "作成者ID",
        note: "備考",
        publish_status: "掲載ステータス",
        varified: "承認日",
        varified_by: "承認者ID",
        walk_bus_car: "徒歩・バス・車",
        user_comment: "メモ",
    },
    school_gym: {
        id: "ID",
        name: "名称",
        name_kana: "チーム名フリガナ",
        account_id: "ユーザー",
        president_name: "代表者責任者名",
        philosophy: "チーム理念・指導方針",
        achievements: "主な過去の実績",
        logo_url: "チームロゴ",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像2",
        image7_url: "画像3",
        video_url: "動画あり(TOPページ用）",
        category: "カテゴリ",
        sub_category: "サブカテゴリ",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        age: "対象年代",
        practice_days: "練習曜日",
        practice_datetime: "練習日・練習時間",
        style: "スタイル",
        trial_type: "体験",
        visit_ok: "見学OKフラグ",
        trial_description: "体験・募集について",
        enrolled: "在籍人数",
        for_parents: "保護者の方にお願いする事",
        admission_fee: "入会金",
        annual_fee: "年会費",
        monthly_fee: "月謝あり・なし",
        monthly_fee_from: "月謝：下限",
        monthly_fee_to: "月謝：上限",
        things_to_prepare: "入会後ご用意いただきたいもの",
        fee_description: "費用に関する説明",
        initial_fee: "入会時に掛かる初期費用の目安",
        gear: "初回購入品と金額のJSON配列",
        created: "作成日",
        created_by: "ユーザーID",
        note: "備考",
        keyword: "",
        attention: "おすすめフラグ",
        publish_status: "掲載ステータス",
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        disability: "障害者歓迎",
        ladies: "レディース",
        team_tab_1: '初期設定情報',
        team_tab_2: '基本情報',
        team_tab_3: 'チーム・スクールの画像・特徴',
        team_tab_4: '特徴',
        team_tab_5: '募集・練習体験・セレクション',
        team_tab_6: '費用について',
        appeal_title: 'アピールタイトル',
        appeal_body: 'アピール本文',
        keyword1: 'タグ種別１',
        keyword2: 'タグ種別２',
        keyword3: 'タグ種別３',
        keyword4: 'タグ種別４',
        enroll_description: '在籍人数の詳細',
        placeholder_style_description_1: '例：だらだらと練習はしません。そのため、練習が厳しく感じるかもしれませんが、 楽しむことが大前提という意識で指導しております。',
        placeholder_style_description_2: '例：日数が少ない分、1日の練習量を多めに確保しております。',
        placeholder_style_description_3: '例：練習試合においては全選手、出場機会があるように心がけておりますが、 公式戦は勝ちにこだわるためシビアに選手選考を行います。',
        placeholder_style_description_4: '例：20代～50代が在籍しており、年齢層は幅広いです。',
        placeholder_style_description_5: '例：基本技術はとても大事にしておりますが、どちらかと言えば戦術重視のチームと 言えます。',
        placeholder_style_description_6: '例：男女比率はほぼ均等で、試合も男女混合で臨んでいます。',
        placeholder_style_description_7: '例：勝ち負けよりも、いかに楽しむかが大切です。小学生のうちは勝つことはそこまで 重要でないと考えております。',
        placeholder_style_description_8: '例：入会する前は、ほとんどの方が未経験です。ですので、未経験の方も安心して ください。',
        style_choice_description_1: '説明',
        style_choice_description_2: '説明',
        style_choice_description_3: '説明',
        style_choice_description_4: '説明',
        style_choice_description_5: '説明',
        style_choice_description_6: '説明',
        style_choice_description_7: '説明',
        style_choice_description_8: '説明',
        age_1: '',
        age_2: '',
        age_3: '',
        age_4: '',
        age_5: '',
        age_6: '',
        age_7: '',
        age_8: '',
        age_9: '',
        age_10: '',
        age_11: '',
        age_12: '',
        age_open_1: '',
        age_open_2: '',
        age_open_3: '',
        age_open_4: '',
        age_open_5: '',
        age_open_6: '',
        age_open_7: '',
        age_open_8: '',
        age_open_9: '',
        age_open_10: '',
        age_open_11: '',
        age_open_12: '',
        radio_value_1: '額',
        radio_value_2: '額',
        radio_value_3_from: 'からの量',
        radio_value_3_to: '匹敵する',
        gear_name_1: '初期購入品1',
        gear_name_2: '初期購入品2',
        gear_name_3: '初期購入品3',
        gear_name_4: '初期購入品4',
        gear_name_5: '初期購入品5',
        gear_name_6: '初期購入品6',
        gear_name_7: '初期購入品7',
        gear_name_8: '初期購入品8',
        gear_name_9: '初期購入品9',
        gear_name_10: '初期購入品10',
        gear_price_1: '金額1',
        gear_price_2: '金額2',
        gear_price_3: '金額3',
        gear_price_4: '金額4',
        gear_price_5: '金額5',
        gear_price_6: '金額6',
        gear_price_7: '金額7',
        gear_price_8: '金額8',
        gear_price_9: '金額9',
        gear_price_10: '金額10',
        is_frontend_created: 'ユーザー',
        is_frontend_created_search: '口コミのフィルタ',
        disapprove: '非承認する',
        msg_title_disapprove: '非承認の確認',
        msg_disapprove_message: '本当に、非承認に変更してよろしいでしょうか？',
        unpublish: '非公開にする(メール送信)',
        modal_copy_title: 'マージする対象の団体チームを選択してください。',
        copy_field: 'プルダウン',
        button_status_0: '作成中にする',
        button_status_1: '申請中にする',
        button_status_2: '公開中にする',
        button_status_3: '非公開にする',
        button_status_4: '非承認にする',
        approved_at: '承認日',
        account_name: '団体',
        team_id: 'チーム・スクール名',
        account_lastname: '姓',
        account_firstname: '名',
        account_email: 'メールアドレス',
        account_source: '流入経路',
        show_account_info: '運営者情報の表示',
        payable_rate: '支払いパーセンテージ',
        review_count: 'クチコミ数',
        team_place_name_1: '主な練習場所１',
        team_place_name_2: '主な練習場所２',
        team_place_name_3: '主な練習場所３',
        updated: '更新日',
        request_date: '申請日',
        monthly_fee_between: '下限・上限あり',
        approve_at: '承認日',
        team_connection: 'つながり設定', 
        shop_id: "店舗", 
        name2: "名称2",
        name_type: "",
        name_kana_type: "",
        name_kana2: "チーム名フリガナ2", 
        type: "種別"

    },
    school_gym_draft: {
        id: "ID",
        name: "名称",
        name_kana: "チーム名フリガナ",
        account_id: "ユーザー",
        president_name: "代表者責任者名",
        philosophy: "チーム理念・指導方針",
        achievements: "主な過去の実績",
        logo_url: "チームロゴ",
        image1_url: "画像1URL",
        image2_url: "画像2URL",
        image3_url: "画像3URL",
        image4_url: "画像4URL",
        image5_url: "画像5URL",
        image6_url: "画像2",
        image7_url: "画像3",
        video_url: "動画あり(TOPページ用）",
        category: "カテゴリ",
        sub_category: "サブカテゴリ",
        area: "エリア",
        pref: "都道府県",
        city: "市区町村",
        genre_id: "ジャンル",
        genre_sport_id: "スポーツ",
        age: "対象年代",
        practice_days: "練習曜日",
        practice_datetime: "練習日・練習時間",
        style: "スタイル",
        trial_type: "体験",
        visit_ok: "見学OKフラグ",
        trial_description: "体験・募集について",
        enrolled: "在籍人数",
        for_parents: "保護者の方にお願いする事",
        admission_fee: "入会金",
        annual_fee: "年会費",
        monthly_fee: "月謝あり・なし",
        monthly_fee_from: "月謝：下限",
        monthly_fee_to: "月謝：上限",
        things_to_prepare: "入会後ご用意いただきたいもの",
        fee_description: "費用に関する説明",
        initial_fee: "入会時に掛かる初期費用の目安",
        gear: "初回購入品と金額のJSON配列",
        created: "作成日",
        created_by: "ユーザーID",
        note: "備考",
        keyword: "",
        attention: "おすすめフラグ",
        publish_status: "掲載ステータス",
        genre_other: "ジャンル",
        genre_sport_other: "スポーツ",
        disability: "障害者歓迎",
        ladies: "レディース",
        team_tab_1: '初期設定情報',
        team_tab_2: '基本情報',
        team_tab_3: 'チーム・スクールの画像・特徴',
        team_tab_4: '特徴',
        team_tab_5: '募集・練習体験・セレクション',
        team_tab_6: '費用について',
        appeal_title: 'アピールタイトル',
        appeal_body: 'アピール本文',
        keyword1: 'タグ種別１',
        keyword2: 'タグ種別２',
        keyword3: 'タグ種別３',
        keyword4: 'タグ種別４',
        enroll_description: '在籍人数の詳細',
        placeholder_style_description_1: '例：だらだらと練習はしません。そのため、練習が厳しく感じるかもしれませんが、 楽しむことが大前提という意識で指導しております。',
        placeholder_style_description_2: '例：日数が少ない分、1日の練習量を多めに確保しております。',
        placeholder_style_description_3: '例：練習試合においては全選手、出場機会があるように心がけておりますが、 公式戦は勝ちにこだわるためシビアに選手選考を行います。',
        placeholder_style_description_4: '例：20代～50代が在籍しており、年齢層は幅広いです。',
        placeholder_style_description_5: '例：基本技術はとても大事にしておりますが、どちらかと言えば戦術重視のチームと 言えます。',
        placeholder_style_description_6: '例：男女比率はほぼ均等で、試合も男女混合で臨んでいます。',
        placeholder_style_description_7: '例：勝ち負けよりも、いかに楽しむかが大切です。小学生のうちは勝つことはそこまで 重要でないと考えております。',
        placeholder_style_description_8: '例：入会する前は、ほとんどの方が未経験です。ですので、未経験の方も安心して ください。',
        style_choice_description_1: '説明',
        style_choice_description_2: '説明',
        style_choice_description_3: '説明',
        style_choice_description_4: '説明',
        style_choice_description_5: '説明',
        style_choice_description_6: '説明',
        style_choice_description_7: '説明',
        style_choice_description_8: '説明',
        age_1: '',
        age_2: '',
        age_3: '',
        age_4: '',
        age_5: '',
        age_6: '',
        age_7: '',
        age_8: '',
        age_9: '',
        age_10: '',
        age_11: '',
        age_12: '',
        age_open_1: '',
        age_open_2: '',
        age_open_3: '',
        age_open_4: '',
        age_open_5: '',
        age_open_6: '',
        age_open_7: '',
        age_open_8: '',
        age_open_9: '',
        age_open_10: '',
        age_open_11: '',
        age_open_12: '',
        radio_value_1: '額',
        radio_value_2: '額',
        radio_value_3_from: 'からの量',
        radio_value_3_to: '匹敵する',
        gear_name_1: '初期購入品1',
        gear_name_2: '初期購入品2',
        gear_name_3: '初期購入品3',
        gear_name_4: '初期購入品4',
        gear_name_5: '初期購入品5',
        gear_name_6: '初期購入品6',
        gear_name_7: '初期購入品7',
        gear_name_8: '初期購入品8',
        gear_name_9: '初期購入品9',
        gear_name_10: '初期購入品10',
        gear_price_1: '金額1',
        gear_price_2: '金額2',
        gear_price_3: '金額3',
        gear_price_4: '金額4',
        gear_price_5: '金額5',
        gear_price_6: '金額6',
        gear_price_7: '金額7',
        gear_price_8: '金額8',
        gear_price_9: '金額9',
        gear_price_10: '金額10',
        is_frontend_created: 'ユーザー',
        is_frontend_created_search: '口コミのフィルタ',
        disapprove: '非承認する',
        msg_title_disapprove: '非承認の確認',
        msg_disapprove_message: '本当に、非承認に変更してよろしいでしょうか？',
        unpublish: '非公開にする(メール送信)',
        modal_copy_title: 'マージする対象の団体チームを選択してください。',
        copy_field: 'プルダウン',
        button_status_0: '作成中にする',
        button_status_1: '申請中にする',
        button_status_2: '公開中にする',
        button_status_3: '非公開にする',
        button_status_4: '非承認にする',
        approved_at: '承認日',
        account_name: '団体',
        team_id: 'チーム・スクール名',
        account_lastname: '姓',
        account_firstname: '名',
        account_email: 'メールアドレス',
        account_source: '流入経路',
        show_account_info: '運営者情報の表示',
        payable_rate: '支払いパーセンテージ',
        review_count: 'クチコミ数',
        team_place_name_1: '主な練習場所１',
        team_place_name_2: '主な練習場所２',
        team_place_name_3: '主な練習場所３',
        updated: '更新日',
        request_date: '申請日',
        monthly_fee_between: '下限・上限あり',
        approve_at: '承認日',
        team_connection: 'つながり設定', 
        shop_id: "店舗", 
        name2: "名称2",
        name_type: "",
        name_kana_type: "",
        name_kana2: "チーム名フリガナ2", 
        type: "種別"

    },
};

export default customJapanMessages;
