import PersonIcon from '@material-ui/icons/Person';

import List from './SchoolGymDraftList';
import Create from './SchoolGymDraftCreate';
import Edit from './SchoolGymDraftEdit';

export default {
    list: List,
    create: Create,
    edit: Edit,
    icon: PersonIcon,
};
