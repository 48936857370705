import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
  APPROVE_EVENT, APPROVE_EVENT_OPENNING, APPROVE_EVENT_WAITING, CopyEventPlanPlace, ExecuteCopyEvent, GetDataEvent, GetEventApproveError, GetEventApproveStatus3, GetEventApproveStatus4, SendMailApprove
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";
import moment from 'moment';


const ButtonApprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  let countSuccess = 0;
  let countFail = 0;

  const { selectedIds } = props;
  const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  const ApproveEvent = async (list_event_draft_id: number[]) => {
    let status = 2;
    for (let index = 0; index < list_event_draft_id.length; index++) {
      const event_draft_id = list_event_draft_id[index];
      // check time approve
      let dateNow = moment().utcOffset('+0900').format('YYYY-MM-DD');
      //console.log('111111111dateNow',dateNow);
      
      const List_status_3 = await GetEventApproveStatus3(apolloClient, event_draft_id, dateNow);
      const List_status_4 = await GetEventApproveStatus4(apolloClient, event_draft_id, dateNow);
      const List_error = await GetEventApproveError(apolloClient, event_draft_id, dateNow);
      //console.log('111111111List_status_3',List_status_3);
      //console.log('111111111List_status_4',List_status_4);
      //console.log('111111111List_error',List_error);

      if (List_status_3.length > 0) {
        status = 3
      } else if (List_status_4.length > 0) {
        status = 4
      }
      const dataEventDraft = await GetDataEvent(apolloClient, event_draft_id);
      //console.log('11111111111dataEventDraft',dataEventDraft);
      
      const dataCopy = await ExecuteCopyEvent(dataEventDraft, apolloClient, status);
      //console.log('11111111111dataCopy',dataCopy);
      
      const dataEventPlace = dataEventDraft.event_place_drafts;
      const dataEventPlan = dataEventDraft.event_plan_drafts;
      const dataEventPlanOld = dataEventDraft.event.event_plans
      const dataInsertPlacePlan = await CopyEventPlanPlace(apolloClient, dataEventPlace, dataEventPlan,dataEventPlanOld, dataEventDraft.event_id);
      const sendMailApprove = await SendMailApprove(apolloClient,dataEventDraft.event_id);
      console.log('sendMailApprove',sendMailApprove);
      
      countSuccess++
      
    }
    //console.log('11111111111countSuccess',countSuccess);
    
    return { countSuccess: countSuccess };
  }
  const handleClick = async () => {
    //console.log('11111111111selectedIds',selectedIds);
    
    const data = await ApproveEvent(selectedIds);
    if (data.countSuccess > 0) {
      notify(`${data.countSuccess}チームが承認されました。`)
      unselectAll('event_draft');
      refresh();
    } else {
      notify(translate('error_approved'), 'warning');
      unselectAll('event_draft');
    }
  }
  return <Button
    disabled={isConverting || props.disabled}
    //variant="contained"
    startIcon={<CheckCircle />}
    color="secondary"
    onClick={handleClick}>
    {translate('account.approve')}
  </Button>
}

ButtonApprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonApprove.defaultProps = {
  selectedIds: []
}

export default ButtonApprove;