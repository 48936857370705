export const TYPE = [
    {id: 1, name: "クチコミ投稿"},
    {id: 2, name: "ＱＡ投稿"},
    {id: 3, name: "ＱＡ回答"},
    {id: 4, name: "応募"},
    {id: 5, name: "問い合わせ"},
    {id: 6, name: "イベント応募"},
    {id: 7, name: "チーム追加"},
    {id: 8, name: "入会決定"},

]
const DATA = [
{ name: 'id', type: 'textinput', options: TYPE,searchField: '',reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'amount', type: 'textinput', numberFormat:true, nullable: false, editable: true, export: true, import: true },
{ name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'user_comment', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'admin_comment', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },


]

export default DATA;