import PersonIcon from '@material-ui/icons/Person';

import AccountOwnerList from './AccountOwnerList';
import AccountOwnerCreate from './AccountOwnerCreate';
import AccountOwnerEdit from './AccountOwnerEdit';

export default {
    list: AccountOwnerList,
    create: AccountOwnerCreate,
    edit: AccountOwnerEdit,
    icon: PersonIcon,
};
