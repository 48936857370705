import { gql } from "@apollo/client";
import { number } from "react-admin";
export const PAID = [
  { id: 0, name: '未払い' },
  { id: 1, name: '支払済み' },
  { id: -1, name: 'エラー' },
]
export const PAYMENT_METHOD = [
  { id: 0, name: '銀行振り込み' },
  { id: 1, name: 'Stripe' }
]
export const GET_DATA_BILLINGS = gql`
query MyQuery($account_address: String, 
  $city: String, 
  $pref: String, 
  $month: Int, 
  $year: Int, 
  $account_name: String, 
  $paid: Int, 
  $payment_method: Int, 
  $date_from: timestamp, 
  $date_to: timestamp
) {
  billing_view(where: {
    account_address: {_ilike: $account_address}, 
    account_pref: {_ilike: $pref}, 
    account_city: {_ilike: $city}, 
    account_name: {_ilike: $account_name}, 
    month: {_eq: $month},
    year: {_eq: $year}, 
    paid: {_eq:$paid},
    payment_method:{_eq:$payment_method}, 
    _and: [{payment_method_update_from: {_gte:$date_from}}, {payment_method_update_from:{_lte: $date_to}}]
  }, order_by: {id: desc}) {
    issue_date
    month
    note_billing
    paid
    paid_date
    payment_method
    payment_reference
    tax_amount
    total_amount
    updated_at_billing
    year
    account_address
    account_city
    account_id
    account_pref
    billing_detail_amount
    billing_detail_created_at
    billing_detail_created_by
    billing_detail_discount
    billing_detail_id
    billing_detail_percentage
    billing_detail_product
    billing_detail_stripe_product_id
    billing_detail_tax
    billing_detail_unit
    billing_detail_updated_at
    billing_id
    created_at_billing
    discount
    filename
    filepath
    account_name
    error_message
    due_date
    receipt_filepath
    receipt_filename
    deposit_amount
    deposit_date
    account_payment_method
    stripe_id
    team_experiencer_name
    join_date
    status_updated_at
    payment_method_update_from
  }
  billing_view_aggregate(where: {
    account_address: {_ilike: $account_address}, 
    account_pref: {_ilike: $pref},
    account_city: {_ilike: $city}, 
    month: {_eq: $month}, 
    year: {_eq: $year}, 
    account_name: {_like: $account_name}
    paid: {_eq: $paid},
    payment_method:{_eq: $payment_method}, 
    _and: [{payment_method_update_from: {_gte:$date_from}}, {payment_method_update_from:{_lte: $date_to}}]
  }) {
    aggregate {
      count(columns: billing_detail_id)
      sum {
        amount_after_discount
      }
      sum {
        tax_amount_after_discount
      }
    }
  }
}


`;
export const GET_DATA_BILLINGS_BY_ID = gql`
query MyQuery($billing_detail_id: Int) {
  billing_view(where: {billing_detail_id: {_eq: $billing_detail_id}}) {
    issue_date
    month
    note_billing
    paid
    paid_date
    payment_method
    payment_reference
    tax_amount
    total_amount
    updated_at_billing
    year
    account_address
    account_city
    account_id
    account_pref
    billing_detail_amount
    billing_detail_created_at
    billing_detail_created_by
    billing_detail_discount
    billing_detail_id
    billing_detail_percentage
    billing_detail_product
    billing_detail_stripe_product_id
    billing_detail_tax
    billing_detail_unit
    billing_detail_updated_at
    billing_id
    created_at_billing
    discount
    filename
    filepath
    account_name
    error_message
    due_date
    receipt_filepath
    receipt_filename
    deposit_amount
    deposit_date
    account_payment_method
    stripe_id
    team_experiencer_name
    join_date
    status_updated_at
    note_billing
    payment_method_update_from
    amount_after_discount
    tax_amount_after_discount
    account_name_history
  }
  billing_detail(where: {id: {_eq: $billing_detail_id}}) {
    id
    amount
    amount_after_discount
    tax_amount_after_discount
  }
}
`;
export const GET_PRESIGNED_DOWNLOAD = gql`
mutation MyMutation($filename: String) {
  generate_download_presigned_url(files: $filename, filetype: "invoice", foldername: "") {
    error
    signedUrl
  }
}`;
export const SEND_EMAIL_BILLING = gql`
mutation send_email_billing_by_id($billing_id: Int) {
  result: send_email_billing_by_id(billing_id: $billing_id) {
    error_code
    error_message
    status_code
  }
}`;
export const GET_YEAR_BILLING = gql`
query MyQuery {
  billing_view(distinct_on: year) {
    year
  }
}`;
export const UPDATE_PAID_BILLING = gql`
mutation MyMutation($paid: Int, $id: Int) {
  update_billing(where: {billing_details: {id: {_eq: $id}}}, _set: {paid: $paid}) {
    affected_rows
  }
}
`;
export const UPDATE_PAYMENT_METHOD_BILLING = gql`
mutation MyMutation($payment_method: Int, $id: Int) {
  update_billing(where: {billing_details: {id: {_eq: $id}}}, _set: {payment_method: $payment_method}) {
    affected_rows
  }
}
`;
export const UPDATE_BILLING_DETAIL = gql`
mutation MyMutation($deposit_amount: Int, $id: Int, $paid: Int, $payment_method: Int, $paid_date: date, $deposit_date: timestamp, $due_date: date, $total_amount: Int, $tax_amount: Int, $issue_date: timestamp) {
  update_billing(where: {billing_details: {id: {_eq: $id}}}, _set: {deposit_amount: $deposit_amount, payment_method: $payment_method, paid: $paid, paid_date: $paid_date, deposit_date: $deposit_date, due_date: $due_date,issue_date: $issue_date}) {
    affected_rows
  }
  update_billing_detail(where: {id: {_eq: $id}}, _set: {amount: $total_amount, tax_amount: $tax_amount,note: $note_billing}) {
    affected_rows
  }
}
`;
export const GET_TOTAL_AMOUNT = gql`
query MyQuery($billing_id: Int) {
  billing_detail_aggregate(where: {billing_id: {_eq:$billing_id}}) {
    aggregate {
      sum {
        amount_after_discount
        tax_amount_after_discount
      }
    }
  }
}
`;
export const UPDATE_BILLING_DETAIL_ONE = gql`
mutation MyMutation($id: Int!, $billing_id: Int, $amount_after_discount: Int, $tax_amount_after_discount: Int, $discount: Int) {
  update_billing_detail_by_pk(pk_columns: {id: $id}, _set: {billing_id: $billing_id, amount_after_discount: $amount_after_discount, tax_amount_after_discount: $tax_amount_after_discount,discount:$discount}) {
    id
  }
}
`;
export const UPDATE_BILLING_ONE = gql`
mutation MyMutation($id: Int!, $paid: Int, $payment_method: Int, $deposit_amount: Int, $deposit_date: timestamp, $due_date: date, $total_amount: Int, $tax_amount: Int, $issue_date: date,$paid_date: date) {
  update_billing_by_pk(pk_columns: {id: $id}, _set: {paid: $paid, payment_method: $payment_method, deposit_amount: $deposit_amount, deposit_date: $deposit_date, due_date: $due_date, total_amount: $total_amount, tax_amount: $tax_amount, issue_date: $issue_date, paid_date: $paid_date}) {
    id
  }
}
`;
export const INSERT_TOKEN_INVOICE = gql`
mutation MyMutation($dataInsert: token_generate_invoice_insert_input!) {
  insert_token_generate_invoice_one(object: $dataInsert) {
    created
    id
    is_used
    note
    token
    update_at
  }
}`;
export const GET_DATA_BILLING_BY_MONTH = gql`
query MyQuery($account_id: Int , $month: Int, $year: Int) {
  billing(where: {account_id: {_eq: $account_id}, month: {_eq: $month}, year: {_eq: $year}}){
    id
  }
}
`;
export const INSERT_BILLING_ONE = gql`
mutation MyMutation($dataBilling: billing_insert_input!) {
  insert_billing_one(object: $dataBilling) {
    id
  }
}
`;
export interface Record {
  [key: string]: any;
}
export interface DataInsertBilling extends Record {
  billing_detail_id: number,
  paid: number,
  payment_method: number,
  deposit_amount: number,
  paid_date: string,
  deposit_date: string,
  due_date: string,
  totalBillingDetailAmount: number,
  TaxBillingDetail: number,
  issue_date: string,
  amount_after_discount: number,
  tax_amount_after_discount: number,
  billing_id: number,
  account_id: number, 
  year: any, 
  month: any, 
  discount: number
}; 
export interface DataCreateNewBilling extends Record {
  account_id: number, 
  year: number, 
  month: number, 
  paid: number, 
  discount: number, 
  issue_date: string, 
  paid_date: string, 
  payment_method: number,
  deposit_amount: number, 
  deposit_date: string
}
export const GetPresignedDownload = async (apolloClient: any, filename: string) => {
  console.log('dataPreSigned3', filename);
  let { data: dataUrl } = await apolloClient.mutate({
    mutation: GET_PRESIGNED_DOWNLOAD,
    variables: { filename: filename }
  });
  console.log('dataPreSigned2', dataUrl);

  return { preUrl: dataUrl.generate_download_presigned_url.signedUrl };
}
export const InsertTokenInvoice = async (apolloClient: any, token: string) => {
  let { data: dataInsert } = await apolloClient.mutate({
    mutation: INSERT_TOKEN_INVOICE,
    variables: { dataInsert: { token } }
  });
  console.log('dataPreSigned2', dataInsert);
  return { dataInsert };
}
export const SendEmailBilling = async (apolloClient: any, billing_id: number) => {
  let { data: dataSendEmail } = await apolloClient.mutate({
    mutation: SEND_EMAIL_BILLING,
    variables: { billing_id: billing_id }
  });
  return dataSendEmail;
}

export const GetDataBilling = async (apolloClient: any,
  month: number,
  year: number,
  accountAddress: string,
  accountPref: string,
  accountCity: string,
  account_name: string,
  paid: number,
  payment_method: number,
  date_from: string,
  date_to: string
) => {
  let dataSearch = {};
  if (month === 0 && year === 0) {
    dataSearch = {
      account_address: `%${accountAddress}%`,
      pref: `%${accountPref}%`,
      city: `%${accountCity}%`,
      account_name: `%${account_name}%`,
    }
  } else if (month === 0 && year !== 0) {
    dataSearch = {
      account_address: `%${accountAddress}%`,
      pref: `%${accountPref}%`,
      city: `%${accountCity}%`,
      account_name: `%${account_name}%`,
      year: year
    }
  } else if (month !== 0 && year === 0) {
    dataSearch = {
      account_address: `%${accountAddress}%`,
      pref: `%${accountPref}%`,
      city: `%${accountCity}%`,
      account_name: `%${account_name}%`,
      month: month
    }
  }
  else if (month !== 0 && year !== 0) {
    dataSearch = {
      account_address: `%${accountAddress}%`,
      pref: `%${accountPref}%`,
      city: `%${accountCity}%`,
      account_name: `%${account_name}%`,
      month: month,
      year: year
    }
  }

  if (paid !== -2) {
    dataSearch = { ...dataSearch, paid: paid }
  }
  if (payment_method !== -2) {
    dataSearch = { ...dataSearch, payment_method: payment_method }
  }
  if (date_from) {
    dataSearch = { ...dataSearch, date_from: date_from }
  }
  if (date_to) {
    dataSearch = { ...dataSearch, date_to: date_to }
  }
  let { data: dataBilling } = await apolloClient.query({
    query: GET_DATA_BILLINGS,
    variables: dataSearch
  });

  return {
    dataBilling: dataBilling.billing_view,
    data_count_account: dataBilling.billing_view_aggregate.aggregate.count,
    data_total_amount: dataBilling.billing_view_aggregate.aggregate.sum.amount_after_discount,
    data_total_tax_amount: dataBilling.billing_view_aggregate.aggregate.sum.tax_amount_after_discount,
  };
}
export const GetDataBillingById = async (apolloClient: any, billing_detail_id: number) => {
  let dataSearch = { billing_detail_id: billing_detail_id };
  let { data: dataBilling } = await apolloClient.query({
    query: GET_DATA_BILLINGS_BY_ID,
    variables: dataSearch
  });
  return {
    dataBilling: dataBilling.billing_view,dataBillingDetail : dataBilling.billing_detail
  };
}
export const GetDataYear = async (apolloClient: any) => {
  let { data: { billing_view } } = await apolloClient.query({
    query: GET_YEAR_BILLING,
    variables: {}
  });
  let ListYear = [{ id: 0, name: '全て' }];
  for (const billing of billing_view) {
    let index = billing.year
    const object = { id: index, name: billing.year.toString() }
    ListYear.push(object)
  }
  return ListYear;
}
export const updateTable = async (apolloClient: any, dataInsert: DataInsertBilling) => {
  let status = 1;
  let billing_id = dataInsert.billing_id;  
  // Check exist billing in this month
  const billing_id_this_month = await GetBillingByMonthYear(apolloClient,dataInsert.account_id, dataInsert.month,dataInsert.year);  
  if(billing_id_this_month > 0){
    billing_id = billing_id_this_month
  }else {
    // Create new billing for this month
    const dataInsertBillingOne = {
      account_id: dataInsert.account_id, 
      year: dataInsert.year, 
      month: dataInsert.month, 
      paid: dataInsert.paid, 
      discount: dataInsert.discount, 
      issue_date: dataInsert.issue_date, 
      paid_date: dataInsert.paid_date, 
      payment_method: dataInsert.payment_method,
      deposit_amount: dataInsert.deposit_amount, 
      deposit_date: dataInsert.deposit_date
    }
    const billing_id_new  = await CreateBillingForThisYear(apolloClient, dataInsertBillingOne);
    billing_id = billing_id_new;
  }

  //update billing detail
  let { data: dataUpdate } = await apolloClient.mutate({
    mutation: UPDATE_BILLING_DETAIL_ONE,
    variables: { 
      id: dataInsert.billing_detail_id, 
      billing_id: billing_id, 
      amount_after_discount: dataInsert.amount_after_discount, 
      tax_amount_after_discount: dataInsert.tax_amount_after_discount, 
      discount: dataInsert.discount
    }
  });
  
  if(!dataUpdate){
    return  0;
  }
  // calcu totalAmountBilling 
  let { data: dataBillingDetail } = await apolloClient.query({
    query: GET_TOTAL_AMOUNT,
    variables: { billing_id: billing_id }
  });
  if(!dataBillingDetail){
    return  0;
  }
  const totalAmountBilling = dataBillingDetail.billing_detail_aggregate.aggregate.sum.amount_after_discount;
  const totalTaxAmountBilling = dataBillingDetail.billing_detail_aggregate.aggregate.sum.tax_amount_after_discount;
  let { data: dataUpdateBilling } = await apolloClient.mutate({
    mutation: UPDATE_BILLING_ONE,
    variables: { 
      id: billing_id, 
      paid: dataInsert.paid, 
      payment_method: dataInsert.payment_method, 
      deposit_amount: dataInsert.deposit_amount, 
      paid_date: dataInsert.paid_date, 
      deposit_date: dataInsert.deposit_date, 
      due_date: dataInsert.due_date, 
      total_amount: totalAmountBilling, 
      tax_amount: totalTaxAmountBilling, 
      issue_date: dataInsert.issue_date, 
    }
  });
  if(!dataUpdateBilling){
    return  0;
  }
  return status;
}
const GetBillingByMonthYear = async (apolloClient: any,account_id: number, month: number, year: number) => {
  let billing_id = 0
  let { data: {billing} } = await apolloClient.query({
    query: GET_DATA_BILLING_BY_MONTH,
    variables: {account_id: account_id, month: month,year: year}
  });
  if(billing.length > 0){
    billing_id = billing[0].id;
  }
  return billing_id;
}
const CreateBillingForThisYear = async (apolloClient: any,dataInsertBilling:DataCreateNewBilling ) => {
  let billing_id = 0;  
  let { data: dataBilling } = await apolloClient.mutate({
    mutation: INSERT_BILLING_ONE,
    variables: {dataBilling: dataInsertBilling}
  });
  if(dataBilling){
    billing_id = dataBilling.insert_billing_one.id
  }
  return billing_id;
}


