import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import { translate } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { TextInput } from 'react-admin';
import { SelectInput } from 'react-admin';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();
  if (id) {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}新規登録
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;
const CustomInput = (props: any) => {
  const items = props.choices[0] ? props.choices[0].account_name_history : [];
  return <>
    {items && <p>団体名変更履歴</p>}
    {items && items.map((item: any, index: any) => (
      <p style={{ color: 'GrayText' }}>旧団体名 {index + 1}: {item.name} : {item.modified}</p>
    ))}
    <p></p>
  </>
}

const NFrom = (props: any) => {
  const translate = useTranslate();
  const { editForm } = props;
  const { record } = props;
  const items = record['account_name_history'] || [];
  if (editForm) {
    var { record: { id } } = props;
  }
  var isEditPage = editForm === true ? true : false;
  return <Container style={{ justifyContent: 'center' }}>
    <Grid style={{ width: '100%' }} container justify='center' spacing={2}>
      <Grid style={{ width: '50%' }} container justify='center' spacing={2} >
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.team_experiencer.edit_page" : "menu.team_experiencer.add_page"} id={editForm ? id : ''} />
          <Separator />
        </Grid>
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }

          if (d.name === 'account_name_history') {
            return <Grid item xs={12} key={idx}>
              <ReferenceInput allowEmpty={true}  disabled={'disabled'}
                fullWidth
                variant="outlined" source="id" reference="team_experiencer_list" label={translate('account.account_name_history')} >
                <CustomInput />
              </ReferenceInput>
            </Grid>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }
          if (d.name === 'team_contact_id') {
            return <></>
          }
          if (d.name === 'team_name' && d.type === "textinput" && isEditPage === true) {
            return <Grid item xs={12} key={idx}>
              <ReferenceInput allowEmpty={true} disabled={'disabled'}
                fullWidth
                variant="outlined" source="id" reference="team_experiencer_list" filterToQuery={(searchText: any) => ({ ['team_name']: searchText })}  label={translate('team_experiencer.team_name')} alwaysOn>
                <SelectInput optionText={'team_name'} />
              </ReferenceInput>
            </Grid>
          }
          if (d.name === 'account_name' && isEditPage === true) {
            return <Grid item xs={12} key={idx}>
              <ReferenceInput   disabled={'disabled'}
                fullWidth
                variant="outlined" source="id" reference="team_experiencer_list" filterToQuery={(searchText: any) => ({ ['account_name']: searchText })} label={translate('team_experiencer.account_name')} >
                <SelectInput optionText={'account_name'} />
              </ReferenceInput>
            </Grid>
          }
          return <Grid item xs={12} key={idx}>
            <InputRenderer source='team_experiencer' data={d} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;