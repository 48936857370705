import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Replay } from '@material-ui/icons';
import {
  useTranslate,
} from 'react-admin';
import { Paper, FormControl, Select, MenuItem, InputLabel, Button, TextField, Grid } from '@material-ui/core';
import { MONTHS, YEARS } from '../billing/dataset';
import { GetDataYear } from './gql';
import { useApolloClient } from '@apollo/client';
import { number } from 'react-admin';
const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20

  },
  formControl: {
    paddingRight: 30,
    minWidth: 100,
    paddingTop: 10
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  InputField : {
    paddingTop: 10
  }, 
  title: {
    
  }
}));

export interface TypeYear {
  id: number, 
  name: string
}
const ToolBar = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const {onChangeBtnSave, isDisable, BillingMonth, BillingYear, AccountAddress, AccountPref, AccountCity, AccountName, onSelectBoxChange , reloadPage} = props;
  const listYear = props.listYear as TypeYear[];
  return <Paper id='paper-toolbar-billing' className={classes.toolbar}>
    <Grid
      alignItems={"flex-start"}
    >
      <FormControl className={classes.formControl}>
      <InputLabel id="bill-year-select-label">{translate('billing.year')}</InputLabel>
      <Select
        labelId="bill-year-select-label"
        id="bill-year-select"
        value={BillingYear}
        name='BillingYear'
        onChange={onSelectBoxChange}
      >
        {listYear.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>

    {/* billing Month */}
    <FormControl className={classes.formControl}>
      <InputLabel id="billing-month-select-label">{translate('billing.month')}</InputLabel>
      <Select
        labelId="billing-month-select-label"
        id="billing-month-select"
        value={BillingMonth}
        name='billingMonth'
        onChange={onSelectBoxChange}
      >
        {MONTHS.map((m, idx) => {
          return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
        })}
      </Select>
    </FormControl>
    <FormControl className={classes.formControl}>
      <InputLabel  className='label-search' id="billing-account-name">{translate('account.name')}</InputLabel>
      <TextField
        //labelId="billing-year-select-label"
        id="billing-account-name"
        value={AccountName}
        className='input-search-bill'
        name='account-name'
        onChange={onSelectBoxChange}
      >
      </TextField>
    </FormControl>
      <FormControl className={classes.formControl}>
      <InputLabel  className='label-search' id="billing-account-address">{translate('account.address')}</InputLabel>
      <TextField
        //labelId="billing-year-select-label"
        id="billing-account-address"
        value={AccountAddress}
        className='input-search-bill'
        name='account-address'
        onChange={onSelectBoxChange}
      >
      </TextField>
    </FormControl>
    <FormControl className={classes.formControl}>
      <InputLabel  className='label-search' id="billing-account-pref">{translate('account.pref')}</InputLabel>
      <TextField
        //labelId="billing-year-select-label"
        id="billing-account-pref"
        value={AccountPref}
        className='input-search-bill'
        name='account-pref'
        onChange={onSelectBoxChange}
      >
      </TextField>
    </FormControl>
    <FormControl className={classes.formControl}>
      <InputLabel className='label-search' id="billing-account-city">{translate('account.city')}</InputLabel>
      <TextField
        //labelId="billing-year-select-label"
        className='input-search-bill'
        id="billing-account-city"
        value={AccountCity}
        name='account_city'
        onChange={onSelectBoxChange}
      >
      </TextField>
    </FormControl>

    <FormControl className={classes.formControl}>
      <Button
        disabled={isDisable}
        variant="contained"
        color="primary"
        onClick={onChangeBtnSave}
        >
       {translate('請求データCSVのダウンロード')}
    </Button>
   
    </FormControl>
    {/* <Button
        disabled={isDisable}
        variant="contained"
        color="primary"
        onClick={reloadPage}
        startIcon={<Replay />}
        >
       {translate('reload')}
    </Button> */}
    </Grid>
  </Paper>
}

export default ToolBar;