import * as React from 'react';
import { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps, Account } from '../../types';
import { useTranslate } from 'react-admin';

const StatusField: FC<FieldProps<any>> = ({ record }) => {
    const translate = useTranslate();
    let label = '';
    let color = '';
    switch (record.status) {
        case 0:
            label = translate('common.status_event_0');
            color = '#808080';
            break;
        case 1:
            label = translate('common.status_event_1');
            color = '#FF5C93';
            break;
        case 2:
            label = translate('common.status_event_2');
            color = '#FF9900';
            break;
        case 3:
            label = translate('common.status_event_draft_3');
            color = '#3CD4A0';
            break;

    }
    return record ? (
        <span>
            <Chip
                label={label}
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor: color,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    ) : null;
};
export default StatusField;
