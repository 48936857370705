import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import { ChipField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import { AutocompleteInput } from 'react-admin';
import exporter from './exporter';
import { useHistory } from 'react-router';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id,amount" label={translate('user_list.id')} alwaysOn />
            <ReferenceInput
                filterToQuery={(searchText: ' ') => ({ 'firstname': searchText })}
                source="user_id" reference="user" alwaysOn label={translate('user_point.user')}>
                <AutocompleteInput optionText="firstname" />
            </ReferenceInput>
            <ReferenceInput allowEmpty={true} filterToQuery={(searchText: any) => ({ phone: searchText })} source="user_id" reference="user" label={translate('user_list.email')} alwaysOn>
                <AutocompleteInput optionText={'phone'}/>
            </ReferenceInput>
            <ReferenceInput allowEmpty={true} filterToQuery={(searchText: any) => ({ email: searchText })} source="user_id" reference="user" label={translate('user_list.phone')} alwaysOn>
                <AutocompleteInput optionText={'email'}/>
            </ReferenceInput>
            <SearchInput source="note" alwaysOn />
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit' : '';
    const module = 'user_point';
    const history = useHistory();
    useEffect(() => {
        history.location.pathname = '/user_point';
    }, [props]);
    if (role === undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'user_point', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='point_type' />}
            empty={<ListEmpty />}
            title={translate('menu.point_type.list')}
        >
            <CustomizableDatagrid rowClick={edit}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, isDate } = f || {}
                    if (type === 'textinput' && name !== 'created') {
                        // if (numberFormat) {
                        //     return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        // }
                        if (isDate) {
                            return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'user_id') {
                            if (searchField === 'id') {
                                return <></>
                            } else {
                                return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                    <TextField source={searchField} />
                                </ReferenceField>
                            }
                        } else if (name === 'point_type_id') {
                            return <ReferenceField label={translate(`${module}.${name}`)} link={false} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                        else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }

                    }

                    if (type === 'virtual_reference') {
                        if (name === 'n_master_name') {
                            return <ReferenceField label={translate('n_master.name')} source="n_master_id" reference='n_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                        if (name === 'n_facility_master_name') {
                            return <ReferenceField label={translate('n_facility_master.name')} source="n_facility_master_id" reference='n_facility_master'>
                                <TextField source="name" />
                            </ReferenceField>
                        }
                    }

                    if (type === 'date' || type === 'datetime' || name === 'created') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default UserList;
