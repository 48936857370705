import * as React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import { useApolloClient } from "@apollo/client";
import {
  APPROVE_EVENT, APPROVE_EVENT_OPENNING, APPROVE_EVENT_OUT_OF_DATE, APPROVE_EVENT_WAITING, GetEventApproveError, GetEventApproveStatus3, GetEventApproveStatus4
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";
import moment from 'moment';


const ButtonApprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  let countSuccess = 0;
  let countFail = 0;

  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  const ApproveEvent = async (event_id: number[]) => { 

    let dateNow = moment().utcOffset('+0900').format('YYYY-MM-DD');
    const List_status_3 = await GetEventApproveStatus3(apolloClient, event_id, dateNow);
    const List_status_4 = await GetEventApproveStatus4(apolloClient, event_id, dateNow);
    const List_error = await GetEventApproveError(apolloClient, event_id, dateNow);
    const approved_at = new Date().toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"});

    //countFail = List_error.length; // out of date
    countSuccess = List_status_3.length + List_status_4.length + List_error.length;
    const { data } = await apolloClient.mutate({
      mutation: APPROVE_EVENT,
      variables: {
        list_event_id: event_id,
        approved_at: approved_at
        //varified_by: parseInt(user_id)
      }
    });
    const { data: dataAproveStatus3 } = await apolloClient.mutate({
      mutation: APPROVE_EVENT_WAITING,
      variables: {
        list_event_id: List_status_3,
        //varified_by: parseInt(user_id)
      }
    });
    const { data: dataAproveStatus4 } = await apolloClient.mutate({
      mutation: APPROVE_EVENT_OPENNING,
      variables: {
        list_event_id: List_status_4,
        //varified_by: parseInt(user_id)
      }
    });

    const { data: dataAproveStatus0 } = await apolloClient.mutate({
      mutation: APPROVE_EVENT_OUT_OF_DATE,
      variables: {
        list_event_id: List_error,
        //varified_by: parseInt(user_id)
      }
    });


    return {countSuccess: countSuccess, countFail: countFail};
  }
  const handleClick = async () => {
    const data = await ApproveEvent(selectedIds);
    if (data.countSuccess > 0) {
      notify(`${data.countSuccess}チームが承認されました。, ${data.countFail} チームが承認されませんでした`)
      unselectAll('event');
      refresh();
    } else {
      notify(translate(`${data.countFail}チームが承認されませんでした`), 'warning')
    }
  }
  return <Button
    disabled={isConverting || props.disabled}
    //variant="contained"
    startIcon={<CheckCircle />}
    color="secondary"
    onClick={handleClick}>
    {translate('account.approve')}
  </Button>
}

ButtonApprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonApprove.defaultProps = {
  selectedIds: []
}

export default ButtonApprove;