import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useNotify,
  useGetIdentity,
  Confirm,
  EditButton,
} from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Box, Button, Checkbox } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ToolBar from './ToolBar';
import { DeleteAdmin, GetDataAdmin, GetDataAdminById, RegisterAdmin, ResetPassAdmin, UpdatePermissionAdmin } from './gql'
import SectionTitle from '../team/SectionTitle';
import FormCreateAdmin from './FormCreateAdmin';
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import { PermissionsType } from '../../types';
import { useRefresh } from 'react-admin';
import ResetPassButton from './actionsResetPass';
const useStyles = makeStyles((theme) => ({
  DataGrid: {
    marginBottom: 15
  },
  ActionButton: {
    marginRight: 10
  }
}));
const ConfirmPage = () => {

  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [rows, setRows] = useState([] as any);
  const [data, setData] = useState([] as any);
  const [confirmed, setConfirmed] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [showDialogReset, setshowDialogReset] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [isDisableEdit, setIsDisableEdit] = useState(false);
  const apolloClient = useApolloClient();
  const [email, setEmail] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [resetPassword, setResetPassword] = useState<string>('');
  const [confirmResetPassword, setConfirmResetPassword] = useState<string>('');
  const [idResetPass, setIdResetPass] = useState<number>(0);
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify();
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();
  const role = permissions?.role;
  const permission = permissions?.list_permission as PermissionsType;
  useEffect(() => {
    if (authenticated) {
      fetchData();

    }

    //fetchData();
  }, [])
  const handleChange = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;
    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_all: !params?.row?.permission_all,
          permission_create_edit: !params?.row?.permission_all,
          permission_view: !params?.row?.permission_all,
          permission_genre: !params?.row?.permission_all,
          permission_payment: !params?.row?.permission_all,
          permission_approve: !params?.row?.permission_all,

        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeCreate = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_create_edit: !params?.row?.permission_create_edit,
          permission_all: (!params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeApprove = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_approve: !params?.row?.permission_approve,
          permission_all: (params?.row?.permission_create_edit === true && !params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeView = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_view: !params?.row?.permission_view,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && !params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeGenre = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_genre: !params?.row?.permission_genre,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && !params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangePayment = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_payment: !params?.row?.permission_payment,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && !params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const renderActionCellPermissionAll = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_all)}
        onChange={(e: any) => handleChange(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionCreateEdit = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_create_edit)}
        onChange={(e: any) => handleChangeCreate(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionApprove = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_approve)}
        onChange={(e: any) => handleChangeApprove(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionView = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_view)}
        onChange={(e: any) => handleChangeView(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionGenre = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_genre)}
        onChange={(e: any) => handleChangeGenre(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionPayment = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_payment)}
        onChange={(e: any) => handleChangePayment(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCell = (params: GridCellParams) => {
    let DeleteDisable = false;
    if (params.row.id === identity.id || !permission?.permission_all) {
      DeleteDisable = true
    }

    return <Box>
      <Button
        className={classes.ActionButton}
        //disabled={!confirmed}
        disabled={DeleteDisable}
        variant="contained"
        color="primary"
        id={"btn-resetpass-admin"}
        onClick={(e: any) => ShowModalResetPass(e, params)}
      >
        {translate('パスワード初期化')}
      </Button>
      <Button
        className={classes.ActionButton}

        disabled={DeleteDisable}
        variant="contained"
        color="primary"
        id={"btn-delete-admin"}
        onClick={(e: any) => delete_admin(e, params)}
      >
        {translate('削除')}
      </Button>
    </Box>
  }
  const ShowModalResetPass = (event: any, params: GridCellParams) => {
    const admin_id = params?.row?.id as number;
    setIdResetPass(admin_id)
    setshowDialogReset(true);
  }
  const onClose = () => {
    setshowDialogReset(false);

  }
  const onCloseConfirm = () => {
    setShowConfirmReset(false);
  }
  const delete_admin = async (event: any, params: GridCellParams) => {
    const { props } = event
    const admin_id = params?.row?.id as number;
    const dataDelete = await DeleteAdmin(apolloClient, admin_id);
    if (dataDelete.id) {
      const dataAfterDelete = data.filter((x: any) => x.id !== admin_id);
      setData(dataAfterDelete);
      notify(translate('common.delete_success'));
    } else {
      notify(translate('common.delete_error'), 'warning');
    }

  }
  const onChangeCreateButton = async () => {
    const dataRes = await RegisterAdmin(apolloClient, email, password);
    if (dataRes.status_code === 200) {
      const dataInsert = await GetDataAdminById(apolloClient, dataRes.admin_id);
      let dataConcat = [...data, dataInsert]
      setData(dataConcat);
      notify(translate('common.register_success'));
    } else if(dataRes.status_code === 204){
      notify(translate('common.register_error_exist'), 'warning');
    } else if(dataRes.status_code === 205){
      notify(translate('common.register_error_exist_not_delete'), 'warning');
    }else {
      notify(translate('common.register_error'), 'warning');
    }

  }
  const onChangeText = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'email-admin') {
      setEmail(value);
    } else if (name === 'password-admin') {
      setPassword(value);
    }
    console.log('email', email);

  }
  const onChangeResetPass = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'password-reset') {
      setResetPassword(value);
    } else if (name === 'password-reset-confirm') {
      setConfirmResetPassword(value);
    }
  }
  const onConfirmDialog = (event: any) => {
    if(!resetPassword) {
      notify(translate('common.reset_pass_error'), 'warning');
    }else {
      setShowConfirmReset(true);
    }
  }
  const onSubmitConfirm = async (event: any) => {
    const dataResetpass = await ResetPassAdmin(apolloClient,idResetPass,resetPassword);    
    if(dataResetpass.status === 200) {
      notify(translate('common.reset_pass_success'));

      onCloseConfirm();
      onClose();
    }else {
      notify(dataResetpass.message, 'warning');
      onCloseConfirm();
    }
   
  }
  const PermissionColumn = [
    //{ field: 'id', headerName: 'ID', typename: 'number', width: 100 },
    { field: 'email', headerName: 'Eメールアドレス', typename: 'string', minWidth: 250, flex: 1, disableColumnMenu: true },
    //{ field: 'status', headerName: 'ステータス', typename: 'number', width: 200, disableColumnMenu: true },
    { field: 'permission_all', headerName: 'システム管理者', renderCell: renderActionCellPermissionAll, flex: 0.5, minWidth: 100 },
    { field: 'permission_create_edit', headerName: '追加と編集（削除不可）', renderCell: renderActionCellPermissionCreateEdit, flex: 0.5, minWidth: 100 },
    { field: 'permission_approve', headerName: '承認（※）', renderCell: renderActionCellPermissionApprove, flex: 0.5, minWidth: 100 },
    { field: 'permission_view', headerName: '閲覧のみ', renderCell: renderActionCellPermissionView, flex: 0.5, minWidth: 100 },
    { field: 'permission_genre', headerName: 'ジャンル、ジャンルスポーツ', renderCell: renderActionCellPermissionGenre, flex: 0.5, minWidth: 100, },
    { field: 'permission_payment', headerName: '請求管理', renderCell: renderActionCellPermissionPayment, flex: 0.5, minWidth: 100 },
    //{ field: 'created', headerName: 'ステータス', typename: 'string', width: 300, disableColumnMenu: true },
    { field: 'action', headerName: '確認', minWidth: 300, flex: 1, renderCell: renderActionCell },
  ]

  const fetchData = async () => {
    const dataAdmin = await GetDataAdmin(apolloClient);
    setData(dataAdmin);
  }
  const onChangeBtnSave = async () => {
    let dataPreUpdate = data.map((admin: any) => {
      return {
        id: admin.id,
        email: admin.email,
        created_by: admin.created_by,
        created: admin.created,
        permission_all: admin.permission_all,
        permission_create_edit: admin.permission_create_edit,
        permission_approve: admin.permission_approve,
        permission_genre: admin.permission_genre,
        permission_payment: admin.permission_payment,
        permission_view: admin.permission_view,
        refresh_token: admin.refresh_token,
        status: admin.status,
        is_deleted: admin.is_deleted
      }
    })
    const dataUpdate = await UpdatePermissionAdmin(apolloClient, dataPreUpdate);
    if (dataUpdate.status_code === 200) {

      notify(translate('common.update_permission_success'));
    } else {
      notify(translate('common.update_permission_fail'), 'warning');
    }
  };
  return <Box width='100%' height='60%'>
    <ToolBar
      onChangeBtnSave={onChangeBtnSave}
      isDisable = {!permission?.permission_all}

    />
    <DataGrid
      rows={data}
      columns={PermissionColumn}
      pageSize={20}
      className={classes.DataGrid}
    //checkboxSelection={!confirmed}
    //disableSelectionOnClick
    //   onSelectionModelChange={(newSelections) => {
    //     const { selectionModel } = newSelections;
    //     setSelectedRows(selectionModel);
    //   }} 
    />
    <FormCreateAdmin
      email={email}
      password={password}
      onChangeText={onChangeText}
      onCreateAdminBtnClick={onChangeCreateButton}
      isDisable={!permission?.permission_all}
    />

    <ResetPassButton
     is_open={showDialogReset}
     is_show_confirm = {showConfirmReset}
     onClose={onClose}
     onCloseConfirm={onCloseConfirm}
     onSubmitConfirm = {onSubmitConfirm}
     onChangeResetPass={onChangeResetPass} 
     resetPassword = {resetPassword}
     confirmResetPassword = {confirmResetPassword}
     onConfirmDialog = {onConfirmDialog}
     isDisable = {!permission?.permission_all}
     />
    {/* <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} /> */}
  </Box>


}






export default ConfirmPage;

