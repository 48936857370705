import * as React from 'react';
import {FC, useEffect} from 'react';
import {
    Datagrid,
    DateField,
    downloadCSV,
    Filter,
    List,
    ReferenceField,
    SearchInput,
    TextField,
    useTranslate
} from 'react-admin';
import ActionsField from './Actions';
import StatusField from './StatusField';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import {FilterProps, PermissionsType} from "../../types";
import { usePermissions } from 'react-admin';
import { Fragment} from 'react';
import BulkDeleteButton from '../components/actionsBulkDelete';
import { TextInput } from 'react-admin';
import { NumberInput } from 'react-admin';
import { useHistory } from 'react-router';

const exporter = (items:any) => {
    const itemsForExport = items.map((item:any) => {
        return [item.name,item.description,item.action,item.title, item.body,item.active,items[0].client_id  ? item.client_id : ''];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'CP932',
        fields: ['名前','説明','アクション','タイトル','サンプルデータ','アクティブフラグ',items[0].client_id ? 'マルチテナント用' : ''],
    });
    downloadCSV(csv, 'template');
};


const EmailFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="name" alwaysOn />
        </Filter>
    )
};
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    
    return (
        <Fragment>
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};

const EmailList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/template';
    },[props]);
    return (
        <List
            {...props}
            filters={<EmailFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            exporter={exporter}
            empty={<ListEmpty />}
            bulkActionButtons={<UserBulkActionButtons />}
            title={translate(' ')}
        >
            <Datagrid optimized rowClick={edit}>
                <TextField source="id" label={translate('email_template.id')}/>
                <TextField source="subject" label={translate('email_template.subject')}/>
                <TextField source="action_key" label={translate('email_template.action_key')}/>
                <TextField source="sendgrid_template_id" label={translate('email_template.sendgrid_template_id')}/>
                <DateField showTime={true} source="created" type="date" label={translate('email_template.created')}/>
                <StatusField source="active" label={translate('email_template.status')}/>
                <ActionsField/>
            </Datagrid>
        </List>

    );
};

export default EmailList;
