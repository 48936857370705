import {Reducer} from 'redux';
import {UPDATE_USER, updateUser} from '../configuration/actions';
import {User} from '../types';

type State = User | null;

type Action =
    | ReturnType<typeof updateUser>
    | { type: 'OTHER_ACTION'; payload?: any };

const userReducer: Reducer<State, Action> = (
    previousState = null,
    action
) => {
    if (action.type === UPDATE_USER) {
        return action.payload;
    }
    return previousState;
};

export default userReducer;
