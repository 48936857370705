import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from '../../types';
import { EditButton } from 'react-admin';
import BtnCreateNew from '../components/BtnCreateNew';
import BtnShowMesage from './BtnShowMessage';

const ActionsField: FC<FieldProps<any>> = ({ record, basePath, resource }) => {
  return record ? (
    <span>
      <BtnShowMesage
        filter_val={record.id} />
      <EditButton label=" " basePath={`team_contact`} source={resource} record={record} />
    </span>
  ) : null;
}

export default ActionsField;
