import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  useTranslate,
} from 'react-admin';
import EditField from '../components/editField';
import { Grid } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { TabbedForm } from 'react-admin';
import { FormTab } from 'react-admin';
import { TextInput } from 'react-admin';
import InputRenderer from '../../utils/InputRenderer';
import { required } from 'react-admin';
import CreateToolbar from "./CreateToolbar";
import { InfoOutlined } from '@material-ui/icons';

import jaLocale from "date-fns/locale/ja";
import {PUBLISH_STATUS, ATTENTION, CATEGORIES, DISABILITY, LADIES, TARGET_AGES, PRACTICE_DAYS, KEYWORD1, KEYWORD2, KEYWORD3, KEYWORD4, TRIAL_TYPES, VISIT_OK, STYLE_CHOICE,STYLE, ENROLLED_GENERATE, MONTHLY_FEE} from './dataset'
import { RadioButtonGroupInput } from 'react-admin';
import { CheckboxGroupInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { useGetIdentity } from 'react-admin';
import { useNotify } from 'react-admin';
import EnrolledField from './EnrollField';
import EnrolledOpenField from './EnrollOpenField';

import { GetAllGenreSport, GetArea, GetCity, GetPref } from './gql';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'ra-core';
import SectionTitle from './SectionTitle';
import StyleInput from './StyleInput';
import RadioMonthlyFee from './RadioMonthlyFee';
import GearField from './GearField';
import { DateInput } from "../../utils/DatePicker";

const TeamEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);
  const notify = useNotify();  
  const requiredValidate = [required()];
  const { identity, loading: identityLoading } = useGetIdentity();
  
  if (identityLoading) {
    return <>Loading...</>;
  }
  const onFailure = (error: any) => {
    notify(`Could not create: ${error.message}`, 'warning');
  };
  
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <TabPanel value={value} index={0}>
        <Edit
          {...props}
          successMessage="common.msg_update_success"
          undoable={false}
          className="formContent"
          title={<EditField titlePage={translate('menu.account.account_edit')} />}
        >
            <TabbedForm
              className="form"
              redirect="list"
              toolbar={<CreateToolbar />}
              //initialValues={defaultValues}
              title={'edit'}
            >
              <FormTab label={translate("team.team_tab_1")} style={{ width: '10%', maxWidth: 'inherit' }} container justify='center' spacing={2} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab1 {...formDataProps} />
                    //初期設定情報
                  )}
                </FormDataConsumer>
              </FormTab>
              <FormTab label={translate("team.team_tab_2")} style={{ width: '10%', maxWidth: 'inherit' }} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab2 {...formDataProps} />
                    //基本情報
                  )}
                </FormDataConsumer>
              </FormTab>
              <FormTab label={translate("team.team_tab_3")} style={{ width: '10%', maxWidth: 'inherit' }} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab3 {...formDataProps} />
                    // チーム・スクールの画像・特徴
                  )}
                </FormDataConsumer>
              </FormTab>
              <FormTab label={translate("team.team_tab_4")} style={{ width: '10%', maxWidth: 'inherit' }} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab4 {...formDataProps} />
                    // 特徴
                  )}
                </FormDataConsumer>
              </FormTab>
              <FormTab label={translate("team.team_tab_5")} style={{ width: '10%', maxWidth: 'inherit' }} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab5 {...formDataProps} />
                    //募集・練習体験・セレクション
                  )}
                </FormDataConsumer>
              </FormTab>
              <FormTab label={translate("team.team_tab_6")} style={{ width: '10%', maxWidth: 'inherit' }} >
                <FormDataConsumer>
                  {(formDataProps: any) => (
                    <CreateEventTab6 {...formDataProps} />
                    // 費用について
                  )}
                </FormDataConsumer>
              </FormTab>
            </TabbedForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditHistory {...props} />
      </TabPanel>
    </div>

  );
};
const CreateEventTab1 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const [monthlyFee, setMonthlyFee] = useState<boolean>(false)
  useEffect( () => {
    if (form.getState().values.monthly_fee === 1) {
      setMonthlyFee(true);
    }
  },[]);
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <ReferenceInput
          allowEmpty={false}
          source='account_id'
          reference={'account'}
          //disabled={!editable && isEditPage}
          // validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('team.account_id')}
          //filter= {{status: 1}}
          filterToQuery={(searchText: any) => ({ ['name']: searchText })}>
          <SelectInput
            optionText={'name'}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={6}>
      <RadioButtonGroupInput
          source='monthly_fee'
          //validate={true}
          choices={MONTHLY_FEE}
          fullWidth
          onChange = {() => {
            setMonthlyFee(!monthlyFee);
          }}  
          variant="outlined"
          label={translate('team.monthly_fee')}
        />
        {monthlyFee === true && (
           <RadioButtonGroupInput
           source='attention'
           //validate={true}
           choices={ATTENTION}
           fullWidth
           variant="outlined"
           label={translate('team.attention')}
         />
        )}
       
      </Grid>
      <Grid item xs={6}>
        <RadioButtonGroupInput
          source='publish_status'
          //validate={true}
          choices={PUBLISH_STATUS}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('team.publish_status')}
        />
      </Grid>
      {/* <div >
        <p style={{ fontWeight: 700, fontSize: '14px' }}>
          「月謝徴収あり」を選択：
        </p>
        <p>
          スポスル経由でチームメンバー、選手、生徒が入会された場合、月謝の半月分or1か月分の成功報酬費用が発生いたします。掲載自体は無料です。
        </p>
        <p style={{ fontWeight: 700, fontSize: '14px' }}>
          「月謝徴収なし」を選択：
        </p>
        <p>
          サイト掲載料は <span style={{ color: '#FF0000', fontWeight: 700, }}>完全無料</span>完全無料となります。
        </p>
      </div> */}
    </div>
  );
};
const CreateEventTab2 = (props: any) => {
  console.log('props', props)
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [dataGenreSport, setdataGenreSport] = useState<any>([]);
  const [isOther, setIsOrther] = useState<boolean>(false);
  const [OptionArea, setOptionArea] = React.useState<any>([]);
  const [OptionPref, setOptionPref] = React.useState<any>([]);
  const [OptionCity, setOptionCity] = React.useState<any>([]);
  const [Style, setStyle] = React.useState<any>([]);


  const formData = props
  useEffect(() => {
    (async () => {
      const dataArea = await GetArea();
      setOptionArea(dataArea);
      if (props.formData.pref) {
        const dataPref = await GetPref(props.formData.area);
        const dataCity = await GetCity(props.formData.pref);
        setOptionPref(dataPref);
        setOptionCity(dataCity);

      }
    })()
  }, [props.formData.area, props.formData.pref])
  useEffect(() => {
    (async () => {
      form.change('style',Style)
    })()
  }, [Style])
  useEffect(() => {
    (async () => {
      let genre_id = 0;
      if (props.formData.genre_id) {
        genre_id = props.formData.genre_id
      }
      if (genre_id === 0) {
        setIsOrther(true);
      }else{
        setIsOrther(false);
      }
      const dataGenreSport = await GetAllGenreSport(dataProvider, genre_id);
      console.log('dataGenreSport', dataGenreSport);
      setdataGenreSport(dataGenreSport);
    })()
  }, [props.formData.genre_id]);
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <ReferenceInput
          allowEmpty={false}
          source='genre_id'
          reference={'genre'}
          //disabled={!editable && isEditPage}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('team.genre_id')}
          //filter= {{status: 1}}
          filterToQuery={(searchText: any) => ({ ['name']: searchText })}>
          <SelectInput
            optionText={'name'}
          />
        </ReferenceInput>
      </Grid>
      {isOther && (
        <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='genre_other'
          validate={requiredValidate}
          label={translate('team.genre_other')}
          //placeholder={placeholder}
          fullWidth
          //multiline={}
          variant="outlined"
        />
        </Grid>
      )}
      <Grid xs={6} style={{ width: '50%' }} >
        <SelectInput
          allowEmpty={false}
          source='genre_sport_id'
          reference={'genre_sport'}
          //disabled={!editable && isEditPage}
          choices={dataGenreSport}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('team.genre_sport_id')}
        >
        </SelectInput>
      </Grid>
      {isOther && (
        <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='genre_sport_other'
          validate={requiredValidate}
          label={translate('team.genre_sport_other')}
          //placeholder={placeholder}
          fullWidth
          //multiline={data.multiline}
          variant="outlined"
        />
        </Grid>
      )}
      <Grid xs={6} style={{ width: '60%' }} >
        <RadioButtonGroupInput
          source='category'
          validate={requiredValidate}
          choices={CATEGORIES}
          fullWidth
          variant="outlined"
          label={translate('team.category')}
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='name'
          validate={requiredValidate}
          label={translate('team.name')}
          //placeholder={placeholder}
          fullWidth
          //multiline={}
          variant="outlined"
        />
        <TextInput
          source='name_kana'
          label={translate('team.name_kana')}
          //placeholder={placeholder}
          fullWidth
          //multiline={data.multiline}
          variant="outlined"
        />
        <TextInput
          source='president_name'
          label={translate('team.president_name')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='appeal_title'
          label={translate('team.appeal_title')}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='appeal_body'
          label={translate('team.appeal_body')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
      </Grid>
      <Grid xs={6} style={{ width: '60%' }} >
        <RadioButtonGroupInput
          source='disability'
          choices={DISABILITY}
          fullWidth
          variant="outlined"
          label={translate('team.disability')}
        />
      </Grid>
      <RadioButtonGroupInput
        source='ladies'
        choices={LADIES}
        fullWidth
        variant="outlined"
        label={translate('team.ladies')}
      />
      <Grid xs={6} style={{ width: '40%' }} >
        <SelectInput
          source='area'
          validate={requiredValidate}
          choices={OptionArea}
          fullWidth
          variant="outlined"
          label={translate('team.area')}
        />
        <SelectInput
          source='pref'
          validate={requiredValidate}
          choices={OptionPref}
          fullWidth
          variant="outlined"
          label={translate('team.pref')}
        />
        <SelectInput
          source='city'
          validate={requiredValidate}
          choices={OptionCity}
          fullWidth
          variant="outlined"
          label={translate('team.city')}
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <CheckboxGroupInput
          source='age'
          choices={TARGET_AGES}
          fullWidth
          variant="outlined"
          label={translate('team.age')}
        />
        <CheckboxGroupInput
          source='practice_days'
          choices={PRACTICE_DAYS}
          fullWidth
          variant="outlined"
          label={translate('team.practice_days')}
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='practice_datetime'
          label={translate('team.practice_datetime')}
          placeholder={'記入例） 小学生：火・金　16:00 - 18:00 \n中学生：火・水・木・金　17:00 - 20:00 \n高校生：月・水・金　17:30 - 20:30 \n※土日は基本的に練習試合が入ります。'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='philosophy'
          label={translate('team.philosophy')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='achievements'
          label={translate('team.achievements')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        </Grid>
        <Grid xs={6} style={{ width: '100%' }} >
        {
          STYLE.map((d, idx) => {
            return <StyleInput props={props} dataStyle={props.formData.style} dataForm={d} />
          })
        }
        </Grid>
        {/* <Grid className='GroupStyle'>
          <Grid className={'Radio_style_check'}>
            <p className={'text_from_to'}>厳しい</p>
            <RadioButtonGroupInput
              className={'style_choice'}
              source='style_choice_1'
              choices={STYLE_CHOICE}
              fullWidth
              variant="outlined"
              label={translate(' ')}
            />
            <p className={'text_from_to'}>のびのび</p>
          </Grid>
          <TextInput
            source='style_choice_description_1'
            label={translate('team.style_choice_description_1')}
            placeholder={'入力してください…'}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid> */}
      
    </div>
  );
}
const CreateEventTab3 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div style={{ width: '98%' }}>
      <Grid className='formContent' xs={6} style={{ width: '50%' }}>
        <InputRenderer source='team' data={{ name: 'logo_url', type: 'imagefield', nullable: true, editable: true }} />
        <div>
          <p>※ファイルはJPG, PNG形式で、70px * 70px以上。</p>
        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <TextInput
          source='video_url'
          label={translate('team.video_url')}
          placeholder={translate('team.video_url_placeholder')}
          fullWidth
          variant="outlined"
        />
        <div>
          <p>※入力ボックスにYouTubeのURLを入力してください。</p>
        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '90%' }}>
        <InputRenderer source='team' data={{ name: 'image1_url', type: 'imagefield', nullable: true, editable: true }} />
        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <InputRenderer source='team' data={{ name: 'image2_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='team' data={{ name: 'image3_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='team' data={{ name: 'image4_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='team' data={{ name: 'image5_url', type: 'imagefield', nullable: true, editable: true }} />

        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <InputRenderer source='team' data={{ name: 'image6_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='team' data={{ name: 'image7_url', type: 'imagefield', nullable: true, editable: true }} />

        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
    </div>
  );
}
const CreateEventTab4 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div style={{ width: '98%' }}>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword1'
          choices={KEYWORD1}
          fullWidth
          variant="outlined"
          label={translate('team.keyword1')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword2'
          choices={KEYWORD2}
          fullWidth
          variant="outlined"
          label={translate('team.keyword2')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword3'
          choices={KEYWORD3}
          fullWidth
          variant="outlined"
          label={translate('team.keyword3')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword4'
          choices={KEYWORD4}
          fullWidth
          variant="outlined"
          label={translate('team.keyword4')}
        />
      </Grid>
    </div>
  );
}
const CreateEventTab5 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <RadioButtonGroupInput
          source='trial_type'
          choices={TRIAL_TYPES}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('team.trial_type')}
        />
        <RadioButtonGroupInput
          source='visit_ok'
          choices={VISIT_OK}
          fullWidth
          variant="outlined"
          label={translate('team.visit_ok')}
        />
        <TextInput
          source='trial_description'
          label={translate('team.trial_description')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <Grid xs={6} style={{ width: '100%' }} >
          <p>在籍人数*</p>
        {
          ENROLLED_GENERATE.map((d, idx) => {
            return <EnrolledField props={props} dataEnable = {props.formData.enrolled || []} dataForm = {d} ListAge={props.formData.age}/>
          })
        }
        </Grid>
        <TextInput
          source='enroll_description'
          label={translate('team.enroll_description')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        </Grid>
         <Grid xs={6} style={{ width: '100%' }} >
          <p>募集人数*</p>
        {
          ENROLLED_GENERATE.map((d, idx) => {
            return <EnrolledOpenField props={props} dataEnable = {props.formData.enrolled} dataForm = {d} ListAge={props.formData.age} />
          })
        }
        </Grid>
      
    </div>
  );
}
const CreateEventTab6 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <RadioMonthlyFee props={props}/>

      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='admission_fee'
          label={translate('team.admission_fee')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='annual_fee'
          label={translate('team.annual_fee')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <GearField props={props}/>
        <TextInput
          source='things_to_prepare'
          label={translate('team.things_to_prepare')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='annual_fee'
          label={translate('team.annual_fee')}
          fullWidth
          multiline={true}
          variant="outlined"
          
        />
        <TextInput
          source='fee_description'
          label={translate('team.fee_description')}
          //placeholder={placeholder}
          multiline={true}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='initial_fee'
          label={translate('team.initial_fee')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <ReferenceInput
          allowEmpty={true}
          source='created_by'
          reference={'user'}
          disabled={'disabled'}
          fullWidth
          variant="outlined"
          label={translate('team.created_by')}
          filterToQuery={(searchText: any) => ({ ['firstname']: searchText })}>
          <SelectInput
            optionText={'firstname'}
          />
        </ReferenceInput>
      </Grid>
    </div>
  );
}
export default TeamEdit;
