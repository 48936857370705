import * as React from 'react';
import { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps, Account } from '../../types';
import { useTranslate } from 'react-admin';
import { PUBLISH_STATUS } from './dataset';

const StatusField: FC<FieldProps<Account>> = ({ record }) => {
    const translate = useTranslate();
    var background_color = '';
    var label = '';
    switch (record?.publish_status) {
        case 0:
            background_color = '#006666';
            label = PUBLISH_STATUS.filter(st => st.id === 0)[0]?.name;
            break;
        case 1:
            background_color = '#CC9966';
            label = PUBLISH_STATUS.filter(st => st.id === 1)[0]?.name;
            break;
        case 2:
            background_color = '#339933';
            label = PUBLISH_STATUS.filter(st => st.id === 2)[0]?.name;
            break;
    }
    return record ? (
        <span>
            <Chip
                label={
                    label
                }
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor: background_color,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    ) : null;
};
export default StatusField;
