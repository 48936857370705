import * as React from "react";
import PropTypes from 'prop-types';


const CustomField = (props: any) => {
  const { record } = props;
  // return <span>N{record['id'] + ' ' + record['name']}</span>;
  const contact_type = record['contact_type']; 
  const change_from_inquiry_flag = record['change_from_inquiry_flag'];
  if(change_from_inquiry_flag === true){
  return <span>問合せ</span>;
  } else {
    if(contact_type === 2 ){
      return <span>体験申込み</span>
    }else if(contact_type === 3){
      return <span>入会申込み</span>
    }else if(contact_type === 4){
      return <span>見学申し込み</span>
    }
      return <span></span>; 
  }
}
const CustomContactType = (props: any) => {
  return (
  <>
      <CustomField {...props} />
  </>)
}
CustomContactType.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomContactType;