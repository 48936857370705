import * as React from 'react';
import {Chip, Grid} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import { TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import {RecruitmentListType,RecruitmentType} from './type'


const EnrollOpenField = ({props, dataForm, dataEnable,ListAge }: {props: any,dataForm: RecruitmentListType, dataEnable: RecruitmentType[], ListAge: string[]} ) =>{
    const form = useForm();
    const translate = useTranslate();
    var isDisable = 'disable';
    var recruitment = form.getState().values.recruitment as RecruitmentType[];
    var data = (dataEnable || []).filter(x => x.name === dataForm.name);
    var AgeEnable = ListAge.filter(x => x === dataForm.name);
  if(AgeEnable[0]){
      isDisable = '';
  }
  if(data[0]){
      isDisable = '';
  }
  const getData = () => {
    if(!data[0]){
      return '';
    }else{
      return data[0].current;
    }
    
  }
    return  <Grid className='EnrolledOpenForm'>
    <p className={'RowOpenTitle'}>{dataForm.name}</p>
    <TextInput
      source={`age_recruitment_${dataForm.id}`}
      disabled={isDisable}
      label={translate(`team.age_open_${dataForm.id}`)}
      fullWidth
      format={getData}
      multiline={true}
      variant="outlined"
      onChange={(event: any) => {
        if(data[0]){
          let objIndex = dataEnable.findIndex((obj => obj.name == dataForm.name))
          dataEnable[objIndex].current = event.target.value;
      }else{
          const current = form.getState().values[`age_recruitment_${dataForm.id}`]
          let dataPush:RecruitmentType = {name:dataForm.name, current: current}
          recruitment.push(dataPush);
      }
      }}
    />
  </Grid>
}
export default EnrollOpenField;
