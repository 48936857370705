const STATUS = [
  {id: 0, name: "終了"},
  {id: 1, name: "作成中"},
  {id: 2, name: "公開待ち"},
  {id: 3, name: "公開中"},
  {id: 4, name: "受付停止中"},

]
const TYPE = [
  {id: 1, name: "大会"},
  {id: 2, name: "イベント"},
  {id: 3, name: "教室"},
  {id: 4, name: "講習会・セミナー"},
  {id: 5, name: "講演会"},
  {id: 6, name: "ツアー・合宿"},
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
  //{ name: 'genre_id',searchField: '', options: [], type: 'reference',reference: 'genre', nullable: false, editable: true, export: true, import: true },
  { name: 'title', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'content', type: 'textinput',multiline: true, nullable: false, editable: true, export: true, import: true },
  { name: 'target_user', type: 'target_user', nullable: true, editable: true, export: true, import: true },
  { name: 'target_admin', type: 'target_admin',nullable: true, editable: true, export: true, import: true },
  { name: 'target_app', type: 'target_app',nullable: true, editable: true, export: true, import: true },
  {},
  { name: 'publish_date', type: 'date', nullable: false, editable: true, export: true, import: true },

  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,nullable: true, editable: false, export: false, import: true },
  { name: 'publish', type: 'boolean', nullable: true, editable: true, export: true, import: true },
  { name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },

]

export default DATA;