import { ApolloClient, gql } from "@apollo/client";

export const FETCH_RESELLERS = gql`
  query GetResellers($ids: [Int!]) {
    reseller(where: {id: {_in: $ids}}) {
      id
      account_name
      account_number
      account_type
      zip_code
      other
      address
      bank_branch_code
      bank_branch_name
      bank_code
      bank_name
      name
    }
  }
`;
export const FETCH_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    account(where: {id: {_eq: $id}}) {
        id
        contact_email
        created_by
    }
  }
`;
export const SEND_MAIL_APPROVED = gql`
  mutation send_mail_approved($account_id: Int!,$contact_email: String!, $created_by: Int!) {
    send_mail_account_approved(account_id: $account_id, contact_email: $contact_email, created_by: $created_by) {
        error_code
        error_message
        sendSuccess
        status_code
      }
  }
`;
export const UPDATE_VERIFIED = gql`
mutation MyMutation($id: Int, $admin_id: Int) {
  update_account(where: {id: {_eq: $id}}, _set: {varified_by: $admin_id}) {
    affected_rows
  }
}
`;
export const GET_TOTAL_TEAM_BY_ACCOUNT_ID = gql`
query MyQuery($account_id: Int) {
  team_aggregate(where: {account_id: {_eq: $account_id}}) {
    aggregate {
      count(columns: id)
    }
  }
}
`;
export const GET_TOTAL_EVENT_BY_ACCOUNT_ID = gql`
query MyQuery($account_id: Int) {
  event_aggregate(where: {account_id: {_eq: $account_id}}) {
    aggregate {
      count(columns: id)
    }
  }
}
`;
export const DELETE_MANY_ACCOUNT = gql`
mutation MyMutation($list_account_id: [Int!]) {
  delete_account(where: {id: {_in: $list_account_id}}) {
    affected_rows
  }
}`;
export const RECOVER_MANY_ACCOUNT = gql`
mutation MyMutation($list_account_id: [Int!]) {
  update_account(where: {id: {_in: $list_account_id}}, _set:  {is_soft_deleted: false}) {
    affected_rows
  }
}`;
export const GET_COUNT_TEAM = async (apolloClient: any, account_id: any)  => {
  let { data: dataCountTeam } = await apolloClient.query({
    query: GET_TOTAL_TEAM_BY_ACCOUNT_ID,
    variables: {
      account_id: account_id
    }
  });
  return dataCountTeam.event_aggregate.aggregate.count;
}