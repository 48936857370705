import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';


const CustomField = (props: any) => {
  const { record } = props;
  // return <span>N{record['id'] + ' ' + record['name']}</span>;
  return <span>{(record['lastname']) +' '+ record['firstname']} </span>;
}

const CustomReferField = (props: any) => {
  return (
  <>
    <ReferenceField {...props}>
      <CustomField />
    </ReferenceField>
  </>)
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;