import * as React from 'react';
import {FC} from 'react';
import {FieldProps, Group} from '../../types';
import {EditButton} from 'react-admin';

const ActionsField: FC<FieldProps<Group>> = ({ record, basePath, resource }) =>
    record ? (
        <span>
            <EditButton basePath={basePath} source={resource} record={record}/>
        </span>
    ) : null;

export default ActionsField;

