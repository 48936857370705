import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  FileInput,
  FileField
} from 'react-admin';
import { useApolloClient, gql } from "@apollo/client";
import { signedUploadQuery, SignedUploadVariables } from '../../types';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const GET_UPLOAD_URL = gql`
    mutation signedQuery($input: GenerateUploadUrlInput!) {
      generate_upload_url(input: $input) {
          presigned_url
          # error
          filename
        }
    }
`;

const useStyles = makeStyles({
  dropFrame: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    padding: '0 10px'
  },
  fileList: {
    display: 'flex',
    alignItems: 'center',
  }
});

const CustomFileInput = (props: any) => {
  const apolloClient = useApolloClient();
  const form = useForm();
  const [uploading, setUploading] = useState<boolean>(false);
  const classes = useStyles();

  const setLoading = (isLoading: boolean) => {
    setUploading(isLoading);
  }

  const upload = async (file: any, fileKey: string) => {
    setLoading(true);
    const ext = file.name;
    const { data: response } = await apolloClient.mutate<signedUploadQuery, SignedUploadVariables>({
      mutation: GET_UPLOAD_URL,
      variables: {
        input: {
          filename: file.name || ""
        }
      },
    });
    if (!response || !response.generate_upload_url.presigned_url) {
      return;
    }
    const serverFileName = response.generate_upload_url.filename;
    await axios.put(response.generate_upload_url.presigned_url, file);
    form.change(fileKey, serverFileName);
    setLoading(false);
  };

  const onRemove = (fileKey: string) => {
    form.change(fileKey, null);
  };

  const onDrop = async (fileKey: string, files: any) => {
    if (files && files.length > 0) {
      await upload(files[0], fileKey);
    }
  };

  return <div className={classes.dropFrame}>
    <FileInput
      options={{
        onRemove: () => onRemove('filename'),
        onDrop: (files: any) => onDrop('filename', files)
      }}
      maxSize={50000000}
      {...props}
    >
      <FileField source="fileobj" title="Files" />
    </FileInput>
    <div className={classes.fileList}>
      { uploading && <CircularProgress /> }
    </div>
  </div>
}

export default CustomFileInput;