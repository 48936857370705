import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceInput,
    ReferenceField,
    SelectField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import { useHistory } from 'react-router';

const exporter = async (items: any,fetchRelatedRecords: any) => {
    console.log('items',items);
    
    var itemsForExport = items.map((item: any) => {
        return [item.id,item.lastname,item.firstname,item.lastname_kana,item.firstname_kana,item.email,item.post_code,item.pref,item.city,item.birthday,item.status,item.phone,item.address,item.gender ,item.nickname,item.varified,new Date(item.created).toLocaleString("ja-JP", {timeZone: "Asia/Tokyo"})];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'CP932',
        fields: [ "ID", "名", "姓", "メイ", "セイ", 'メールアドレス', '郵便番号', '都道府県', '市区町村', '生年月日', "ステータス", '電話番号', '住所', '性別', "ニックネーム", 'メールアドレス確認日', '作成日'],
    });
    downloadCSV(csv, 'User_No_Role');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const UserFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="note,firstname,lastname,firstname_kana,lastname_kana,email,post_code,pref,city,address,phone,nickname" alwaysOn />
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/user_remove_admin';
    },[props]);
    const STATUS = [
        { id: 0, name: translate("common.status_inactive") },
        { id: 1, name: translate("common.status_active") },
    ]
    const GENDER = [
        { id: 0, name: '不明' },
        { id: 1, name: '男性' },
        { id: 2, name: '女性' },
        { id: 3, name: '未選択'}
    ]
    if (role ===undefined) {
        return null;
    }
    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            //filterDefaultValues={{ kuchikomi_user: false }}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={exporter}
            actions={<ListActions to='user' />}
            empty={<ListEmpty />}
            //filterDefaultValues={{ status: 1 }}
            title={translate(' ')}
        >
            <CustomizableDatagrid rowClick={edit}>
                <TextField source="id" label={translate('user_list.id')} />
                <TextField source="lastname" label={translate('user_list.lastname')} />
                <TextField source="firstname" label={translate('user_list.firstname')} />
                <TextField source="lastname_kana" label={translate('user_list.lastname_kana')} />
                <TextField source="firstname_kana" label={translate('user_list.firstname_kana')} />
                <EmailField source="email" label={translate('user_list.email')} />
                <TextField source="post_code" label={translate('user_list.post_code')} />
                <TextField source="pref" label={translate('user_list.pref')} />
                <TextField source="city" label={translate('user_list.city')} />
                <TextField source="birthday" label={translate('user_list.birthday')} />
                <SelectField source="status" choices={STATUS} label={translate('user_list.status')} />
                <TextField source="phone" label={translate('user_list.phone')} />
                <TextField source="address" label={translate('user_list.address')} />
                <SelectField source="gender" choices={GENDER} label={translate('user_list.gender')} />
                <TextField source="nickname" label={translate('user_list.nickname')} />
                <DateField source="varified" label={translate('user_list.varified')} showTime={true} locales="ja-JP" />
                <DateField source="created" label={translate('user_list.created')} showTime={true} locales="ja-JP" />

            </CustomizableDatagrid>
        </List>
    );
};

export default UserList;
