const CONTACT_TYPE = [
  { id: 1, name: '問合せ'},
  { id: 2, name: '体験申込み'},
  { id: 3, name: '入会申込み'}, 
  { id: 4, name: '見学申し込み'}, 
]
export const CONTACT_STATUS = [
  { id: 0, name: '未対応'},
  { id: 1, name: '対応済み'},
  { id: 2, name: '入会'},
  { id: 9, name: 'キャンセル'},

]
const PARENT_PLAG = [
  { id: true, name: '保護者です'},
  { id: false, name: '保護者ではないです'}
]
const ALREADY_IN_SPORTS = [
  { id: true, name: '同スポーツの団体に所属している'},
  { id: false, name: '同スポーツの団体に所属していません'}
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],reference: '', nullable: false, editable: false, export: true  },
  { name: 'user_id',  type: 'reference',reference: 'user',searchField: 'lastname', nullable: false, export: false },
  { name: 'account_name',type: 'account_name',  export: true },
  { name: 'team_name', type: 'hidden',  export: true },
  
  { name: 'user_name',  type: 'hidden', export: true },
  { name: 'user_phone', type: 'hidden',  export: true },
  { name: 'user_email', type: 'hidden',  export: true },
  { name: 'team_id',  type: 'reference',reference: 'team',searchField: 'name', nullable: false, export: true },
  { name: 'button_filter_team_contact', type: 'button_filter', nullable: false, editable: false, export: false, import: false },
  { name: 'contact_type', type: 'status',options: CONTACT_TYPE, nullable: false, editable: true, export: true, import: true },
  { name: 'parent_flg', type: 'status',options: PARENT_PLAG, nullable: false, editable: true, export: true, import: true },
  { name: 'contact_status', type: 'status',options: CONTACT_STATUS, nullable: false, editable: true, export: true, import: true },
  { name: 'phone', type: 'textinput', nullable: true, editable: true, export: true },
  { name: 'gender', type: 'gender', export: true },
  { name: 'birthday', type: 'birthday', export: true },
  { name: 'age', type: 'age',  export: true, },
  { name: 'already_in_sports', type: 'status',options: ALREADY_IN_SPORTS, nullable: false, editable: true, export: true, import: true },
  { name: 'message', type: 'textinput',multiline: true, nullable: false, editable: true, export: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true },
  { name: 'user_comment', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },
  { name: 'note', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },
]
export default DATA;