import { gql } from "@apollo/client";
export const FETCH_ALL_TEAM = gql`
query MyQuery {
  team {
    id
    name
    account {
      name
    }
  }
}
`;
export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;
export const FETCH_DATA_CHAT_TEAM_CONTACT = gql`
query MyQuery($team_contact_id: Int) {
  team_contact(where: {id: {_eq: $team_contact_id}}) {
    team_contact_messages(  order_by: {created: asc}) {
        message
        id
        created
        assigned_team_admin_id
        viewed
    }
    user{
      firstname
      lastname
      image_url
    }
    team{
      name
    }
  }
}
`;
export const GET_ALL_TEAM_ACCOUNT = async (apolloClient: any) => {
  let { data: {team} } = await apolloClient.query({
    query: FETCH_ALL_TEAM,
    variables: {
    }
  });
  return team;
}
export const DeleteBulkTeamContact = gql`
mutation MyMutation($list_team_contact_id: [Int!]) {
  delete_team_contact(where: {id: {_in: $list_team_contact_id}}) {
    affected_rows
  }
}
`;
export const UPDATE_FLAG_TEAM_CONTACT = gql`
mutation MyMutation($id: Int!, $flag_disable: Boolean) {
  update_team_contact_by_pk(pk_columns: {id: $id}, _set: {flag_disable: $flag_disable}) {
    id
  }
}`;
export const UpdateContactFlag = async (apolloClient: any, team_contact_id: number, flag_disable: boolean) => {
  let status = 0;
  try {
    let { data: dataUpdateTeamContact } = await apolloClient.mutate({
      mutation: UPDATE_FLAG_TEAM_CONTACT,
      variables: {
        id: team_contact_id,
        flag_disable: flag_disable
      }
    });
    status = 1;
  } catch (e) {
    status = 0;
  }
  return status;
}
export const DeleteMultiTeamContact = async (apolloClient: any,list_team_contact_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DeleteBulkTeamContact,
      variables: {
        list_team_contact_id: list_team_contact_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
  return status;
}
export const GetDataChatTeamContact = async (apolloClient: any,team_contact_id: number) => {
  let { data: dataMessage } = await apolloClient.mutate({
    mutation: FETCH_DATA_CHAT_TEAM_CONTACT,
    variables: {
      team_contact_id: team_contact_id
    }
  });
return dataMessage.team_contact[0];
};



