import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import { Paper, FormControl, Select, MenuItem, InputLabel, Button, TextField, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20

  },
  formControl: {
    paddingRight: 100,
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  InputField : {
    paddingTop: 10
  }, 
  title: {
    
  }
}));

const ToolBar = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles();
  const {onChangeBtnSave, isDisable} = props;
  return <Paper id='paper-toolbar' className={classes.toolbar}>
    <Grid
      alignItems={"flex-end"}
    >
    <FormControl className={classes.formControl}>
      <Button
      
        disabled={isDisable}
        variant="contained"
        color="primary"
        onClick={onChangeBtnSave}
        >
       {translate('保存')}
    </Button>
    </FormControl>
    </Grid>
    
    
  </Paper>
}

export default ToolBar;