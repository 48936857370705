import PersonIcon from '@material-ui/icons/Person';

import UserRemoveAdminList from './UserRemoveAdminList';
import UserRemoveAdminCreate from './UserRemoveAdminCreate';
import UserRemoveAdminEdit from './UserRemoveAdminEdit';

export default {
    list: UserRemoveAdminList,
    create: UserRemoveAdminCreate,
    edit: UserRemoveAdminEdit,
    icon: PersonIcon,
};
