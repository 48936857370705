import * as React from 'react';
import {cloneElement} from 'react';
import {
    Button,
    CreateButton,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';

const ListActions = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const {
        className,
        exporter,
        filters,
        maxResults,
        to,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/* {(role ==='admin' || role ==='client_admin' || resource !== 'user') && resource !== 'import' && resource !== 'user'
            && <CreateButton basePath={basePath} />} */}
            {resource !== 'import' && 
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={10000000}
                label={translate('common.export')}
            />
            }
        </TopToolbar>
    );
};
export default ListActions;