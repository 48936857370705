import {Button, SaveButton, Toolbar, useTranslate} from "react-admin";
import {useHistory} from "react-router";
import * as React from "react";
import { useApolloClient } from "@apollo/client";


const UpdateToolbar = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const cancelButton = () => {
        history.goBack();
    };
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
            <SaveButton
                disabled={props.pristine}
                undoable={false}
                label={translate('common.btn_update')}
                style={ props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default"/>
        </Toolbar>
    );
};
export default UpdateToolbar;