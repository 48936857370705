const STATUS = [
  {id: 0, name: "無効"},
  {id: 1, name: "有効"},
]

const DATA = [
  { name: 'id', type: 'textinput',options: [], searchField: '',  nullable: true, editable: false, export: true, import: true },
  { name: 'name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'account_name_history', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'name_kana', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'status', type: 'status',options: STATUS, nullable: false, editable: true, export: true, import: true },

  { name: 'representative_last_name',isName: true, type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'representative_first_name',isName: true, type: 'textinput', nullable: false, editable: true, export: true, import: true },
  
  { name: 'representative_lastname_kana',isName: true, type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'representative_firstname_kana',isName: true, type: 'textinput', nullable: true, editable: true, export: true, import: true },
  
  { name: 'contact_last_name', type: 'textinput',isName: true, nullable: true, editable: true, export: true, import: true },
  { name: 'contact_first_name', type: 'textinput',isName: true, nullable: true, editable: true, export: true, import: true },
  
  { name: 'contact_lastname_kana', type: 'textinput',isName: true, nullable: true, editable: true, export: true, import: true },
  { name: 'contact_firstname_kana', type: 'textinput',isName: true, nullable: true, editable: true, export: true, import: true },
  
  { name: 'source',type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'payable_rate',type: 'textinput', nullable: false, editable: true, export: true, import: true },

  //{ name: 'contact_email', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'post_code', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'pref', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'city', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'address', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'building', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'contact_phone', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'business_contents', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
  { name: 'affiliation_association', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
  //{ name: 'affiliation_association_kana', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'sports', type: 'textinput', nullable: true,multiline: true, editable: true, export: true, import: true },
  { name: 'varified', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'varified_by', type: 'reference',reference: 'user',searchField: 'email',nullable: true, editable: false, export: true, import: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true},
  { name: 'created_by', type: 'reference',reference: 'user',searchField: 'firstname',nullable: false, editable: false, export: true, import: true },
  {},
  { name: 'note', type: 'textinput', multiline: true,nullable: true, editable: true, export: true, import: true },
  { name: 'fee_notice_flg', type: 'checkbox', default: false, editable: true, export: true, import: true },
  


]

export default DATA;