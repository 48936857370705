import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';
import { ChipField } from "react-admin";
import { useTranslate } from "react-admin";
import { Chip } from "@material-ui/core";
import {FC} from 'react';

const module = 'event';
const ChipFieldJsonb: FC<any> = (props: any) => {
  const {source} = props;
  const {record} = props;
  const data = record[source];
  const translate = useTranslate();

  if (data === undefined) {
    return <></>
  }else{
    return data.map((x:any) => {
      return <Chip
      label={x}
      style={{
          // color: '#fff',
          // height: 20,
          // width: 'auto',
          // backgroundColor: '#808080',
          // fontSize: 15,
          margin: 2,
      }}
  />             
    })
  }
    
  
  
  // return <ChipField {...props}>
  // </ChipField>
}

ChipFieldJsonb.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default ChipFieldJsonb;