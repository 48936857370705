import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useTranslate,
  useAuthState,
  useRedirect,
  useNotify,
  useGetIdentity,
} from 'react-admin';
import { useApolloClient, useQuery } from '@apollo/client';
import { Box, Button, Grid, Select, MenuItem, TextField, InputLabel } from '@material-ui/core';
import { TypeYear } from './ToolBar';
import { DataInsertBilling, GET_DATA_BILLINGS_BY_ID, GetDataBillingById, GetDataYear, PAID, PAYMENT_METHOD, updateTable } from './gql'
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import { PermissionsType } from '../../types';
import { useRefresh } from 'react-admin';
import { useHistory, useLocation } from "react-router-dom";
import { DatePicker } from '@material-ui/pickers';
const useStyles = makeStyles((theme) => ({
  DataGrid: {
    marginBottom: 15
  },
  ActionButton: {
    marginRight: 10
  }
}));
const ConfirmPage = (props: any) => {
  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [data, setData] = useState([] as any);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const apolloClient = useApolloClient();
  const uselocation = useLocation();
  const [resetPassword, setResetPassword] = useState<string>('');
  const [year, setYear] = useState<number | string | null>(0);
  const [month, setMonth] = useState<number | string | null>(0);
  const [accountAddress, setAccountAddess] = useState<string>('');
  const [accountPref, setaccountPref] = useState<string>('');
  const [accountCity, setaccountCity] = useState<string>('');
  const [accountName, setaccountName] = useState<string>('');
  const [listYear, setListYear] = useState<TypeYear[]>([]);
  const history = useHistory();
  const [paid, setPaid] = useState<number|any|string>(2);
  const [paymentMethod, setPaymentMethod] = useState<number|any|string>(3);
  const [billingDepositAmount, setBillingDepositAmount] = useState<number|string|null>();
  const [billingNote, setBillingNote] = useState<string|null>();

  const [totalBillingDetailAmount, setTotalBillingDetailAmount] = useState<number|any|string>();
  const [totalTaxBillingDetail, setTotalTaxBillingDetail] = useState<number|any|string>();
  const [billingDiscount, setBillingDiscount] = useState<number|any|string>();
  const [billingIssueDate, setBillingIssueDate] = useState<number|string|null>('');
  const [billingPaidDate, setBillingPaidDate] = useState<string|any>('');
  const [billingDueDate, setBillingDueDate] = useState<string|any>('');
  const [billingDepositDate, setBillingDepositDate] = useState<string>('');

  const [billingDetailAmountAfter, setBillingDetailAmountAfter] = useState<number | string | null>(0);
  const [billingDetailTaxAmountAfter, setBillingDetailTaxAmountAfter] = useState<number | string | null>(0);
  const [billingDetailTax, setBillingDetailTax] = useState<number>(0);
  const [accountId, setAccountId] = useState<number>(0);
  const [historiesAccountName, setHistoriesAccountName] = useState<any[]>([]);




  const [billingId, setBillingId] = useState<number>(0);
  const params = new URLSearchParams(uselocation.search);
  let id = params.get('id') ? Number(params.get('id')) : 0;
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify();
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();
  const role = permissions?.role;
  const permission = permissions?.list_permission as PermissionsType;
  useEffect(() => {
    // get data edit
    let billing_detail_amount = params.get('billing_detail_amount') !== 'undefined' ? params.get('billing_detail_amount') : 0;
    let tax_amount = params.get('tax_amount') !== 'undefined' ? params.get('tax_amount') : 0;
    let discount = params.get('discount') !== 'undefined' ? params.get('discount') : 0;
    let year = params.get('year') !== 'undefined' ? params.get('year') : 0;
    let month = params.get('month') !== 'undefined' ? params.get('month') : 0;
    let due_date_edit = params.get('due_date') !== 'undefined' ? params.get('due_date') : null;
    let paid_date = params.get('paid_date') !== 'null' ? params.get('paid_date') : null;
    let issue_date = params.get('issue_date') !== 'undefined' ? params.get('issue_date') : null;
    let paid_status = params.get('paid');
    let payment_method = params.get('payment_method');
    let deposit_amount = params.get('deposit_amount') !== 'undefined' ? params.get('deposit_amount') : 0;
    let amount_after_discount = params.get('amount_after_discount') !== 'undefined' ? params.get('amount_after_discount') : 0;
    let tax_amount_after_discount = params.get('tax_amount_after_discount') !== 'undefined' ? params.get('tax_amount_after_discount') : 0;


    setYear(year);
    setMonth(month);
    setBillingIssueDate(issue_date)
    setBillingDetailAmountAfter(amount_after_discount);
    setBillingDetailTaxAmountAfter(tax_amount_after_discount);


    setPaid(paid_status);
    setPaymentMethod(payment_method);
    setTotalBillingDetailAmount(billing_detail_amount);
    setTotalTaxBillingDetail(tax_amount);
    setBillingDiscount(discount);
    setBillingDepositAmount(deposit_amount);
    setBillingDueDate(due_date_edit);
    setBillingPaidDate(paid_date);    
    if (authenticated) {
      fetchData();
    }
    //fetchData();
  }, [])
  const fetchYear = async () => {
    const ListYear = await GetDataYear(apolloClient);
    setListYear(ListYear)
  }
  const onSelectBoxChange = (event: any) => {
    const { target: { value, name } } = event;
    console.log('event.target', value);

    if (name === 'billingPaid') {
      setPaid(value);
    } else if (name === 'billingPaymentMethod') {
      setPaymentMethod(value);
    } else if (name === 'billingDepositAmount') {
      setBillingDepositAmount(value);
    } else if (name === 'account-pref') {
      setaccountPref(value);
    }
    else if (name === 'account-name') {
      setaccountName(value);
    }
    else if (name === 'billingTotalBilling') {
      setBillingDetailAmountAfter(value);
    }
    else if (name === 'billingTaxAmount') {
      setBillingDetailTaxAmountAfter(value);
    }
    else if (name === 'billingMonth') {
      setMonth(value);
    }
    else if (name === 'billingYear') {
      setYear(value);
    } 
    else if (name === 'billingDiscount') {
      setBillingDiscount(value)
      // const amount_after_discount = totalBillingDetailAmount * (100 - (value ? value : 0)) / 100;
      // const tax_amount_after_discount = amount_after_discount * billingDetailTax / 100;
      //setBillingDetailAmountAfter(amount_after_discount);
      //setBillingDetailTaxAmountAfter(tax_amount_after_discount);
    }
  }
  const onConfirmDialog = (event: any) => {
    if (!resetPassword) {
      notify(translate('common.reset_pass_error'), 'warning');
    } else {
      setShowConfirmReset(true);
    }
  }
  const onChangeValueInvoice = async (billing_detail_id: number, paid: number, payment_method: number, deposit_amount: any, paid_date: string, deposit_date: string, due_date: string, totalBillingDetailAmount: any, totalTaxBillingDetail: any, isUpdateAndGenReceipt: boolean, issue_date: any, amount_after_discount: any, tax_amount_after_discount: any, billing_id: number) => {
    const checkNotNumber = isNaN(deposit_amount);
    const checkNotNumberAmount = isNaN(totalBillingDetailAmount);
    const checkNotNumberTax = isNaN(totalTaxBillingDetail);

    if (checkNotNumber || checkNotNumberTax || checkNotNumberAmount) {
      notify(translate('common.must_be_number'), 'warning');
    } else {
      const dataInsert: DataInsertBilling = {
        billing_detail_id: billing_detail_id,
        paid: paid,
        TaxBillingDetail: billingDetailTax,
        payment_method: payment_method,
        deposit_amount: deposit_amount,
        paid_date: paid_date,
        deposit_date: deposit_date,
        due_date: due_date,
        totalBillingDetailAmount: totalBillingDetailAmount,
        totalTaxBillingDetail: totalTaxBillingDetail,
        isUpdateAndGenReceipt: isUpdateAndGenReceipt,
        issue_date: issue_date,
        amount_after_discount: amount_after_discount,
        tax_amount_after_discount: tax_amount_after_discount,
        billing_id: billing_id,
        account_id: accountId,
        year: year,
        month: month,
        discount: parseInt(billingDiscount)
      }
      await updateTable(apolloClient, dataInsert);
      if (isUpdateAndGenReceipt === true) {
        // generate receipt
        const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
        const response = await fetch(`${Domain}api/create-receipt/${billingId}`);
      }
      notify(translate('common.update_billing_success'));
      //setTimeout(function(){  redirect('/billings')}, 1000);
      history.push('/billing_view');
      //window.location.reload();
    }
  }
  const fetchData = async () => {
    const dataFetch = await GetDataBillingById(apolloClient, id);
    const dataDetail = dataFetch.dataBilling[0];
    setaccountName(dataDetail.account_name);
    setBillingDepositDate(dataDetail.deposit_date)
    setBillingId(dataDetail.billing_id);
    setHistoriesAccountName(dataDetail.account_name_history)
    setBillingDetailTax(dataDetail.billing_detail_tax);
    setAccountId(dataDetail.account_id)
  }
  return <Box width='100%' height='60%'>

    <Grid className='content-station'>
      <Grid>
        <h1>請求書編集画面</h1>
      </Grid>
      <Grid className='form-edit-billing' style={{ display: 'grid' }}>

        {/* <Grid>
          <p>Thông tin billing</p>
        </Grid> */}
        <Grid className='form-data-edit-column'>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-account-name">{translate('billing_view.account_name')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing-account-pref"
              value={accountName}
              className='input-search-bill'
              name='account-pref'
              disabled={true}
              onChange={onSelectBoxChange}
            />
            <br></br>
            {historiesAccountName.length &&
              <p style={{ color: 'GrayText' }}>旧団体名: {historiesAccountName[historiesAccountName.length - 1] ? historiesAccountName[historiesAccountName.length - 1].name : ''} : {historiesAccountName[historiesAccountName.length - 1] ? historiesAccountName[historiesAccountName.length - 1].modified : ''}</p>
            }
          </Grid>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-total-amount">{translate('billing_view.total_amount')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing-total-amount"
              value={billingDetailAmountAfter}
              className='input-search-bill'
              name='billingTotalBilling'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-tax-amount">{translate('billing_view.tax_amount')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing_tax_amount"
              value={billingDetailTaxAmountAfter}
              className='input-search-bill'
              name='billingTaxAmount'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>
        </Grid>
        <Grid className='form-data-edit-column'>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-discount">{translate('billing_view.discount')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing_discount"
              value={billingDiscount}
              className='input-search-bill'
              name='billingDiscount'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-issue-date">{translate('billing.issue_date')}</InputLabel>
            <DatePicker
              //labelId="billing-year-select-label"
              id="billing_issue_date"
              format='yyyy/MM/dd'
              value={billingIssueDate ? billingIssueDate : null}
              className='input-search-bill'
              name='billingIssueDate'
              disabled={false}
              onChange={(date: any) => { setBillingIssueDate(date) }}
            />
          </Grid>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-paid-date">{translate('billing_view.paid_date')}</InputLabel>
            <DatePicker
              //labelId="billing-year-select-label"
              id="billing_paid_date"
              format='yyyy/MM/dd'
              value={billingPaidDate ? billingPaidDate : null}
              className='input-search-bill'
              name='billingPaidDate'
              //disabled={true}
              onChange={(date: any) => { setBillingPaidDate(date) }}
            />
          </Grid>
        </Grid>
        <Grid className='form-data-edit-column'>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-due-date">{translate('billing_view.due_date')}</InputLabel>
            <DatePicker
              //labelId="billing-year-select-label"
              id="billing_due_date"
              format='yyyy/MM/dd'
              value={billingDueDate ? billingDueDate : null}
              className='input-search-bill'
              name='billingDueDate'
              //disabled={true}
              onChange={(date: any) => { setBillingDueDate(date) }}
            />
          </Grid>
        </Grid>
        <Grid className='form-data-edit-column'>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-year">{translate('billing_view.year')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing-year"
              value={year}
              type="number"
              className='input-search-bill'
              name='billingYear'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-month">{translate('billing_view.month')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              type="number"
              id="billing-month"
              value={month}
              className='input-search-bill'
              name='billingMonth'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-paid-select-label">{translate('billing.paid')}</InputLabel>
            <Select
              labelId="billing-paid-select-label"
              //id="billing-paid-select"
              className='input-search-bill'
              value={paid}
              name='billingPaid'
              onChange={onSelectBoxChange}
            >
              {PAID.map((m, idx) => {
                return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
              })}
            </Select>

          </Grid>
        </Grid>

        <Grid className='form-data-edit-column'>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-payment-method-select-label">{translate('billing.payment_method')}</InputLabel>
            <Select
              labelId="billing-payment-method-select-label"
              //id="billing-payment-method-select"
              value={paymentMethod}
              className='input-search-bill'
              name='billingPaymentMethod'
              onChange={onSelectBoxChange}
            >
              {PAYMENT_METHOD.map((m, idx) => {
                return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
              })}
            </Select>

          </Grid>

          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-amount">{translate('billing_view.deposit_amount')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing-deposit-amount"
              value={billingDepositAmount}
              className='input-search-bill'
              //variant= 'outlined'
              name='billingDepositAmount'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>
          <Grid className='row-form-data-edit' >
            <InputLabel id="billing-note">{translate('billing_view.note')}</InputLabel>
            <TextField
              //labelId="billing-year-select-label"
              id="billing-note"
              value={billingNote}
              className='input-search-bill'
              multiline
              //variant= 'outlined'
              name='billingNote'
              disabled={false}
              onChange={onSelectBoxChange}
            />
          </Grid>
        </Grid>

        <Grid>
          <Button
            className='btn-save-edit-billing'
            variant="contained"
            color="primary"
            onClick={() => { onChangeValueInvoice(id, paid, paymentMethod, billingDepositAmount, billingPaidDate, billingDepositDate, billingDueDate, totalBillingDetailAmount, totalTaxBillingDetail, false, billingIssueDate, billingDetailAmountAfter, billingDetailTaxAmountAfter, billingId) }}
          >
            保存
          </Button>
          <Button
            className='btn-generate_receipt-billing'
            variant="contained"
            color="primary"
            onClick={() => { onChangeValueInvoice(id, paid, paymentMethod, billingDepositAmount, billingPaidDate, billingDepositDate, billingDueDate, totalBillingDetailAmount, totalTaxBillingDetail, true, billingIssueDate, billingDetailAmountAfter, billingDetailTaxAmountAfter, billingId) }}
          >
            領収書を生成して保存
          </Button>
        </Grid>
      </Grid>
      {/* <Grid style={{ display: 'grid' }}>
        <Grid>
          <p>Thông tin biiling detail</p>
        </Grid>
        <Grid style={{ display: 'inline-flex', maxWidth: '80%' }}>
          <TextField size="small" style={{ marginRight: '15px' }} id="outlined-basic" label="路線名2" variant="outlined" />
          <TextField size="small" id="outlined-basic" label="駅名2" variant="outlined" />
        </Grid>
        <Grid style={{ marginTop: '10px' }}>
          <TextField size="small" style={{ width: '80%' }} id="outlined-basic" label="徒歩2" variant="outlined" />
        </Grid>
        <Grid style={{ marginTop: '10px' }}>
          <Select
            labelId="bill-year-select-label"
            id="bill-year-select"
            //value={BillingYear}
            name='BillingYear'
          //onChange={onSelectBoxChange}
          >
            {listYear.map((m, idx) => {
              return <MenuItem value={m.id} key={idx}>{m.name}</MenuItem>
            })}
          </Select>
        </Grid>
      </Grid> */}
    </Grid>
  </Box>


}






export default ConfirmPage;

