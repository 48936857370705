export const PUBLISH_STATUS = [
{id: 0, name: "作成中"},
{id: 1, name: "申請中"},
{id: 2, name: "非承認"}
]
const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],reference: '',export_name: 'id', nullable: true, editable: false, export: true, import: true },

{ name: 'name', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'account_id', type: 'reference', reference: 'account',searchField: 'name',export_name: 'account_id', nullable: true, editable: false, export: true, import: true },
{ name: 'manager_name', type: 'textinput',nullable: true,export_name: 'manager_name', editable: true, export: true, import: true },
{ name: 'button_filter_school', type: 'button_filter', nullable: false, editable: true, export: false, import: true },
{ name: 'button_filter_gym', type: 'button_filter', nullable: false, editable: true, export: false, import: true },
{ name: 'publish_status', type: 'status',options: PUBLISH_STATUS, nullable: false, export_name: 'publish_status', editable: true, export: true, import: true },

{ name: 'post_code', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'pref_name', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'city_name', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'address', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'building', type: 'textinput',nullable: false,export_name: 'name', editable: true, export: true, import: true },
{ name: 'area', type: 'reference', reference: 'area',searchField: 'name',export_name: 'area', nullable: true, editable: false, export: true, import: true },
{ name: 'pref', type: 'reference', reference: 'pref',searchField: 'name',export_name: 'pref', nullable: true, editable: false, export: true, import: true },
{ name: 'city', type: 'reference', reference: 'city',searchField: 'name',export_name: 'pref', nullable: true, editable: false, export: true, import: true },

// { name: 'line1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
// { name: 'line2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
// { name: 'line3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'line_cd3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'station_cd3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'walk3', type: 'textinput', nullable: true, editable: true, export: true, import: true },


{ name: 'created', type: 'textinput', nullable: true, editable: false,export_name: 'created', export: true, import: true },
{ name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,export_name: 'created_by',nullable: true, editable: false, export: false, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true,export_name: 'note', editable: true, export: true, import: true },

]

export default DATA;