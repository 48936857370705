import { gql } from "@apollo/client";
export const FETCH_ALL_EVENT = gql`
query MyQuery {
  event {
    id
    account {
      name
    }
  }
}
`;
export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;
export const GET_ALL_EVENT_ACCOUNT = async (apolloClient: any) => {
  let { data: {event} } = await apolloClient.query({
    query: FETCH_ALL_EVENT,
    variables: {
    }
  });
  return event;
}