import { downloadCSV } from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import moment from 'moment';
const exporter = async (items: any, resource: string, fields: any, translate: any,fetchRelatedRecords: any) => {
  var data:any = items
  await fetchRelatedRecords(items, 'user_id', 'user').then((user: any) => {
    const data_user = items.map((item: any) => (
      {
        ...item,
        lastname: `${user[item.user_id].lastname}`,
        firstname: `${user[item.user_id].firstname}`,
        email: `${user[item.user_id].email}`,
        phone: user[item.user_id].phone ? `${user[item.user_id].phone}`  :'',
        point_received:  item.created ? moment(item.point_received).format('YYYY-MM-DD h:mm:ss a' ) : item.point_received,
        created:  item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a' ) : item.created,
      }
    ));
    data = data_user;
  });
  var fieldName = fields.map((f: any) => (f.type === 'reference' && f.name ==='user_id') ?`${f.searchField}` : f.name )
  //var fieldName = fields.map((f: any) =>f.name)
  // create header

  const itemsForExport = data.map((item: any) => {
      return fieldName.map((f: any) => item[f])
  });
  
  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if ( val === `${resource}.csv_fields.${name}` ) {
      return translate(`${resource}.${name}`);
    }

    return val;
  }

  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    //encoding: 'UTF-8 BOM',
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

