import { AuthProvider } from 'ra-core';
import moment from "moment";
import Providers, { executeRefreshToken } from './utils/dataProvider'



const authProvider: AuthProvider = {
    login: ({ token, email,id, tokenExpiry, refreshToken, permission_all, permission_approve, permission_create_edit, permission_genre, permission_payment, permission_view }) => {
        const tokenString = token as string;
        const user = {
            id: id,
            email
        };
        localStorage.setItem('user', JSON.stringify(user));
        const token1 = tokenString.substring(0, tokenString.length / 2);
        const token2 = tokenString.substring(token1.length, tokenString.length);
        localStorage.setItem('token', tokenString);
        localStorage.setItem('token1', token1);
        localStorage.setItem('token2', token2);
        localStorage.setItem('tokenExpiry', tokenExpiry);
        localStorage.setItem('role', 'admin');
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('permission_all', permission_all);
        localStorage.setItem('permission_approve', permission_approve);
        localStorage.setItem('permission_create_edit', permission_create_edit);
        localStorage.setItem('permission_genre', permission_genre);
        localStorage.setItem('permission_payment', permission_payment);
        localStorage.setItem('permission_view', permission_view);
        return Promise.resolve();
    },
    logout: () => {
        console.log(`logout`);
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        localStorage.removeItem('token1');
        localStorage.removeItem('token2');
        localStorage.removeItem('tokenExpiry');
        localStorage.removeItem('language');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('permission_all');
        localStorage.removeItem('permission_approve');
        localStorage.removeItem('permission_create_edit');
        localStorage.removeItem('permission_genre');
        localStorage.removeItem('permission_payment');
        localStorage.removeItem('permission_view');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: async () => {
        const tokenExpiry = localStorage.getItem('tokenExpiry');
        const refreshToken = localStorage.getItem('refreshToken');
        const accessToken = localStorage.getItem('token');

        if (!tokenExpiry) {
            return Promise.reject({ message: false });
        }
        var date = new Date(parseInt(tokenExpiry) * 1000);
        const dateNow = Math.floor(Date.now() / 1000)
        if (parseInt(tokenExpiry) - dateNow < 0) {
            const { data, errors } = await executeRefreshToken({ refresh_token: refreshToken }, accessToken)
            if (!data || errors) {
                //refresh token expire
                return Promise.reject({ message: false })
            }
            const { token: tokenResponse, error } = data.refresh_token
            if (!error) {
                const setTimeExpire = (Math.floor(Date.now() / 1000) + (24 * 60 * 60)).toString();
                const tokenString = tokenResponse as string;
                localStorage.setItem('tokenExpiry', setTimeExpire);
                localStorage.setItem('token', tokenString);
                return Promise.resolve();
            }
            else {
                //Expired
                localStorage.removeItem('user');
                localStorage.removeItem('role');
                localStorage.removeItem('token1');
                localStorage.removeItem('token2');
                localStorage.removeItem('tokenExpiry');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('permission_all');
                localStorage.removeItem('permission_approve');
                localStorage.removeItem('permission_create_edit');
                localStorage.removeItem('permission_genre');
                localStorage.removeItem('permission_payment');
                localStorage.removeItem('permission_view');
                return Promise.reject({ message: false });
            }
        }
        return (localStorage.getItem('token1') && localStorage.getItem('token2')) ? Promise.resolve() : Promise.reject({ message: false });
    },
    getIdentity: () => {
        try {
            const user = JSON.parse(localStorage.getItem('user') || '');
            return Promise.resolve(user);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        const  permission_all =   localStorage.getItem('permission_all');
        const  permission_approve = localStorage.getItem('permission_approve');
        const  permission_create_edit = localStorage.getItem('permission_create_edit');
        const  permission_genre = localStorage.getItem('permission_genre');
        const  permission_payment = localStorage.getItem('permission_payment');
        const  permission_view = localStorage.getItem('permission_view');
        const dataPermission = {
            role: role, 
            list_permission : {
                permission_all: permission_all === 'true' ? true : false, 
                permission_approve: permission_approve === 'true' ? true : false, 
                permission_create_edit: permission_create_edit === 'true' ? true : false, 
                permission_genre: permission_genre === 'true' ? true : false, 
                permission_payment: permission_payment === 'true' ? true : false, 
                permission_view: permission_view === 'true' ? true : false, 
            }
        }
        return role ? Promise.resolve(dataPermission) : Promise.reject();
    }
};

export default authProvider;
