import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';

const CustomField = (props: any) => {
  const { record } = props;
  return <span>{(record['birthday'])} </span>;
}
const ReferenceFieldBirthday = (props: any) => {
  return (
  <>
    <ReferenceField link={false} {...props}>
      <CustomField />
    </ReferenceField>
  </>)
}
ReferenceFieldBirthday.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default ReferenceFieldBirthday;