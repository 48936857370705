import * as React from 'react';
import {Route} from 'react-router-dom';
import Configuration from './configuration/Configuration';
import ResetPassword from './pages/reset-password/ResetPassword';
import ActiveUser from './pages/active-user/ActiveUser';
import ChangePassword from './pages/change-password/ChangePassword';
import Permission from './pages/permission/PermissionList';
import PermissionDraft from './pages/permission/PermissionDraft';
import Billings from './pages/billing_view/BillingsList';
import EditBillings from './pages/billing_view/BillingsEdit';



export default [
    <Route exact path="/change-password" render={() => <ChangePassword />} />,
    <Route exact path="/permission-list" render={() => <Permission />} />,
    <Route exact path="/billings" render={() => <Billings />} />,
    <Route exact path="/edit-billings" render={() => <EditBillings />} />,

    <Route exact path="/permission-draft" render={() => <PermissionDraft />} />,
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/reset-password/:token" component={ResetPassword} noLayout/>,
    <Route exact path="/confirm/:token" component={ActiveUser} noLayout/>,
];
