import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import {Button, CircularProgress, Grid, Typography} from '@material-ui/core'
import {Notification, useTranslate} from 'react-admin';
import {useHistory, useParams} from 'react-router-dom'
import useStyles from './style'
import gql from "graphql-tag";
import {Field, withTypes} from "react-final-form";
import {useMutation} from "@apollo/client";
import {resetPassword, resetPasswordVariables} from "../../generated/resetPassword";
import {AppState} from "../../types";
import {useNotify} from "ra-core";
import {renderInput} from '../../utils/renderInput';

const RESET_PASSWORD = gql`
    mutation resetPassword(
        $password: String!
        $token: String!
    ) {
        resetPassword(token: $token, password: $password) {
            ... on ActionResult {
                success
            }
            ... on ValidationResult {
                code
                message
            }
        }
    }
`;

interface FormValues {
    password: string;
    confirmPassword: string;
}

interface FormValuesError {
    confirmPassword?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const ResetPassword = () => {
    const translate = useTranslate();
    const classes = useStyles();
    const { token } = useParams<{token:string}>();
    const [resetPass] = useMutation<resetPassword, resetPasswordVariables>(RESET_PASSWORD);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const client = useSelector((appState: AppState) => appState.client);
    const notify = useNotify();

    const validate = (values: FormValues) => {
        const errors: FormValuesError = {};
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }else if (values.password.length <= 5) {
            errors.password = translate('common.minLenght');
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = translate('common.msg_confirm_not_match');
        }
        return errors;
    };

    const handleResetPassword = async (values: FormValues) => {
        setLoading(true);
        const { data } = await resetPass({
            variables: {
                password: values.password,
                token: token
            }
        });
        setLoading(false);
        if (data) {
            if (data.resetPassword.__typename === 'ActionResult' && data.resetPassword.success) {
                notify(translate('forgot_password.mgs_recover_success'), 'info');
                history.push('/login');
            } else if (data.resetPassword.__typename === 'ValidationResult') {
                notify(data.resetPassword.message, 'warning');
            } else {
                notify("forgot_password.failed", 'warning');
            }
        }

    };

    return (
        <Grid container className={classes.container}>
            <div className={classes.customFormContainer}>
                <div className={classes.form}>
                    <Form
                        onSubmit={handleResetPassword}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Field
                                    autoFocus
                                    name="password"
                                    // @ts-ignore
                                    component={renderInput}
                                    variant="outlined"
                                    label={translate('reset.password')}
                                    type="password"
                                    disabled={loading}
                                    style={{ marginBottom: 35 }}
                                />

                                <Field
                                    name="confirmPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    variant="outlined"
                                    label={translate('reset.confirm_password')}
                                    type="password"
                                    disabled={loading}
                                    style={{ marginBottom: 35 }}
                                />

                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('reset.reset')}
                                </Button>
                            </form>
                        )}
                    />
                    <Button
                        color="primary"
                        size="large"
                        onClick={() => history.push('/login')}
                        className={classes.forgetButton}
                    >
                        {translate('reset.back_to_login')}
                    </Button>
                </div>
                <Typography color="primary" className={classes.copyright}>
                    {client.name}
                </Typography>
            </div>
            <Notification />
        </Grid>
    );
};

export default ResetPassword;
