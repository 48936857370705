import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import { GetAllGenre, GetAllGenreSport } from './gql';
import { useEffect, useState } from 'react';
import { required, useDataProvider } from 'ra-core';
import { SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();
  if (id) {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}新規登録
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const [dataGenreSport, setdataGenreSport] = useState<any>([]);
  const [dataGenre, setdataGenre] = useState<any>([]);
  const { editForm } = props;
  const dataProvider = useDataProvider();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const form = useForm();

  if (editForm) {
    var { record: { id } } = props;
  }
  useEffect(() => {
    (async () => {
      const dataGenre = await GetAllGenre(dataProvider);
      dataGenre.push({ id: 100, name: 'スポーツ全般' });      
      if (!props.formData.genre_id && props.formData.genre_id !== 0) {
        props.formData.genre_id = 100
        form.change('genre_id', 100)
        form.change('genre_sport_id', 100)
      }
      if (props.formData.genre_id !== 0 && !props.formData.genre_sport_id) {
        form.change('genre_sport_id', 101)
      }
      const genre_name = dataGenre.filter(x => x.id === props.formData.genre_id);      
      const dataGenreSport = await GetAllGenreSport(dataProvider, props.formData.genre_id,genre_name[0].name);
      setdataGenreSport(dataGenreSport);
      setdataGenre(dataGenre);
    })()
  }, [props.formData.genre_id]);
  // useEffect(() => {
  //   (async () => {
  //     let genre_id = 0;
  //     if(!props.formData.genre_id){
  //       genre_id = 100
  //     }
  //     if (props.formData.genre_id !== null) {
  //       genre_id = props.formData.genre_id
  //     }
  //     const dataGenreSport = await GetAllGenreSport(dataProvider, genre_id);
  //     setdataGenreSport(dataGenreSport);
  //   })()
  // }, [props.formData.genre_id]);

  var isEditPage = editForm === true ? true : false;
  return <Container style={{ justifyContent: 'center' }}>
    <Grid style={{ width: '100%' }} container justify='center' spacing={2}>
      <Grid style={{ width: '50%' }} container justify='center' spacing={2} >
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.question.edit_page" : "menu.question.add_page"} id={editForm ? id : ''} />
          <Separator />
        </Grid>
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          if (d.name === 'genre_id') {
            return <Grid item xs={12} key={idx}>
              <SelectInput
                allowEmpty={false}
                source='genre_id'
                reference={'genre'}
                //disabled={!editable && isEditPage}
                choices={dataGenre}
                validate={requiredValidate}
                fullWidth
                variant="outlined"
                label={translate('question.genre_id')}
              >
              </SelectInput>
            </Grid>
          }
          if (d.name === 'genre_sport_id') {
            return <Grid item xs={12} key={idx}>
              <SelectInput
                allowEmpty={false}
                source='genre_sport_id'
                reference={'genre_sport'}
                //disabled={!editable && isEditPage}
                choices={dataGenreSport}
                validate={requiredValidate}
                fullWidth
                variant="outlined"
                label={translate('question.genre_sport_id')}
              >
              </SelectInput>
            </Grid>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }
          return <Grid item xs={12} key={idx}>
            <InputRenderer source='question' data={d} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;