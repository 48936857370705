import {ApolloClient, ApolloLink, HttpLink, InMemoryCache, split} from '@apollo/client'
import possibleTypes from './possibleTypes.json';
import {getMainDefinition} from '@apollo/client/utilities';
import {WebSocketLink} from '@apollo/client/link/ws';


const httpLink = new HttpLink({
    uri: process.env.REACT_APP_AUTH
});

// create the web socket link
const wsLink = new WebSocketLink({
    uri: `${process.env.REACT_APP_HASURA}`.replace('http', 'ws'),
    options: {
        reconnect: true,
        connectionParams: () => ({
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token1')}${localStorage.getItem('token2')}`
            }
        }),
    }
});

const middlewareHttpLink = new ApolloLink((operation, forward) => {
    if (localStorage.getItem('token1')) {
        operation.setContext({
            uri: process.env.REACT_APP_HASURA,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token1')}${localStorage.getItem('token2')}`,
            },
        })
    } else {
        operation.setContext({
            uri: process.env.REACT_APP_AUTH,
            headers: {},
        })
    }

    return forward(operation)
});

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    middlewareHttpLink.concat(httpLink)
);


const createAuthClient = () => {
    const cache = new InMemoryCache({
        possibleTypes,
    });

    return new ApolloClient({
        link: splitLink,
        cache,
    });
};

export default createAuthClient;