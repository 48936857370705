import { gql } from "@apollo/client";

export const RecoverBulkAccountAdmin = gql`
mutation MyMutation($list_account_admin_id: [Int!]) {
  update_account_admin(where: {id: {_in: $list_account_admin_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
  }
}
`;
export const DeleteBulkAccountAdmin = gql`
mutation MyMutation($list_account_admin_id: [Int!]) {
  delete_account_admin(where: {id: {_in: $list_account_admin_id}}) {
    affected_rows
  }
}
`;
export const RecoverAccountAdmin = async (apolloClient: any,list_account_admin_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: RecoverBulkAccountAdmin,
      variables: {
        list_account_admin_id: list_account_admin_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
  console.log('11111111',status);
  
  return status;
}
export const DeleteAccountAdmin = async (apolloClient: any,list_account_admin_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DeleteBulkAccountAdmin,
      variables: {
        list_account_admin_id: list_account_admin_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}