import * as React from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { NumberInput } from 'react-admin';
import { useState } from 'react';


const RadioMonthlyFee = (props: any) => {
    const translate = useTranslate();
    const {handleChange} = props
    const [monthlyFeeBetween, setMonthlyFeeBetween] = useState<boolean>(false);
    const [monthlyFeeFrom, SetMonthlyFeeFrom] = useState(0);
    const [monthlyFeeTo, SetMonthlyFeeTo] = useState(0);

    const [checked_1, setCheck1] = useState<boolean>(false);
    const [checked_2, setCheck2] = useState<boolean>(false);
    const [checked_3, setCheck3] = useState<boolean>(false);

    const [isDisable1, setIsDisable1] = useState<string>('disable');
    const [isDisable2, setIsDisable2] = useState<string>('disable');
    const [isDisable3, setIsDisable3] = useState<string>('disable');
    
    return <Grid className='RadioMonthlyFee'>
        <RadioGroup row aria-label="gender" className='Radio-Group-Fee' name="row-radio-buttons-group">
            <Grid className={'Radio-1'}>
                <FormControlLabel value="一律" checked={checked_1} control={<Radio />} label="一律"
                    onClick={ async (event: any) => {
                        setCheck1(true)
                        setCheck2(false)
                        setCheck3(false)
                        setMonthlyFeeBetween(false);
                        setIsDisable1('')
                        setIsDisable2('disable')
                        setIsDisable3('disable')
                    }}
                />
                <NumberInput
                    source={'radio_value_1'}
                    disabled={isDisable1}
                    label={translate(`team.radio_value_1`)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= { async (event:any) => {
                        SetMonthlyFeeFrom(event.target.value);
                        SetMonthlyFeeTo(event.target.value);
                        await handleChange(monthlyFeeBetween,event.target.value,event.target.value);
                    }}
                />
                <p className='amount'>円</p>
            </Grid>
            <Grid className={'Radio-2'}>
                <FormControlLabel value="下限のみ" checked={checked_2} control={<Radio />} label="下限のみ"
                    onClick={(event: any) => {
                        setCheck2(true)
                        setCheck1(false)
                        setCheck3(false)
                        setIsDisable2('')
                        setIsDisable1('disable')
                        setIsDisable3('disable')
                        setMonthlyFeeBetween(true);
                        SetMonthlyFeeTo(event.target.value);
                    }}
                />
                <NumberInput
                    source={'radio_value_2'}
                    disabled={isDisable2}
                    label={translate(`team.radio_value_2`)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= { async(event:any) => {
                        SetMonthlyFeeFrom(event.target.value);
                        SetMonthlyFeeTo(0);
                        await handleChange(monthlyFeeBetween,event.target.value,0);
                    }}
                />
                <p className='amount'>円 〜</p>

            </Grid>
            <Grid className={'Radio-3'}>
                <FormControlLabel value="下限・上限あり" checked={checked_3} control={<Radio />} label="下限・上限あり"
                    onClick={(event: any) => {
                        setCheck3(true)
                        setCheck1(false)
                        setCheck2(false)
                        setMonthlyFeeBetween(true);
                        SetMonthlyFeeTo(1);
                        setIsDisable3('')
                        setIsDisable2('disable')
                        setIsDisable1('disable')
                    }}
                />
                <NumberInput
                    source={'radio_value_3_from'}
                    disabled={isDisable3}
                    label={translate(` `)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= {async (event:any) => {
                        SetMonthlyFeeFrom(event.target.value);
                        await handleChange(monthlyFeeBetween,event.target.value,monthlyFeeTo);

                    }}
                />
                <span className='SpanBetween'>~</span>
                <NumberInput
                    source={'radio_value_3_to'}
                    disabled={isDisable3}
                    label={translate(` `)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= { async(event:any) => {
                        SetMonthlyFeeTo(event.target.value);
                        await handleChange(monthlyFeeBetween,monthlyFeeFrom,event.target.value);
                    }}
                />
                <p className='amount'>円</p>

            </Grid>
        </RadioGroup>
    </Grid>
}
export default RadioMonthlyFee;
