import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
    SaveButton
} from 'react-admin';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { Grid, DialogActions, DialogContent, DialogTitle, Dialog, TextField, Input } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
//import { quickCreateFields } from './dataset'
import { SelectInput } from 'react-admin';
import { GET_ALL_GENRE, GET_ALL_GENRE_LIST, GET_ALL_GENRE_SPORT_LIST, GET_NAME_GENRE_SPORT, RemoveDuplicate } from './gql';
import { useDataProvider } from 'ra-core';
import Select from 'react-select'
import EditIcon from '@material-ui/icons/FileCopy';
import { TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import IconContentAdd from '@material-ui/icons/Add';
import { CheckboxGroupInput } from 'react-admin';

const GenreSportInput = (props: any) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const form = useForm();
    const [listGenreSport, setListGenreSport] = useState<string>('');
    const [listGenre, setListGenre] = useState<any[]>([]);
    const [listGenreSportAll, setListGenreSportAll] = useState<any[]>([]);
    const [listGenreSportSelected, setListGenreSportSelected] = useState<any[]>([]);
    const [activeItem, setActiveItem] = useState<number>(-1);
    const [initGenreSport, setInitGenreSport] = useState<any[]>([]);
    const [updateGenreSport, setUpdateGenreSport] = useState<boolean>(false)
    useEffect(() => {
        (async () => {
            const list_genre = [];
            if (form.getState().values.favorite_genre_sport_id1 && form.getState().values.favorite_genre_sport_id1 >= 0) {
                list_genre.push(form.getState().values.favorite_genre_sport_id1)
            }
            if (form.getState().values.favorite_genre_sport_id2 && form.getState().values.favorite_genre_sport_id2 >= 0) {
                list_genre.push(form.getState().values.favorite_genre_sport_id2)
            }
            if (form.getState().values.favorite_genre_sport_id3 && form.getState().values.favorite_genre_sport_id3 >= 0) {
                list_genre.push(form.getState().values.favorite_genre_sport_id3)
            }
            if (form.getState().values.favorite_genre_sport_id4 && form.getState().values.favorite_genre_sport_id4 >= 0) {
                list_genre.push(form.getState().values.favorite_genre_sport_id4)
            }
            if (form.getState().values.favorite_genre_sport_id5 && form.getState().values.favorite_genre_sport_id5 >= 0) {
                list_genre.push(form.getState().values.favorite_genre_sport_id5)
            }
            const removeDuplicate = RemoveDuplicate(list_genre)
            setInitGenreSport(removeDuplicate);
            const genre_sport = await GET_NAME_GENRE_SPORT(apolloClient, removeDuplicate);
            const list_all_genre = await GET_ALL_GENRE_LIST(apolloClient);
            const list_all_genre_sport = await GET_ALL_GENRE_SPORT_LIST(apolloClient);

            setListGenreSportAll(list_all_genre_sport);
            setListGenreSport(genre_sport);
            setListGenre(list_all_genre);

        })()
    }, [updateGenreSport]);
    const GetListGenre = () => {
        return listGenreSport
    }
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const handleAddGenre = () => {
        if(initGenreSport.length > 5){
            notify(translate('ジャンルスポーツを5つ以上選択しました。'), 'warning');
        }else {
            for (let index = 0; index < 5; index++) {
                let element = initGenreSport[index] ? initGenreSport[index] : -1;
                form.change(`favorite_genre_sport_id${index+1}`,element);
            }
            form.change('list_genre','-')
            notify(translate('お気に入りのジャンルスポーツを正常に更新しました。'));
            setUpdateGenreSport(!updateGenreSport);
            setShowDialog(false);
            
        }
    };
    const handleSubmit = async () => {
        setOpen(true)
    }
    const GenreClick = (genre_id: number) => {
        const list_genre_sport_selected = listGenreSportAll.filter(x => x.genre_id === genre_id);
        const format_genre_sport = list_genre_sport_selected.map(x => ({ id: x.id, name: x.secondary_name }));
        setListGenreSportSelected(format_genre_sport);
    }
    const onClickCheckBox = (genre_sport_id_selected: number, checked: boolean) => {
        let list_genre_sport: any[] = initGenreSport;
        let checkExist = list_genre_sport.filter((x: any) => x === genre_sport_id_selected);
        if (checkExist.length > 0 && checked == false) {
            const  list_genre_sport_remove = list_genre_sport.filter((x: any) => x !== genre_sport_id_selected);
            setInitGenreSport(list_genre_sport_remove);   
        } else {
            if (list_genre_sport.length > 5 &&  checked == true) {
                notify(translate('ジャンルスポーツを5つ以上選択しました。'), 'warning');
            } else if(checked == true) {
                list_genre_sport.push(genre_sport_id_selected);
                setInitGenreSport(list_genre_sport);
            }
        }
    }
    return (
        <Fragment>
            <TextInput
                source="list_genre"
                resource="user"
                fullWidth
                label={translate("user_list.list_genre")}
                variant="outlined"
                format={GetListGenre}
                onClick={async () => {
                    handleClick();
                }}
            />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                className={'MuiDialog-paperWidthSm-Genre-Sport'}
            >
                <DialogTitle className='title-modal-genre-sport'>{translate(`活動拠点住所`)}</DialogTitle>

                <FormWithRedirect
                    //resource={resource}
                    save={handleSubmit}

                    //initialValues={{ created_by: identity.id }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-list-genre-sport">
                                <div className='cp-tab'>
                                    <ul className="cp-tab-cat cp-tab-cat-sport">
                                        {listGenre.map((item, index) => {
                                            return <li
                                                onClick={(event: any) => {
                                                    setActiveItem(index)
                                                    GenreClick(event.target.value);
                                                }}
                                                className={activeItem === index ? 'li-genre active-genre' : 'li-genre'}
                                                key={index}
                                                value={item.id}>
                                                {item.name}
                                            </li>
                                        })}
                                    </ul>
                                    <ul className='cp-tabpanels cp-tabpanels-sport'>
                                        <li className="cp-tabpanel">
                                            <div className='checkbox'>
                                                <CheckboxGroupInput
                                                    source="list_genre"
                                                    choices={listGenreSportSelected}
                                                    initialValue={initGenreSport}
                                                    //disabled={disableCheckbox ? 'disabled' : ''}
                                                    onClick={(event: any) => { onClickCheckBox(parseInt(event.target.value), event.target.checked) }}
                                                    label={''}
                                                />
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="決定する"
                                    onClick={handleAddGenre}
                                >
                                    {/* <IconContentAdd /> */}
                                </Button>
                                {/* <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                >
                                    <IconCancel />
                                </Button> */}

                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </Fragment>
    );
};

export default GenreSportInput;