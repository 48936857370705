import * as React from 'react';
import {FC, Fragment, useEffect} from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    usePermissions,
    useTranslate,
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import {FilterProps, PermissionsType} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { PUBLISH_STATUS } from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import StatusField from './StatusField';
import ButtonApprove from './ButtonApprove';
import ButtonDisapprove from './ButtonDisapprove';

import ChipFieldJsonb from '../components/ChipFieldJsonb';
import exporter from './exporter';
import { ReferenceField } from 'react-admin';
import { ACCOUNT_SEARCH } from '../team/dataset';
import { useHistory } from 'react-router';
import AccountCreateField from '../team/AccountCreateField';
import { NumberInput } from 'react-admin';

const ExportableFields = Fields.filter(d => d.export);

const UserBulkActionButtons = (props: any) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    const {filterValues: {publish_status}} = props as any
    const {selectedIds} = props as any
    return (
        <Fragment>
            {((publish_status === 1 || publish_status === 4 || publish_status === undefined) && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonApprove {...props}/>}
            {((publish_status === 1 || publish_status === 4 || publish_status === undefined) && (permission?.permission_approve  === true || permission?.permission_all  === true)) && <ButtonDisapprove {...props}/>}
            {(role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('team.id')} alwaysOn />
            <SearchInput source="name,name_kana,president_name,philosophy,achievements,practice_datetime,trial_description,fee_description,appeal_title,genre_other,genre_sport_other" alwaysOn />
            <SelectInput choices={PUBLISH_STATUS} source="publish_status" label={translate('team.publish_status')} alwaysOn />
            <SelectInput
                allowEmpty={false}
                choices={ACCOUNT_SEARCH}
                parse={(val: number) => val === 0 ? null : true}
                format={(val: any) => val ? 1 : 0}
                source="account_id@_is_null"
                label={translate('team.is_frontend_created_search')} alwaysOn />
            {/* <SelectInput choices={PUBLISH_STATUS} source="publish_status" label={translate('team.publish_status')} alwaysOn/> */}
        </Filter>
    )
};

const TeamList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'team';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/team_draft';
    },[props]);
    if (role ===undefined) {
        return null;
    }

    return (
        <List
            {...props}
            filters={<TeamFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            filterDefaultValues={{ publish_status: 1 }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons/>}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'team_draft', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='team' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            <CustomizableDatagrid rowClick={edit}>

            {Fields.map((f, idx) => {
                if (!Boolean(f)) return <></>
                const { name, reference, searchField, type, options, numberFormat } = f || {}
                if (type === 'textinput') {
                    if (numberFormat) {
                        return <NumberField source={name} label={translate(`${module}.${name}`)} />
                    }
                    return <TextField source={name} label={translate(`${module}.${name}`)} />
                }
                if (type === 'reference') {
                    return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                        <TextField source={searchField} />
                    </ReferenceField>
                }
                if (type === 'status') {
                    return <StatusField source={name} label={translate(`${module}.${name}`)} />
                }

                if (type === 'date' || type === 'datetime') {
                    return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                }
                if (type === 'imagefield') {
                    return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                }

                if (type === 'checkbox') {
                    return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                }

                if (type === 'selectbox') {
                    return <SelectField
                        source={name}
                        choices={options}
                        label={translate(`${module}.${name}`)}
                    />
                }
                if (type === 'is_frontend_created') {
                    return <AccountCreateField
                        source={name}
                        label={translate(`${module}.${type}`)}
                    />
                }
                if (type === 'mutipleChoiceInput') {
                    return <ChipFieldJsonb
                    source={name}
                    label={translate(`${module}.${name}`)}
                />
            }

                return <></>
            })}
            </CustomizableDatagrid>
        </List>

    );
};

export default TeamList;
