import { gql } from "@apollo/client";

export const DeleteBulkTeamAdmin = gql`
mutation MyMutation($list_team_admin_id: [Int!]) {
  delete_team_admin(where: {id: {_in: $list_team_admin_id}}) {
    affected_rows
  }
}
`;
export const RecoverBulkTeamAdmin = gql`
mutation MyMutation($list_team_admin_id: [Int!]) {
  update_team_admin(where: {id: {_in: $list_team_admin_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
  }
}
`;
export const DeleteMultiTeamAdmin = async (apolloClient: any,list_team_admin_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DeleteBulkTeamAdmin,
      variables: {
        list_team_admin_id: list_team_admin_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
};
export const RecoverMultiTeamAdmin = async (apolloClient: any,list_team_admin_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: RecoverBulkTeamAdmin,
      variables: {
        list_team_admin_id: list_team_admin_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
