import * as React from "react";
import PropTypes from 'prop-types';

const CustomField = (props: any) => {
  const { record } = props;
  const monthly_text = record['monthly_fee'] == 0 ? 'なし' : 'あり'; 
  return <span> {monthly_text}</span>
}

const CustomReferField = (props: any) => {
  return <>
  <CustomField {...props}/>
  </>
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferField;