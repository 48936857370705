import PersonIcon from '@material-ui/icons/Person';

import List from './List';
import Create from './Create';
import Edit from './Edit';

export default {
    list: List,
    create: Create,
    edit: Edit,
    icon: PersonIcon,
};
