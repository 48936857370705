import { gql } from "@apollo/client";
export const FETCH_RESELLERS = gql`
  query GetResellers($ids: [Int!]) {
    reseller(where: {id: {_in: $ids}}) {
      id
      account_name
      account_number
      account_type
      zip_code
      other
      address
      bank_branch_code
      bank_branch_name
      bank_code
      bank_name
      name
    }
  }
`;
export const FETCH_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    account(where: {id: {_eq: $id}}) {
        id
        contact_email
        created_by
    }
  }
`;
export const FETCH_TEAM = gql`
  query GetTeam($id: Int!) {
    team(where: {id: {_eq: $id}}) {
        id
        account_id
        contact_email
        created_by
    }
  }
`;
export const APPROVE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!],$varified_by: Int, $approved_at: timestamp) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:2,varified_by:$varified_by, approved_at: $approved_at}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const DISAPPROVE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!],$varified_by: Int) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:4,varified_by:$varified_by}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const UNPUBLISH_TEAM = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const SEND_MAIL_APPROVE_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "APPROVE", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_APPROVE_TEAM_BY_ADMIN = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_account_team(list_team: $list_team) {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_UNPUBLISH_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "UNPUBLISH", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_DISAPPROVE_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "DISAPPROVE", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const GET_TEAM_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_in: [1, 4]}}) {
      id
      account_id
      created_by 
    }
}
`;
export const GET_TEAM_ACCESS_UNPUBLISH = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_in: [1]}, account_id: {_is_null: true}}) {
      id
      account_id
    }
}
`;
export const GET_TEAM_NOT_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_nin: [1]}}) {
      id
    }
}
`;
export const GET_TEAM_NOT_ACCESS_UNPUBLISH = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_nin: [3]}, account_id: {_is_null: false}}) {
      id
    }
}
`;
export const GET_TEAM = gql`
query Myquery($team_id: Int) {
    team(where: {id: {_eq: $team_id}}) {
      id
      account_id
    }
}
`;
export const INSERT_USER_POINT = gql`
mutation MyMutation($user_id: Int, $amount: Int) {
  insert_user_point(objects: {amount: $amount, user_id: $user_id, point_type_id: 7, created_by: 0}) {
    affected_rows
  }
}
`;
export const GET_AMOUNT_USER_POINT = gql`
query Myquery($point_type_id: Int) {
    point_type(where: {id: {_eq: $point_type_id}}) {
      amount
    }
}
`;
export const FETCH_ALL_TEAM_ADMIN = gql`
query MyQuery($account_id: Boolean, $name: String) {
  team(where: {account_id: {_is_null: $account_id} ,name: {_ilike: $name}}) {
    id
    name
  }
}
`;
export const UPDATE_TEAM_REVIEW = gql`
mutation MyMutation($team_id_to: Int, $team_id_from: Int) {
  update_team_review(where: {team_id: {_eq: $team_id_from}}, _set: {team_id: $team_id_to}) {
    affected_rows
  }
}

`;
export const DeleteBulkTeam = gql`
mutation MyMutation($list_team_id: [Int!]) {
  delete_app_user(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_place(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_staff(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_blog(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_chat_room(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_favorite(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_news(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_survey(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_admin(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_app_menu(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_contact(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_draft(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_game(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_game_contact(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_group(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_staff(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_todo(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team(where: {id: {_in: $list_team_id}}) {
    affected_rows
  }
}
`;
export const DeleteBulkTeamId = gql`
mutation MyMutation($list_team_id: [Int!]) {
  delete_team(where: {id: {_in: $list_team_id}}) {
    affected_rows
  }
}
`;
export const RECOVER_BULK_TEAM = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {is_soft_deleted:false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;

export const FETCH_DATA_USER = gql`
query MyQuery {
  team {
    name
    account {
      name
      user {
        email
        firstname
        lastname
        phone
      }
    }
    id
    created_by
    prefByPref {
      name
    }
    cityByCity {
      name
    }
    areaByArea {
      name
    }
    genre{
      name
    }
    genre_sport {
      secondary_name
    }
  }
}
`;
export const FETCH_ALL_USER = gql`
query MyQuery {
  user {
    lastname
    firstname
    phone
    email
    id
  }
}
`;
export const GetAllAccountUser = async (apolloClient: any) => {
  let { data: dataUserAccount } = await apolloClient.query({
    query: FETCH_DATA_USER,
    variables: {
    }
  });
  return dataUserAccount.team;
}
export const GetAmountPoint = async (apolloClient: any, point_type_id: number) => {
  let { data: dataUserPointType } = await apolloClient.query({
    query: GET_AMOUNT_USER_POINT,
    variables: {
      point_type_id: point_type_id
    }
  });
  return dataUserPointType.point_type;
}
export const GetAllUser = async (apolloClient: any) => {
  let { data: dataUser } = await apolloClient.query({
    query: FETCH_ALL_USER,
    variables: {
    }
  });
  return dataUser.user;
  
}
export const DeleteMultiTeam = async (apolloClient: any,list_team_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DeleteBulkTeamId,
      variables: {
        list_team_id: list_team_id
      }
    });
    console.log('dataDeleteMulti',dataDeleteMulti);

    status = 1;
  }catch(e){
    //console.log('1111111111114',e);
    
    status = 0
  }
 
  return status;
}
export const RecoverMultiTeam = async (apolloClient: any,list_team_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: RECOVER_BULK_TEAM,
      variables: {
        list_team_id: list_team_id
      }
    });
    console.log('dataDeleteMulti',dataDeleteMulti);
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
export const GetAllTeam = async (apolloClient: any,name: string) => {
  let nameSearch = `%${name}%`
  let dataTeam = [];
  let { data: dataTeamAll } = await apolloClient.query({
    query: FETCH_ALL_TEAM_ADMIN,
    variables: {
      account_id: false,
      name: nameSearch
    }
  });
  const team = dataTeamAll.team;
  for (let index = 0; index < team.length; index++) {
    const element = team[index];
    dataTeam.push({ value: element.id, label: element.name })
  }
  return dataTeam;
}
export const CopyTeamreview = async (dataProvider: any, team_id_from: number, team_id_to: number,apolloClient:any) => {
  let status = 0
  const { data } = await apolloClient.mutate({
    mutation: UPDATE_TEAM_REVIEW,
    variables: {
      team_id_to: team_id_to, 
      team_id_from: team_id_from
    }
  });
  let dataUpdateStatusTeam = await dataProvider.update("team", {
    id: team_id_from,
    data: { publish_status: 3 }
  });  
  if(data && dataUpdateStatusTeam){
    status = 1
  }
  return status;
}
export const GetTeamById = async (dataProvider: any, team_id: number) => {
  let data_team = await dataProvider.getList("team", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    limit: 1,
    filter: { id: team_id },
  });
  return data_team;
}
export const GetAllGenreSport = async (dataProvider: any, genre_id: number) => {
  let data_genre_sport = await dataProvider.getList("genre_sport", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: { genre_id: genre_id },
  });

  let ListData = [];
  const { data } = data_genre_sport;
  for (let item = 0; item < data.length; item++) {
    ListData.push({ id: data[item].id, name: data[item].secondary_name })
  }
  return ListData;
};
// fetch data json
export const fetchJson = async (link: string) => {
  try {
    const response = await fetch(link);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
export const GetArea = async () => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
  const ListArea = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    ListArea.push({ id: dataAllArea[are].id, name: dataAllArea[are].name });
  }
  return ListArea;
};
export const GetPref = async (area_id: Number) => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
  const ListPref = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    const listPrefByArea = dataAllArea[are].pref;
    if (dataAllArea[are].id === area_id) {
      for (var pref = 0; pref < listPrefByArea.length; pref++) {
        ListPref.push({ id: listPrefByArea[pref].id, name: listPrefByArea[pref].name })
      }
      return ListPref;
    }
  }
};
export const GetCity = async (pref_id: Number) => {
  const dataAllCityByPref = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/${pref_id}.json`);
  const ListCity = [];
  for (var city = 0; city < dataAllCityByPref.length; city++) {
    ListCity.push({ id: dataAllCityByPref[city].id, name: dataAllCityByPref[city].city });
  }
  return ListCity;
};