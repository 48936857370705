import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { CONTACT_STATUS, CONTACT_STATUS_INQUIRY } from './dataset';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import CreateField from '../components/editField';
import exporter from './exporter';
import ReferenceFieldFullName from './ReferenceFieldFullName';
import ButtonExperiencer from './LinkToExperiencer';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import ReferenceFieldBirthday from './ReferenceFieldBirthday';
import ReferenceFieldGender from './ReferenceFieldGender';
import ReferenceFieldAge from './ReferenceFieldAge';
import FeeField from './FeeField'
import { MONTHLY_FEE_LIST } from '../team/dataset';
import ActionsField from './Actions';
import ToggleSwitch from './toggleSwitchInput';
import { FLAG_DISABLE } from '../team_experiencer_list/dataset';
import { DateInput } from 'react-admin';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="note,phone,message,name,account_email,account_name,account_phone,user_phone,user_email,fullname" alwaysOn />
            <SelectInput source="contact_status" label={translate('team_contact.contact_status')} choices={CONTACT_STATUS} alwaysOn />
            <SelectInput source="monthly_fee" label={translate('team.monthly_fee')} choices={MONTHLY_FEE_LIST} alwaysOn />
            <SelectInput source="flag_disable" label={translate('team_experiencer.flag_disable')} choices={FLAG_DISABLE} alwaysOn />
            <DateInput source="created@_gte" label={translate('team_experiencer.created_from')} alwaysOn />
            <DateInput source="created@_lte" label={translate('team_experiencer.created_to')} alwaysOn />
        </Filter>
    )
};
const CustomDataShow = (props: any) => {
    const { record } = props;
    if (props.type === 'monthly_fee') {
        if (record['monthly_fee_between'] == false) {
            if (!record['monthly_fee_from']) {
                return <span> 0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 </span>
        }
        if (record['monthly_fee_between'] == true && !record['monthly_fee_to']) {
            if (!record['monthly_fee_from']) {
                return <span>0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 ~ </span>
        }
        if (record['monthly_fee_between'] == true && record['monthly_fee_to']) {
            return <span> {record['monthly_fee_from']} ~ {record['monthly_fee_to']} 円  </span>

        }
    }
    if (props.type === 'fee') {
        const monthly_text = record['monthly_fee'] == 0 ? 'なし' : 'あり';
        return <span> {monthly_text}</span>
    }
    return <TextField source={props.type} />
}
const TeamReferenceFieldFee = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <CustomDataShow />
    </ReferenceField>
}
const AccountReferenceField = (props: any) => {
    return <ReferenceField {...props} source={'account_id'} reference={'account'} link={false}>
        <TextField source={'name'} />
    </ReferenceField>
}
const ContactStatusField = (props: any) => {
    const { record } = props
    let contact_status = '';    
    switch (record.contact_status) {
        case 0:
            contact_status = '未対応'
            break;
        case 1:
            contact_status = '対応済み'
            break;
        case 2:
            record.change_from_inquiry_flag === true ?  contact_status = '問合せ入会' : contact_status = '入会'
            break;
        case 9:
            record.change_from_inquiry_flag === true ? contact_status = '問合せキャンセル' : contact_status = 'キャンセル'
            break;
        default:
            break;
    }
    return <span>{contact_status}</span>
}
const TeamContactList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const apolloClient = useApolloClient();
    const dataFilter = props.location.search as string;
    const checkStatus = dataFilter.includes('@_neq%22:1');
    const checkShowPayableRate = dataFilter.includes('%22contact_type%22:1');

    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit' : '';
    const module = 'team_contact';
    const history = useHistory();
    useEffect(() => {
        history.location.pathname = '/team_contact_list';
    }, [props]);
    if (role === undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'team_contact', ExportableFields, translate, fetchRelatedRecords, apolloClient)}
            actions={<ListActions to='team_contact' />}
            empty={<ListEmpty />}
            title={<CreateField titlePage={''} />}
        >
            <CustomizableDatagrid rowClick={''}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options } = f || {}
                    if (type === 'textinput') {
                        // if (numberFormat) {
                        //     return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        // }
                        if(checkShowPayableRate === false && name === 'payable_rate'){
                            return <></>
                        }
                        if(name === 'team_name'){
                            return <></>
                        }
                        if(name === 'user_phone'){
                            return <TextField source={name} label={translate(`${module}.created_by_phone`)} />
                        }
                        if(name === 'user_email'){
                            return <TextField source={name} label={translate(`${module}.created_by_email`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                   
                    if (type === 'reference') {
                        if (name === 'user_id') {
                            return <ReferenceFieldFullName source="user_id" label={translate(`${module}.${name}`)} reference='user' />
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} link={false}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }
                    if (type === 'account_name') {
                        return <ReferenceField link={false} label={translate(`${module}.account_name`)} source={'team_id'} reference={'team'}>
                            <AccountReferenceField />
                        </ReferenceField>
                    }
                    if(name === 'status_updated_at' && checkStatus === true){
                        return <></>
                    }
                    if(name === 'status_updated_at' && checkStatus === false){
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'date' || type === 'datetime') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'gender') {
                        return <ReferenceFieldGender source="gender" label={translate(`${module}.${name}`)}  />
                    }
                    if (type === 'birthday') {
                        return <ReferenceFieldBirthday source="user_id" label={translate(`${module}.${name}`)} reference='user' />
                    }
                    if (type === 'age') {
                        return <ReferenceFieldAge source="user_id" label={translate(`${module}.${name}`)} reference='user' />
                    }
                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    
                    if (type === 'status') {
                        if (name === 'contact_status' && checkStatus === true) {
                            return <></>
                        } else if(name === 'contact_status' && checkStatus === false) {
                            return <ContactStatusField label={translate(`${module}.${name}`)} />
                        }else {
                            return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                        }

                    }
                    if(type === 'button'){
                        return <ToggleSwitch label={'完了フラグ'} props={props}/>
                     }
                    if(name === 'action'){
                        return <ActionsField label={translate('common.actions')} />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'button_filter') {
                        if (name === 'button_filter_team_contact') {
                            return <ButtonExperiencer label={'体験者一覧ボタン'} source={" "} />
                        }
                    }
                    if ((type === 'monthly_fee' || type === 'monthly_fee_from' || type === 'monthly_fee_to' || type === 'monthly_fee_between') && name === 'team_contact_id') {
                        return <ReferenceField link={false} label={translate(`team_experiencer.${type}`)} sortBy={"monthly_fee_from"} source={name} reference={reference}>
                            <TeamReferenceFieldFee type={type} />
                        </ReferenceField>
                    }
                    if ((type === 'fee') && name === 'fee') {
                        return <FeeField label={translate(`${'team'}.${'monthly_fee'}`)} source={name} reference={reference} />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default TeamContactList;
