import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
    SaveButton
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { Grid, DialogActions, DialogContent, DialogTitle, Dialog, FormControl, InputLabel, TextField } from '@material-ui/core';
import { useDataProvider } from 'ra-core';
import { TextInput } from 'react-admin';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    toolbar: {
      padding: 30,
      marginBottom: 20,
      paddingTop: 30
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      width: 300,
      paddingTop: 30
  
    },
    formControlButton: {
      margin: theme.spacing(1),
      minWidth: 100,
      width: 100,
      paddingTop: 35
  
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    InputField : {
      paddingTop: 10
    }, 
    title: {
      
    }
  }));
const ActionsResetPass = (props: any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const {is_open, onClose,onCloseConfirm,isDisable, onChangeResetPass,onConfirmDialog, is_show_confirm, onSubmitConfirm} = props;
    const [showDialog, setShowDialog] = useState(false);
    const [idTeamCopy, setIdTeamCopy] = useState(0);
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const [dataTeam, setdataTeam] = useState<any>([])
    const [name, setName] = useState<string>('');
    const dataProvider = useDataProvider();
    const classes = useStyles();
    useEffect(() => {
        (async () => {
            setShowDialog(is_open);
            setOpen(is_show_confirm);
        })()
    }, [is_open,is_show_confirm]);
    // const handleClick = () => {
    //     setShowDialog(true);
    // };

    // const handleCloseClick = () => {
    //     setShowDialog(false);
    // };
    // //const handleClick = () => setOpen(true);
    // const handleDialogClose = () => setOpen(false);

    // const handleConfirm = async () => {
    //     //deleteMany();
    //     //const dataUpdateTeam = await CopyTeamreview(dataProvider, selectedIds[0], idTeamCopy, apolloClient);
    // };
    // const handleSubmit = async () => {
    //     onConfirmDialog();
    //     setOpen(true);

    // }

    return (
        <Fragment>
            {/* <Button label={translate('common.btn_copy_team_review')} onClick={handleClick} startIcon={<FileCopy />} style={{ color: '#2596be' }} /> */}
            <Dialog
                fullWidth
                open={showDialog}
                onClose={onClose}
            >
                <DialogTitle>{translate(`common.modal_reset_title`)}</DialogTitle>

                <FormWithRedirect
                    save={onConfirmDialog}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-reset-pass">
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="admin-password-label">{translate('common.reset_password')}</InputLabel>
                                    <TextField
                                        className={classes.InputField}
                                        id="reset-admin-password"
                                        name='password-reset'
                                        onChange={onChangeResetPass}
                                    >
                                    </TextField>
                                </FormControl>
                                {/* <FormControl className={classes.formControl}>
                                    <InputLabel id="admin-password-label">{translate('common.reset_password_confirm')}</InputLabel>
                                    <TextField
                                        className={classes.InputField}
                                        id="reset-password-confirm"
                                        name='password-reset-confirm'
                                        onChange={onChangeResetPass}
                                    >
                                    </TextField>
                                </FormControl> */}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={onClose}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    label={translate(`common.reset_pass_confirm_btn`)}
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    //pristine={pristine}
                                    saving={saving}
                                //disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            <Confirm
                isOpen={is_show_confirm}
                title={translate('common.msg_title_copy')}
                content={translate('common.msg_copy_confirm')}
                onConfirm={onSubmitConfirm}
                onClose={onCloseConfirm}
            />
        </Fragment>
    );
};

export default ActionsResetPass;