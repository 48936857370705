import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import { useState } from 'react';
import RichTextInput from 'ra-input-rich-text';


const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();

  if (id) {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)} 新規追加
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  var isEditPage = editForm === true ? true : false;
  const translate = useTranslate();
  if (editForm) {
    var { record: { id } } = props;

  }
  return <Container>

    <Grid container justify='center' style={{ width: '100%' }} spacing={2}>

      <Grid style={{ width: '50%' }} spacing={2} >
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.sposuru_news.page_edit" : "menu.sposuru_news.page_create"} id={editForm ? id : ''} />
          <Separator />
        </Grid>
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }
          if (d.name === 'id' && isEditPage === false) {
            return <></>
          }
          if (d.name === 'content') {
            return <>
              <RichTextInput
                source="content"
                toolbar={[
                  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                  ['bold', 'italic', 'underline', 'strike', 'link'],        // toggled buttons
                  ['blockquote', 'code-block'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                  [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                  [{ 'direction': 'rtl' }],                         // text direction
                  ['clean']                                         // remove formatting button
                ]}
                fullWidth
                multiline
                rows={20}
                resource="sposuru_news"
                label={translate('sposuru_news.content')}
                style={{ border: '1px solid #000' }}
              />
          </>
          }
          if (d.type === 'target_app' || d.type === 'target_user' || d.type === 'target_admin') {
            return <Grid item xs={4} key={idx}>
              <InputRenderer isEditPage={isEditPage} source='sposuru_news' data={d} />
            </Grid>
          }
          return <Grid item xs={12} key={idx}>
            <InputRenderer isEditPage={isEditPage} source='sposuru_news' data={d} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;