import * as React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
// import ListIcon from '@material-ui/icons/List';
import ListIcon from '@material-ui/icons/ChatOutlined';

import IconCancel from '@material-ui/icons/Cancel';
import ChatList from './ChatList';
import { useApolloClient } from '@apollo/client';


import { Fragment, useState, useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import {
  FormWithRedirect,
  SaveButton
} from 'react-admin';
import { GetDataChatTeamContact } from './gql';
const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 20,
  },
});

const BtnShowList = (props: any) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [dataChat, setDataChat] = useState<any>([]);

  const apolloClient = useApolloClient();

  const { target_name, filter_name, filter_val } = props || {}
  const handleCloseClick = () => {
    setShowDialog(false);
  };
  const handleClick = async () => {
    const dataChatMessage = await GetDataChatTeamContact(apolloClient,filter_val);
    setDataChat(dataChatMessage);
    setShowDialog(true);
  };
  return (
    <React.Fragment>
      <Button
        size="medium"
        color="primary"
        onClick={handleClick}
        className={classes.link}
      >
        <ListIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
      >
        <DialogTitle>{`メッセージ履歴 ( ${dataChat.team ? dataChat.team.name : ``} )`}</DialogTitle>

        <FormWithRedirect
          render={({
            handleSubmitWithRedirect,
            pristine,
            saving
          }: any) => (
            <>
              <DialogContent className="dialog-show-message-experiencer">
                <ChatList dataChat={dataChat}/>
              </DialogContent>
              <DialogActions>
                <Button
                  size="large"
                  color="inherit"
                  onClick={handleCloseClick}
                >
                  キャンセル
                  <IconCancel />
                </Button>
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </React.Fragment>
  )
};

export default BtnShowList;
