import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import EditRounded from '@material-ui/icons/EditRounded';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
    SaveButton
} from 'react-admin';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { DialogActions, DialogContent, DialogTitle, Dialog, FormControl, InputLabel, TextField, makeStyles, CircularProgress, Box, Typography, MenuItem, Select, OutlinedInput, Grid } from '@material-ui/core';
import { CopyTeamreview, GetAllTeam, UpdateMultiTeam } from './gql';
import { useDataProvider } from 'ra-core';
import { ATTENTION, MONTHLY_FEE_LIST, PRACTICE_DAYS, SHOW_ACCOUNT_INFO, TRIAL_TYPES, VISIT_OK } from './dataset';
import { CheckboxGroupInput } from 'react-admin';
import { CheckBox } from '@material-ui/icons';
import RadioMonthlyFee from './RadioMonthlyFeeMultiUpdate';
const useStyles = makeStyles((theme) => ({
    toolbar: {
        padding: 30,
        marginBottom: 20,
        paddingTop: 30
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 500,
        width: 500,
        paddingTop: 30

    },
    formControlButton: {
        margin: theme.spacing(1),
        minWidth: 100,
        width: 100,
        paddingTop: 35

    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    InputField: {
        paddingTop: 10,
        height: 10
    },
    title: {

    },
    textInput: {
        width: '50%'
    }
}));
const BulkEditTeam = (props: any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const classes = useStyles();
    const [showDialog, setShowDialog] = useState(false);
    const [monthlyFee, SetMonthlyFee] = useState();
    const [attention, SetAttention] = useState();
    const [showAccountInfo, SetShowAccountInfo] = useState<boolean>();
    const [practiceDay, SetPracticeDay] = useState<[]>([]);
    const [trialType, SetTrialType] = useState();
    const [visitOk, SetVisitOk] = useState<boolean>();
    const [monthlyFeeFrom, SetMonthlyFeeFrom] = useState(0);
    const [monthlyFeeTo, SetMonthlyFeeTo] = useState(0);
    const [monthlyFeeBetween, SetMonthlyFeeBetween] = useState<boolean>(false);
    const [admissionFee, SetAdmissionFee] = useState();
    const [annualFee, SetAnnualFee] = useState();
    const [initialFee, SetInitialFee] = useState();

    const [loading, setLoading] = useState<boolean>(false);

    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const dataProvider = useDataProvider();
    useEffect(() => {
    }, []);
    const {
        resource,
    } = useListContext();
    const handleClick = () => {
        setShowDialog(true);
    };
    const handleCloseClick = () => {
        setShowDialog(false);
    };
    //const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleChangeFee = async (monthly_fee_between: boolean, monthly_fee_from: number, monthly_fee_to: number) => {
        SetMonthlyFeeBetween(monthly_fee_between);
        SetMonthlyFeeFrom(monthly_fee_from);
        SetMonthlyFeeTo(monthly_fee_to);
    }
    const handleConfirm = async () => {
        //deleteMany();
        setLoading(true);
        const team_ids = selectedIds;
        const dataUpdate = {
            monthly_fee: monthlyFee,
            attention: attention,
            show_account_info: showAccountInfo,
            practice_days: practiceDay,
            trial_type: trialType,
            visit_ok: visitOk,
            monthly_fee_from: monthlyFeeFrom,
            monthly_fee_to: monthlyFeeTo,
            monthly_fee_between: monthlyFeeBetween,
            admission_fee: admissionFee,
            annual_fee: annualFee,
            initial_fee: initialFee
        }
        const dataUpdateTeam = await UpdateMultiTeam(team_ids, dataUpdate, apolloClient);
        if (dataUpdateTeam === 1) {
            setLoading(false)
            refresh();
            notify(translate('common.msg_update_multi_team_success'));
            unselectAll(resource);
            setOpen(false);
            setShowDialog(false);
        } else {
            notify(translate('common.msg_update_multi_team_fail'), 'warning');
        }
    };
    const handleSubmit = async (value: any) => {
        if (monthlyFee === undefined
            || attention === undefined
            || showAccountInfo === undefined
            || !practiceDay 
            || trialType === undefined
            || visitOk === undefined
            || admissionFee === undefined
            || annualFee === undefined
            || initialFee === undefined
        ) {
            setLoading(false)
            notify(translate('common.msg_update_multi_team_fail'), 'warning');
        } else {
            setOpen(true)
        }
    }
    const handleChange = async (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'monthly_fee') {
            SetMonthlyFee(value);
        } else if (name === 'attention') {
            SetAttention(value);
        } else if (name === 'show_account_info') {
            const dataFilter = SHOW_ACCOUNT_INFO.filter(x => x.name === value);
            SetShowAccountInfo(dataFilter[0].id);
        } else if (name === 'practice_days') {
            SetPracticeDay(value);
        } else if (name === 'trial_type') {
            SetTrialType(value);

        } else if (name === 'visit_ok') {
            const dataFilter = VISIT_OK.filter(x => x.name === value);
            SetVisitOk(dataFilter[0].id);
        } else if (name === 'monthly_fee_from') {
            SetMonthlyFeeFrom(value);

        } else if (name === 'monthly_fee_to') {
            SetMonthlyFeeTo(value);

        } else if (name === 'monthly_fee_between') {
            SetMonthlyFeeBetween(value);

        } else if (name === 'admission_fee') {
            SetAdmissionFee(value);

        } else if (name === 'annual_fee') {
            SetAnnualFee(value);

        } else if (name === 'initial_fee') {
            SetInitialFee(value);
        }
    }

    return (
        <Fragment>
            <Button label={translate('一括変更する')} onClick={handleClick} startIcon={<EditRounded />} style={{ color: '#CC9933' }} />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                className='dialog-update-multi-team'
            >
                <DialogTitle>{translate(`一括変更`)}</DialogTitle>
                <FormWithRedirect
                    resource={resource}
                    save={handleSubmit}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-copy-team">
                                <FormControl className={classes.formControl}>
                                    <div className="form-multi-edit-team">
                                        <TextField
                                            id="filled-helperText"
                                            select
                                            //className={'text-input-edit-form'}
                                            className={'text-input-edit-form'}
                                            required={true}
                                            label={translate(`team.monthly_fee`)}
                                            size="small"
                                            name="monthly_fee"
                                            onChange={handleChange}
                                        >
                                            {MONTHLY_FEE_LIST.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="filled-helperText"
                                            size="small"
                                            select
                                            required={true}
                                            label={translate(`team.attention`)}
                                            className={'text-input-edit-form'}
                                            name="attention"
                                            onChange={handleChange}
                                        >
                                            {ATTENTION.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="filled-helperText"
                                            size="small"
                                            required={true}
                                            label={translate(`team.show_account_info`)}
                                            className={'text-input-edit-form'}
                                            onChange={handleChange}
                                            select
                                            name="show_account_info"
                                        >
                                            {SHOW_ACCOUNT_INFO.map((option) => (
                                                <MenuItem key={option.name} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <Grid className='multiselect-practice-day'>
                                            <CheckboxGroupInput
                                                source='practice_days'
                                                choices={PRACTICE_DAYS}
                                                fullWidth
                                                onChange={async (event: any) => {
                                                    let dataConvert = {
                                                        target: {
                                                            name: 'practice_days',
                                                            value: event
                                                        }
                                                    }
                                                    await handleChange(dataConvert);
                                                }}
                                                variant="outlined"
                                                label={translate('team.practice_days')}
                                            />
                                        </Grid>
                                        <TextField
                                            id="filled-helperText"
                                            size="small"
                                            select
                                            required={true}
                                            label={translate(`team.trial_type`)}
                                            className={'text-input-edit-form'}
                                            name="trial_type"
                                            onChange={handleChange}
                                        >
                                            {TRIAL_TYPES.map((option) => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="filled-helperText"
                                            size="small"
                                            select
                                            label={translate(`team.visit_ok`)}
                                            className={'text-input-edit-form'}
                                            name="visit_ok"
                                            onChange={handleChange}

                                        >
                                            {VISIT_OK.map((option) => (
                                                <MenuItem key={option.name} value={option.name}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {monthlyFee === 1 && <RadioMonthlyFee {...props} handleChange={handleChangeFee} />}
                                        <TextField
                                            id="filled-helperText"
                                            label={translate(`team.admission_fee`)}
                                            size="small"
                                            required={true}
                                            className={'text-input-edit-form'}
                                            type="number"
                                            name="admission_fee"
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            id="filled-helperText"
                                            label={translate(`team.annual_fee`)}
                                            size="small"
                                            required={true}
                                            className={'text-input-edit-form'}
                                            type="number"
                                            name="annual_fee"
                                            onChange={handleChange}
                                        />
                                        <TextField
                                            id="filled-helperText"
                                            label={translate(`team.initial_fee`)}
                                            required={true}
                                            size="small"
                                            className={'text-input-edit-form'}
                                            type="number"
                                            name="initial_fee"
                                            onChange={handleChange}
                                        />
                                    </div>

                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    label={translate(`common.confirm_button_copy`)}
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={loading}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('一括変更確認')}
                content={translate('本当に一括変更しますか？')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkEditTeam;


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;