import * as React from 'react';
import { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button, CircularProgress } from '@material-ui/core';
import {
  signedDownloadQuery,
  SignedDownloadVariables
} from '../../types';
import { useApolloClient } from "@apollo/client";
import axios from 'axios';
import moment from 'moment';
import {
   GET_EVENT_DRAFT_NOT_ACCESS_APPROVE, GET_EVENT_DRAFT_ACCESS_APPROVE, DISAPPROVE_EVENT_DRAFT
} from './gql';
import 'moment/locale/ja';
import { CheckCircle } from '@material-ui/icons';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import { RemoveCircle } from '@material-ui/icons';
import jwt_decode from "jwt-decode";
import { Confirm } from 'react-admin';


const ButtonDisapprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const [open, setOpen] = useState(false);
  const apolloClient = useApolloClient();
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();

  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataDispprove: number[] = [];
  var dataDispproveSendMail: number[] = [];

  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;
  const ApproveEventDraft = async (event_draft_id: number[]) => {
    let { data: dataNotAccess } = await apolloClient.query({
      query: GET_EVENT_DRAFT_NOT_ACCESS_APPROVE,
      variables: {
        list_event_draft_id: event_draft_id
      }
    });
    let { data: dataAccess } = await apolloClient.query({
      query: GET_EVENT_DRAFT_ACCESS_APPROVE,
      variables: {
        list_event_draft_id: event_draft_id
      }
    });
    if (dataAccess.event_draft.length > 0) {
      //setCountSuccess(dataAccess.team.length);
      dataAccess.event_draft.map((x: any) => {
        dataDispprove.push(x.id)
        //dataDispproveSendMail.push(x.id);
      })
    } else {
      return {
        countSuccess: 0,
        countFail: dataNotAccess.event_draft.length
      };
    }
    //setCountFail(dataNotAccess.team.length);
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
    const { data } = await apolloClient.mutate({
      mutation: DISAPPROVE_EVENT_DRAFT,
      variables: {
        list_event_draft_id: dataDispprove
      }
    });
    let dataReturn = {
      countFail: dataNotAccess.event_draft.length,
      countSuccess: dataAccess.event_draft.length,
    }
    // send mail     
    return dataReturn;
  }
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = async () => {
    const data = await ApproveEventDraft(selectedIds);
    if (data.countSuccess > 0) {
      //notify(translate('common.msg_approved'))
      // notify(`${data.countSuccess}非承認にしました`)
      notify(`非承認にしました。`)
      unselectAll('event_draft');
      refresh();
    } else {
      notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning');
      setOpen(false)
    }
  }
  return (
    <Fragment>
      <Button
        disabled={isConverting || props.disabled}
        //variant="contained"
        startIcon={<RemoveCircle />}
        color="secondary"
        onClick={handleClick}>
        {translate('team.disapprove')}
      </Button>
      <Confirm
        isOpen={open}
        title={translate('team.msg_title_disapprove')}
        content={translate('team.msg_disapprove_message')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        cancel={translate('common.cancel_text_disapprove_team')}
        confirm= {translate('common.confirm_text_disapprove_team')}
      />
    </Fragment>
  )
}

ButtonDisapprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonDisapprove.defaultProps = {
  selectedIds: []
}

export default ButtonDisapprove;