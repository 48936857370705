import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import { Paper, FormControl, Select, MenuItem, InputLabel, Button, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 30,
    marginBottom: 20,
    paddingTop: 30
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    width: 300,
    paddingTop: 30

  },
  formControlButton: {
    margin: theme.spacing(1),
    minWidth: 100,
    width: 100,
    paddingTop: 35

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  InputField : {
    paddingTop: 10
  }, 
  title: {
    
  }
}));


const FormCreateAdmin = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles();
  const { 
    email, 
    password,
    onChangeText, 
    onCreateAdminBtnClick,
    isDisable
 } = props;
  return <Paper className={classes.toolbar}>

    <div className={classes.title}>
     <h2>{translate('admin.create_title')}</h2> 
    </div>
    <div>
    <FormControl className={classes.formControl}>
      <InputLabel id="admin-email-label">{translate('admin.email')}</InputLabel>
      <TextField
       className={classes.InputField}
        //labelId="invoice-year-select-label"
        id="admin-email"
        name='email-admin'
        onChange={onChangeText}
      >
      </TextField>
    </FormControl>

    <FormControl className={classes.formControl}>
      <InputLabel id="admin-password-label">{translate('admin.password')}</InputLabel>
      <TextField
       className={classes.InputField}
        type="password"
        //labelId="invoice-year-select-label"
        id="admin-password"
        name='password-admin'
        onChange={onChangeText}
      >
      </TextField>
    </FormControl>

    <FormControl className={classes.formControlButton}>
      <Button
        //disabled={!confirmed}
        disabled={isDisable}
        variant="contained"
        color="primary"
        onClick={onCreateAdminBtnClick}
        >
       {translate('追加')}
    </Button>
    </FormControl>
    </div>
    
  </Paper>
}

export default FormCreateAdmin;