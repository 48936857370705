import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useNotify,
  useGetIdentity,
  Confirm,
  EditButton,
} from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
import { Box, Button, Checkbox } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ToolBar from './ToolBar';
import { DeleteAdmin, DeleteAdminPermanently, GetDataAdmin, GetDataAdminById, GetDataAdminDraft, RegisterAdmin, RestoreAdmin, UpdatePermissionAdmin } from './gql'
import SectionTitle from '../team/SectionTitle';
import FormCreateAdmin from './FormCreateAdmin';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  DataGrid: {
    marginBottom: 15
  },
  ActionButton: {
    marginRight: 10
  }
}));
const ConfirmPage = () => {

  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [rows, setRows] = useState([] as any);
  const [data, setData] = useState([] as any);
  const [confirmed, setConfirmed] = useState(0);
  const [selectedRows, setSelectedRows] = useState([] as any);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const apolloClient = useApolloClient();
  const [email, setEmail] = useState<string>('');
  const [reload, setReload] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const translate = useTranslate()
  const notify = useNotify();
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();

  useEffect(() => {
    // if (!loading && authenticated) {
    //   fetchData();
    // }

    fetchData();
  }, [])
  const handleChange = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;
    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_all: !params?.row?.permission_all,
          permission_create_edit: !params?.row?.permission_all,
          permission_view: !params?.row?.permission_all,
          permission_genre: !params?.row?.permission_all,
          permission_payment: !params?.row?.permission_all,
          permission_approve: !params?.row?.permission_all,

        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeCreate = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_create_edit: !params?.row?.permission_create_edit,
          permission_all: (!params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeApprove = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_approve: !params?.row?.permission_approve,
          permission_all: (params?.row?.permission_create_edit === true && !params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeView = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_view: !params?.row?.permission_view,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && !params?.row?.permission_view == true && params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangeGenre = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_genre: !params?.row?.permission_genre,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && !params?.row?.permission_genre == true && params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const handleChangePayment = async (e: any, params: GridCellParams) => {
    const { target: { checked } } = e;

    const id = params?.row?.id;
    const dataUpdate = data.map((x: any) => {
      if (x.id === id) {
        return {
          ...x,
          permission_payment: !params?.row?.permission_payment,
          permission_all: (params?.row?.permission_create_edit === true && params?.row?.permission_approve == true && params?.row?.permission_view == true && params?.row?.permission_genre == true && !params?.row?.permission_payment == true) ? true : false
        }
      } else {
        return {
          ...x
        }
      }

    })
    setData(dataUpdate)
  }
  const renderActionCellPermissionAll = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_all)}
        onChange={(e: any) => handleChange(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionCreateEdit = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_create_edit)}
        onChange={(e: any) => handleChangeCreate(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionApprove = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_approve)}
        onChange={(e: any) => handleChangeApprove(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionView = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_view)}
        onChange={(e: any) => handleChangeView(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionGenre = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_genre)}
        onChange={(e: any) => handleChangeGenre(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCellPermissionPayment = (params: GridCellParams) => {
    return <Box>
      <Checkbox
        checked={Boolean(params?.row?.permission_payment)}
        onChange={(e: any) => handleChangePayment(e, params)}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Box>
  }
  const renderActionCell = (params: GridCellParams) => {
    let DeleteDisable = false;
    if (params.row.id === identity.id) {
      DeleteDisable = true
    }

    return <Box>
      <Button
        className={classes.ActionButton}
        //disabled={!confirmed}
        variant="contained"
        color="primary"
        id={"btn-resetpass-admin"}
        onClick={(e: any) => onRestore(e, params)}
      >
        {translate('戻す')}
      </Button>
      <Button
        className={classes.ActionButton}

        disabled={DeleteDisable}
        variant="contained"
        color="primary"
        id={"btn-delete-admin"}
        onClick={(e: any) => delete_admin(e, params)}
      >
        {translate('完全に削除')}
      </Button>
    </Box>
  }
  const delete_admin = async (event: any, params: GridCellParams) => {
    const { props } = event
    const admin_id = params?.row?.id as number;
    const dataDelete = await DeleteAdminPermanently(apolloClient, admin_id);
    if (dataDelete.id) {
      const dataAfterDelete = data.filter((x: any) => x.id !== admin_id);
      setData(dataAfterDelete);
      notify(translate('common.msg_delete_permanently'));
    } else {
      notify(translate('common.delete_error'), 'warning');
    }
  }
  const onRestore = async (event: any, params: GridCellParams) => {
    const { props } = event
    const admin_id = params?.row?.id as number;
    const dataRestore = await RestoreAdmin(apolloClient, admin_id);
    if (dataRestore.id) {
      const dataAfterRestore = data.filter((x: any) => x.id !== admin_id);
      setData(dataAfterRestore);
      notify(translate('common.restore_success_permission'));
    } else {
      notify(translate('common.restore_error'), 'warning');
    }
  }
  const onChangeText = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'email-admin') {
      setEmail(value);
    } else if (name === 'password-admin') {
      setPassword(value);
    }
    console.log('email', email);

  }
  const PermissionColumn = [
    //{ field: 'id', headerName: 'ID', typename: 'number', width: 100 },
    { field: 'email', headerName: 'Eメールアドレス', typename: 'string', minWidth: 250,flex: 1, disableColumnMenu: true },
    //{ field: 'status', headerName: 'ステータス', typename: 'number', width: 200, disableColumnMenu: true },
    { field: 'permission_all', headerName: 'システム管理者', renderCell: renderActionCellPermissionAll, minWidth: 100,flex: 0.5 },
    { field: 'permission_create_edit', headerName: '追加と編集（削除不可）', renderCell: renderActionCellPermissionCreateEdit, minWidth: 100,flex: 0.5 },
    { field: 'permission_approve', headerName: '承認（※）', renderCell: renderActionCellPermissionApprove, minWidth: 100,flex: 0.5 },
    { field: 'permission_view', headerName: '閲覧のみ', renderCell: renderActionCellPermissionView, minWidth: 100,flex: 0.5 },
    { field: 'permission_genre', headerName: 'ジャンル、ジャンルスポーツ', renderCell: renderActionCellPermissionGenre, minWidth: 100,flex: 0.5 },
    { field: 'permission_payment', headerName: '請求管理', renderCell: renderActionCellPermissionPayment, minWidth: 100,flex: 0.5 },
    //{ field: 'created', headerName: 'ステータス', typename: 'string', width: 300, disableColumnMenu: true },
    { field: 'action', headerName: '確認', minWidth: 300,flex: 1, renderCell: renderActionCell },
  ]

  const fetchData = async () => {
    const dataAdmin = await GetDataAdminDraft(apolloClient);
    setData(dataAdmin);
  }
  const onChangeBtnSave = async () => {
    let dataPreUpdate = data.map((admin: any) => {
      return {
        id: admin.id,
        email: admin.email,
        created_by: admin.created_by,
        created: admin.created,
        permission_all: admin.permission_all,
        permission_create_edit: admin.permission_create_edit,
        permission_approve: admin.permission_approve,
        permission_genre: admin.permission_genre,
        permission_payment: admin.permission_payment,
        permission_view: admin.permission_view,
        refresh_token: admin.refresh_token,
        status: admin.status,
        is_deleted: admin.is_deleted
      }
    })
    const dataUpdate = await UpdatePermissionAdmin(apolloClient, dataPreUpdate);
    if (dataUpdate.status_code === 200) {
      notify(translate('common.update_permission_success'));
    } else {
      notify(translate('common.update_permission_fail'), 'warning');
    }
  };
  return <Box width='100%' height='60%'>
    <DataGrid
      rows={data}
      columns={PermissionColumn}
      pageSize={20}
      className={classes.DataGrid}
    />
    {/* <Confirm
      isOpen={showConfirmPopup}
      title={translate('common.batch_update_confirm_title')}
      content={`${selectedRows.length}${translate('common.batch_update_confirm_content')} ${translate('common.batch_update_confirmation')}`}
      onConfirm={handleBatchUpdate}
      onClose={() => setShowConfirmPopup(false)} /> */}
  </Box>


}






export default ConfirmPage;

