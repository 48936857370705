import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
import { GET_ALL_EVENT_ACCOUNT } from './gql';
const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any,apolloClient: any) => {
  var data: any = items
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.name)
  await fetchRelatedRecords(items, 'user_id', 'user').then((user: any) => {
    const data_user = items.map((item: any) => (
      {
        ...item,
        user_name: `${user[item.user_id].lastname} ${user[item.user_id].firstname}`,
        contact_type: item.contact_type  == 1 ? '申込み' : '問い合わせ',
        contact_status: item.contact_status == 0 ? '未対応' : '対応済み',
        event_contact_category: item.event_contact_category == 10 ? 'イベント・大会全般について' : (item.event_contact_category == 20 ? 'イベント・大会参加について': (item.event_contact_category == 30 ? 'その他質問' : '')),
        parent_flg: item.parent_flg == true ? '保護者です' :  '保護者ではないです',
        created:  item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a' ) : item.created,
        user_phone: user[item.user_id].phone,
        user_email: user[item.user_id].email,
      }
    ));
    data = data_user;
  });
  const dataevent = await GET_ALL_EVENT_ACCOUNT(apolloClient);
  
  data = data.map((item: any) => {
    const dataFilter = dataevent.filter((x: any) => x.id === item.event_id);
    let account_name = '';
    if (dataFilter[0]) {
      account_name = dataFilter[0].account.name
    }
    return {
      ...item, 
      account_name: account_name
    }
  })
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }
    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

