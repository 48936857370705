import * as React from 'react';
import {
    Button,
    CreateButton,
    ExportButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
    usePermissions,
    useTranslate,
} from 'react-admin';
import { useEffect, useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Grid } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import { GetDataBilling } from '../billing_view/gql';

const ListActions = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const apolloClient = useApolloClient();
    const [numOfAccount, SetNumOfAccount] = useState<number>(0);
    const [totalAmount, SetTotalAmount] = useState<number>(0);
    const {
        className,
        exporter,
        filters,
        maxResults,
        to,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
        total,
    } = useListContext();
    useEffect(() => {
        const month = filterValues.month ? filterValues.month : 0;
        const year = filterValues.year ? filterValues.year : 0;
        const account_address = filterValues.account_address ? filterValues.account_address : '';
        const account_pref = filterValues.account_pref ? filterValues.account_pref : '';
        const account_city = filterValues.account_city ? filterValues.account_city : '';
        const account_name = filterValues.account_name ? filterValues.account_name : '';
        const paid = filterValues.paid !== undefined ? filterValues.paid : -2;
        const payment_method = filterValues.payment_method !== undefined ? filterValues.payment_method : -2;
        const payment_method_update_from = filterValues['payment_method_update_from@_gte'] ? filterValues['payment_method_update_from@_gte'] : null; 
        const payment_method_update_to = filterValues['payment_method_update_from@_lte'] ? filterValues['payment_method_update_from@_lte'] : null; 
        // get data edit
        const dataBilling = GetDataBilling(apolloClient,month,year,account_address,account_pref,account_city,account_name, paid, payment_method,payment_method_update_from, payment_method_update_to).then((data: any) => {
            SetNumOfAccount(data.data_count_account);
            SetTotalAmount(data.data_total_amount);
        });
        //fetchData();
      }, [filterValues.year,
        filterValues.month,
        filterValues.account_address,
        filterValues.account_pref,
        filterValues.account_city,
        filterValues.account_name,
        filterValues.paid,
        filterValues.payment_method,
        filterValues['payment_method_update_from@_gte'],
        filterValues['payment_method_update_from@_lte']])
    
    const data_currency = new Intl.NumberFormat('en-US').format(totalAmount)
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <Grid className='top-tool-bar-billing'>
                <Grid>
                    <ul className='list-total-detail'>
                        <li className='list-total-detail-li'>入会数：</li>
                        <li className='list-total-detail-li'>{numOfAccount}</li>
                        <li className='list-total-detail-li'>請求金額合計：</li>
                        <li className='list-total-detail-li'>{data_currency}</li>
                    </ul>
                </Grid>
                <Grid className='export-button-billing'>
                    {resource !== 'import' &&
                        <ExportButton
                            disabled={total === 0}
                            resource={resource}
                            sort={currentSort}
                            filterValues={filterValues}
                            maxResults={10000000}
                            label={translate('common.export')}
                        />
                    }
                </Grid>
            </Grid>
            {/* {(role ==='admin' || role ==='client_admin' || resource !== 'user') && resource !== 'import' && resource !== 'user'
            && <CreateButton basePath={basePath} />} */}


        </TopToolbar>
    );
};
export default ListActions;