import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
    SaveButton
} from 'react-admin';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { DialogActions, DialogContent, DialogTitle, Dialog } from '@material-ui/core';
import { CopyTeamreview, GetAllTeam } from './gql';
import { useDataProvider } from 'ra-core';
import Select from 'react-select'

const BulkCopyTeamReviewButtonAction = (props: any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [idTeamCopy, setIdTeamCopy] = useState(0);
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const [dataTeam, setdataTeam] = useState<any>([])
    const [name, setName] = useState<string>('');
    const dataProvider = useDataProvider();
    useEffect(() => {
        (async () => {
            const dataTeamResult = await GetAllTeam(apolloClient, name);
            setdataTeam(dataTeamResult);
        })()
    }, [name, setName]);
    const {
        resource,
    } = useListContext();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const [deleteMany, { loading }] = useDeleteMany(
        resource,
        selectedIds,
        {
            onSuccess: () => {
                refresh();
                notify(translate('common.msg_delete'));
                unselectAll(resource);
            },
            onFailure: () => notify(translate('common.msg_delete_fail'), 'warning'),
        }
    );
    //const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        //deleteMany();
        const dataUpdateTeam = await CopyTeamreview(dataProvider, selectedIds[0], idTeamCopy, apolloClient);
        if (dataUpdateTeam === 1) {
            refresh();
            notify(translate('common.msg_delete'));
            unselectAll(resource);
            setOpen(false);
            setShowDialog(false);
        } else {
            notify(translate('common.msg_delete_fail'), 'warning');
        }
    };
    const handleSubmit = async () => {
        setOpen(true)
    }

    return (
        <Fragment>
            <Button label={translate('common.btn_copy_team_review')} onClick={handleClick} startIcon={<FileCopy />} style={{ color: '#2596be' }} />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>{translate(`${resource}.modal_copy_title`)}</DialogTitle>

                <FormWithRedirect
                    resource={resource}
                    save={handleSubmit}
                    //initialValues={{ created_by: identity.id }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-copy-team">
                                <Select options={dataTeam}
                                    onChange={((newValue: any) => {
                                        setIdTeamCopy(newValue.value)
                                    })}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    label={translate(`common.confirm_button_copy`)}
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('common.msg_title_copy')}
                content={translate('common.msg_copy_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkCopyTeamReviewButtonAction;