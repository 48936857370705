import * as React from 'react';
import {FC, Fragment} from 'react';
import {
    BooleanField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    SearchInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import exporter from '../../utils/exporter';
import ListEmpty from '../components/listEmpty';
import {FilterProps, PermissionsType} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';

const ExportableFields = Fields.filter(d => d.export);

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn />
        </Filter>
    )
};

const StaffList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'area';
    return (
        <List
            {...props}
            filters={<TeamFilter/>}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'team_staff', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='team_staff' />}
            empty={<ListEmpty />}
            title={translate('menu.team_staff.list')}
        >
            <CustomizableDatagrid rowClick={edit}>
        
            {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options } = f || {}
                    if (type === 'textinput' && name !== 'created') {
                        
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'reference') {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                            return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default StaffList;
