import {Button, SaveButton, Toolbar, useNotify, useRedirect, useTranslate} from "react-admin";
import {useHistory} from "react-router";
import * as React from "react";

const CreateToolbar = (props: any) => {   
    const translate = useTranslate();
    const history = useHistory();
    const redirect = useRedirect();
    const notify = useNotify();
    const cancelButton = () => {
        history.goBack();
    };
    const onSuccess = () => {
        notify(`${translate('common.msg_add_success')}`, 'info');
        redirect('/school_gym', props.basePath);
    };   
    
    const onFailure = (error: any) => {
        notify(`${translate('common.could_not')} ${error.message}`, 'warning');
    };

    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }}>
            <SaveButton
                disabled={props.pristine}
                transform = {(data:any) => ({...data})}
                undoable={false}
                redirect="/school_gym"
                label={translate('common.btn_save')}
                onSuccess={props.onSuccess || onSuccess}
                onFailure={onFailure}
                style={ props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default"/>
        </Toolbar>
    );
};
export default CreateToolbar;