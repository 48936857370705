import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import BulkDeletePermanently from '../components/actionsBulkDelete';

import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import exporter from './exporter';
import { useHistory } from 'react-router';
import BulkRecoverButton from './actionsBulkRecover';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    const { filterValues: { is_soft_deleted } } = props as any
    return (
        <Fragment>
            { (is_soft_deleted === false && (role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true)) && <BulkDeleteButton {...props} />}
            { (is_soft_deleted === true && (role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true)) && <BulkDeletePermanently {...props} />}
            { (is_soft_deleted === true && (role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true)) && <BulkRecoverButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('question.id')} alwaysOn />
            <TextInput source="user_id@_eq" label={translate('question.user_id')} alwaysOn />
            <TextInput source="title,question,note,nickname" label={translate('question.search_title')} alwaysOn />
            <ReferenceInput allowEmpty={true} filterToQuery={(searchText: any) => ({ email: searchText })} source="user_id" reference="user" label={translate('account_admin.email')} alwaysOn>
                <AutocompleteInput optionText={'email'}/>
            </ReferenceInput>
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'question';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/question';
    },[props]);
    if (role ===undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            filterDefaultValues={{ is_soft_deleted: false }}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any,fetchRelatedRecords:any) => exporter(items, 'question', ExportableFields, translate,fetchRelatedRecords)}
            actions={<ListActions to='question'/>}
            empty={<ListEmpty />}
            title={translate('menu.question.list')}
        >
            <CustomizableDatagrid rowClick={edit}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput' && name !== 'created') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (searchField === 'lastname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                        if (searchField === 'firstname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                        if (searchField === 'nickname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }

                    }


                    if (type === 'date' || type === 'datetime' || name === 'created') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default UserList;
