const STATUS = [
    { id: 0, name: "終了" },
    { id: 1, name: "作成中" },
    { id: 2, name: "公開待ち" },
    { id: 3, name: "公開中" },
    { id: 4, name: "受付停止中" },
]
const TYPE = [
    { id: 0, name: "スクール・教室" },
    { id: 1, name: "ジム・フィットネス" }
]
const NAME_TYPE = [
    { id: 0, name: "name" },
    { id: 1, name: "name2" }
]
const NAME_KANA_TYPE = [
    { id: 0, name: "name_kana" },
    { id: 1, name: "name_kana2" }
]
export const MONTHLY_FEE = [
    { id: 0, name: "なし" },
    { id: 1, name: "あり" }
]
export const ATTENTION = [
    { id: 0, name: "OFF" },
    { id: 1, name: "ON" }
]
const TRIAL_TYPE = [
    { id: 0, name: "あり" },
    { id: 1, name: "なし" }
]
export const PUBLISH_STATUS = [
    { id: 0, name: "作成中" },
    { id: 1, name: "申請中" },
    { id: 2, name: "公開中" },
    { id: 3, name: "非公開" },
    { id: 4, name: "非承認" },
  ]




  ///

  export const IS_SOFT_DELETED = [
    { id: true, name: "TRUE" },
    { id: false, name: "FALSE" }
  ]
  export const ACCOUNT_SEARCH = [
    { id: 0, name: "" },
    { id: 1, name: "ユーザー" }
  ]

  export const MONTHLY_FEE_LIST = [
    { id: 1, name: 'あり'},
    { id: 0, name: 'なし' }
  ]
  export const STYLE_CHOICE = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
  ]
  export const TARGET_AGES = [
    { id: "3歳以下", name: "3歳以下" },
    { id: "幼児", name: "幼児" },
    { id: "小学生", name: "小学生" },
    { id: "中学生", name: "中学生" },
    { id: "高校生", name: "高校生" },
    { id: "大学生・専門学生", name: "大学生・専門学生" },
    { id: "18歳以上", name: "18歳以上" },
    { id: "30歳以上", name: "30歳以上" },
    { id: "40歳以上", name: "40歳以上" },
    { id: "50歳以上", name: "50歳以上" },
    { id: "60歳以上", name: "60歳以上" },
    { id: "年齢不問", name: "年齢不問" },
  ]
  export const ENROLLED_GENERATE = [
    { id: 1, name: "3歳以下" },
    { id: 2, name: "幼児" },
    { id: 3, name: "小学生" },
    { id: 4, name: "中学生" },
    { id: 5, name: "高校生" },
    { id: 6, name: "大学生・専門学生" },
    { id: 7, name: "18歳以上" },
    { id: 8, name: "30歳以上" },
    { id: 9, name: "40歳以上" },
    { id: 10, name: "50歳以上" },
    { id: 11, name: "60歳以上" },
    { id: 12, name: "年齢不問" },
  ]
  
  export const CATEGORIES = [
    { id: 10, name: "クラブチーム" },
    { id: 20, name: "少年団" },
    { id: 30, name: "スクール・教室" },
    { id: 40, name: "プライベートレッスン" },
    { id: 50, name: "同好会・サークル" },
    { id: 60, name: "社会人チーム" },
    { id: 70, name: "部活動" },
  ]
  
  export const PRACTICE_DAYS = [
    { id: "月", name: "月" },
    { id: "火", name: "火" },
    { id: "水", name: "水" },
    { id: "木", name: "木" },
    { id: "金", name: "金" },
    { id: "土", name: "土" },
    { id: "日", name: "日" },
    { id: "祝日", name: "祝日" },
    { id: "不定期", name: "不定期" },
  ]
  
  export const TRIAL_TYPES = [
    { id: 1, name: "無料" },
    { id: 2, name: "有料" },
    { id: 0, name: "なし" },
  ]
  export const DISABILITY = [
    { id: 10, name: "はい" },
    { id: 20, name: "いいえ" }
  ]
  export const VISIT_OK = [
    { id: true, name: "見学をOKにする" },
    { id: false, name: "見学をNGにする" }
  ]
  export const LADIES = [
    { id: 1, name: "レディースのみ" },
    { id: 2, name: "レディース歓迎" },
  ]
  export const KEYWORD1 = [
    { id: '元プロのコーチがいる', name: "元プロのコーチがいる" },
    { id: '1学年20名以上', name: "1学年20名以上" },
    { id: '少数精鋭', name: "少数精鋭" },
    { id: 'チームとスクールが連携', name: "チームとスクールが連携" },
    { id: '勝ちにこだわる', name: "勝ちにこだわる" },
    { id: '楽しくが第一', name: "楽しくが第一" },
    { id: '私生活・態度に重きを置く', name: "私生活・態度に重きを置く" },
    { id: '礼儀に厳しい', name: "礼儀に厳しい" },
    { id: '飛び級制度あり', name: "飛び級制度あり" },
    { id: 'プロ選手を輩出', name: "プロ選手を輩出" },
    { id: '五輪選手を輩出', name: "五輪選手を輩出" },
    { id: '積極的に体験受け入れ', name: "積極的に体験受け入れ" },
    { id: '入団はセレクション前提', name: "入団はセレクション前提" },
    { id: '選手の進路にこだわる', name: "選手の進路にこだわる" },
    { id: '新人が溶け込みやすい', name: "新人が溶け込みやすい" },
    { id: '初心者歓迎', name: "初心者歓迎" },
    { id: '初心者多数在籍', name: "初心者多数在籍" },
    { id: '育成に自信あり', name: "育成に自信あり" },
    { id: 'コーチとの距離感が近い', name: "コーチとの距離感が近い" },
    { id: 'レディースチーム', name: "レディースチーム" },
  ]
  export const KEYWORD2 = [
    { id: '練習試合が多い', name: "練習試合が多い" },
    { id: '週5練習', name: "週5練習" },
    { id: '週4練習', name: "週4練習" },
    { id: '週3練習', name: "週3練習" },
    { id: '週2練習', name: "週2練習" },
    { id: '週1練習', name: "週1練習" },
    { id: '合宿・遠征あり', name: "合宿・遠征あり" },
    { id: '海外遠征あり', name: "海外遠征あり" },
    { id: '練習場所は1つに固定', name: "練習場所は1つに固定" },
    { id: '屋内練習あり', name: "屋内練習あり" },
    { id: '練習場所が広い', name: "練習場所が広い" },
    { id: '強豪と試合ができる', name: "強豪と試合ができる" },
    { id: '専用練習着あり', name: "専用練習着あり" },
    { id: '体験無料', name: "体験無料" },
    { id: '体験有料', name: "体験有料" },
    { id: '見学可能', name: "見学可能" },
  
  ]
  export const KEYWORD3 = [
    { id: '月謝が3000円以下', name: "月謝が3000円以下" },
    { id: '月謝が5000円以下', name: "月謝が5000円以下" },
    { id: '月謝が10,000円以下', name: "月謝が10,000円以下" },
    { id: '年会費なし', name: "年会費なし" },
    { id: '入会金なし', name: "入会金なし" },
    { id: '月謝なし', name: "月謝なし" },
    { id: '初回購入品あり', name: "初回購入品あり" },
    { id: '今なら入会金無料', name: "今なら入会金無料" },
  ]
  export const KEYWORD4 = [
    { id: '保護者の当番あり', name: "保護者の当番あり" },
    { id: '保護者の当番なし', name: "保護者の当番なし" },
    { id: '練習引率あり', name: "練習引率あり" },
    { id: '試合引率あり', name: "試合引率あり" },
    { id: '送迎車あり', name: "送迎車あり" },
    { id: '保護者の代表制度あり', name: "保護者の代表制度あり" },
  ]
  export const STYLE = [
    {
      id: 1,
      title: '練習スタイル',
      label_left: "厳しい",
      label_right: "のびのび",
      description: "TODO"
    },
    {
      id: 2,
      title: '練習量',
      label_left: "多い",
      label_right: "少ない",
      description: "TODO"
    },{
      id: 3,
      title: '選手選考',
      label_left: "実力主義",
      label_right: "出場機会を平等に",
      description: "TODO"
    },{
      id: 4,
      title: '年齢幅',
      label_left: "幅広い",
      label_right: "限定的",
      description: "TODO"
    },{
      id: 5,
      title: 'チームスタイル',
      label_left: "個人重視",
      label_right: "戦術重視",
      description: "TODO"
    },{
      id: 6,
      title: '男女比率',
      label_left: "男性が多い",
      label_right: "女性が多い",
      description: "TODO"
    },{
      id: 7,
      title: '勝負へのこだわり',
      label_left: "勝ちにこだわる",
      label_right: "楽しさ重視",
      description: "TODO"
    },{
      id: 8,
      title: '経験者比率',
      label_left: "経験者が多い",
      label_right: "初心者が多い",
      description: "TODO"
    },
  ]
  export const SHOW_ACCOUNT_INFO = [
    { id: true, name: "表示する" },
    { id: false, name: "表示しない"}
  ]
const DATA = [
    { name: 'id', type: 'textinput', searchField: '', options: [], reference: '', export_name: 'id', nullable: true, editable: false, export: true, import: true },
    { name: 'name',isShow: false, type: 'textinput', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'name2',isShow: false, type: 'textinput', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'name_type',isShow: false, type: 'selectbox',options: NAME_TYPE, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'name_kana_type', isShow: false, type: 'selectbox',options: NAME_KANA_TYPE, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'publish_status', type: 'status',options: PUBLISH_STATUS, nullable: false, export_name: 'publish_status', editable: true, export: true, import: true },

    { name: 'shop_id', type: 'reference', reference: 'shop', searchField: 'name', export_name: 'shop_id', nullable: true, editable: false, export: true, import: true },
    { name: 'type', type: 'selectbox', options: TYPE, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'monthly_fee', type: 'selectbox', options: MONTHLY_FEE, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'attention', type: 'selectbox', options: ATTENTION, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'genre_id', type: 'reference', reference: 'genre', searchField: 'name', export_name: 'genre_id', nullable: true, editable: false, export: true, import: true },
    { name: 'genre_sport_id', type: 'reference', reference: 'genre_sport', searchField: 'name', export_name: 'genre_sport_id', nullable: true, editable: false, export: true, import: true },

    { name: 'genre_other', type: 'textinput', nullable: false, export_name: 'genre_other', editable: true, export: true, import: true },
    { name: 'genre_sport_other', type: 'textinput', nullable: false, export_name: 'genre_sport_other', editable: true, export: true, import: true },

    { name: 'name_kana', type: 'textinput', nullable: false, export_name: 'name_kana', editable: true, export: true, import: true },
    { name: 'name_kana2', type: 'textinput', nullable: false, export_name: 'name_kana2', editable: true, export: true, import: true },
    { name: 'president_name', type: 'textinput', nullable: false, export_name: 'president_name', editable: true, export: true, import: true },
    { name: 'appeal_title', type: 'textinput', nullable: false, export_name: 'appeal_title', editable: true, export: true, import: true },
    { name: 'appeal_body', type: 'textinput', nullable: false, export_name: 'appeal_body', editable: true, export: true, import: true },
    { name: 'disability', type: 'textinput', numberFormat: true, nullable: false, export_name: 'disability', editable: true, export: true, import: true },
    { name: 'ladies', type: 'textinput', numberFormat: true, nullable: false, export_name: 'ladies', editable: true, export: true, import: true },

    { name: 'philosophy', type: 'textinput', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'achievements', type: 'textinput', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'style', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'logo_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image1_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image2_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image3_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image4_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image5_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image6_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'image7_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'video_url', type: 'imagefield', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'keyword', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'keyword1', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'keyword2', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'keyword3', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    // { name: 'keyword4', type: 'jsonb', nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'trial_type', type: 'selectbox',options: TRIAL_TYPE, nullable: false, export_name: 'answer', editable: true, export: true, import: true },
    { name: 'visit_ok', type: 'boolean', nullable: false, export_name: 'answer', editable: true, export: true, import: true },

    // { name: 'created', type: 'textinput', nullable: true, editable: false,export_name: 'created', export: true, import: true },
    // { name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,export_name: 'created_by',nullable: true, editable: false, export: false, import: true },
    // { name: 'note', type: 'textinput', multiline: true, nullable: true,export_name: 'note', editable: true, export: true, import: true },

]

export default DATA;