export const TYPE = [
    {id: 11, name: "ボーナスポイント"},
    {id: 1, name: "クチコミ投稿"},
    {id: 2, name: "ＱＡ投稿"},
    {id: 3, name: "ＱＡ回答"},
    {id: 4, name: "応募"},
    {id: 5, name: "問い合わせ"},
    {id: 6, name: "イベント応募"},
]
export const POINT_EXCHANGE_STATUS = [
    {id: 1, name: "交換応募"},
    {id: 2, name: "発送準備中"},
    {id: 3, name: "発送済み"},
    {id: 4, name: "完了"},
]

const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],reference: '', nullable: true, editable: false, export: true},
{ name: 'user_id',  type: 'textinput',reference: 'user', nullable: false, export: false},
{ name: 'user_id',  type: 'reference',reference: 'user',searchField: 'lastname', nullable: false, export: true},
{ name: 'user_id',  type: 'reference',reference: 'user',searchField: 'firstname', nullable: false, export: true},
{ name: 'user_id',  type: 'reference',reference: 'user',searchField: 'email', nullable: false, export: true},
{ name: 'user_id',  type: 'reference',reference: 'user',searchField: 'phone', nullable: false, export: true},
{ name: 'point_type_id',  type: 'status',options: TYPE, nullable: true, export: true},
{ name: 'amount', type: 'textinput', numberFormat:true, nullable: false, editable: true, export: true},
//{ name: 'point_exchange_id',  type: 'reference',reference: 'point_exchange',searchField: 'title', nullable: true, export: true},
//{ name: 'point_exchange_status',  type: 'status',options: POINT_EXCHANGE_STATUS, nullable: true, export: true},
{ name: 'point_type_id',  type: 'reference',reference: 'point_type',searchField: 'note', editable: false, link: false, nullable: true, export: true},
{ name: 'point_received', type: 'datetime',isDate : true, nullable: true, editable: false, export: true},
{ name: 'created', type: 'textinput', nullable: true, editable: false, export: true},

{ name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true },
//{ name: 'created_by',  type: 'reference',reference: 'user',searchField: 'firstname', nullable: true, editable: false, export: true},
]

export default DATA;