import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any) => {
  var data: any = items
  await fetchRelatedRecords(items, 'user_id', 'user').then((user: any) => {
    const data_user = items.map((item: any) => (
      {
        ...item,
        user_id: `${user[item.user_id].lastname} ${user[item.user_id].firstname}`,
        lastname: `${user[item.user_id].lastname}`,
        firstname: `${user[item.user_id].firstname}`,
        created:  item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a' ) : item.created,
      }
    ));
    data = data_user;
  });
  await fetchRelatedRecords(data, 'genre_id', 'genre').then((genre: any) => {
    const data_genre = data.map((item: any) => (
      {
        ...item,
        genre_id :item.genre_id ? genre[item.genre_id].name : '',
      }
    ));
    data = data_genre;
  });
  await fetchRelatedRecords(data, 'genre_sport_id', 'genre_sport').then((genre_sport: any) => {
    const data_genre = data.map((item: any) => (
      {
        ...item,
        genre_sport_id: item.genre_sport_id ? genre_sport[item.genre_sport_id].secondary_name : '',
      }
    ));
    data = data_genre;
  });
  
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.export_name)

  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }

    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

