import * as React from 'react';
import {Fragment, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
} from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { DeleteMultiTeamAdmin } from './gql';

const BulkDeleteButtonAction = (props :any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const {
        resource,
    } = useListContext();

    const deleteMany = async () => {
        const Response = await DeleteMultiTeamAdmin(apolloClient,selectedIds);
        if (Response === 1) {
            refresh();
            notify(translate('common.msg_delete'));
            unselectAll(resource);
        }else if (Response === 0) {
            notify(translate('common.msg_delete_fail'), 'warning')
        }
    }
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    
    const handleConfirm = async () => {
        await deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate('common.btn_delete')} onClick={handleClick} startIcon={<DeleteIcon />} style={{color:'#f44336'}}/>
            <Confirm
                isOpen={open}
                //loading={loading}
                title={translate('common.msg_title_delete')}
                content={translate('common.msg_delete_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButtonAction;