const CONNECTION_TYPE = [
  {id: 0, name: "問い合わせ"},
  {id: 1, name: "練習試合"},
]
const STATUS = [
  {id: 0, name: "未対応"},
  {id: 1, name: "つながり中"},
  {id: 2, name: "ブロック"}
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
  //{ name: 'genre_id',searchField: '', options: [], type: 'reference',reference: 'genre', nullable: false, editable: true, export: true, import: true },
  { name: 'account_name_from', reference_id: 'from_team_id', type: 'reference',reference: 'account', nullable: false, editable: true, export: true, import: true },

  { name: 'from_team_id', type: 'reference',reference: 'team',searchField: 'name', nullable: false, editable: true, export: true, import: true },

  { name: 'account_name_to', reference_id: 'to_team_id', type: 'reference',reference: 'account',nullable: false, editable: true, export: true, import: true },

  { name: 'to_team_id', type: 'reference',reference: 'team',searchField: 'name',nullable: false, editable: true, export: true, import: true },
  { name: 'team_game_id', type: 'reference',reference: 'team_game',searchField: 'title', nullable: true, editable: true, export: true, import: true },
  { name: 'message', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'status', type: 'selectbox',nullable: false,options:STATUS, editable: true, export: true, import: true },
  {},
  { name: 'connection_type', type: 'selectbox',options:CONNECTION_TYPE, nullable: false, editable: true, export: true, import: true },

  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,nullable: true, editable: false, export: false, import: true },
  { name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },

]

export default DATA;