import moment from 'moment';

export const toLocalTime = (utcTime: string | null): string => {
    if (!utcTime) return '';
    return moment.utc(utcTime).local().format('YYYY/MM/DD HH:mm:ss');
};

export const toLocalTimeFromJP = (jpTime: string | null): string => {
    if (!jpTime) return '';
    return moment(`${jpTime}+09:00`).local().format('YYYY/MM/DD HH:mm:ss');
};
export const CountNumberDiffInList = async (listItem: any) => {
    let list_account_id = []
    for (const billing of listItem) {
        list_account_id.push(billing.account_id)
    }
    const UniqueCount = new Set(list_account_id).size;
    return UniqueCount;
}

export const toLocalHour = (utcHour: string | null): string => {
    if (!utcHour) return '';
    const date = `2020-01-02T${utcHour}:00`;
    console.log(date);
    return moment(date).local().format('HH:mm:ss');
};

export const toLocalTimeEndDate = (jpTime: string | null): string => {
    if (!jpTime) return '';
    return moment(`${jpTime}+09:00`).add(14,'days').local().format('YYYY/MM/DD HH:mm:ss');
};

export const random = (): number => {
    const min = 1;
    const max = 100000;
    return min + Math.random() * (max - min);
};

export const buildRoleOptions = (role: string, translate: any): Array<any> => {
    const options = [
        { id: 'user', name: translate('role.user') },
        { id: 'owner', name: translate('role.owner') },
        { id: 'vendor_admin', name: translate('role.vendor_admin') },
        { id: 'vendor_manager', name: translate('role.vendor_manager') },
        { id: 'vendor_employee', name: translate('role.vendor_employee') },
    ];
    if (role === 'admin') {
        options.push({ id: 'admin', name: translate('role.admin') });
        options.push({ id: 'client_admin', name: translate('role.client_admin') });
    } else if (role === 'client_admin') {
        options.push({ id: 'client_admin', name: translate('role.client_admin') });
    } else {
        return [];
    }
    return options;

};
export function numberWithCommas(x: number): string {
    return (x || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function stringToNumber(x: string): number {
    return Number((x || '').replace(',', ''));
}
export const array_move  = (arr:any, old_index:any, new_index:any) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };

export function getLocalStorageValue<T>(key: string, defaultValue: any) {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      if (item && !item.startsWith("{")) {
        return item;
      }
      // Parse stored json or if none return initialValue
      if (item) {
        let jsonObj: any = JSON.parse(item);
        return jsonObj as T;
      } else {
        return defaultValue;
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return defaultValue;
    }
  }
 export const  makeid = async (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}