import * as React from 'react';
import {FC} from 'react';
import {Chip} from '@material-ui/core';
import {FieldProps, Template} from '../../types';
import {useTranslate} from 'react-admin';

const StatusField: FC<FieldProps<Template>> = ({ record }) => {
    const translate = useTranslate();
    let label = '';
    let background = '';
    switch (record?.contact_user) {
        case 1:
            label = translate('common.contact_user_1');
            background = '#3CD4A0'
            break;
        case 5:
            label = translate('common.contact_user_5');
            background = '#FF5C93'
            break;
        case 10:
            label = translate('common.contact_user_10');
            background = '#FFD700'
            break;
        default:
            break;
    }
    return record ? (
        <span>
            <Chip
                label={label}
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor:background,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    ) : null;
};
export default StatusField;
