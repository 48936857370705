import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    SearchInput,
    ReferenceField,
} from 'react-admin';
import Switch from '@material-ui/core/Switch';
import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import exporter from './exporter';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, { FLAG_DISABLE } from './dataset';
import { NumberField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import { NumberInput } from 'react-admin';
import { DateField } from 'react-admin';
import FullNameField from './FullnameField'
import FeeField from './FeeField'
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import ReferenceFieldAge from '../event_application/ReferenceFieldAge';
import ReferenceContactType from './ReferenceFieldContactType';
import { SelectInput } from 'react-admin';
import { STATUS } from '../team_experiencer/dataset';
import { MONTHLY_FEE_LIST } from '../team/dataset';
import ActionsField from './Actions';
import ToggleSwitch from './toggleSwitchInput';
import { useRefresh } from 'react-admin';
import { SelectArrayInput } from 'react-admin';
import { DateInput } from 'react-admin';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="lastname,firstname,firstname_kana,lastname_kana,account_name,team_name,note,account_email,account_phone,created_by_phone,created_by_email" alwaysOn />
            <SelectInput source="monthly_fee" label={translate('team.monthly_fee')} choices={MONTHLY_FEE_LIST} alwaysOn />
            <SelectInput source="flag_disable" label={translate('team_experiencer.flag_disable')} choices={FLAG_DISABLE} alwaysOn />
            <SelectArrayInput className="array-select-status"  source="status" label={translate('team_experiencer.status')} choices={STATUS} alwaysOn />
            <DateInput source="created@_gte" label={translate('team_experiencer.created_from')} alwaysOn />
            <DateInput source="created@_lte" label={translate('team_experiencer.created_to')} alwaysOn />


        </Filter>
    )
};
const ContactStatusField = (props: any) => {
    const { record } = props
    let status = '';
    switch (record.status) {
        case 0:
            status = '未対応'
            break;
        case 1:
            status = '体験中'
            break;
        case 2:
             status = 'キャンセル'
            break;
        case 3:
             status = '入会'
            break;
        case 4:
            status = '体験終了'
            break;
        case 5:
            status = '辞退'
            break;
        case 6:
            status = '問合せキャンセル'
            break;
        case 7:
            status = '問合せ入会'
            break;
        default:
            break;
    }
    return <span>{status}</span>
}

const CustomDataShow = (props: any) => {
    const { record } = props;
    if (props.type === 'monthly_fee') {
        if (record['monthly_fee_between'] == false) {
            if (!record['monthly_fee_from']) {
                return <span> 0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 </span>
        }
        if (record['monthly_fee_between'] == true && !record['monthly_fee_to']) {
            if (!record['monthly_fee_from']) {
                return <span>0 円</span>
            }
            return <span> {record['monthly_fee_from']} 円 ~ </span>
        }
        if (record['monthly_fee_between'] == true && record['monthly_fee_to']) {
            return <span> {record['monthly_fee_from']} ~ {record['monthly_fee_to']} 円  </span>

        }
    }
    if (props.type === 'fee') {
        const monthly_text = record['monthly_fee'] == 0 ? 'なし' : 'あり';
        return <span> {monthly_text}</span>
    }
    return <TextField source={props.type} />
}
const TeamReferenceFieldFee = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <CustomDataShow />
    </ReferenceField>
}
const AccountReferenceField = (props: any) => {
    return <ReferenceField {...props} source={'team_id'} reference={'team'} link={false}>
        <ReferenceField source={'account_id'} reference={'account'} link={false}>
            <TextField source={'name'} />
        </ReferenceField>
    </ReferenceField>
}
const TeamExperiencerList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const refresh = useRefresh();
    const [checkReport, setCheckReport] = React.useState<boolean>(false)
    const apolloClient = useApolloClient();
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit' : '';
    const dataFilter = props.location.search as string;    
    let checkShow = false;
    const checkShowReport = dataFilter.includes('%22join_reported%22:true');
    const checkJoinDecline = dataFilter.includes('%22join_declined%22:false');
    const checkJoinDeclineFilter = dataFilter.includes('join_declined%22%3Afalse');
    const checkShowReportFilter = dataFilter.includes('join_reported%22%3Atrue');
    if((checkShowReport === true || checkShowReportFilter === true) && (checkJoinDecline === true || checkJoinDeclineFilter === true)){
        checkShow = true
    }

    
    //setCheckReport(checkShowReport)
    const module = 'team_experiencer';
    const history = useHistory();
    useEffect(() => {
        history.location.pathname = '/team_experiencer_list';  
        refresh()     
    }, [props,checkShowReport,checkShowReportFilter]);
    if (role === undefined) {
        return null;
    }
    
    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'team_experiencer', ExportableFields, translate, fetchRelatedRecords, apolloClient)}
            actions={<ListActions to='team_experiencer' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            {/* <CustomizableDatagrid rowClick={(id: any, resource: any, record: any) => `/team_experiencer/${id}`}> */}
            <CustomizableDatagrid rowClick={''}>
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if((name === 'report_admin_approve_status' && checkShow == false) ||(name === 'report_admin_decline_reason' && checkShow == false) ){
                        return <></>
                    }
                    if (type === 'textinput' && name !== 'created') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        if (name == 'lastname' || name === 'firstname') {
                            return <></>
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'team_contact_name' && name === 'team_contact_name') {
                        // return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={name} reference={reference}>
                        //     <ReferenceEventContact label={translate(`${module}.${name}`)} source={'user_id'} reference={'user'} />
                        // </ReferenceField>
                        return <FullNameField label={translate(`${module}.${name}`)} source={name} reference={reference} />
                    }

                    if (type === 'deepReference' && name === 'team_name') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'account_name' && name === 'account_name') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'account_source' && name === 'account_source') {
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'button') {
                        return <ToggleSwitch label={'完了フラグ'} props={props} />
                    }
                    if (type === 'contact_type') {
                        return <ReferenceContactType
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if ((type === 'monthly_fee' || type === 'monthly_fee_from' || type === 'monthly_fee_to' || type === 'monthly_fee_between') && name === 'team_contact_id') {
                        return <ReferenceField link={false} label={translate(`team_experiencer.${type}`)} sortBy={"monthly_fee_from"} source={name} reference={reference}>
                            <TeamReferenceFieldFee type={type} />
                        </ReferenceField>
                    }
                    if ((type === 'fee') && name === 'fee') {
                        return <FeeField label={translate(`${'team'}.${'monthly_fee'}`)} source={name} reference={reference} />
                    }

                    if (type === 'age') {
                        return <ReferenceFieldAge
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'account_name') {
                        return <ReferenceField link={false} label={translate(`${module}.account_name`)} source={'team_contact_id'} reference={'team_contact'}>
                            <AccountReferenceField />
                        </ReferenceField>
                    }

                    if (type === 'reference') {
                        return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={name} reference={reference}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status') {
                        if (name === 'status') {
                            return <ContactStatusField source={name} label={translate(`${module}.${name}`)} />
                        } else {
                            return <SelectField
                                source={name}
                                choices={options}
                                label={translate(`${module}.${name}`)}
                            />
                        }

                    }
                    if (type === 'date') {
                        if (name === 'birthday') {
                            return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                        }
                        if(name === 'status_updated_at' || name === 'join_date' ){
                        return <DateField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                        }
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (name === 'action') {
                        return <ActionsField label={translate('common.actions')} />
                    }
                    // if (name === 'created_by_phone') {
                    //     return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={'created_by'} reference={'user'}>
                    //         <TextField source={'phone'} />
                    //     </ReferenceField>

                    // }
                    // if (name === 'created_by_email') {
                    //     return <ReferenceField link={false} label={translate(`${module}.${name}`)} source={'created_by'} reference={'user'}>
                    //         <TextField source={'email'} />
                    //     </ReferenceField>

                    // }
                    // if (name === 'account_phone') {
                    //     return <ReferenceField link={false} label={translate(`${module}.account_phone`)} source={'team_contact_id'} reference={'team_contact'}>
                    //         <AccountReferenceFieldPhone />
                    //     </ReferenceField>
                    // }
                    // if (name === 'account_email') {
                    //     return <ReferenceField link={false} label={translate(`${module}.account_email`)} source={'team_contact_id'} reference={'team_contact'}>
                    //         <AccountReferenceFieldEmail />
                    //     </ReferenceField>
                    // }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default TeamExperiencerList;
