import { gql } from "@apollo/client";

export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}

`;
export const SOFT_DELETE_QUESTION = gql`
mutation MyMutation($list_question_id: [Int!]) {
  update_question(where: {id: {_in: $list_question_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const REVERT_SOFT_DELETE_QUESTION = gql`
mutation MyMutation($list_question_id: [Int!]) {
  update_question(where: {id: {_in: $list_question_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GetAllGenreSport = async (dataProvider: any, genre_id: any, genre_name: string) => {
  let ListData = [];
  if (genre_id !== 100) {
    var data_genre_sport = await dataProvider.getList("genre_sport", {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: "id", order: "ASC" },
      filter: { genre_id: genre_id },
    });
    const { data } = data_genre_sport;
    if (genre_id !== 0) {
      ListData.push({ id: 101, name: `${genre_name}全般` })
    }
    for (let item = 0; item < data.length; item++) {
      ListData.push({ id: data[item].id, name: data[item].secondary_name })
    }
  } else {
    ListData.push({ id: 100, name: 'スポーツ全般' })
  }

  return ListData;
};
export const GetAllGenre = async (dataProvider: any) => {
  let data_genre = await dataProvider.getList("genre", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" }
  });
  let ListData = [];
  const { data } = data_genre;
  for (let item = 0; item < data.length; item++) {
    ListData.push({ id: data[item].id, name: data[item].name })
  }
  return ListData;
};
export const DeleteMultiQuestion = async (apolloClient: any,list_question_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SOFT_DELETE_QUESTION,
      variables: {
        list_question_id: list_question_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
export const RecoverMultiQuestion= async (apolloClient: any,list_question_id: number[]) => {
  let status = 0;
  try{
    let { data: dataRecoverMulti } = await apolloClient.mutate({
      mutation: REVERT_SOFT_DELETE_QUESTION,
      variables: {
        list_team_review_id: list_question_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}