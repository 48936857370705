import StatusField from "../email/StatusField";

const ALREADY_IN_SPORTS = [
    { id: false, name: '同スポーツの団体に所属していない' },
    { id: true, name: '同スポーツの団体に所属している' }
]

const SAME_USER_ID = [
    { id: false, name: '申込み本人ではない' },
    { id: true, name: '申込み本人' }
]
const GENDER = [
    { id: 0, name: '不明' },
    { id: 1, name: '男性' },
    { id: 2, name: '女性' },
    { id: 3, name: '未選択'}

]
const STATUS = [
    { id: 0, name: '未対応' },
    { id: 1, name: '体験中' },
    { id: 2, name: 'キャンセル' },
    { id: 3, name: '入会' },
    { id: 4, name: '体験終了' },
    { id: 5, name: '辞退' },
]

const CONTACT_TYPE = [
    { id: 2, name: '体験申込み' },
    { id: 3, name: '入会申込み' }, 
    { id: 4, name: '見学申し込み'}, 
]
export const FLAG_DISABLE = [
    { id: true, name: 'オン' },
    { id: false, name: 'オフ' },
]
const USER_DELETED = [
    { id: true, name: '退会'},
    { id: false, name: ''}
]
export const REPORT_TYPE = [
    { id: 0, name: '承認待ち'},
    { id: 1, name: '承認' },
    { id: 2, name: '非認' },
]
const DATA = [
    { name: 'id', type: 'textinput', searchField: '', options:[], reference: '', nullable: true, editable: false, export: true, import: true },
    { name: 'team_contact_name', type: 'team_contact_name',reference: 'team_contact', searchField: 'name', nullable: false, editable: false, export: false, import: true },
    { name: 'user_fullname', type: 'textinput', nullable: false, editable: false, export: false },
    // { name: 'is_user_deleted', type: 'status',options: USER_DELETED, nullable: true, editable: false, export: true, import: true },

    { name: 'created_by_email', type: 'textinput',  export: false },
    { name: 'created_by_phone', type: 'textinput',  export: false },

    { name: 'account_name',type: 'account_name',  export: true },
    { name: 'team_name', type: 'hidden',  export: true },
    { name: 'fullname', type: 'hidden',  export: true },
    { name: 'lastname', type: 'textinput', nullable: false, editable: true, export: false, import: true },
    { name: 'firstname', type: 'textinput', nullable: false, editable: true, export: false, import: true },
    { name: 'user_name',  type: 'hidden', export: true },
    { name: 'user_phone', type: 'hidden',  export: true },
    { name: 'user_email', type: 'hidden',  export: true },
    { name: 'account_email', type: 'textinput',  export: true },
    { name: 'account_phone', type: 'textinput',  export: true },
    { name: 'payable_rate', type: 'textinput',  export: true },

    { name: 'account_owner_name', type: 'hidden',  export: true },
    { name: 'account_owner_email', type: 'hidden',  export: true },
    { name: 'team_name', type: 'deepReference',reference: 'team_contact', searchField: 'name', nullable: false, editable: false, export: false, import: false },
    { name: 'disable_button', type: 'button',  export: false },

    { name: 'same_user_id', type: 'status',options: SAME_USER_ID, nullable: false, editable: true, export: true, import: true },
    //{ name: 'team_contact_id', type: 'contact_type',reference: 'team_contact', searchField: 'contact_type', nullable: false, editable: false, export: false, import: false },
    { name: 'contact_type', type: 'contact_type', options: CONTACT_TYPE, nullable: false, editable: false, export: true, import: true },
    { name: 'account_source', type: 'account_source', searchField: 'source', nullable: false, editable: false, export: true, import: false },
    { name: 'status', type: 'status', options:STATUS, nullable: false, editable: true, export: true, import: true },
    { name: 'lastname_kana', type: 'textinput', nullable: false, editable: true, export: true, import: true },
    { name: 'firstname_kana', type: 'textinput', nullable: false, editable: true, export: true, import: true },
    {name: 'representative_last_name', type: 'textinput', nullable: true, editable: true, export: true },
    {name: 'representative_first_name', type: 'textinput', nullable: true, editable: true, export: true },
    { name: 'gender', type: 'status',options: GENDER, nullable: false, editable: true, export: true, import: true },
    { name: 'birthday', type: 'date', nullable: false, editable: false,  export: true, import: true },
    { name: 'age', type: 'age',  export: true},
    { name: 'amount', type: 'textinput',numberFormat: true, nullable: true, editable: true, export: true, import: true },
    { name: 'fee', type: 'fee',reference: 'team_contact', searchField: 'monthly_fee', nullable: false, editable: false, export: false, import: true },
    { name: 'team_contact_id', type: 'monthly_fee',reference: 'team_contact', searchField: 'name', nullable: false, editable: false, export: false, import: true },
    { name: 'already_in_sports', type: 'status',options: ALREADY_IN_SPORTS, nullable: false, editable: true, export: true, import: true },
    { name: 'team_contact_message', type: 'textinput',multiline: true, nullable: true, editable: false, export: true },
    { name: 'status_updated_at', type: 'date',nullable: false, editable: true, export: true, import: true },
    { name: 'join_date', type: 'date',nullable: false, editable: true, export: true, import: true },
    { name: 'report_admin_approve_status', type: 'status',options: REPORT_TYPE, nullable: false, editable: true, export: true, import: true },
    { name: 'report_admin_decline_reason', type: 'textinput', nullable: true, editable: false, export: true },
    { name: 'created', type: 'date', nullable: false, editable: true, export: true, import: true },
    { name: 'created_by', type: 'textinput', nullable: false, editable: false, export: true, import: true },
    { name: 'user_comment', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },
    {name: 'action'},
    { name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
]

export default DATA;