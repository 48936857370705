
import * as React from 'react';
import {
  required,
  TextInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
  AutocompleteArrayInput,
} from 'react-admin';
import jaLocale from "date-fns/locale/ja";
import { DateInput, DateTimeInput } from '../../utils/DatePicker';
import FileInput from '../../pages/components/FileInput';
import { ImageInput } from 'react-admin';
import { ImageField } from 'react-admin';
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useForm } from "react-final-form";
import { UPLOAD_FILE_S3 } from '../../pages/event/gql';
import { RadioButtonGroupInput } from 'react-admin';
import { CheckboxGroupInput } from 'react-admin';
import { NumberInput } from 'ra-ui-materialui';
import TargetField from '../../utils/TargetField';
const requiredValidate = [required()];
const PreviewImage = ({ record, source }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string | null>(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (typeof (record) == "string") {
      setUrl(record)
    } else {
      setIsLoading(false);
    }
  }, [record, apolloClient]);

  // if (isLoading) {
  //   return (<CircularProgress
  //     size={25}
  //     thickness={2}
  //   />);
  // }
  if (typeof (record) == "string" && url) {
    return <img src={url} className="sizeImage" alt={record} />
  } else {
    return <ImageField record={record} source={source} className="sizeImage" />
  }
};
const InputComponent = (props: any) => {
  const form = useForm();
  const apolloClient = useApolloClient();
  const [CheckedTargetUser, setCheckedTargetUser] = useState<boolean>(form.getState().values.target_user);
  const [CheckedTargetApp, setCheckedTargetApp] = useState<boolean>(form.getState().values.target_app);
  const [CheckedTargetAdmin, setCheckedTargetAdmin] = useState<boolean>(form.getState().values.target_admin); 

  const date = new Date();
  const toBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const dataRecord = form.getState().values;
  const onDrop = async (index: number, fileKey: string, files: any, fileRejections: [any], props: any) => {
    // if (fileRejections && fileRejections.length > 0) {
    //   notify(`Error max file size 2MB`, 'warning');
    // }
    const { source } = props;
    const data = ''
    if (files && files.length > 0) {
      const base64 = await toBase64(files[0]).then((data: any) => {
        const __filename = Date.now() + files[0].name
        upload(index, data, fileKey, record, __filename, source);
        //return data;
      });

    }
  };
  const upload = async (index: number, base64: string, fileKey: string, record: any, filename: any, source: String) => {
    var { data: response } = await apolloClient.mutate({
      mutation: UPLOAD_FILE_S3,
      variables: {
        binary: base64,
        filename: filename,
        foldername: source
      },
    });
    if (!response || !response.upload_image.image_url) {
      return;
    }
    const serverFileName = response.upload_image.image_url;
    console.log('serverFileName', serverFileName);
    form.change(fileKey, serverFileName);
  };
  const onRemove = (fileKey: string) => {
    form.change(fileKey, null);
  };
  const onChangeTargetUser = () => {
    setCheckedTargetUser(!CheckedTargetUser)
    form.change('created', date.toLocaleDateString());

    form.change('target_user', !CheckedTargetUser);
  };
  const onChangeTargetApp = () => {
    setCheckedTargetApp(!CheckedTargetApp)
    form.change('created', date.toLocaleDateString());

    form.change('target_app', !CheckedTargetApp);
  };
  const onChangeTargetAdmin = () => {
    setCheckedTargetAdmin(!CheckedTargetAdmin)
    form.change('created', date.toLocaleDateString());
    form.change('target_admin', !CheckedTargetAdmin);
  };
  const translate = useTranslate();
  const { data, source, isEditPage, record } = props || {}
  // empty cell
  if (!Boolean(data)) return <></>

  const { name, reference, searchField, type, nullable, options, editable, placeholder, numberFormat,text_detail } = data || {}
  // if(type === 'textinput' && (name === 'id' && source !== 'point_type')){
  //   return <></>
  // }
  if (name === 'created_at') {
    return <></>
  }
  if (type === 'textinput') {
    
    if (numberFormat) {
      return <NumberInput
        source={name}
        validate={nullable ? [] : requiredValidate}
        label={translate(`${source}.${name}`)}
        placeholder={placeholder}
        fullWidth
        multiline={data.multiline}
        variant="outlined"
      />
    }
    if(name === 'user_id'){
      return <>
       <NumberInput
      source={name}
      //disabled={(editable === false) ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.id_of_user`)}
      placeholder={placeholder}
      fullWidth
      onChange = {(event: React.ChangeEvent<HTMLInputElement>) => {
        const { values } = form.getState();
        form.change('user_id',parseInt(event.target.value));     
    }}
      multiline={data.multiline}
      variant="outlined"
    />
      </>
    }
    if (name === 'line1'||name === 'line2'||name === 'line3' ) {
      return <>
      <p style={{width: 100}}>{translate(`${source}.${text_detail}`)}</p>
      <TextInput
      source={name}
      disabled={(editable === false) ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.${name}`)}
      placeholder={placeholder}
      fullWidth
      multiline={data.multiline}
      variant="outlined"
    /></>
    }
    return <TextInput
      source={name}
      disabled={(editable === false) ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.${name}`)}
      placeholder={placeholder}
      fullWidth
      multiline={data.multiline}
      variant="outlined"
    />
  }
  if (type === 'reference') {
    if(name === 'user_id' && searchField === 'firstname'){
      return <ReferenceInput
      allowEmpty={nullable ? true : false}
      source={name}
      reference={reference}
      //disabled={!editable && isEditPage}
      disabled={(editable === false && (isEditPage === true || !isEditPage )) === true ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      perPage={1000}
      label={translate(`${source}.${name}`)}
      //filter= {{status: 1}}
      filterToQuery={(searchText: any) => ({ [searchField]: searchText })}>
      <AutocompleteInput
        optionText={(r: any) => `${(r?.lastname)} ${r?.firstname}`}
      />
    </ReferenceInput>
    } else if(name === 'user_id' && searchField === 'email'){
      return <ReferenceInput
      allowEmpty={nullable ? true : false}
      source={name}
      reference={reference}
      //disabled={!editable && isEditPage}
      disabled={(editable === false && (isEditPage === true || !isEditPage )) === true ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      perPage={1000}
      label={translate(`${source}.${searchField}`)}
      //filter= {{status: 1}}
      filterToQuery={(searchText: any) => ({ [searchField]: searchText })}>
      <AutocompleteInput
        optionText={searchField}
      />
    </ReferenceInput>
    } else{
      return <ReferenceInput
      allowEmpty={nullable ? true : false}
      source={name}
      reference={reference}
      //disabled={!editable && isEditPage}
      disabled={(editable === false && (isEditPage === true || !isEditPage )) === true ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      perPage={1000}
      label={translate(`${source}.${name}`)}
      //filter= {{status: 1}}
      filterToQuery={(searchText: any) => ({ [searchField]: searchText })}>
      <AutocompleteInput
        optionText={searchField}
      />
    </ReferenceInput>
    }
   
  }

  if (type === 'date') {
    return <DateInput
      source={name}
      disabled={((name === 'created' || name === 'varified')) ? 'disabled' : ''}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{
        placeholder: '年/月/日',
        format: 'yyyy/MM/dd',
        ampm: false,
        cancelLabel: translate('common.btn_cancel'),
        okLabel: translate('common.btn_ok')
      }}
    />
  }

  if (type === 'datetime') {
    return <DateTimeInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{
        placeholder: '年/月/日 時:分',
        format: 'yyyy/MM/dd HH:mm',
        ampm: false,
        cancelLabel: translate('common.btn_cancel'),
        okLabel: translate('common.btn_ok')
      }}
    />
  }

  if (type === 'yearmonth') {
    return <DateInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      views={["year", "month"]}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{
        placeholder: '年/月',
        format: 'yyyy/MM',
        ampm: false,
        cancelLabel: translate('common.btn_cancel'),
        okLabel: translate('common.btn_ok')
      }}
    />
  }
  if (type === 'date') {
    return <DateInput
      source={name}
      disabled={(editable === false) ? 'disabled' : ''}
      //disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      views={["year", "month"]}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{
        placeholder: '年/月/日',
        format: 'yyyy/MM/DD',
        ampm: false,
        cancelLabel: translate('common.btn_cancel'),
        okLabel: translate('common.btn_ok')
      }}
    />
  }

  if (type === 'checkbox') {
    return <BooleanInput
      source={name}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'selectbox') {
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'fileinput') {
    return <FileInput
      source="fileobj"
      validate={requiredValidate}
      label={translate('evidence.filename')}
      className="errorInput"
      maxSize={50000000}
    />
  }
  if (type === 'status') {
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'status_color') {
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'boolean') {
    return <BooleanInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'jsonb') {
    return <TextInput
      source={name}
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'imagefield') {
    return <ImageInput
      source={name}
      label={translate(`${source}.${name}`)} accept="image/jpg, image/jpeg, image/png"
      validate={nullable ? [] : requiredValidate}
      //maxSize={MAX_SIZE}
      placeholder={placeholder || translate("common.imageUploadPlaceholder")}
      options={{
        onRemove: () => onRemove(name),
        onDrop: (files: any, fileRejections: [any]) => onDrop(9, name, files, fileRejections, { ...props })
      }}
    >
      <PreviewImage source={name} />
    </ImageInput>
  }
  if (type === 'radio') {
    return <RadioButtonGroupInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'checkboxinput') {
    return <CheckboxGroupInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'mutipleChoiceInput') {
    return <AutocompleteArrayInput
      source={name}
      choices={options}
      fullWidth
      validate={nullable ? [] : requiredValidate}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'target_user') {
    return <div className="target_box"><label>
      <input type="checkbox"
        defaultChecked={dataRecord.target_user ? dataRecord.target_user : CheckedTargetUser}
        onChange={() => onChangeTargetUser()}
      />
      マイページ
    </label>
    </div>
  }
  if (type === 'target_admin') {
    return <div className="target_box"> <label>
      <input type="checkbox"
        defaultChecked={dataRecord.target_admin ? dataRecord.target_admin : CheckedTargetAdmin}
        onChange={() => onChangeTargetAdmin()}
      />
      チーム管理画面
    </label>
    </div>
  }
  if (type === 'target_app') {
    return <div className="target_box"> <label style={{ marginBottom: '40px' }}>
      <input type="checkbox"
        defaultChecked={dataRecord.target_app ? dataRecord.target_app : CheckedTargetApp}
        onChange={() => onChangeTargetApp()}
      />
      アプリ
      <br></br>
    </label>
    </div>

  }





  return <></>;
}

export default InputComponent;
const optionsStatus = [
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Active' },
];