const STATUS = [
{id: 1, name: "特徴・方針"},
{id: 2, name: "試合・練習"},
{id: 3, name: "費用について"},
{id: 4, name: "保護者負担"},
]
const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'type', type: 'status', options: STATUS, nullable: false, editable: true, export: true, import: true },
{ name: 'name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'image_url', type: 'imagefield', nullable: false, editable: true, export: true, import: true },
{ name: 'mobile_image_url', type: 'imagefield', nullable: false, editable: true, export: true, import: true },
{ name: 'seq', type: 'textinput', numberFormat:true, nullable: false, editable: true, export: true, import: true },
{ name: 'created', type: 'textinput', nullable: true, editable: false, export: true, import: true },
{ name: 'created_by', type: 'hidden', numberFormat:true,nullable: true, editable: false, export: false, import: true },
{ name: 'icon_tag', type: 'textinput',nullable: false, editable: true, export: true, import: true },
{ name: 'note', type: 'textinput', multiline: true,nullable: true, editable: true, export: true, import: true },

]

export default DATA;