import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {
    Button,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
} from 'react-admin';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { Grid, DialogActions, DialogContent, DialogTitle, Dialog,TextField } from '@material-ui/core';
import { CopyTeamreview, GetAccountPlaceByTeamPlaceId } from './gql';
import { useDataProvider } from 'ra-core';
import EditIcon from '@material-ui/icons/FileCopy';

const ModalShowPlaceTeam = (props: any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds, team_place_id } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [idTeamCopy, setIdTeamCopy] = useState(0);
    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const [dataAccountPlace, setdataAccountPlace] = useState<any>([])
    const [name, setName] = useState<string>('');
    const dataProvider = useDataProvider();
    useEffect(() => {
        (async () => {
            const dataAccountPlaceResult = await GetAccountPlaceByTeamPlaceId(apolloClient, team_place_id);
            setdataAccountPlace(dataAccountPlaceResult);
        })()
    }, [name, setName]);
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    //const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        //deleteMany();
        const dataUpdateTeam = await CopyTeamreview(dataProvider, selectedIds[0], idTeamCopy, apolloClient);
        if (dataUpdateTeam === 1) {
            refresh();
            notify(translate('common.msg_delete'));
            //unselectAll(resource);
            setOpen(false);
            setShowDialog(false);
        } else {
            notify(translate('common.msg_delete_fail'), 'warning');
        }
    };
    const handleSubmit = async () => {
        setOpen(true)
    }

    return (
        <Fragment>
            <Button
                label={translate('詳細情報')}
                disabled = {team_place_id !== null ? '' : "disabled"}
                onClick={async () => {
                    handleClick();
                }}
            >
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
            >
                <DialogTitle>{translate(`活動拠点住所`)}</DialogTitle>

                <FormWithRedirect
                    //resource={resource}
                    save={handleSubmit}
                    //initialValues={{ created_by: identity.id }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-show-mobal-place">
                                <Grid style={{ width: '80%', display: 'grid' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <p style={{margin: '10px'}}>
                                            〒
                                        </p>
                                        <TextField value={dataAccountPlace.post_code} size="small" disabled={true} style={{ width: '30%' }} id="outlined-basic" label="例 ： 1700014" variant="outlined" />
                                    </div>
                                    <TextField disabled={true} value={dataAccountPlace.pref_name} size="small" style={{ width: '50%' }} className="outlined-basic" label="pref_name" variant="outlined" />
                                    <TextField disabled={true} value={dataAccountPlace.city_name} size="small" style={{ width: '100%' }} className="outlined-basic" label="市区町村" variant="outlined" />
                                    <TextField disabled={true} value={dataAccountPlace.address} size="small" style={{ width: '100%' }} className="outlined-basic" label="その他住所" variant="outlined" />
                                    <TextField disabled={true} value={dataAccountPlace.building} size="small" style={{ width: '100%' }} className="outlined-basic" label="建物名・部屋番号" variant="outlined" />
                                </Grid>

                                <Grid className='content-station'>
                                    <Grid style={{display: 'grid'}}>
                                        <Grid>
                                            <p>路線駅1</p>
                                        </Grid>
                                        <Grid style={{ display: 'inline-flex',maxWidth: '80%' }}>
                                            <TextField disabled={true} value={dataAccountPlace.line1} size="small" style={{ marginRight: '15px' }} id="outlined-basic" label="路線名1" variant="outlined" />
                                            <TextField disabled={true} value={dataAccountPlace.station1} size="small" id="outlined-basic" label="駅名1" variant="outlined" />
                                        </Grid>
                                        <Grid style={{marginTop: '10px'}}>
                                            <TextField disabled={true} value={dataAccountPlace.walk1} size="small" style={{ width: '80%' }} id="outlined-basic" label="徒歩1" variant="outlined" />
                                        </Grid>

                                    </Grid>
                                    <Grid style={{display: 'grid'}}>
                                        <Grid>
                                            <p>路線駅2</p>
                                        </Grid>
                                        <Grid style={{ display: 'inline-flex', maxWidth: '80%'}}>
                                            <TextField disabled={true} value={dataAccountPlace.line2} size="small" style={{ marginRight: '15px'}} id="outlined-basic" label="路線名2" variant="outlined" />
                                            <TextField disabled={true} value={dataAccountPlace.station2} size="small"  id="outlined-basic" label="駅名2" variant="outlined" />
                                        </Grid>
                                        <Grid style={{marginTop: '10px'}}>
                                            <TextField disabled={true} value={dataAccountPlace.walk2} size="small" style={{ width: '80%' }} id="outlined-basic" label="徒歩2" variant="outlined" />
                                        </Grid>

                                    </Grid>
                                    <Grid style={{display: 'grid'}}>
                                        <Grid>
                                            <p>路線駅1</p>
                                        </Grid>
                                        <Grid style={{ display: 'inline-flex', maxWidth: '80%'}}>
                                            <TextField disabled={true} value={dataAccountPlace.line3} size="small" style={{ marginRight: '15px' }} id="outlined-basic" label="路線名3" variant="outlined" />
                                            <TextField disabled={true} value={dataAccountPlace.station2} size="small" id="outlined-basic" label="駅名3" variant="outlined" />
                                        </Grid>
                                        <Grid style={{marginTop: '10px'}}>
                                            <TextField disabled={true} value={dataAccountPlace.walk3} size="small" style={{ width: '80%' }} id="outlined-basic" label="徒歩3" variant="outlined" />
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                >
                                    <IconCancel />
                                </Button>

                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </Fragment>
    );
};

export default ModalShowPlaceTeam;