import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import moment from 'moment';
import imageAdmin from "../../assets/images/icon-admin.png";
const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
    minWidth: 20,
  },
});
const ChatList = (props: any) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const apolloClient = useApolloClient();
  const { dataChat } = props || {}
  useEffect(() => {

    //const dataMessage = GetDataChatTeamContact(apolloClient,team_experiencer_id);
  }, [dataChat]);
  const firstname = dataChat.firstname;
  const lastname = dataChat.lastname;
  const avatar = dataChat.user.image_url;
  const dataLastest = dataChat.team_contact.team_contact_messages[dataChat.team_contact.team_contact_messages.length - 1];
  
  return (
    <React.Fragment>
      {dataChat.team_contact.team_contact_messages.map((chat: any) => {
        if (!chat.assigned_team_admin_id) {
          return <>
            <Grid className='grid-message-left'>
            <div className='text-chat-message-date-left'>
                <span>{moment(chat.created).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
              <div className='message-left'>
                <img className='img-message' src={avatar}></img>
                <span className='text-chat-message'>{chat.message}</span>
              </div>
              <br></br>
            </Grid>
          </>
        } else {
          return <>
            <Grid className='grid-message-right'   >
              <div className='text-chat-message-date-right'>
                <span>{moment(chat.created).format('YYYY-MM-DD HH:mm:ss')}</span>
                {dataLastest.id === chat.id && <span style={{fontSize:11}}> ({(chat.viewed) ? ' 既読 ' : '未読'}) </span>}
              </div>
              <div className='message-right'>
                <span className='text-chat-message'>{chat.message}</span>
                <img className='img-message-right' src={imageAdmin}></img>
              </div>
              <br></br> 
            </Grid>
          </>
        }

      })}
    </React.Fragment>
  )
};

export default ChatList;
