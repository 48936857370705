import * as React from 'react';
import {FC, useState} from 'react';
import {Chip, Grid} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import { TextInput } from 'react-admin';
import { RadioButtonGroupInput } from 'react-admin';
import { STYLE_CHOICE } from './dataset';
import { useForm } from 'react-final-form';
import {TeamStyle,TeamStyleRender} from './type'


const StyleInput = ({props,dataStyle, dataForm}: {props: any,dataStyle:TeamStyle[], dataForm: TeamStyleRender}) =>{
    const form = useForm();
    const translate = useTranslate();
    var isDisable = 'disable';

    var style = form.getState().values.style as TeamStyle[];
    var data = dataStyle?.filter(x => x.id === dataForm.id);
    if (data && data[0]){
        isDisable = '';
        const valueEdit = data[0].value
        const descriptionEdit = data[0].description
        form.change(`style_choice_${dataForm.id}`,valueEdit)
        form.change(`style_choice_description_${dataForm.id}`, descriptionEdit)
    }
    return <Grid className='GroupStyle'>
    <Grid className={'Radio_style_check'}>
      <p className={'text_from_to'}>{dataForm.label_left}</p>
      <RadioButtonGroupInput
        className={'style_choice'}
        source={`style_choice_${dataForm.id}`}
        choices={STYLE_CHOICE}
        fullWidth
        variant="outlined"
        label={translate(' ')}
        onChange = {async (id: any)=> {
            if(data[0]){
                let objIndex = style.findIndex((obj => obj.id == dataForm.id));
                style[objIndex].value = id;
                isDisable = '';
            }else{
                isDisable = '';
                const description = form.getState().values[`style_choice_description_${dataForm.id}`]
                let dataPush:TeamStyle = {id:dataForm.id, name: dataForm.title, label_left: dataForm.label_left, label_right: dataForm.label_right, value: id, description: description}
                style.push(dataPush)
            }
        }}
      />
      <p className={'text_from_to'}>{dataForm.label_right}</p>
    </Grid>
    <TextInput
      source={`style_choice_description_${dataForm.id}`}
      label={translate(`team.style_choice_description_${dataForm.id}`)}
      placeholder={translate(`team.placeholder_style_description_${dataForm.id}`)}
      fullWidth
      multiline={true}
      disabled={isDisable}
      variant="outlined"
      onChange={(event: any) => {
        if(data[0]){
            let objIndex = style.findIndex((obj => obj.id == dataForm.id));
            style[objIndex].description = event.target.value;
        }
      }}
    />
  </Grid>
}
export default StyleInput;
