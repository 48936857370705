import {makeStyles} from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0
  },
  logotype: {
    display: "flex",
    alignItems: "center",
    marginBottom: '20px',

  },
  logotypeText: {
    fontWeight: 500,
    color: 'red',
    marginLeft: '20px',
  },
  logotypeIcon: {
    width: 70,
    marginRight: '20px',
  },
  paperRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: '40px',
    paddingBottom: '40px',
    paddingLeft: '30px',
    paddingRight: '30px',
    maxWidth: 604
  },
  textRow: {
    marginBottom: '10px',
    textAlign: "center"
  },
  errorCode: {
    fontSize:30,
    fontWeight: 600
  },
  safetyText: {
    fontWeight: 300,
  },
  backButton: {
    textTransform: "none",
    fontSize: 22
  }
}));
