import * as React from 'react';
import {FC} from 'react';
import {CreateButton, useListContext, usePermissions, useTranslate} from 'react-admin';
import InboxIcon from '@material-ui/icons/Inbox';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PermissionsType } from '../../types';

export interface ListEmptyProps {
    showImport?: boolean;
}


const ListEmpty: FC<ListEmptyProps> = ({showImport}) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    const { basePath, resource } = useListContext();
    return (
        <Box textAlign="center" m={1} style={{color:'#999'}}>
            <InboxIcon style={{ fontSize: 220 }}/>
            <Typography variant="h4" paragraph>
                {translate('common.no_record')}
            </Typography>
            {showImport && <Typography variant="body1" style={{margin:'15px'}}>
                {translate('common.import')}
            </Typography>}
            <CreateButton basePath={basePath} style={{background:'#536DFE', color:'#FFF', padding:'7px 12px', minWidth:'120px'}}/>
            {(role ==='admin' || role ==='client_admin') && 
            <Button onClick={() => { window.location.href = `/#/import/create?table=${resource}` }} style={{marginLeft:'15px', background:'rgb(60 212 160)', color:'#FFF', padding:'7px 15px'}}><GetAppIcon/> {translate('common.btn_import')}</Button>
            }
        </Box>
    );
};

ListEmpty.defaultProps = {
    showImport: true
};

export default ListEmpty;