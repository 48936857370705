import PersonIcon from '@material-ui/icons/Person';

import TeamDraftList from './TeamDraftList';
import TeamDraftCreate from './TeamDraftCreate';
import TeamDraftEdit from './TeamDraftEdit';

export default {
    list: TeamDraftList,
    create: TeamDraftCreate,
    edit: TeamDraftEdit,
    icon: PersonIcon,
};
