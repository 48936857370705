
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
  { name: 'genre_id',  type: 'reference',reference: 'genre',searchField: 'name', nullable: false, export: true, import: true },
  { name: 'secondary_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'secondary_english_name', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'image_url', type: 'imagefield', nullable: false, editable: true, export: true, import: true },
  { name: 'mobile_image_url', type: 'imagefield', nullable: false, editable: true, export: true, import: true },
  { name: 'seq', type: 'textinput',numberFormat: true ,nullable: false, editable: true, export: true, import: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'note', type: 'textinput',multiline: true,nullable: true, editable: true, export: true, import: true },
]

export default DATA;