export const CATEGORY = [
    {id: 'クチコミ、みんなのQ&Aについて', name: "クチコミ、みんなのQ&Aについて"},
    {id: 'チーム・スクールへの申込み・問い合わせについて', name: "チーム・スクールへの申込み・問い合わせについて"},
    {id: 'スポーツイベント・大会への参加申し込みについて', name: "スポーツイベント・大会への参加申し込みについて"},
    {id: '会員情報・ログインについて', name: "会員情報・ログインについて"},
    {id: 'スポスルポイントについて', name: "スポスルポイントについて"},
    {id: '退会について', name: "退会について"},
    {id: '不具合報告(ユーザー)', name: "不具合報告(ユーザー)"},
    {id: 'スポスルの掲載料金について', name: "スポスルの掲載料金について"},
    {id: 'スポーツ団体・組織登録について', name: "スポーツ団体・組織登録について"},
    {id: 'チーム・スクール・教室・習い事情報の募集掲載について', name: "チーム・スクール・教室・習い事情報の募集掲載について"},
    {id: 'スポーツイベント・大会の掲載について', name: "スポーツイベント・大会の掲載について"},
    {id: 'スポーツジャンル・スポーツ追加依頼', name: "スポーツジャンル・スポーツ追加依頼"},
    {id: 'スポスルアプリについて', name: "スポスルアプリについて"},
    {id: '不具合報告(スポーツ団体・組織)', name: "不具合報告(スポーツ団体・組織)"},
    {id: '広告掲載について', name: "広告掲載について"},
    {id: 'メディア・取材について', name: "メディア・取材について"},
    {id: 'チーム・スクールへの申込・お問い合わせについて', name: "チーム・スクールへの申込・お問い合わせについて"},
    {id: 'その他', name: "その他"},
    
    ]
const CONATCT_USER = [
    {id: 1, name: 'ユーザー'},
    {id: 5, name: 'スポーツ団体・組織'},
    {id: 10, name: 'その他'}

]
const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'category', type: 'status',options: CATEGORY , nullable: false, editable: true, export: true, import: true },
{ name: 'contact', type: 'textinput',multiline: true, nullable: false, editable: true, export: true, import: true },
{ name: 'lastname', type: 'textinput',isName: true, nullable: false, editable: true, export: true, import: true },
{ name: 'firstname', type: 'textinput',isName: true, nullable: false, editable: true, export: true, import: true },
{ name: 'phone', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'email', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'user_id', type: 'reference',reference: 'user',searchField: 'firstname', nullable: true, editable: true, export: true, import: true },
{ name: 'contact_user', type: 'status_color',options: CONATCT_USER, nullable: false, editable: true, export: true, import: true },
{ name: 'company_name', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'corporate_name', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'created', type: 'textinput', nullable: true, editable: false, export: true, import: true },
]
export default DATA;