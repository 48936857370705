import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import { ChipField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import CreateField from '../components/editField';
import exporter from '../../utils/exporter';
import { useHistory } from 'react-router';
import AccountName from '../team_game/ReferenceFieldAccountName';


const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'client_admin' || role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('team_game_contact.id')} alwaysOn />
            <SearchInput source="title,content,note" alwaysOn />
        </Filter>
    )
};

const TeamGameContactList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit' : '';
    const module = 'team_game_contact';
    const history = useHistory();
    useEffect(() => {
        history.location.pathname = '/team_game_contact';
    }, [props]);
    if (role === undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'team_game_contact', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='team_game_contact' />}
            empty={<ListEmpty />}
            title={<CreateField titlePage={''} />}
        >
            <CustomizableDatagrid rowClick={edit}>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat,reference_id} = f || {}
                    const _reference_id = reference_id ? reference_id : '';
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if(name == 'account_name_from' || name == 'account_name_to'){
                            return <AccountName label={translate(`${module}.${name}`)} source={_reference_id} link={false} reference={'team'}></AccountName>
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference} link={false}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }

                    if (type === 'date' || type === 'datetime') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'target_user' || type === 'target_app' || type === 'target_admin') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default TeamGameContactList;
