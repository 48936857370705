import * as React from 'react';
import {Chip, Grid} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import { useForm } from 'react-final-form';
import {EnrolledListType,EnrolledType} from './type'
import { NumberInput } from 'react-admin';


const EnrollField = ({props, dataForm, dataEnable,ListAge }: {props: any,dataForm: EnrolledListType, dataEnable: EnrolledType[], ListAge: string[]} ) =>{
    const form = useForm();
    const translate = useTranslate();
    var enrolled = form.getState().values.enrolled as EnrolledType[];
    var isDisable = 'disable';
    var data = (dataEnable || []).filter(x => x.name === dataForm.name);
    var AgeEnable = ListAge.filter(x => x === dataForm.name);
    if(AgeEnable[0]){
        isDisable = '';
    }
    if(data && data[0]){
        isDisable = '';
        let dataCurrent = data[0].current;
        form.change(`age_${dataForm.id}`,dataCurrent)
    }

    return  <Grid className='EnrolledForm'>
    <p className={'RowTitle'}>{dataForm.name}</p>
    <NumberInput
      source={`age_${dataForm.id}`}
      label={translate(`team.age_${dataForm.id}`)}
      placeholder={'0'}
      fullWidth
      min= {0}
      disabled={isDisable}
      variant="outlined"
      onChange = {(event:any) => {
        if(data[0]){
            let objIndex = dataEnable.findIndex((obj => obj.name == dataForm.name));
            dataEnable[objIndex].current = event.target.value;
        }else{
            const open = form.getState().values[`age_open_${dataForm.id}`]
            let dataPush:EnrolledType = {name:dataForm.name, current: event.target.value, open: open ? open: ""}
            enrolled.push(dataPush);
        }
      }}
    />
    <p>人</p>
  </Grid>
}
export default EnrollField;
