import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer, { fetchJson, getListLine, getListStattion } from './InputRendererEventPlace';
import Fields from './dataset';
import { useForm } from "react-final-form";
const SectionTitle = ({ label,id }: { label: string, id:number }) => {
  const translate = useTranslate();
  if(id){
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  }else{
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const From = (props: any) => {
  const { editForm } = props;
  const form = useForm();
  const [OptionLine, setOptionLine] = React.useState<any>([]);
  const [OptionStation, setOptionStation] = React.useState<any>([]);
  const [OptionStation2, setOptionStation2] = React.useState<any>([]);
  const [OptionStation3, setOptionStation3] = React.useState<any>([]);
  var isEditPage = editForm === true ? true : false;
  if((OptionLine.length === 0) && editForm){
    const {record: {pref}} = props;
    const {record: {venue_line_cd1}} = props;
    const {record: {venue_line_cd2}} = props;
    const {record: {venue_line_cd3}} = props;
    
    getListLine(pref).then( data => {
      setOptionLine(data);
    });
    if (venue_line_cd1) {
      getListStattion(venue_line_cd1).then(dataStation => {
        setOptionStation(dataStation)
      });
    }
    if (venue_line_cd2) {
      getListStattion(venue_line_cd2).then(dataStation2 => {
        setOptionStation2(dataStation2)
      });
    }
    if (venue_line_cd3) {
      getListStattion(venue_line_cd3).then(dataStation3 => {
        setOptionStation3(dataStation3)
      });
    }
  }
  if(editForm){
    var {record: {id}} = props;
  }
  return <Container>
    <Grid container justify='center' style={{ width: '100%'}} spacing={2}>
    <Grid  style={{ width: '50%'}} container spacing={2} justify='center'>
    <Grid  style={{ width: '100%'}} >
    <SectionTitle label={editForm ? "menu.event_place.page_edit" : "menu.event_place.page_create"} id  = {editForm? id : ''} />
    <Separator />
    </Grid >
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'created' && isEditPage === false) {
          return <></>
        }
        if(d.isName){
          return <Grid item xs={6} key={idx}>
          <InputRenderer isEditPage={isEditPage} source='event_place' data={d} />
        </Grid>
        }else{
          return <Grid item xs={12} key={idx}>
          <InputRenderer isEditPage={isEditPage} source='event_place' OptionLine={OptionLine} setOptionLine={setOptionLine} OptionStation={OptionStation} setOptionStation={setOptionStation} OptionStation2={OptionStation2} setOptionStation2={setOptionStation2} OptionStation3={OptionStation3} setOptionStation3={setOptionStation3} data={d} />
        </Grid>
        }
      })}
      </Grid>
    </Grid>
  </Container>;
};

export default From;