import * as React from 'react';
import { Fragment, useState, useEffect } from 'react';
import FileCopy from '@material-ui/icons/FileCopy';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    FormWithRedirect,
    SaveButton
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useApolloClient } from "@apollo/client";
import IconCancel from '@material-ui/icons/Cancel';
import { Grid, DialogActions, DialogContent, DialogTitle, Dialog, FormControl, InputLabel } from '@material-ui/core';
import moment from 'moment';
import { InsertTokenInvoice } from '../billing_view/gql';
import { makeid } from '../../utils/Utils';

const formatDateToJapanese = (dateString:string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}年${month}月${day}日`;
  };
const ActionsUpdateInoivce = (props: any) => {
    const today = new Date().toISOString().slice(0, 10);
    let dateNow = moment().utcOffset('+0900').format('YYYY-MM-DD');
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    const translate = useTranslate();
    const apolloClient = useApolloClient();
    const [selectedIssueDate, setSelectedIssueDate] = useState(dateNow);
    const [selectedDueDate, setSelectedDueDate] = useState(dateNow);

    let { isOpen, handleCloseClick, data } = props;
    useEffect(() => {
        const { isOpen } = props
        setShowDialog(isOpen);
    }, [isOpen]);
    const {
        resource,
    } = useListContext();
    //const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = async () => {
        //deleteMany();

    };

    const handleIssueDateChange = (event: any) => {
        setSelectedIssueDate(event.target.value);        
    };
    const handleDueDateChange = (event: any) => {
        setSelectedDueDate(event.target.value);        
    };
    const handleSubmit = async () => {
    setLoading(true);
    const billing_id = data.billing_id as number;
    const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
    const token = await makeid(200);
    const InsertToken = await InsertTokenInvoice(apolloClient, token);
    const response = await fetch(`${Domain}api/create-invoice-biling-id/${billing_id}/${token}?issue_date=${selectedIssueDate}&due_date=${selectedDueDate}`).then((data: any) => {
      console.log('response', data);
      if (data.status === 200) {
        setLoading(false)
        notify(translate('請求書が再生成されました。'));
        handleCloseClick()
      } else {
        notify(translate('請求書再生成が失敗しました。'), 'warning');

      }
    });
    return response;
    }

    return (
        <Fragment>
            <Dialog
                fullWidth
                open={isOpen}
                onClose={handleCloseClick}
            >
                <DialogTitle>{translate(`common.update_invoice`)}</DialogTitle>

                <FormWithRedirect
                    resource={resource}
                    save={handleSubmit}
                    //initialValues={{ created_by: identity.id }}
                    render={({
                        handleSubmitWithRedirect,
                        pristine,
                        saving
                    }: any) => (
                        <>
                            <DialogContent className="dialog-update-invoice">
                            <FormControl className={'form-update-invoice'}>
                                    <label className='text-update-invoice' id="update-invoice-issue-date">{translate('billing.issue_date')}</label>
                                    <input
                                    className='input-issue-date'
                                    type="date"
                                    id="dateInput"
                                    value={selectedIssueDate}
                                    onChange={handleIssueDateChange}
                                />
                                </FormControl>
                                <FormControl className={'form-update-invoice'}>
                                    <label className='text-update-invoice' id="update-invoice-issue-date">{translate('billing.due_date')}</label>
                                    <input
                                    className='input-due-date'
                                    type="date"
                                    id="dateInput"
                                    value={selectedDueDate}
                                    onChange={handleDueDateChange}
                                />
                                </FormControl>
                               
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="ra.action.cancel"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                >
                                    <IconCancel />
                                </Button>
                                <SaveButton
                                    label={translate(`common.confirm_button_update_invoice`)}
                                    handleSubmitWithRedirect={
                                        handleSubmitWithRedirect
                                    }
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
            {/* <Confirm
                isOpen={open}
                loading={loading}
                title={translate('common.msg_title_copy')}
                content={translate('common.msg_copy_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            /> */}
        </Fragment>
    );
};

export default ActionsUpdateInoivce;