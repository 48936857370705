import * as React from 'react';
import { FC } from 'react';
import { Toolbar } from 'react-admin';
import { Button } from 'react-admin';
import { useRedirect } from 'react-admin';
import { SaveButton } from 'react-admin';
import {
   Create,
   FormDataConsumer,
   SimpleForm,
   useNotify,
   useTranslate,
   useGetIdentity,
} from 'react-admin';
import { useHistory } from 'react-router';
import { CreateComponentProps } from '../../types';
import Form from './Form';

const ContactCreate: FC<CreateComponentProps> = props => {
   const translate = useTranslate();
   const notify = useNotify();
   
   const { identity, loading: identityLoading } = useGetIdentity();

   if (identityLoading) {
      return <>Loading...</>;
   }

   const onFailure = (error: any) => {
      notify(`Could not create: ${error.message}`, 'warning');
   };
   const CreateToolbar = (props: any) => {

      const translate = useTranslate();
      const history = useHistory();
      const redirect = useRedirect();
      const notify = useNotify();
      const cancelButton = () => {
          history.goBack();
      };
      const onSuccess = () => {
          notify(`${translate('common.msg_add_success')}`, 'info');
          redirect('list', props.basePath);
      };   
      
      const onFailure = (error: any) => {
          notify(`${translate('common.could_not')} ${error.message}`, 'warning');
      };
  
      return (
          <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }}>
              <SaveButton
                  disabled={props.pristine}
                  transform = {(data:any) => ({...data})}
                  undoable={false}
                  redirect="list"
                  label={translate('common.btn_save')}
                  onSuccess={props.onSuccess || onSuccess}
                  onFailure={onFailure}
                  style={ props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
              />
              <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default"/>
          </Toolbar>
      );
  };
   const defaultValues = () => ({created_by: 1});
   return (
      <Create {...props} className="formContent"
         onSuccess={() => {
            notify(`${translate('common.msg_add_success')}`, 'info');
         }}
         onFailure={onFailure}
         undoable={false}  
         title={translate('menu.answer.add')}
      >
         <SimpleForm 
            className="form" 
            redirect="list"
            toolbar={<CreateToolbar />}
         >
            <FormDataConsumer>
               {(formDataProps: any) => (
                  <Form {...formDataProps}  />
               )}
            </FormDataConsumer>
         </SimpleForm>
      </Create>
   );
};

export default ContactCreate;
