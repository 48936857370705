import PersonIcon from '@material-ui/icons/Person';

import TeamList from './TeamList';
import TeamCreate from './TeamCreate';
import TeamEdit from './TeamEdit';

export default {
    list: TeamList,
    create: TeamCreate,
    edit: TeamEdit,
    icon: PersonIcon,
};
