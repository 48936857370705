
const CONTACT_TYPE = [
  { id: 1, name: '申込み' },
  { id: 2, name: '問い合わせ'}
]
const CONTACT_STATUS = [
  { id: 0, name: '未対応' },
  { id: 1, name: '対応済み'}
]
const EVENT_CONTACT_CATEGORY = [
  { id: 10, name: 'イベント・大会全般について' },
  { id: 20, name: 'イベント・大会参加について' },
  { id: 30, name: 'その他質問'}
]
const PARENT_PLAG = [
  { id: true, name: '保護者です'},
  { id: false, name: '保護者ではないです'}
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],reference: '', nullable: false, editable: false, export: true },
  { name: 'account_name',  export: true },
  { name: 'user_id',  type: 'reference',reference: 'user',searchField: 'firstname', nullable: false, export: false },
  { name: 'user_name',  type: 'hidden', export: true },
  { name: 'user_phone', type: 'hidden',  export: true },
  { name: 'user_email', type: 'hidden',  export: true },
  { name: 'event_id',  type: 'reference',reference: 'event',searchField: 'title', nullable: false, export: true },
  { name: 'button_filter_event_contact', type: 'button_filter', nullable: false, editable: false, export: false, import: false },
  { name: 'contact_type', type: 'status', options: CONTACT_TYPE , nullable: false, editable: true, export: true },
  { name: 'contact_status', type: 'status', options: CONTACT_STATUS , nullable: false, editable: true, export: true },
  { name: 'event_contact_category', type: 'status', options: EVENT_CONTACT_CATEGORY , nullable: true, editable: true, export: true },
  { name: 'message', type: 'textinput',multiline: true, nullable: false, editable: true, export: true },
  { name: 'parent_flg', type: 'checkbox' , options: PARENT_PLAG, nullable: true, editable: true, export: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true },
  { name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true },
]

export default DATA;