import { gql } from "@apollo/client";

export const DeleteBulkUser = gql`
mutation MyMutation($list_user_id: [Int!]) {
  delete_user(where: {id: {_in: $list_user_id}}) {
    affected_rows
  }
}
`;
export const SoftDeleteBulkUser = gql`
mutation MyMutation($list_user_id: [Int!]) {
  update_user(where: {id: {_in: $list_user_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
  }
}
`;

export const DeleteMultiUser = async (apolloClient: any,list_user_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SoftDeleteBulkUser,
      variables: {
        list_user_id: list_user_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
