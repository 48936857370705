import React, {FC, useState} from 'react'
import {
    BooleanInput,
    Create,
    FormDataConsumer,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput,
    Toolbar,
    useNotify,
    useTranslate
} from 'react-admin';
import {Box, Typography} from '@material-ui/core';
import { CreateComponentProps} from '../../types';
import ReactHtmlParser from 'react-html-parser'
import {buildPreview} from "./buildActions";
import {useForm} from 'react-final-form';
import { useApolloClient } from "@apollo/client";
import { CREATE_TEMPLATE_EMAIL } from './gql';

const STATUS = [
    {id: 0, name: "無効"},
    {id: 1, name: "有効"},
  ]
const EmailCreate: FC<CreateComponentProps> = props => {
    const translate = useTranslate();
    const notify = useNotify();
    const apolloClient = useApolloClient();
    const CreateTemplate = async (record: any) => {
        const data = await apolloClient.mutate({
            mutation: CREATE_TEMPLATE_EMAIL,
            variables: {
                html_content: record.body, 
                name: record.name,
                subject: record.subject
            },
          });
          return data;
    }

    const onFailure = (error: any) => {
        notify(`${translate('common.could_not')} ${error.message}`, 'warning');
    };
    //const client = useSelector((appState: AppState) => appState.client);
    const transform = async (data:any) => {
        const bodyConvert_duplicate =  data.virtual_body.toString().replace('<p><br></p>', '<p></p>');
        const bodyConvert = bodyConvert_duplicate.toString().replace('</p>','</p>');
        console.log('bodyConvert',data);
        
        const record = {
            body: bodyConvert,
            name: data.name, 
            subject: data.subject
        }
        const dataResponse =  await CreateTemplate(record);
        const {data: {create_email_template}} = dataResponse;
        const template_id = create_email_template.template_id
        const dataInsert = {
            action_key: data.action_key, 
            name: data.name, 
            status: data.status, 
            sendgrid_template_id: template_id,
            params: data.params, 
            subject: data.subject,
            virtual_body: data.virtual_body
        }
        return dataInsert
    };
   
    
    
    const PostCreateToolbar = (props: any) => (
        <Toolbar {...props}>
            <SaveButton
                label={translate('common.btn_save')}
                transform={transform}
                submitOnEnter={false}
            />
        </Toolbar>
    );

    return (
        <Create {...props} className="formContent"
            successMessage={translate('common.msg_add_success')}
            onFailure={onFailure}
            title={translate(' ')}
        >
            <SimpleForm toolbar={<PostCreateToolbar {...props}/>} redirect="list">
                <FormDataConsumer>
                    {(formDataProps: any) => (
                        <CreateEmailForm {...formDataProps} />
                    )}
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    );
};

const CreateEmailForm = (props: any) => {
    const [preview, setPreview] = useState('');
    const form = useForm();
    const translate = useTranslate();

    return (
        <Box display={{ md: 'block', lg: 'flex' }} style={{ width: '100%' }}>
            <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                <SectionTitle label={translate('menu.email_template.create_new')} />
                <Separator/>
                <TextInput
                    autoFocus
                    source="name"
                    validate={requiredValidate}
                    label={translate('email_template.name')}
                    fullWidth
                    variant="outlined"
                    resource="template"
                />
                <SelectInput
                    source="status"
                    validate={requiredValidate}
                    resource="template"
                    choices={STATUS}
                    fullWidth
                    variant="outlined"
                    label={translate('email_template.status')}
                />
                <TextInput
                    source="action_key"
                    validation={{ email: true }}
                    validate={[required()]}
                    fullWidth
                    label={translate('email_template.action_key')}
                    variant="outlined"
                />
                <TextInput
                    source="subject"
                    validate={[required()]}
                    fullWidth
                    label={translate('email_template.subject')}
                    variant="outlined"
                />
                <TextInput
                    source="params"
                    validate={[required()]}
                    fullWidth
                    multiline
                    rows={5}
                    resource="template"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const { values } = form.getState();
                        setPreview(buildPreview(values.virtual_body, event.target.value));
                    }}
                    variant="outlined"
                    label={translate('email_template.params')}
                />
                 <TextInput
                    source="virtual_body"
                    fullWidth
                    multiline
                    rows={20}
                    resource="template"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const { values } = form.getState();
                        const preview = buildPreview(values.virtual_body, values.params);
                        setPreview(preview);
                    }}
                    variant="outlined"
                    label={translate('email_template.body')}
                />
            </Box>
            <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                {ReactHtmlParser(preview)}
            </Box>
        </Box>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;

export default EmailCreate;
