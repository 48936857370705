
import * as React from 'react';
import {
  required,
  TextInput,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import jaLocale from "date-fns/locale/ja";
import { DateInput, DateTimeInput } from '../../utils/DatePicker';
import FileInput from '../components/FileInput';
import { ImageInput } from 'react-admin';
import { ImageField } from 'react-admin';
import { CircularProgress, Grid } from '@material-ui/core';
import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useForm } from "react-final-form";
import { promises, resolve } from 'dns';
import { UPLOAD_FILE_S3 } from '../event/gql';
import { RadioButtonGroupInput } from 'react-admin';
import { CheckboxGroupInput } from 'react-admin';
import { Button, NumberInput } from 'ra-ui-materialui';
import EditIcon from '@material-ui/icons/EditLocationOutlined';
import axios from 'axios';


export const checkExist = async (arr: any, pref_cd: Number) => {
  const found = arr.some((item:any) => item.id === pref_cd); 
  if (found) {
    return true;
  }else {
    return false;
  }
}
export const getZipCodeUrl = (zip: string, code: string): string => {
  return `${process.env.REACT_APP_LOOKUP_ENDPOINT}/post/${zip}/${code}.json`;
};
export const GetArea = async (pref_cd: Number) => {
  var res = {are_name: '', area_id: 0};
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
  for(var are = 0; are < dataAllArea.length ; are++){ 
    const pref_arr = dataAllArea[are].pref 
    const checkExist = pref_arr.some((item:any) => item.id === pref_cd);
  }
}; 
export const getListLine = async (pref_cd: Number) => {
  const OptionLine = [];
  const dataAllLine = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/line/${pref_cd}.json`);
  for(var line = 0; line < dataAllLine.length ; line++){ 
    OptionLine.push({id: parseInt(dataAllLine[line].line_cd), name: dataAllLine[line].line_name})
  }
  return OptionLine;
}
export const getListStattion = async (line_cd: Number) => {
  const OptionStation = [];
  const dataAllStation = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/station/${line_cd}.json`);
  for(var station = 0; station < dataAllStation.length ; station++){ 
    OptionStation.push({id: parseInt(dataAllStation[station].station_cd), name: dataAllStation[station].station_name})
  }
  console.log('dataOptionStation',OptionStation);
  
  return OptionStation;
}
// fetch data json
export const fetchJson = async (link: string) => {
  try {
    const response = await fetch(link);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
const requiredValidate = [required()];
const PreviewImage = ({ record, source }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [url, setUrl] = useState<string|null>(null);
  const apolloClient = useApolloClient();
  
  useEffect(() => {
    if (typeof (record) == "string") {
      setUrl(record)
    } else {
      setIsLoading(false);
    }
  },[record, apolloClient]);
  
  // if (isLoading) {
  //   return (<CircularProgress
  //     size={25}
  //     thickness={2}
  //   />);
  // }
  if (typeof (record) == "string" && url) {
    return <img src={url} className="sizeImage" alt={record}/>
  } else {
    return <ImageField record={record} source={source} className="sizeImage" />
  }
};
const InputComponent = (props: any) => {
  //console.log('props',props);
  
  const form = useForm();
  const apolloClient = useApolloClient();
  const toBase64 = async (file:any) => {
    return new Promise((resolve,reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }


  const updateVenue_line = async (line_id: Number, lineIndex: Number) => {
    let ListLine = props.OptionLine; 
    for(let line = 0 ; line < ListLine.length; line++){
      if(ListLine[line].id === line_id){
        form.change(`venue_line${lineIndex}`,ListLine[line].name)
        return;
      }
    }
  }
  const updateVenue_Station = async (station_id: Number, StationIndex: Number) => {
    let ListStation = []; 
    if (StationIndex === 1) {
      ListStation = props.OptionStation
    }else if(StationIndex === 2){
      ListStation = props.OptionStation2
    }else if(StationIndex === 3){
      ListStation = props.OptionStation3
    }
    for(let station = 0 ; station < ListStation.length; station++){
      if(ListStation[station].id === station_id){
        form.change(`venue_station${StationIndex}`,ListStation[station].name)
        return;
      }
    }
  }
  
 const GetArea = async (pref_cd: Number) => {
    const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
    for(var are = 0; are < dataAllArea.length ; are++){ 
      const pref_arr = dataAllArea[are].pref 
      const checkExist = pref_arr.some((item:any) => item.id === pref_cd);
      if (checkExist) {
        form.change('area',dataAllArea[are].id)
        form.change('area_name',dataAllArea[are].name)
      }
    }
  }; 

  const GetCity = async (pref_cd: Number, city_name: String) => {
    const dataCity = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/${pref_cd}.json`);
    for(var city = 0; city < dataCity.length ; city++){ 
      const city_arr = dataCity[city]
      if (city_arr.city === city_name) {
        form.change('city',city_arr.id)
        form.change('city_name',city_arr.city)
        return;
      }
    }
  }; 
  // get 
   const handleZipcodeFound = async (post_code: string): Promise<void> => {
    var regexD = new RegExp(/^[0-9]{3}(-[0-9]{4})?$/gm);
    var regexN = new RegExp(/^[0-9]{3}([0-9]{4})?$/gm);
    if (!regexD.test(post_code) && !regexN.test(post_code)) {
      // setPostalCodeError("※郵便番号は、7桁数字で入力してください。");
      return;
    }
    if (post_code.indexOf("-") !== -1) {
      post_code = post_code.replaceAll("-", "");
    }
    var zip = post_code.substr(0, 3);
    var code = post_code.substr(3, 4);

    const response = await axios.get(getZipCodeUrl(zip, code));
    if (response.status === 200) {
      console.log('response',response);
      await GetArea(response.data.pref_cd);
      await GetCity(response.data.pref_cd,response.data.city);
      var dataLine = await getListLine(response.data.pref_cd);
      props.setOptionLine(dataLine);
      form.change("venue_pref", response.data.pref);
      form.change("venue_city", response.data.city);
      form.change("venue_address1", response.data.address);
      form.change("pref", response.data.pref_cd)
      form.change("pref_name",response.data.pref)
    }
  };
  const onDrop = async (index: number, fileKey: string, files: any, fileRejections: [any], props: any) => {
    // if (fileRejections && fileRejections.length > 0) {
    //   notify(`Error max file size 2MB`, 'warning');
    // }
    const {source} = props;
    const data = ''
    if (files && files.length > 0) {
      const base64 = await toBase64(files[0]).then((data:any) =>{
        const __filename = files[0].name
        upload(index,data, fileKey, record, __filename,source);
        //return data;
      });
      
    }
  };
  const upload = async (index: number, base64: string, fileKey: string, record: any, filename: any, source:String) => {
      var { data: response } = await apolloClient.mutate({
        mutation: UPLOAD_FILE_S3,
        variables: {
          binary: base64, 
          filename:filename, 
          foldername: source
        },
      });
    if (!response || !response.upload_image.image_url) {
      return;
    }
    const serverFileName = response.upload_image.image_url;
    console.log('serverFileName',serverFileName);
    form.change(fileKey, serverFileName);
  };
  const onRemove = (fileKey: string) => {
    form.change(fileKey, null);
  };
  const translate = useTranslate();
  const { data, source,isEditPage, record } = props || {}
  console.log('editForm',isEditPage);

  // empty cell
  if (!Boolean(data)) return <></>

  const { name, reference, searchField, type, nullable, options, editable, placeholder, numberFormat } = data || {}
  if(type === 'textinput' && name === 'id'){
    return <></>
  }
  if(name === 'created_at'){
    return <></>
  }
  if (type === 'textinput') {
    if (name === 'venue_post_code') {
      return <Grid className="post_code_input">
        <Grid item xs={6} style={{width: '50%'}}> 
        <TextInput
          source={name}
          disabled={(editable === false) ? 'disabled': ''}
          validate={nullable ? [] : requiredValidate}
          label={translate(`${source}.${name}`)}
          placeholder={placeholder}
          fullWidth
          multiline={data.multiline}
          variant="outlined"
        />
        </Grid>
        <Grid item xs={6} style={{width: '50%', marginTop:15, marginLeft: 5}} >
        <Button 
          label= {translate('event_place.gen_code_button')}
          onClick={async () => { 
            const {venue_post_code} = form.getState().values;
            await handleZipcodeFound(venue_post_code);
          }}
        >
          <EditIcon />
        </Button>
        </Grid>
      </Grid>
    }
    if (numberFormat) {
      return <NumberInput
      source={name}
      //disabled={(editable === false) ? 'disabled': ''}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.${name}`)}
      placeholder={placeholder}
      fullWidth
      multiline={data.multiline}
      variant="outlined"
    />
    }
    return <TextInput
      source={name}
      disabled={(editable === false) ? 'disabled': ''}
      validate={nullable ? [] : requiredValidate}
      label={translate(`${source}.${name}`)}
      placeholder={placeholder}
      fullWidth
      multiline={data.multiline}
      variant="outlined"
    />
  }

  if (type === 'reference') {
    return <ReferenceInput
      allowEmpty={nullable ? true : false}
      source={name}
      reference={reference}
      //disabled={!editable && isEditPage}
      disabled={(editable === false && isEditPage === true) === true ? 'disabled': ''}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      //filter= {{status: 1}}
      filterToQuery={(searchText: any) => ({[searchField]: searchText })}>
      <AutocompleteInput
        optionText={searchField}
      />
    </ReferenceInput>
  }

  if (type === 'date') {
    return <DateInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月/日', 
        format: 'yyyy/MM/dd', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'datetime') {
    return <DateTimeInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月/日 時:分', 
        format: 'yyyy/MM/dd HH:mm', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'yearmonth') {
    return <DateInput
      source={name}
      disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      views={["year", "month"]}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月', 
        format: 'yyyy/MM', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }
  if (type === 'date') {
    return <DateInput
      source={name}
      disabled={(editable === false) ? 'disabled': ''}
      //disabled={!editable && isEditPage}
      validate={nullable ? [] : requiredValidate}
      views={["year", "month"]}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
      providerOptions={{ locale: jaLocale }}
      options={{ 
        placeholder: '年/月/日', 
        format: 'yyyy/MM/DD', 
        ampm: false, 
        cancelLabel: translate('common.btn_cancel'), 
        okLabel: translate('common.btn_ok') }}
    />
  }

  if (type === 'checkbox') {
    return <BooleanInput
      source={name}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'selectbox') {
    if (name === 'venue_line_cd1') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionLine}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_line_cd1 = values.venue_line_cd1;
        await updateVenue_line(venue_line_cd1, 1)
        const dataStation1 = await getListStattion(venue_line_cd1) 
        props.setOptionStation(dataStation1);
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    if (name === 'venue_line_cd2') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionLine}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_line_cd2 = values.venue_line_cd2;
        await updateVenue_line(venue_line_cd2, 2)
        const dataStation2 = await getListStattion(venue_line_cd2) 
        props.setOptionStation2(dataStation2);
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    if (name === 'venue_line_cd3') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionLine}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_line_cd3 = values.venue_line_cd3;
        await updateVenue_line(venue_line_cd3, 3)
        const dataStation3 = await getListStattion(venue_line_cd3) 
        props.setOptionStation3(dataStation3);
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    if (name === 'venue_station_cd1') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionStation}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_station_cd1 = values.venue_station_cd1;
        await updateVenue_Station(venue_station_cd1, 1)
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    if (name === 'venue_station_cd2') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionStation2}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_station_cd2 = values.venue_station_cd2;
        await updateVenue_Station(venue_station_cd2, 2)
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    if (name === 'venue_station_cd3') {
      return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={props.OptionStation3}
      fullWidth
      onChange={async () => {
        const { values } = form.getState();
        const venue_station_cd3 = values.venue_station_cd3;
        await updateVenue_Station(venue_station_cd3, 3)
      }}
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
    }
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }

  if (type === 'fileinput') {
    return <FileInput
      source="fileobj"
      validate={requiredValidate}
      label={translate('evidence.filename')}
      className="errorInput"
      maxSize={50000000}
    />
  }
  if (type === 'status') {
    return <SelectInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      choices={options}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'boolean') {
    return <BooleanInput
      source={name}
      validate={nullable ? [] : requiredValidate}
      fullWidth
      variant="outlined"
      label={translate(`${source}.${name}`)}
    />
  }
  if (type === 'jsonb') {
    return <TextInput
        source={name}
        label={translate(`${source}.${name}`)}
    />
}
    if(type === 'imagefield'){
      return <ImageInput
      source={name}
      label={translate(`${source}.${name}`)} accept="image/jpg, image/jpeg"
      //maxSize={MAX_SIZE}
      options={{
        onRemove: () => onRemove(name),
        onDrop: (files: any, fileRejections: [any]) => onDrop(9, name, files, fileRejections, {...props})
      }}
      >
      <PreviewImage source={name} />
    </ImageInput>
    }
    if (type === 'radio') {
      return <RadioButtonGroupInput
        source={name}
        validate={nullable ? [] : requiredValidate}
        choices={options}
        fullWidth
        variant="outlined"
        label={translate(`${source}.${name}`)}
      />
    }
    if (type === 'checkboxinput') {
      return <CheckboxGroupInput
        source={name}
        validate={nullable ? [] : requiredValidate}
        choices={options}
        fullWidth
        variant="outlined"
        label={translate(`${source}.${name}`)}
      />
    }




  return <></>;
}

export default InputComponent;
const optionsStatus = [
  { id: 0, name: 'Inactive' },
  { id: 1, name: 'Active' },
];