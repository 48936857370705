import TextField from "@material-ui/core/TextField/TextField";
import * as React from "react";

export const renderInput = ({
                         meta: { touched, error } = { touched: false, error: undefined },
                         input: { ...inputProps },
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);