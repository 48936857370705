import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from './InputRenderer';
import Fields from './dataset';
import { AutocompleteInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();
  if(id){
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  }else{
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  if(editForm){
    var {record: {id}} = props;
  }
  var isEditPage = editForm === true ? true : false;
  return <Container style={{justifyContent: 'center'}}>
    
    <Grid  style={{ width: '100%'}}  container justify='center' spacing={2}> 
      <Grid  style={{ width: '50%'}} container justify='center' spacing={2} >
      <Grid  style={{ width: '100%'}} >
      <SectionTitle  label={editForm ? "menu.user_point.edit_page" : "menu.user_point.add_page"} id = {editForm? id : ''}  />
      <Separator />
      </Grid>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'created' && isEditPage === false) {
          return <></>
        }
        if (d.name === 'id' && isEditPage === false) {
          return <></>
        }
        if (d.name === 'user_id' && ( d.searchField === 'phone'|| d.searchField === 'lastname')) {
          return <></>
        }
        return <Grid item xs={12} key={idx}>
          <InputRenderer source='user_point' data={d} />
        </Grid>
      })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;