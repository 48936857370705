import * as React from "react";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { SvgIcon, Theme, useMediaQuery, Box } from "@material-ui/core";
import { MenuItemLink, usePermissions, useTranslate } from "react-admin";
import SubMenu from "./SubMenu";
import { AppState, PermissionsType } from "../types";
import GroupIcon from "@material-ui/icons/Group";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useRouteMatch } from "react-router-dom";

import { ReactComponent as UserListIcon } from "../assets/icons/icon-side-01.svg";
import { ReactComponent as GenreIcon } from "../assets/icons/icon-side-02.svg";
import { ReactComponent as TeamIcon } from "../assets/icons/icon-side-03.svg";
import { ReactComponent as AccountIcon } from "../assets/icons/icon-side-04.svg";
import { ReactComponent as EventIcon } from "../assets/icons/icon-side-05.svg";
import { ReactComponent as SposuruNewsIcon } from "../assets/icons/icon-side-06.svg";
import { ReactComponent as QuestionIcon } from "../assets/icons/icon-side-07.svg";
import { ReactComponent as AnswerIcon } from "../assets/icons/icon-side-08.svg";
import { ReactComponent as CharacterIcon } from "../assets/icons/icon-side-09.svg";
import { ReactComponent as TeamReviewIcon } from "../assets/icons/icon-side-10.svg";
import { ReactComponent as ContactIcon } from "../assets/icons/icon-side-11.svg";
import { ReactComponent as PointTypeIcon } from "../assets/icons/icon-side-12.svg";
import { ReactComponent as UserPointIcon } from "../assets/icons/icon-side-13.svg";
import { ReactComponent as GenreSportIcon } from "../assets/icons/icon-side-14.svg";
import { ReactComponent as AccountAdminIcon } from "../assets/icons/icon-side-15.svg";
import { ReactComponent as TeamAdminIcon } from "../assets/icons/icon-side-16.svg";
import { ReactComponent as SettingIcon } from "../assets/icons/icon-side-17.svg";
import { ReactComponent as AccountOwnerIcon } from "../assets/icons/icon-side-18.svg";
import { ReactComponent as USerRemoveAdminIcon } from "../assets/icons/icon-side-19.svg";
import { ReactComponent as UserTeamEx } from "../assets/icons/icon-side-21.svg";
import { ReactComponent as BillIcon } from "../assets/icons/icon-side-22.svg";
import { ReactComponent as BillDetailIcon } from "../assets/icons/icon-side-10.svg";
import { ReactComponent as TeamGameContactIcon } from "../assets/icons/icon-side-28.svg";
import { ReactComponent as TeamGameIcon } from "../assets/icons/icon-side-27.svg";







type MenuName =
  | "mainMenuInvoice"
  | "mainMenuApplication"
  | "menuUser"
  | "menuImport"
  | "menuGenre"
  | "menuTeam"
  | "menuAccount"
  | "menuAdmin"
  | "menuTemplate"
  | "menuEvent"
  | "menuSposuruNews"
  | "menuQuestion"
  | "menuAnswer"
  | "menuCharacter"
  | "menuTeamReview"
  | "menuContact"
  | "menuPointType"
  | "menuUserPoint"
  | "menuPointExchange"
  | "menuGenreSport"
  | "menuEventPlace"
  | "menuAccountAdmin"
  | "menuTeamAdmin"
  | "menuAccountOwner"
  | "menuUserRemoveAdmin"
  | "menuPermission"
  | "menuTrash"
  | "menuContactEventTeam"
  | "menuDataAccountTrash"
  | "menuTeamTrash"
  | "menuEventTrash"
  | "menuTeamReviewTrash"
  | "menuUserTrash"
  | "menuAccountOwnerTrash"
  | "menuAccountAdminTrash"
  | "menuTeamAdminTrash"
  | "menuExperiencerDetail"
  | "menuBilling"
  | "menuBillingDetail"
  | "menuTeamGameContact"
  | "menuTeamGame"
  | "menuShop"
  | "menuShopDraft"
  | "menuSchoolGym"
  | "menuReport";


interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Separator = () => <Box pt="1em" />;

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    mainMenuInvoice: true,
    mainMenuApplication: false,
    menuUser: false,
    menuGenre: false,
    menuImport: false,
    menuTeam: false,
    menuAccount: false,
    menuAdmin: false,
    menuTemplate: false,
    menuEvent: false,
    menuSposuruNews: false,
    menuQuestion: false,
    menuAnswer: false,
    menuCharacter: false,
    menuTeamReview: false,
    menuContact: false,
    menuPointType: false,
    menuUserPoint: false,
    menuPointExchange: false,
    menuGenreSport: false,
    menuEventPlace: false,
    menuAccountAdmin: false,
    menuTeamAdmin: false,
    menuAccountOwner: false,
    menuUserRemoveAdmin: false,
    menuPermission: false,
    menuTrash: false,
    menuContactEventTeam: false,
    menuDataAccountTrash: false,
    menuTeamTrash: false,
    menuEventTrash: false,
    menuTeamReviewTrash: false,
    menuUserTrash: false,
    menuAccountOwnerTrash: false,
    menuAccountAdminTrash: false,
    menuTeamAdminTrash: false,
    menuExperiencerDetail: false,
    menuBilling: false,
    menuBillingDetail: false,
    menuTeamGameContact: false,
    menuTeamGame: false,
    menuShop: false,
    menuShopDraft: false,
    menuSchoolGym: false,
    menuReport: false
  });
  const translate = useTranslate();
  const { permissions } = usePermissions();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const role = permissions?.role;
  const permission = permissions?.list_permission as PermissionsType;

  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const client = useSelector((appState: AppState) => appState.client);
  const user = useSelector((appState: AppState) => appState.user);
  // if (!user) {
  //   return null;
  // }
  return (
    <div>
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuAccount")}
          isOpen={state.menuAccount}
          sidebarIsOpen={open}
          name="menu.account.account_menu"
          icon={<SvgIcon component={AccountIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/data_account`}
              primaryText={translate(`menu.account.account_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/account_place`}
              primaryText={translate(`menu.account_place.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuShop")}
          isOpen={state.menuShop}
          sidebarIsOpen={open}
          name="menu.shop.shop_menu"
          icon={<SvgIcon component={TeamIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/shop`}
              primaryText={translate(`menu.shop.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/school_gym`}
              primaryText={translate(`menu.school_gym.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/school_gym_draft`}
              primaryText={translate(`menu.school_gym_draft.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTeam")}
          isOpen={state.menuTeam}
          sidebarIsOpen={open}
          name="menu.team.team_menu"
          icon={<SvgIcon component={TeamIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_list`}
              primaryText={translate(`menu.team.team_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_draft`}
              primaryText={translate(`menu.team_draft.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_staff`}
              primaryText={translate(`menu.team_staff.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_place_view`}
              primaryText={translate(`menu.team_place.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuContactEventTeam")}
          isOpen={state.menuContactEventTeam}
          sidebarIsOpen={open}
          name="menu.menuContactEventTeam.menu"
          icon={<SvgIcon component={UserTeamEx} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_contact_list',
                search: `filter=${JSON.stringify({ contact_type: 1 })}`,
              }}
              primaryText={translate(`menu.menuContactEventTeam.team_contact_type_1`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/event_contact_list',
                search: `filter=${JSON.stringify({ contact_type: 2 })}`,
              }}
              primaryText={translate(`menu.menuContactEventTeam.event_contact_type_2`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuExperiencerDetail")}
          isOpen={state.menuExperiencerDetail}
          sidebarIsOpen={open}
          name="menu.menuExperiencerDetail.menu"
          icon={<SvgIcon component={UserTeamEx} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_contact_list',
                search: `filter=${JSON.stringify({ "contact_type@_neq": 1 })}`,
              }}
              primaryText={translate(`menu.menuExperiencerDetail.team_contact_type_2`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              //to={`/team_experiencer`}
              to={{
                pathname: '/team_experiencer_list',
                search: `filter=${JSON.stringify({ lastname: '' })}`,
              }}
              primaryText={translate(`menu.menuExperiencerDetail.team_experiencer_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/event_contact_list',
                search: `filter=${JSON.stringify({ contact_type: 1 })}`,
              }}
              primaryText={translate(`menu.menuExperiencerDetail.event_contact_type_1`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              //to={`/event_application`}
              to={{
                pathname: '/event_application_list',
                search: `filter=${JSON.stringify({ lastname: '' })}`,
              }}
              primaryText={translate(`menu.menuExperiencerDetail.event_application_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}


        </SubMenu>
      )}
      {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
        <SubMenu
          handleToggle={() => handleToggle("menuReport")}
          isOpen={state.menuReport}
          sidebarIsOpen={open}
          name="menu.join_reported.menu"
          icon={<SvgIcon component={TeamGameIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_experiencer_list',
                search: `filter=${JSON.stringify({ join_reported: true, join_declined: false })}`,
              }}
              primaryText={translate(`menu.join_reported.submenu_1`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && ((permission?.permission_genre == true && permission?.permission_create_edit == true) || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_experiencer_list',
                search: `filter=${JSON.stringify({ join_reported: true, join_declined: true })}`,
              }}
              primaryText={translate(`menu.join_reported.submenu_2`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
        <SubMenu
          handleToggle={() => handleToggle("menuGenre")}
          isOpen={state.menuGenre}
          sidebarIsOpen={open}
          name="menu.genre.genre_menu"
          icon={<SvgIcon component={GenreIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/genre`}
              primaryText={translate(`menu.genre.genre_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && ((permission?.permission_genre == true && permission?.permission_create_edit == true) || permission.permission_all)) && (
            <MenuItemLink
              to={`/genre/create`}
              primaryText={translate(`menu.user.user_add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
        <SubMenu
          handleToggle={() => handleToggle("menuGenreSport")}
          isOpen={state.menuGenreSport}
          sidebarIsOpen={open}
          name="menu.genre_sport.menu"
          icon={<SvgIcon component={GenreIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_genre == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/genre_sport`}
              primaryText={translate(`menu.genre_sport.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && ((permission?.permission_genre == true && permission?.permission_create_edit == true) || permission.permission_all)) && (
            <MenuItemLink
              to={`/genre_sport/create`}
              primaryText={translate(`menu.genre_sport.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuPermission")}
          isOpen={state.menuPermission}
          sidebarIsOpen={open}
          name="menu.permission.menu"
          icon={<SvgIcon component={UserPointIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          <MenuItemLink
            to={`/permission-list`}
            primaryText={translate(`menu.permission.list`, {
              smart_count: 2,
            })}
            leftIcon={<ArrowRightIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {/* {role === "admin" && (
            <MenuItemLink
              to={`/permission-draft`}
              primaryText={translate(`menu.permission.draft`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )} */}
        </SubMenu>
      )}

      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuAccountAdmin")}
          isOpen={state.menuAccountAdmin}
          sidebarIsOpen={open}
          name="menu.account_admin.menu"
          icon={<SvgIcon component={AccountAdminIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/account_admin`}
              primaryText={translate(`menu.account_admin.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {/* {role === "admin" && (
            <MenuItemLink
              to={`/account_admin/create`}
              primaryText={translate(`menu.account_admin.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )} */}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTeamAdmin")}
          isOpen={state.menuTeamAdmin}
          sidebarIsOpen={open}
          name="menu.team_admin.menu"
          icon={<SvgIcon component={TeamAdminIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_admin`}
              primaryText={translate(`menu.team_admin.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {/* {role === "admin" && (
            <MenuItemLink
              to={`/account_admin/create`}
              primaryText={translate(`menu.account_admin.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )} */}
        </SubMenu>
      )}

      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuCharacter")}
          isOpen={state.menuCharacter}
          sidebarIsOpen={open}
          name="menu.character.menu"
          icon={<SvgIcon component={CharacterIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/character`}
              primaryText={translate(`menu.character.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/character/create`}
              primaryText={translate(`menu.character.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      { /* team game  */}
      {/* {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTeamGame")}
          isOpen={state.menuTeamGame}
          sidebarIsOpen={open}
          name="menu.team_game.menu"
          icon={<SvgIcon component={TeamGameIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_game`}
              primaryText={translate(`menu.team_game.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_game/create`}
              primaryText={translate(`menu.team_game.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )} */}
      { /* team game contact */}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTeamGameContact")}
          isOpen={state.menuTeamGameContact}
          sidebarIsOpen={open}
          name="menu.team_game_contact.menu"
          icon={<SvgIcon component={TeamGameContactIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_game`}
              primaryText={translate(`menu.team_game.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_game_contact',
                search: `filter=${JSON.stringify({ "team_game_id@_is_null": false })}`,
              }}
              primaryText={translate(`menu.team_game_contact.list_null`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={{
                pathname: '/team_game_contact',
                search: `filter=${JSON.stringify({ "team_game_id@_is_null": true })}`,
              }}
              primaryText={translate(`menu.team_game_contact.list_not_null`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

        </SubMenu>
      )}

      <SubMenu
        handleToggle={() => handleToggle("menuUser")}
        isOpen={state.menuUser}
        sidebarIsOpen={open}
        name="menu.user.user_menu"
        icon={
          <SvgIcon component={UserListIcon} viewBox="0 0 16.875 13.125" />
        }
        dense={dense}
      >
        {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
          <MenuItemLink
            to={`/user_list`}
            primaryText={translate(`menu.user.user_list`, {
              smart_count: 2,
            })}
            leftIcon={<ArrowRightIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        )}
      </SubMenu>
      {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
        <SubMenu
          handleToggle={() => handleToggle("menuAccountOwner")}
          isOpen={state.menuAccountOwner}
          sidebarIsOpen={open}
          name="menu.account_owner.menu"
          icon={
            <SvgIcon component={AccountOwnerIcon} viewBox="0 0 16.875 13.125" />
          }
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/account_owner`}
              primaryText={translate(`menu.account_owner.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuUserRemoveAdmin")}
          isOpen={state.menuUserRemoveAdmin}
          sidebarIsOpen={open}
          name="menu.user_remove_admin.menu"
          icon={
            <SvgIcon component={AccountOwnerIcon} viewBox="0 0 16.875 13.125" />
          }
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/user_remove_admin`}
              primaryText={translate(`menu.user_remove_admin.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTemplate")}
          isOpen={state.menuTemplate}
          sidebarIsOpen={open}
          name="menu.email_template.email_template_menu"
          icon={<SvgIcon component={GenreSportIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/template`}
              primaryText={translate(`menu.email_template.email_template_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/template/create`}
              primaryText={translate(`menu.email_template.email_template_add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}

      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuEvent")}
          isOpen={state.menuEvent}
          sidebarIsOpen={open}
          name="menu.event.event_menu"
          icon={<SvgIcon component={EventIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/event`}
              primaryText={translate(`menu.event.event_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/event_draft`}
              primaryText={translate(`menu.event_draft.event_draft_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/event_place`}
              primaryText={translate(`menu.event_place.event_place_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/event_plan`}
              primaryText={translate(`menu.event_plan.event_plan_list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

          {/* {role === "admin" && (
            <MenuItemLink
              to={`/event/create`}
              primaryText={translate(`menu.event.event_add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )} */}
        </SubMenu>
      )}
      {/* {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuEventPlace")}
          isOpen={state.menuEventPlace}
          sidebarIsOpen={open}
          name="menu.event.event_menu"
          icon={<SvgIcon component={EventPlaceIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          <MenuItemLink
            to={`/event_place`}
            primaryText={translate(`menu.event_place.event_place_list`, {
              smart_count: 2,
            })}
            leftIcon={<ArrowRightIcon/>}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
          {role === "admin" && (
            <MenuItemLink
              to={`/event_place/create`}
              primaryText={translate(`menu.event_place.event_place_add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )} */}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuSposuruNews")}
          isOpen={state.menuSposuruNews}
          sidebarIsOpen={open}
          name="menu.sposuru_news.menu"
          icon={<SvgIcon component={SposuruNewsIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/sposuru_news`}
              primaryText={translate(`menu.sposuru_news.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/sposuru_news/create`}
              primaryText={translate(`menu.sposuru_news.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuQuestion")}
          isOpen={state.menuQuestion}
          sidebarIsOpen={open}
          name="menu.question.menu"
          icon={<SvgIcon component={QuestionIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/question`}
              primaryText={translate(`menu.question.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/question/create`}
              primaryText={translate(`menu.question.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuAnswer")}
          isOpen={state.menuAnswer}
          sidebarIsOpen={open}
          name="menu.answer.menu"
          icon={<SvgIcon component={AnswerIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/answer`}
              primaryText={translate(`menu.answer.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/answer/create`}
              primaryText={translate(`menu.answer.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}

      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTeamReview")}
          isOpen={state.menuTeamReview}
          sidebarIsOpen={open}
          name="menu.team_review.menu"
          icon={<SvgIcon component={TeamReviewIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_review_list`}
              primaryText={translate(`menu.team_review.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/team_review/create`}
              primaryText={translate(`menu.team_review.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuContact")}
          isOpen={state.menuContact}
          sidebarIsOpen={open}
          name="menu.contact.menu"
          icon={<SvgIcon component={ContactIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/contact`}
              primaryText={translate(`menu.contact.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/contact/create`}
              primaryText={translate(`menu.contact.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuPointType")}
          isOpen={state.menuPointType}
          sidebarIsOpen={open}
          name="menu.point_type.menu"
          icon={<SvgIcon component={PointTypeIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/point_type`}
              primaryText={translate(`menu.point_type.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/point_type/create`}
              primaryText={translate(`menu.point_type.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuBilling")}
          isOpen={state.menuBilling}
          sidebarIsOpen={open}
          name="menu.billing.menu"
          icon={<SvgIcon component={BillIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/billing_view`}
              primaryText={translate(`menu.billing.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}

        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuUserPoint")}
          isOpen={state.menuUserPoint}
          sidebarIsOpen={open}
          name="menu.user_point.menu"
          icon={<SvgIcon component={UserPointIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/user_point`}
              primaryText={translate(`menu.user_point.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/user_point/create`}
              primaryText={translate(`menu.user_point.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuPointExchange")}
          isOpen={state.menuPointExchange}
          sidebarIsOpen={open}
          name="menu.point_exchange.menu"
          icon={<SvgIcon component={UserPointIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/point_exchange`}
              primaryText={translate(`menu.point_exchange.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {(role === "admin" && (permission?.permission_create_edit == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/point_exchange/create`}
              primaryText={translate(`menu.point_exchange.add`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "client_admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuDataAccountTrash")}
          isOpen={state.menuDataAccountTrash}
          sidebarIsOpen={open}
          name="menu.data_account_trash.menu"
          icon={<SvgIcon component={UserPointIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {(role === "admin" && (permission?.permission_view == true || permission.permission_all)) && (
            <MenuItemLink
              to={`/data_account_trash`}
              primaryText={translate(`menu.data_account.list`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}
      {role === "admin" && (
        <SubMenu
          handleToggle={() => handleToggle("menuTrash")}
          isOpen={state.menuTrash}
          sidebarIsOpen={open}
          name="menu.trash.menu"
          icon={<SvgIcon component={UserPointIcon} viewBox="0 0 16.875 13.125" />}
          dense={dense}
        >
          {role === "admin" && (
            <MenuItemLink
              to={`/permission-draft`}
              primaryText={translate(`menu.permission.draft`, {
                smart_count: 2,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/data_account_trash`}
              primaryText={translate(`menu.account.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/team_trash`}
              primaryText={translate(`menu.team.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/event_trash`}
              primaryText={translate(`menu.event.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/team_review_trash`}
              primaryText={translate(`menu.team_review.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/user_trash`}
              primaryText={translate(`menu.user.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/account_owner_trash`}
              primaryText={translate(`menu.account_owner.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/account_admin_trash`}
              primaryText={translate(`menu.account_admin.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={`/team_admin_trash`}
              primaryText={translate(`menu.team_admin.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={{
                pathname: '/question',
                search: `filter=${JSON.stringify({ is_soft_deleted: true })}`,
              }}
              primaryText={translate(`menu.question.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
          {role === "admin" && (
            <MenuItemLink
              to={{
                pathname: '/answer',
                search: `filter=${JSON.stringify({ is_soft_deleted: true })}`,
              }}
              primaryText={translate(`menu.answer.trash`, {
                smart_count: 3,
              })}
              leftIcon={<ArrowRightIcon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          )}
        </SubMenu>
      )}

      {isXSmall && logout}
    </div>
  );
};

export default Menu;
