import * as React from "react";
import {
  BooleanInput,
  Button,
  Edit,
  email,
  FormTab,
  minLength,
  PasswordInput,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TabbedForm,
  TextInput,
  Toolbar,
  usePermissions,
  useRedirect,
  useRefresh,
  useTranslate,
  useDataProvider,
  useNotify,
  Loading,
  DateInput
} from "react-admin";
import { Box, Typography, Container } from "@material-ui/core";
import { AppState, PermissionsType } from "../../types";
import { updateUser } from "../../configuration/actions";
import { useDispatch, useSelector } from "react-redux";
import { buildRoleOptions } from "../../utils/Utils";
import EditField from "../components/editField";
import { useApolloClient } from "@apollo/client";
import { AutocompleteInput } from "react-admin";
import GenreSportInput from "./GenreSportInput";
import { useEffect, useState } from "react";
import { useForm } from 'react-final-form';

export const validatePasswords = ({ password, confirm_password }: any) => {
  if (!password || !confirm_password) {
    return
  }
  const errors = {} as any;
  if (password !== confirm_password) {
    errors.confirm_password = ["common.msg_confirm_not_match"];
  }
  return errors;
};

const UserEdit = (props: any) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const cancelButton = () => {
    redirect("/user");
    refresh();
  };

  const { loaded } = usePermissions();

  // Check current user is me
  const id = Number(props.id);
  const apolloClient = useApolloClient();
  const user = useSelector((appState: AppState) => appState.user);
  const isMe = user?.id === id;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { permissions } = usePermissions();
  const role = permissions?.role;
  const permission = permissions?.list_permission as PermissionsType;
  const [listGenreSport, setListGenreSport] = useState<any[]>([]);
  useEffect(() => {
    
    const listGenre = [];
    //const favorite_genre_sport_id1 = props.
    //listGenre.push()
  }, [props]);
  const transform = (data: any) => {
    if (isMe) {
      const userData = {
        id: data.id,
        first_name: data.first_name,
        last_name: data.last_name,
        full_name: `${data.last_name} ${data.first_name}`,
        first_name_kana: data.first_name_kana,
        last_name_kana: data.last_name_kana,
        full_name_kana: `${data.last_name_kana} ${data.first_name_kana}`,
        role: data.role
      };
      localStorage.setItem('user', JSON.stringify(userData));
      dispatch(updateUser(userData));
    }
    delete data.confirm_password;
    if (data.password === '') {
      delete data.password;
    }
    return data;
  };

  const UserCreateToolbar = (props: any) => (
    <Toolbar
      {...props}
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      {role === "user" && !isMe ?
        <SaveButton
          label={translate("common.btn_update")}
          submitOnEnter={false}
          disabled
        /> :
        <SaveButton
          label={translate("common.btn_update")}
          submitOnEnter={false}
          disabled={props.pristine}
        />
      }
      <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" />
    </Toolbar>
  );

  const passwordFormatter = (password: string) => {
    if (password && password.startsWith('$argon2id')) {
      return '';
    }
    return password;
  };

  if (!loaded) {
    return <Loading />
  }

  return (
    <div className="formContent">
      <Container maxWidth="sm">
        <SectionTitle label="user_list.update_user_title" />
        <Edit
          transform={transform}
          title={<EditField titlePage={translate('menu.user.user_menu')} />}
          undoable={false}
          successMessage="common.msg_update_success"
          {...props}
        >
          <TabbedForm
            redirect="list"
            toolbar={<UserCreateToolbar />}
            className="form"
            validate={validatePasswords}
          >
            <FormTab label={translate("user_list.tab_profile")} style={{ width: '50%', maxWidth: 'inherit' }}>
              <Separator />
              <Box display={{ md: "block", lg: "flex" }} style={{ width: "100%" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <TextInput
                      source="lastname"
                      validate={requiredValidate}
                      resource="user"
                      fullWidth
                      label={translate("user_list.lastname")}
                      variant="outlined"
                      require={required()}
                      disabled={!(role === "admin" || role === "client_admin" || isMe)}
                    />
                    <TextInput
                      source="firstname"
                      resource="user"
                      validate={requiredValidate}
                      fullWidth
                      label={translate("user_list.firstname")}
                      variant="outlined"
                      require={required()}
                      disabled={!(role === "admin" || role === "client_admin" || isMe)}
                      style={{ marginLeft: "10px" }}
                    />
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <TextInput
                      source="lastname_kana"
                      fullWidth
                      label={translate("user_list.lastname_kana")}
                      variant="outlined"
                    />
                    <TextInput
                      source="firstname_kana"
                      fullWidth
                      label={translate("user_list.firstname_kana")}
                      variant="outlined"
                      style={{ marginLeft: "10px" }}
                    />
                  </Box>
                  <TextInput
                    type="email"
                    source="email"
                    disabled
                    label={translate("user_list.email")}
                    resource="user"
                    validate={[email(), required()]}
                    fullWidth
                    variant="outlined"
                  />
                  <TextInput
                    source="post_code"
                    resource="user"
                    fullWidth
                    label={translate("user_list.lastname_kana")}
                    variant="outlined"
                  />

                  <TextInput
                    source="pref"
                    resource="user"
                    fullWidth
                    label={translate("user_list.pref")}
                    variant="outlined"
                  />
                  <TextInput
                    source="city"
                    resource="user"
                    fullWidth
                    label={translate("user_list.city")}
                    variant="outlined"
                  />
                  <TextInput
                    source="nickname"
                    resource="user"
                    fullWidth
                    label={translate("user_list.nickname")}
                    variant="outlined"
                  />
                  <TextInput
                    source="phone"
                    resource="user"
                    fullWidth
                    options={{ autoComplete: "new-password" }}
                    variant="outlined"
                    disabled={!(role === "admin" || role === "client_admin" || isMe)}
                    label={translate("user_list.phone")}
                  />
                  <TextInput
                    source="address"
                    resource="user"
                    fullWidth
                    options={{ autoComplete: "new-password" }}
                    variant="outlined"
                    disabled={!(role === "admin" || role === "client_admin" || isMe)}
                    label={translate("user_list.address")}
                  />
                  <SelectInput
                    label={translate("common.gender")}
                    source="gender"
                    resource="user"
                    fullWidth
                    variant="outlined"
                    choices={[
                      { id: 0, name: translate("common.unknow") },
                      { id: 1, name: translate("common.male") },
                      { id: 2, name: translate("common.female") },
                      { id: 3, name: translate("common.no_choice") },
                    ]}
                  />
                  {/* {((role ==='admin') || (role ==='client_admin')) &&
                    <SelectInput
                      source="role"
                      label={translate('role.title')}
                      fullWidth
                      validate={[required()]}
                      variant="outlined"
                      choices={buildRoleOptions(permissions, translate)}
                    />} */}
                  <GenreSportInput {...props}/>
                  <DateInput
                    label={translate("user_list.birthday")}
                    source="birthday"
                    resource="user"
                    fullWidth
                    variant="outlined"
                  />
                  {(role === "admin" || role === 'client_admin') && (
                    <SelectInput
                      label={translate("common.status")}
                      source="status"
                      resource="user"
                      fullWidth
                      variant="outlined"
                      choices={[
                        { id: "0", name: translate("common.status_inactive") },
                        { id: "1", name: translate("common.status_active") },
                      ]}
                    />
                  )}
                  {/* {(role ==="admin" || role ==="client_admin") && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <BooleanInput
                        label={translate("user_list.email_verified")}
                        source="email_verified"
                      />
                    </Box>
                  )}
                  {role ==="admin" && (
                    <BooleanInput
                      label={translate("user_list.is_admin")}
                      source="is_admin"
                    />
                  )} */}
                </Box>
              </Box>
            </FormTab>
            <FormTab label={translate("user_list.tab_password")} path="password" style={{ width: '50%', maxWidth: 'inherit' }}>
              <Separator />
              <PasswordInput
                source="password"
                fullWidth
                variant="outlined"
                format={passwordFormatter}
                validate={minLength(6)}
                disabled={!(role === "admin" || role === "client_admin" || isMe)}
                label={translate("user_list.password")}
                inputProps={{ autoComplete: 'newsss_edit_password' }}
              />
              <PasswordInput
                source="confirm_password"
                fullWidth
                disabled={!(role === "admin" || role === "client_admin" || isMe)}
                variant="outlined"
                label={translate("user_list.confirm_password")}
                inputProps={{ autoComplete: 'newsss_edit_passwordconfirm' }}
              />
            </FormTab>
          </TabbedForm>
        </Edit>
      </Container>
    </div>
  );
};

const requiredValidate = [required()];


const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom style={{ paddingTop: '50px', maxWidth: '600px', margin: 'auto' }}>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="3em" />;
export default UserEdit;
