import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';

const CustomField = (props: any) => {
  const { record } = props;
  // return <span>N{record['id'] + ' ' + record['name']}</span>;
  return <span>{(record['name'])} </span>;
}
const CustomReferFieldFullName = (props: any) => {
  return (
  <>
    <ReferenceField {...props}>
      <ReferenceField source ={'account_id'} link={false} reference = 'account'>
          <CustomField />
      </ReferenceField>
    </ReferenceField>
  </>)
}
CustomReferFieldFullName.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
  link: PropTypes.bool
};

export default CustomReferFieldFullName;