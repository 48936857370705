import React, {useEffect, useState} from 'react'
import {
    Edit,
    FormDataConsumer,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    useTranslate,
} from 'react-admin';
import {useForm} from 'react-final-form';
import {Box, CardContent, Typography} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser'
import {buildPreview} from "./buildActions";
import EditField from '../components/editField';
import { useApolloClient } from "@apollo/client";
import { GET_TEMPLATE_EMAIL } from './gql';
import UpdateToolbarEmail from './UpdateToolbarEmail';


const STATUS = [
    {id: 0, name: "無効"},
    {id: 1, name: "有効"},
  ]

const EmailEdit = (props: any) => {
    const translate = useTranslate();

    return (
        <Edit 
            {...props} 
            undoable={false} 
            successMessage={translate('common.msg_update_success')}  
            title={<EditField titlePage={translate('menu.email_template.email_template_menu')}/>}>
            <SimpleForm redirect="list" toolbar={<UpdateToolbarEmail />} className="form">
                <FormDataConsumer>
                    {(formDataProps: any) => (
                        <EmailGroupForm {...formDataProps} />
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Edit>
    );
};


const EmailGroupForm = (props: any) => {
    const {formData} = props;
    const translate = useTranslate();
    const [subject, setSubject] = useState("")
    const form = useForm();
    const apolloClient = useApolloClient();
    
    const [preview, setPreview] = useState<string | null>(null);
    const { record } = props;
    useEffect( () => {
        setSubject(record.subject);
        const GetDataTemplate = async () => {
            const data = await apolloClient.mutate({
                mutation: GET_TEMPLATE_EMAIL,
                variables: {
                    id: record.id
                },
              });
              const {data: {get_email_template}} = data;       
              setSubject(get_email_template.subject);
              setPreview(buildPreview(record.virtual_body, record.params));
              return get_email_template.plain_content
        }
        GetDataTemplate();
    },[]);

    //get data email
    return (
        <form>
            <CardContent>
                <SectionTitle label={translate('menu.email_template.page_edit')} id = {formData.id} />
                <Separator />
                <Box display={{ md: 'block', lg: 'flex' }} style={{ width: '100%' }}>
                    <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                        <TextInput
                            autoFocus
                            source="name"
                            validate={requiredValidate}
                            label={translate('email_template.name')}
                            fullWidth
                            variant="outlined"
                            resource="template"
                        />
                         <SelectInput
                            source="status"
                            validate={requiredValidate}
                            resource="template"
                            choices={STATUS}
                            fullWidth
                            variant="outlined"
                            label={translate('email_template.status')}
                        />
                        <TextInput
                            source="action_key"
                            validation={{ email: true }}
                            validate={[required()]}
                            fullWidth
                            disabled
                            label={translate('email_template.action_key')}
                            variant="outlined"
                        />
                        <TextInput
                            source="subject"
                            //validate={[required()]}
                            fullWidth
                            label={translate('email_template.subject')}
                            variant="outlined"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                form.change('subject',event.target.value)
                                setSubject(event.target.value)
                            }}
                        />
                        <TextInput
                            source="params"
                            fullWidth
                            multiline
                            rows={5}
                            resource="template"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const { values } = form.getState();
                                setPreview(buildPreview(values.virtual_body, event.target.value));
                            }}
                            variant="outlined"
                            label={translate('email_template.params')}
                        />
                        <TextInput
                            source="virtual_body"
                            fullWidth
                            multiline
                            rows={20}
                            resource="template"
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const { values } = form.getState();
                                const preview = buildPreview(values.virtual_body, values.params);
                                setPreview(preview);
                            }}
                            variant="outlined"
                            label={translate('email_template.body')}
                        />
                    </Box>
                    <Box flex={1} mr={{ md: 0, lg: '1em' }} style={{ width: '50%' }}>
                        {preview && ReactHtmlParser(preview)}
                    </Box>
                </Box>
            </CardContent>
        </form>
    );
};

const requiredValidate = [required()];

const SectionTitle = ({ label,id }: { label: string,id: number }) => {
    const translate = useTranslate();
    return (
        <Typography variant="h6" gutterBottom>
            {label}ID {id} の編集
        </Typography>
    );
};

const Separator = () => <Box pt="1em" />;
export default EmailEdit;


