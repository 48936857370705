import * as React from 'react';
import { Chip, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { Gear } from './type'
import { NumberInput } from 'react-admin';
import { TextInput } from 'react-admin';

const ListGear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const GearField = ({ props }: { props: any }) => {
    const form = useForm();
    const dataGear = props.formData.gear as Gear[]
    var gearForm = form.getState().values.gear as Gear[];

    if (dataGear && dataGear.length > 0) {
        for (var index = 0; index < dataGear.length; index++) {
            let name = dataGear[index].name;
            let price = dataGear[index].price;
            form.change(`gear_name_${index + 1}`, name)
            form.change(`gear_price_${index + 1}`, price)
        }
    }
    const translate = useTranslate();
    return <Grid className='Gear-Group'>
        <Grid className='header-gear-group'>
            <div>
                <span>初期購入品</span>
            </div>
            <div>
                <span>金額</span>
            </div>
        </Grid>
        {
            ListGear.map((id, idx) => {
                const dataGearCurrent = dataGear ? dataGear[idx] : null;
                return <Grid className='body-gear-group'>
                    <Grid style={{ width: '70%' }}>
                        <TextInput
                            source={`gear_name_${id}`}
                            className={`gear_name`}
                            label={translate(`team.gear_name_${id}`)}
                            fullWidth
                            variant="outlined"
                            onChange={(event: any) => {
                                if (dataGearCurrent) {
                                    dataGear[idx].name = event.target.value;
                                    form.change(`gear_name_${id}`, event.target.value)
                                } else {
                                    const price = form.getState().values[`gear_price_${id}`]
                                    const dataPush: Gear = {name: event.target.value, price:price }
                                    dataGear.push(dataPush)
                                    form.change(`gear_name_${id}`, event.target.value)
                                }
                            }}
                        />
                    </Grid>
                    <Grid style={{ width: '30%', marginLeft: '10px' }}>
                        <NumberInput
                            className={`gear_price`}
                            source={`gear_price_${id}`}
                            label={translate(`team.gear_price_${id}`)}
                            fullWidth
                            min={0}
                            variant="outlined"
                            onChange = {(event:any) => {
                                if (dataGearCurrent) {
                                    dataGear[idx].price = event.target.value;
                                    form.change(`gear_price_${id}`, event.target.value)
                                } else {
                                    const name = form.getState().values[`gear_name_${id}`]
                                    const dataPush: Gear = {name:name , price: event.target.value }
                                    dataGear.push(dataPush)
                                    form.change(`gear_price_${id}`, event.target.value)
                                }
                            }}
                        />
                    </Grid>
                    <span style={{ marginTop: '15px', marginLeft: '10px' }}>円</span>
                </Grid>
            })
        }

    </Grid>
}
export default GearField;
