import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields, {ImageFields} from './dataset';


const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  return <Container>
    <SectionTitle label={editForm ? "menu.team.team_edit" : "menu.team.team_add"} />
    <Separator />
    <Separator />
    <Grid container item justify='center' spacing={4}>
      <Grid container xs={8} spacing={2}>
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          return <Grid item xs={6} key={idx}>
            <InputRenderer source='team' data={d} />
          </Grid>
        })}
      </Grid>
      <Grid xs={4} container item spacing={2} alignContent="flex-start">
        {ImageFields.map((d, idx) => 
          <Grid item xs={12} key={idx}>
            <InputRenderer source='team' data={d} />
          </Grid>)
        }
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;