import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    DateField,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput,
    SelectField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import { NumberInput } from 'react-admin';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ReferenceField } from 'react-admin';
import LinkToUserPoint from './LinkToUserPoint';

const exporter = async (items: any, fetchRelatedRecords: any, translate: any) => {
    let data = items;
    await fetchRelatedRecords(data, 'favorite_genre_sport_id1', 'genre_sport').then((genre_sport: any) => {
        const data_genre = data.map((item: any) => (
          {
            ...item,
            favorite_genre_sport_id1: genre_sport[item.favorite_genre_sport_id1] ? genre_sport[item.favorite_genre_sport_id1].secondary_name : '',
          }
        ));
        data = data_genre;
      });
      await fetchRelatedRecords(data, 'favorite_genre_sport_id2', 'genre_sport').then((genre_sport: any) => {
        const data_genre = data.map((item: any) => (
          {
            ...item,
            favorite_genre_sport_id2: genre_sport[item.favorite_genre_sport_id2] ? genre_sport[item.favorite_genre_sport_id2].secondary_name : '',
          }
        ));
        data = data_genre;
      });
      await fetchRelatedRecords(data, 'favorite_genre_sport_id3', 'genre_sport').then((genre_sport: any) => {
        const data_genre = data.map((item: any) => (
          {
            ...item,
            favorite_genre_sport_id3: genre_sport[item.favorite_genre_sport_id3] ? genre_sport[item.favorite_genre_sport_id3].secondary_name : '',
          }
        ));
        data = data_genre;
      });
      await fetchRelatedRecords(data, 'favorite_genre_sport_id4', 'genre_sport').then((genre_sport: any) => {
        const data_genre = data.map((item: any) => (
          {
            ...item,
            favorite_genre_sport_id4: genre_sport[item.favorite_genre_sport_id4] ? genre_sport[item.favorite_genre_sport_id4].secondary_name : '',
          }
        ));
        data = data_genre;
      });
      await fetchRelatedRecords(data, 'favorite_genre_sport_id5', 'genre_sport').then((genre_sport: any) => {
        const data_genre = data.map((item: any) => (
          {
            ...item,
            favorite_genre_sport_id5: genre_sport[item.favorite_genre_sport_id5] ? genre_sport[item.favorite_genre_sport_id5].secondary_name : '',
          }
        ));
        data = data_genre;
      });
    var itemsForExport = data.map((item: any) => {
        const status = item.status == 0 ? translate("common.status_inactive") : translate("common.status_active");
        let gender = '';
        switch (item.gender) {
            case 0:
                gender = '不明'
                break;
            case 1:
                gender = '男性'
                break;
            case 2:
                gender = '女性'
                break;
            case 3:
                gender = '未選択'
                break;
        }

        return [item.id, item.lastname, item.firstname,item.lastname_kana, item.firstname_kana,  item.email,item.amount,item.post_code, item.pref, item.city, item.birthday, item.age,status,item.favorite_genre_sport_id1,item.favorite_genre_sport_id2,item.favorite_genre_sport_id3,item.favorite_genre_sport_id4,item.favorite_genre_sport_id5,item.phone, item.address, gender, item.nickname, item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a') : item.created, item.varified ? moment(item.varified).format('YYYY-MM-DD h:mm:ss a') : item.varified];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        encoding: 'CP932',
        fields: ["ID","姓", "名", "セイ", "メイ",  'メールアドレス','ポイント集計結果', '郵便番号', '都道府県', '市区町村', "生年月日",'年齢', 'ステータス','好きなスポーツ1','好きなスポーツ2','好きなスポーツ3','好きなスポーツ4','好きなスポーツ5', '電話番号', '住所', "性別", 'ニックネーム','メールアドレス登録日','メールアドレス確認日'],
    });
    downloadCSV(csv, 'User_No_Role');
};

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role ==='client_admin' || role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};

const UserFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="note,firstname,lastname,firstname_kana,lastname_kana,email,post_code,pref,city,address,phone,nickname" alwaysOn />
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/user_list';
    },[props]);
    const STATUS = [
        { id: 0, name: translate("common.status_inactive") },
        { id: 1, name: translate("common.status_active") },
    ]
    const GENDER = [
        { id: 0, name: '不明' },
        { id: 1, name: '男性' },
        { id: 2, name: '女性' },
        { id: 3, name: '未選択' },

    ]
    if (role ===undefined) {
        return null;
    }
    return (
        <List
            {...props}
            filters={<UserFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            filterDefaultValues={{ kuchikomi_user: true, deleted: false, is_soft_deleted: false }}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, fetchRelatedRecords, translate)}
            actions={<ListActions to='user_list' />}
            empty={<ListEmpty />}
            //filterDefaultValues={{ status: 1 }}
            title={translate('menu.user.user_list')}
        >
            <CustomizableDatagrid rowClick={(id: any, resource: any, record: any) => `/user/${id}`}>
                <TextField source="id" label={translate('user_list.id')} />
                <TextField source="lastname" label={translate('user_list.lastname')} />
                <TextField source="firstname" label={translate('user_list.firstname')} />
                <TextField source="lastname_kana" label={translate('user_list.lastname_kana')} />
                <TextField source="firstname_kana" label={translate('user_list.firstname_kana')} />
                <EmailField source="email" label={translate('user_list.email')} />
                <LinkToUserPoint source={"amount"} label={translate('user_list.amount')} />
                <TextField source="post_code" label={translate('user_list.post_code')} />
                <TextField source="pref" label={translate('user_list.pref')} />
                <TextField source="city" label={translate('user_list.city')} />
                <TextField source="birthday" label={translate('user_list.birthday')} />
                <TextField source="age" label={translate('user_list.age')} />
                <SelectField source="status" choices={STATUS} label={translate('user_list.status')} />
                <TextField source="phone" label={translate('user_list.phone')} />
                <TextField source="address" label={translate('user_list.address')} />
                <SelectField source="gender" choices={GENDER} label={translate('user_list.gender')} />
                <TextField source="nickname" label={translate('user_list.nickname')} />
                <ReferenceField source="favorite_genre_sport_id1" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id1')} >
                     <TextField source={'secondary_name'} /> 
                </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id2" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id2')} >
                     <TextField source={'secondary_name'} /> 
                </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id3" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id3')} >
                     <TextField source={'secondary_name'} /> 
                </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id4" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id4')} >
                     <TextField source={'secondary_name'} /> 
                </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id5" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id5')} >
                     <TextField source={'secondary_name'} /> 
                </ReferenceField>
                {/* <ReferenceField source="favorite_genre_sport_id2" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id2')} > <TextField source={'secondary_name'} /> </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id3" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id3')} ><TextField source={'secondary_name'} /> </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id4" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id4')} ><TextField source={'secondary_name'} /> </ReferenceField>
                <ReferenceField source="favorite_genre_sport_id5" reference="genre_sport" label={translate('user_list.favorite_genre_sport_id5')} ><TextField source={'secondary_name'} /> </ReferenceField> */}
                <DateField source="created" label={translate('user_list.created_email')} showTime={true} locales="ja-JP" />
                <DateField source="varified" label={translate('user_list.varified')} showTime={true} locales="ja-JP" />
                <TextField source="note" label={translate('user_list.note')} />
            </CustomizableDatagrid>
        </List>
    );
};

export default UserList;
