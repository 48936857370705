import { gql } from "@apollo/client";
export const FETCH_RESELLERS = gql`
  query GetResellers($ids: [Int!]) {
    reseller(where: {id: {_in: $ids}}) {
      id
      account_name
      account_number
      account_type
      zip_code
      other
      address
      bank_branch_code
      bank_branch_name
      bank_code
      bank_name
      name
    }
  }
`;
export const FETCH_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    account(where: {id: {_eq: $id}}) {
        id
        contact_email
        created_by
    }
  }
`;
export const FETCH_TEAM = gql`
  query GetTeam($id: Int!) {
    team(where: {id: {_eq: $id}}) {
        id
        account_id
        contact_email
        created_by
    }
  }
`;
export const APPROVE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!],$varified_by: Int, $approved_at: timestamp) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:2,varified_by:$varified_by, approved_at: $approved_at}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const DISAPPROVE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!],$varified_by: Int) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:4,varified_by:$varified_by}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const UNPUBLISH_TEAM = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const SEND_MAIL_APPROVE_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "APPROVE", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_APPROVE_TEAM_BY_ADMIN = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_account_team(list_team: $list_team) {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_UNPUBLISH_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "UNPUBLISH", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_DISAPPROVE_TEAM = gql`
mutation MyMutation($list_team: [Int] ) {
  send_email_approve_team_team_review(action: "DISAPPROVE", list_team: $list_team, table: "TEAM") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const GET_TEAM_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_in: [1, 4]}}) {
      id
      account_id
      created_by 
    }
}
`;
export const GET_TEAM_ACCESS_UNPUBLISH = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_in: [1]}, account_id: {_is_null: true}}) {
      id
      account_id
    }
}
`;
export const GET_TEAM_NOT_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_nin: [1]}}) {
      id
    }
}
`;
export const GET_TEAM_NOT_ACCESS_UNPUBLISH = gql`
query Myquery($list_team_id: [Int!]) {
    team(where: {id: {_in: $list_team_id}, publish_status: {_nin: [3]}, account_id: {_is_null: false}}) {
      id
    }
}
`;
export const GET_TEAM = gql`
query Myquery($team_id: Int) {
    team(where: {id: {_eq: $team_id}}) {
      id
      account_id
    }
}
`;
export const INSERT_USER_POINT = gql`
mutation MyMutation($user_id: Int, $amount: Int) {
  insert_user_point(objects: {amount: $amount, user_id: $user_id, point_type_id: 7, created_by: 0}) {
    affected_rows
  }
}
`;
export const GET_AMOUNT_USER_POINT = gql`
query Myquery($point_type_id: Int) {
    point_type(where: {id: {_eq: $point_type_id}}) {
      amount
    }
}
`;
export const GET_TEAM_BY_ID = gql`
query MyQuery($team_id: Int!) {
  team_list(where: {id:{_eq:$team_id}}) {
    team_place_name_1
    team_place_name_2
    team_place_name_3
    team_place_id_1
    team_place_id_2
    team_place_id_3
    id
  }
}

`;
export const FETCH_ALL_TEAM_ADMIN = gql`
query MyQuery($account_id: Boolean, $name: String) {
  team(where: {account_id: {_is_null: $account_id} ,name: {_ilike: $name}}) {
    id
    name
  }
}
`;
export const UPDATE_TEAM_REVIEW = gql`
mutation MyMutation($team_id_to: Int, $team_id_from: Int) {
  update_team_review(where: {team_id: {_eq: $team_id_from}}, _set: {team_id: $team_id_to}) {
    affected_rows
  }
}
`;
export const UPDATE_MULTI_TEAM = gql`
mutation MyMutation($team_ids: [Int!], $monthly_fee: Int,
  $attention: Int, 
  $show_account_info: Boolean,
  $practice_days: jsonb,
  $trial_type: Int, 
  $visit_ok:Boolean, 
  $monthly_fee_from: Int, 
  $monthly_fee_to: Int, 
  $monthly_fee_between:Boolean, 
  $admission_fee:Int, 
  $annual_fee: Int, 
  $initial_fee:Int
) {
  update_team(where: {id: {_in: $team_ids}}, _set: {monthly_fee: $monthly_fee, attention:$attention, 
  show_account_info:$show_account_info,
  practice_days:$practice_days,
    trial_type:$trial_type, 
    visit_ok:$visit_ok, 
    monthly_fee_from:$monthly_fee_from, 
    monthly_fee_to:$monthly_fee_to,
    monthly_fee_between:$monthly_fee_between,
    admission_fee: $admission_fee,
    annual_fee:$annual_fee, 
    initial_fee:$initial_fee
  }) {
    affected_rows
  }
}
`;
export const GET_PRESIGN_URL = gql`
mutation MyMutation($filename: String, $foldername: String ) {
  generate_upload_presigned_url(filename: $filename, foldername: $foldername) {
    filename
    presigned_url
  }
}
`;
export const DeleteBulkTeam = gql`
mutation MyMutation($list_team_id: [Int!]) {
  delete_app_user(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_place(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_staff(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_blog(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_chat_room(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_favorite(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_news(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_survey(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_admin(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_app_menu(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_contact(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_draft(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_game(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_game_contact(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_group(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_staff(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team_todo(where: {team_id: {_in: $list_team_id}}) {
    affected_rows
  }
  delete_team(where: {id: {_in: $list_team_id}}) {
    affected_rows
  }
}
`;
export const SOFT_DELETE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_0 = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:0}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_1 = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:1}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_2 = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:2}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_3 = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const CHANGE_STATUS_4 = gql`
mutation MyMutation($list_team_id: [Int!]) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:4}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const FETCH_DATA_USER = gql`
query MyQuery {
  team {
    name
    account {
      name
      user {
        email
        firstname
        lastname
        phone
      }
    }
    id
    created_by
    prefByPref {
      name
    }
    cityByCity {
      name
    }
    areaByArea {
      name
    }
    genre{
      name
    }
    genre_sport {
      secondary_name
    }
  }
}
`;
export const FETCH_ALL_USER = gql`
query MyQuery {
  user {
    lastname
    firstname
    phone
    email
    id
  }
}
`;
export const GET_ACCOUNT_PLACE_BY_TEAM_PLACE_ID = gql`
query MyQuery($team_place_id: Int) {
  team_place(where: {id: {_eq: $team_place_id}}) {
    account_place {
      address
      area
      area_name
      building
      city
      city_name
      line1
      line2
      line3
      line_cd1
      line_cd2
      line_cd3
      name
      note
      post_code
      pref_name
      station1
      station2
      station3
      walk1
      walk2
      walk3
    }
  }
}
`;
export const UPDATE_FLAG_TEAM = gql`
mutation MyMutation($id: Int!, $mail_remind_flag: Boolean) {
  update_team_by_pk(pk_columns: {id: $id}, _set: {mail_remind_flag: $mail_remind_flag}) {
    id
  }
}`;

export const UpdateTeamFlag = async (apolloClient: any, team_id: number, mail_remind_flag: boolean) => {
  let status = 0;
  try {
    let { data: dataUpdateTeam } = await apolloClient.mutate({
      mutation: UPDATE_FLAG_TEAM,
      variables: {
        id: team_id,
        mail_remind_flag: mail_remind_flag
      }
    });
    status = 1;
  } catch (e) {
    status = 0;
  }
  return status;
}
export const GetAllAccountUser = async (apolloClient: any) => {
  let { data: dataUserAccount } = await apolloClient.query({
    query: FETCH_DATA_USER,
    variables: {
    }
  });
  return dataUserAccount.team;
}
export const GetAmountPoint = async (apolloClient: any, point_type_id: number) => {
  let { data: dataUserPointType } = await apolloClient.query({
    query: GET_AMOUNT_USER_POINT,
    variables: {
      point_type_id: point_type_id
    }
  });
  return dataUserPointType.point_type;
}
export const GetAccountPlaceByTeamPlaceId = async (apolloClient: any, team_place_id: number) => {
  let { data: { team_place } } = await apolloClient.query({
    query: GET_ACCOUNT_PLACE_BY_TEAM_PLACE_ID,
    variables: {
      team_place_id: team_place_id
    }
  });
  //console.log('11111111111',team_place);

  return team_place[0].account_place;
}
export const GetTeamPlaceByTeamId = async (apolloClient: any, team_id: number) => {
  let { data } = await apolloClient.query({
    query: GET_TEAM_BY_ID,
    variables: {
      team_id: team_id
    }
  });
  return data.team_list[0];
}
export const GetAllUser = async (apolloClient: any) => {
  let { data: dataUser } = await apolloClient.query({
    query: FETCH_ALL_USER,
    variables: {
    }
  });
  return dataUser.user;

}
export const DeleteMultiTeam = async (apolloClient: any, list_team_id: number[]) => {
  let status = 0;
  try {
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SOFT_DELETE_TEAM,
      variables: {
        list_team_id: list_team_id
      }
    });
    console.log('dataDeleteMulti', dataDeleteMulti);

    status = 1;
  } catch (e) {
    //console.log('1111111111114',e);

    status = 0
  }

  return status;
}
export const GetAllTeam = async (apolloClient: any, name: string) => {
  let nameSearch = `%${name}%`
  let dataTeam = [];
  let { data: dataTeamAll } = await apolloClient.query({
    query: FETCH_ALL_TEAM_ADMIN,
    variables: {
      account_id: false,
      name: nameSearch
    }
  });
  const team = dataTeamAll.team;
  for (let index = 0; index < team.length; index++) {
    const element = team[index];
    dataTeam.push({ value: element.id, label: element.name })
  }
  return dataTeam;
}
export const CopyTeamreview = async (dataProvider: any, team_id_from: number, team_id_to: number, apolloClient: any) => {
  let status = 0
  const { data } = await apolloClient.mutate({
    mutation: UPDATE_TEAM_REVIEW,
    variables: {
      team_id_to: team_id_to,
      team_id_from: team_id_from
    }
  });
  let dataUpdateStatusTeam = await dataProvider.update("team", {
    id: team_id_from,
    data: { publish_status: 3 }
  });
  if (data && dataUpdateStatusTeam) {
    status = 1
  }
  return status;
}
export const UpdateMultiTeam = async (team_ids: number[], dataUpdate: any, apolloClient: any) => {
  let status = 0
  const { data } = await apolloClient.mutate({
    mutation: UPDATE_MULTI_TEAM,
    variables: {
      team_ids: team_ids,
      monthly_fee: dataUpdate.monthly_fee,
      attention: dataUpdate.attention,
      show_account_info: dataUpdate.show_account_info,
      practice_days: dataUpdate.practice_days,
      trial_type: dataUpdate.trial_type,
      visit_ok: dataUpdate.visit_ok,
      monthly_fee_from: dataUpdate.monthly_fee_from,
      monthly_fee_to: dataUpdate.monthly_fee_to,
      monthly_fee_between: dataUpdate.monthly_fee_between,
      admission_fee: dataUpdate.admission_fee,
      annual_fee: dataUpdate.annual_fee,
      initial_fee: dataUpdate.initial_fee
    }
  });
  if (data) {
    status = 1;
  }
  return status;
}
export const GetTeamById = async (dataProvider: any, team_id: number) => {
  let data_team = await dataProvider.getList("team", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    limit: 1,
    filter: { id: team_id },
  });
  return data_team;
}
export const GetAllGenreSport = async (dataProvider: any, genre_id: number) => {
  let data_genre_sport = await dataProvider.getList("genre_sport", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: { genre_id: genre_id },
  });

  let ListData = [];
  const { data } = data_genre_sport;
  for (let item = 0; item < data.length; item++) {
    ListData.push({ id: data[item].id, name: data[item].secondary_name })
  }
  return ListData;
};
// fetch data json
export const fetchJson = async (link: string) => {
  try {
    const response = await fetch(link);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
export const GetArea = async () => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
  const ListArea = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    ListArea.push({ id: dataAllArea[are].id, name: dataAllArea[are].name });
  }
  return ListArea;
};
export const GetPref = async (area_id: Number) => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`);
  const ListPref = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    const listPrefByArea = dataAllArea[are].pref;
    if (dataAllArea[are].id === area_id) {
      for (var pref = 0; pref < listPrefByArea.length; pref++) {
        ListPref.push({ id: listPrefByArea[pref].id, name: listPrefByArea[pref].name })
      }
      return ListPref;
    }
  }
};
export const GetCity = async (pref_id: Number) => {
  const dataAllCityByPref = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/${pref_id}.json`);
  const ListCity = [];
  for (var city = 0; city < dataAllCityByPref.length; city++) {
    ListCity.push({ id: dataAllCityByPref[city].id, name: dataAllCityByPref[city].city });
  }
  return ListCity;
};