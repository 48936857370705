import * as React from 'react';
import { FC, Fragment } from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    downloadCSV,
    EmailField,
    Filter,
    List,
    SearchInput,
    SelectInput,
    TextField,
    NumberInput,
    usePermissions,
    useTranslate,
    TextInput,
    ReferenceInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import StatusField from './StatusField';
import ButtonApprove from './ButtonApprove';
import CreateField from '../components/editField';
import ReferenceFieldFullName from './ReferenceFieldFullName';
import { useNotify } from 'react-admin';
import { number } from 'react-admin';
import CountTeamField from './CountTeamField';
import ButtonEvent from './LinkToEvent';
import ButtonTeam from './LinkToTeam';

//import exporter from '../../utils/exporter';

const exporter = (items: any) => {
    const itemsForExport = items.map((item: any) => {
        return [item.id, item.name, item.name_kana, item.no_of_team,item.no_of_team_draft, item.representative_first_name, item.representative_last_name, item.representative_firstname_kana, item.representative_lastname_kana, item.contact_first_name, item.contact_last_name, item.contact_firstname_kana, item.contact_lastname_kana, item.contact_email, item.post_code, item.pref, item.city, item.address, item.building, item.contact_phone, item.business_contents, item.affiliation_association, item.affiliation_association_kana, item.sports, item.status,item.created_by, item.created, item.varified_by, item.varified,  item.note, item.create_new, item.approve, item.email,];
    });
    const csv = '\uFEFF' + convertToCSV({
        data: itemsForExport,
        //encoding: 'UTF-8 BOM',
        encoding: 'CP932',
        fields: ["ID", "企業名/団体名", "企業名/団体名フリガナ","公開中のチーム数","作成中のチーム数", "代表者名", "代表者姓", "代表者名フリガナ", "代表者姓フリガナ", "担当者名", "担当者姓", "担当者名フリガナ", "担当者姓フリガナ", "Eメールアドレス", "郵便番号", "都道府県", "市区町村", "住所", "建物名・部屋番号", "連絡先電話番号", "事業内容", "所属協会", "所属協会フリガナ", "取り扱いスポーツ", "ステータス", "作成者", "作成日", "承認者", "承認日", "備考", '新規登録', '承認する', '承認者'],
    });
    downloadCSV(csv, 'accounts');
};
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => { 
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {/* <ButtonApprove {...props}/> */}
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const notify = useNotify();
    const translate = useTranslate();
    return (
        <Filter {...props} >
            <NumberInput source="id" label={translate('account.id')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                }} alwaysOn />
            <SearchInput source="note,name,name_kana,representative_first_name,representative_last_name,representative_firstname_kana,representative_lastname_kana,contact_email,post_code,pref,city,address,contact_phone,contact_first_name,contact_last_name,contact_firstname_kana,contact_lastname_kana" alwaysOn />
        </Filter>
    )
};

const UserList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'account';

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            filterDefaultValues={{ is_soft_deleted: true }}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items)}
            actions={<ListActions to='get_all_data_of_account' />}
            empty={<ListEmpty />}
            title={<CreateField titlePage={''} />}
        >
            {/* <CustomizableDatagrid rowClick={edit}> */}
            {/* isRowSelectable={(record:any) => record.status === 0} */}
            <CustomizableDatagrid >
                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options } = f || {}
                    if (type === 'textinput') {
                        // if (numberFormat) {
                        //     return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        // }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'boolean') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'status') {
                        return <StatusField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (name === 'created_by') {
                            return <ReferenceFieldFullName source="created_by" label={translate('account.created_by')} reference='user' />
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }

                    if (type === 'date' || type === 'datetime') {
                        return <DateField source={name} showTime={true} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'button_filter') {
                        if (name === 'button_filter_event') {
                            return <ButtonEvent source={"イベント一覧"} />
                        }
                        if (name === 'button_filter_team') {
                             return <ButtonTeam source={"チーム一覧"} />

                        }
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default UserList;
