export const STATUS = [
  {id: 0, name: "無効"},
  {id: 1, name: "有効"},
]
export const TYPE = [
  {id: 1, name: "交換"},
  {id: 2, name: "抽選"},
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],reference: '', nullable: true, editable: false, export: true },
  { name: 'title', type: 'textinput', nullable: false, editable: true, export: true },
  { name: 'type', type: 'status',options: TYPE, nullable: false, editable: true, export: true },
  { name: 'description', type: 'textinput',placeholder: '100～150文字程度を想定',multiline:true, nullable: false, editable: true, export: true },
  { name: 'item_name', type: 'textinput', nullable: false, editable: true, export: true },
  { name: 'image_url', type: 'imagefield', nullable: false, editable: true, export: true },
  { name: 'amount', type: 'textinput',numberFormat:true, nullable: false, editable: true, export: true },
  { name: 'status', type: 'status',options: STATUS, nullable: false, editable: true, export: true },
  { name: 'created', type: 'date', nullable: true, editable: false, export: true },
  { name: 'display_order', type: 'textinput',numberFormat: true, nullable: true, editable: true, export: true },
  { name: 'note', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },

]

export default DATA;