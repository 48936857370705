const DATA = [
{ name: 'id', type: 'textinput', searchField: '', options:[], reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'team_id', type: 'reference', reference: 'team',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'user_id', type: 'textinput', nullable: true, editable: false, export: true, import: true },
{ name: 'name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'email', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'title', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'comment', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
{ name: 'image_url', type: 'imagefield', nullable: true, editable: true, export: false, import: false },
{},
{ name: 'created', type: 'hidden', nullable: true, editable: false, export: true, import: true },
{ name: 'created_by', type: 'hidden', numberFormat:true,nullable: true, editable: false, export: true, import: true },
]

export default DATA;