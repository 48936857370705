import { gql } from "@apollo/client";

export const SoftDeleteBulkAccountAdmin = gql`
mutation MyMutation($list_account_admin_id: [Int!]) {
  update_account_admin(where: {id: {_in: $list_account_admin_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
  }
}
`;

export const DeleteMultiAccountAdmin = async (apolloClient: any,list_account_admin_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SoftDeleteBulkAccountAdmin,
      variables: {
        list_account_admin_id: list_account_admin_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
