import * as React from 'react';
import {FC, Fragment, useEffect} from 'react';
import {
    BooleanField,
    DateField,
    Filter,
    List,
    SelectInput,
    TextField,
    usePermissions,
    useTranslate,
    TextInput, 
    ReferenceField,
} from 'react-admin';
import moment from 'moment';
import ListActions from './actionsExport';
import BulkDeleteButton from '../components/actionsBulkDelete';
import ListEmpty from '../components/listEmpty';
import {FilterProps, PermissionsType} from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields, {  MONTHS, PAID, YEARS } from './dataset';
import { NumberField } from 'react-admin';
import { ImageField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';


import exporter from './exporter';
import { useHistory } from 'react-router';
import ActionsField from './Actions';
import { PAYMENT_METHOD } from '../billing_view/gql';
import { DateInput } from 'react-admin';

const ExportableFields = Fields.filter(d => d.export);
const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props}/>}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
             <SelectInput source="year" label={translate('billing.year')} choices= {YEARS} alwaysOn />
             <SelectInput source="month" label={translate('billing.month')} choices= {MONTHS} alwaysOn />
             <TextInput source="account_name" label={translate('account.name')} alwaysOn />
             <TextInput source="account_pref" label={translate('account.pref')} alwaysOn />
             <TextInput source="account_city" label={translate('account.city')} alwaysOn />
             <TextInput source="account_address" label={translate('account.address')} alwaysOn />
             <SelectInput source="paid" choices= {PAID}  label={translate('billing.paid')} alwaysOn />
             <SelectInput source="payment_method" choices= {PAYMENT_METHOD}  label={translate('billing.payment_method')} alwaysOn />
             <DateInput source="payment_method_update_from@_gte" label={translate('billing.method_update_from')} alwaysOn />
             <DateInput source="payment_method_update_from@_lte" label={translate('billing.method_update_to')} alwaysOn />
             {/* <DateBetweenInput source="payment_method_update_from" label={translate('billing.payment_method_update_to')} alwaysOn/> */}
        </Filter>
    )
};

const BillList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/billing_view';
    },[props]);
    const module = 'billing';
    return (
        <List
            {...props}
            filters={<TeamFilter/>}
            filterDefaultValues={{ year: moment().year() }}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'billing', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='billing_view'/>}
            empty={<ListEmpty />}
            title={translate('menu.billing.billing_list')}
        >
            <CustomizableDatagrid rowClick={''}>
        
            {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput' && name !== 'created') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'reference') {
                        if (searchField === 'lastname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                        if (searchField === 'firstname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }
                        if (searchField === 'nickname') {
                            return <ReferenceField label={translate(`${module}.${searchField}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        } else {
                            return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                                <TextField source={searchField} />
                            </ReferenceField>
                        }

                    }
                    if (type === 'datetime' || name === 'created') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'date') {
                        return <DateField showTime={false} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    if (type === 'imagefield') {
                        return <ImageField source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                            return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if(name === 'action'){
                        return <ActionsField label={translate('common.actions')} />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};
export default BillList;
