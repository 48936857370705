import { Button, SaveButton, Toolbar, useTranslate } from "react-admin";
import { useHistory } from "react-router";
import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { useForm } from 'react-final-form';

const UpdateToolbar = (props: any) => {
    const translate = useTranslate();
    const history = useHistory();
    const apolloClient = useApolloClient();
    const form = useForm();
    const cancelButton = () => {
        history.goBack();
    };
    const transform = async (data: any) => {
        let dataQuestion = {};
        if (data.genre_id === 100) {
            dataQuestion = {
                user_id: data.user_id,
                category: data.category,
                title: data.title,
                question: data.question,
                approved: data.approved,
                note: data.note,
                violation: data.violation,
                nickname: data.nickname,
                genre_id: null,
                genre_sport_id: null,
                created: data.created,
                created_by: 0
            }
        } else if (data.genre_sport_id === 101) {
            dataQuestion = {
                user_id: data.user_id,
                category: data.category,
                title: data.title,
                question: data.question,
                approved: data.approved,
                note: data.note,
                violation: data.violation,
                nickname: data.nickname,
                genre_id: data.genre_id,
                genre_sport_id: null,
                created: data.created,
                created_by: 0
            }
        } else {
            dataQuestion = {
                user_id: data.user_id,
                category: data.category,
                title: data.title,
                question: data.question,
                approved: data.approved,
                note: data.note,
                violation: data.violation,
                nickname: data.nickname,
                created: data.created,
                genre_id: data.genre_id,
                genre_sport_id: data.genre_sport_id,
                created_by: 0
            }
        }
        console.log('1111111116', dataQuestion);

        return dataQuestion
    };

    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
            <SaveButton
                transform={transform}
                disabled={props.pristine}
                undoable={false}
                label={translate('common.btn_update')}
                style={props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default" />
        </Toolbar>
    );
};
export default UpdateToolbar;