const STATUS = [
{id: 0, name: "終了"},
{id: 1, name: "作成中"},
{id: 2, name: "公開待ち"},
{id: 3, name: "公開中"},
{id: 4, name: "受付停止中"},
]
const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],reference: '',export_name: 'id', nullable: true, editable: false, export: true, import: true },
{ name: 'answered_by', type: 'reference', reference: 'user',searchField: 'id',export_name: 'answered_by', nullable: true, editable: false, export: true, import: true },
{ name: 'answered_by', type: 'reference', reference: 'user', searchField: 'lastname',export_name: 'lastname', nullable: false, editable: true, export: true, import: true },
{ name: 'answered_by', type: 'reference', reference: 'user', searchField: 'firstname',export_name: 'firstname', nullable: false, editable: true, export: true, import: true }, 
{ name: 'answered_nickname', type: 'textinput', nullable: true,export_name: 'answered_nickname', editable: true, export: true, import: true },
{ name: 'question_id', type: 'reference', reference: 'question',export_name: 'question_id',searchField: 'title', nullable: false, editable: false, export: true, import: true },


{ name: 'answer', type: 'textinput',multiline: true, nullable: false,export_name: 'answer', editable: true, export: true, import: true },
{ name: 'good', type: 'textinput', numberFormat:true, nullable: true,export_name: 'good', editable: true, export: true, import: true },
{ name: 'violation', type: 'textinput', numberFormat:true, nullable: true,export_name: 'violation', editable: true, export: true, import: true },
{ name: 'created', type: 'textinput', nullable: true, editable: false,export_name: 'created', export: true, import: true },
{ name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,export_name: 'created_by',nullable: true, editable: false, export: false, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true,export_name: 'note', editable: true, export: true, import: true },

]

export default DATA;