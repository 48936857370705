import * as React from 'react';
import {useDispatch} from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {Title, useSetLocale, useTranslate} from 'react-admin';
import {makeStyles} from '@material-ui/core/styles';
import {changeTheme} from './actions';
import useLocalStorage from "../utils/useLocalStorage";

const useStyles = makeStyles({
    label: { width: '10em', display: 'inline-block' },
    button: { margin: '1em' },
});

const Configuration = () => {
    const translate = useTranslate();
    const setLocale = useSetLocale();
    const classes = useStyles();
    const [language, setLanguage] = useLocalStorage<string>('language', 'ja');
    const [theme, setTheme] = useLocalStorage<string>('theme', 'light');

    const dispatch = useDispatch();
    return (
        <Card>
            <Title title={translate('common.configuration')} />
            <CardContent>
                <div className={classes.label}>
                    {translate('common.theme')}
                </div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={theme === 'light' ? 'primary' : 'default'}
                    onClick={() => {
                        setTheme('light');
                        dispatch(changeTheme('light'));
                    }}
                >
                    {translate('common.theme_light')}
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={theme === 'dark' ? 'primary' : 'default'}
                    onClick={() => {
                        setTheme('dark');
                        dispatch(changeTheme('dark'));
                    } }
                >
                    {translate('common.theme_dark')}
                </Button>
            </CardContent>
            <CardContent>
                <div className={classes.label}>{translate('common.language')}</div>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={language === 'en' ? 'primary' : 'default'}
                    onClick={() => {
                        setLocale('en');
                        setLanguage('en');
                    }}
                >
                    English
                </Button>
                <Button
                    variant="contained"
                    className={classes.button}
                    color={language === 'ja' ? 'primary' : 'default'}
                    onClick={() => {
                        setLocale('ja');
                        setLanguage('ja');
                    }
                    }
                >
                    Japanese
                </Button>                
            </CardContent>
        </Card>
    );
};

export default Configuration;
