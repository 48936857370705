import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  useTranslate,
} from 'react-admin';
import EditField from '../components/editField';
import { Grid } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { TabbedForm } from 'react-admin';
import { FormTab } from 'react-admin';
import { TextInput } from 'react-admin';
import InputRenderer from '../../utils/InputRenderer';
import { required } from 'react-admin';
import CreateToolbar from "./CreateToolbar";
import { InfoOutlined } from '@material-ui/icons';
import { useApolloClient } from "@apollo/client";
import EditIcon from '@material-ui/icons/EditLocationOutlined';

import jaLocale from "date-fns/locale/ja";
import { PUBLISH_STATUS, ATTENTION, CATEGORIES, DISABILITY, LADIES, TARGET_AGES, PRACTICE_DAYS, KEYWORD1, KEYWORD2, KEYWORD3, KEYWORD4, TRIAL_TYPES, VISIT_OK, STYLE_CHOICE, STYLE, ENROLLED_GENERATE, MONTHLY_FEE, SHOW_ACCOUNT_INFO } from './dataset'
import { RadioButtonGroupInput } from 'react-admin';
import { CheckboxGroupInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { useGetIdentity } from 'react-admin';
import { useNotify } from 'react-admin';
import EnrolledField from './EnrollField';
import EnrolledOpenField from './EnrollOpenField';
import { useEffect, useState } from 'react';
import RadioMonthlyFee from './RadioMonthlyFee';
import GearField from './GearField';



const TeamEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);
  const notify = useNotify();
  const requiredValidate = [required()];
  const { identity, loading: identityLoading } = useGetIdentity();
  
  if (identityLoading) {
    return <>Loading...</>;
  }
  const onFailure = (error: any) => {
    notify(`Could not create: ${error.message}`, 'warning');
  };

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>
      <TabPanel value={value} index={0}>
        <Edit
          {...props}
          successMessage="common.msg_update_success"
          undoable={false}
          className="formContent"
          title={<EditField titlePage={translate('menu.account.account_edit')} />}
        >
          <TabbedForm
            className="form"
            redirect="list"
            toolbar={<CreateToolbar />}
            //initialValues={defaultValues}
            title={'edit'}
          >
            <FormTab label={translate("school_gym_draft.team_tab_1")} style={{ width: '10%', maxWidth: 'inherit' }} container justify='center' spacing={2} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab1 {...formDataProps} />
                  //初期設定情報
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("school_gym_draft.team_tab_3")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab3 {...formDataProps} />
                  // チーム・スクールの画像・特徴
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("school_gym_draft.team_tab_4")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab4 {...formDataProps} />
                  // 特徴
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("school_gym_draft.team_tab_5")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab5 {...formDataProps} />
                  //募集・練習体験・セレクション
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("school_gym_draft.team_tab_6")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab6 {...formDataProps} />
                  // 費用について
                )}
              </FormDataConsumer>
            </FormTab>
          </TabbedForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditHistory {...props} />
      </TabPanel>
    </div>

  );
};
const CreateEventTab1 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const [monthlyFee, setMonthlyFee] = useState<boolean>(false)
  const [payableRate, setPayableRate] = useState<number>(100)
  useEffect(() => {
    if (form.getState().values.monthly_fee === 1) {
      setMonthlyFee(true);
    }
    setPayableRate(form.getState().values.payable_rate)
  }, []);
  const formData = props
  const getValueAttention = () => {
    return payableRate;
  }
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <ReferenceInput
          allowEmpty={false}
          source='shop_id'
          reference={'shop'}
          disabled={'disable'}
          // validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.shop_id')}
          //filter= {{status: 1}}
          filterToQuery={(searchText: any) => ({ ['name']: searchText })}>
          <SelectInput
            optionText={'name'}
          />
        </ReferenceInput>
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
      {form.getState().values.name_type === 1 ? 
      <TextInput
        source='name2'
        label={translate('school_gym_draft.name')}
        placeholder={'名称'}
        fullWidth
        multiline={false}
        variant="outlined"
      /> : 
      <TextInput
          source='name'
          label={translate('school_gym_draft.name')}
          placeholder={'名称'}
          fullWidth
          multiline={false}
          variant="outlined"
        />
      }
      {form.getState().values.name_kana_type === 1 ? 
      <TextInput
        source='name_kana2'
        label={translate('school_gym_draft.name_kana')}
        placeholder={'チーム名フリガナ'}
        fullWidth
        multiline={false}
        variant="outlined"
      /> : 
      <TextInput
          source='name_kana'
          label={translate('school_gym_draft.name_kana')}
          placeholder={'チーム名フリガナ'}
          fullWidth
          multiline={false}
          variant="outlined"
        />
      }
      </Grid>
      <Grid item xs={6}>
        <RadioButtonGroupInput
          source='monthly_fee'
          //validate={true}
          choices={MONTHLY_FEE}
          fullWidth
          onChange={() => {
            setMonthlyFee(!monthlyFee);
          }}
          variant="outlined"
          label={translate('school_gym_draft.monthly_fee')}
        />
        {monthlyFee === true && (
          <RadioButtonGroupInput
            source='attention'
            //validate={true}
            choices={ATTENTION}
            fullWidth
            variant="outlined"
            label={translate('school_gym_draft.attention')}
          />
        )}

      </Grid>
      <Grid item xs={6}>
        <RadioButtonGroupInput
          source='publish_status'
          //validate={true}
          choices={PUBLISH_STATUS}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.publish_status')}
        />
      </Grid>
      <Grid item xs={6}>
        <RadioButtonGroupInput
          source='show_account_info'
          //validate={true}
          choices={SHOW_ACCOUNT_INFO}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.show_account_info')}
        />
      </Grid>
      {/* <Grid xs={6} style={{ width: '30%' }} >
        <BooleanInput
          source='team_connection'
          //validate={requiredValidate}
          label={translate('school_gym_draft.team_connection')}
          //format={getValueAttention}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
      </Grid> */}
      {/* <div >
        <p style={{ fontWeight: 700, fontSize: '14px' }}>
          「月謝徴収あり」を選択：
        </p>
        <p>
          スポスル経由でチームメンバー、選手、生徒が入会された場合、月謝の半月分or1か月分の成功報酬費用が発生いたします。掲載自体は無料です。
        </p>
        <p style={{ fontWeight: 700, fontSize: '14px' }}>
          「月謝徴収なし」を選択：
        </p>
        <p>
          サイト掲載料は <span style={{ color: '#FF0000', fontWeight: 700, }}>完全無料</span>完全無料となります。
        </p>
      </div> */}
    </div>
  );
};

const CreateEventTab3 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div style={{ width: '98%' }}>
      <Grid className='formContent' xs={6} style={{ width: '50%' }}>
        <InputRenderer source='school_gym_draft' data={{ name: 'logo_url', type: 'imagefield', nullable: true, editable: true }} />
        <div>
          <p>※ファイルはJPG, PNG形式で、70px * 70px以上。</p>
        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <TextInput
          source='video_url'
          label={translate('school_gym_draft.video_url')}
          placeholder={translate('school_gym_draft.video_url_placeholder')}
          fullWidth
          variant="outlined"
        />
        <div>
          <p>※入力ボックスにYouTubeのURLを入力してください。</p>
        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '90%' }}>
        <InputRenderer source='school_gym_draft' data={{ name: 'image1_url', type: 'imagefield', nullable: true, editable: true }} />
        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <InputRenderer source='school_gym_draft' data={{ name: 'image2_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='school_gym_draft' data={{ name: 'image3_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='school_gym_draft' data={{ name: 'image4_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='school_gym_draft' data={{ name: 'image5_url', type: 'imagefield', nullable: true, editable: true }} />

        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }}>
        <InputRenderer source='school_gym_draft' data={{ name: 'image6_url', type: 'imagefield', nullable: true, editable: true }} />
        <InputRenderer source='school_gym_draft' data={{ name: 'image7_url', type: 'imagefield', nullable: true, editable: true }} />

        <div>
          <p>※ファイルはJPG, PNG形式で、650px * 365px以上。</p>
          <p>公開されますので、被写体の方の公開許可を必ず得てください。</p>
          <p>アップロードすると許可を得ているものとします。</p>

        </div>
      </Grid>
    </div>
  );
}
const CreateEventTab4 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div style={{ width: '98%' }}>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword1'
          choices={KEYWORD1}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.keyword1')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword2'
          choices={KEYWORD2}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.keyword2')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword3'
          choices={KEYWORD3}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.keyword3')}
        />
      </Grid>
      <Grid className='formContent' xs={6} style={{ width: '50%', marginTop: '20px', marginBottom: '20px' }} >
        <CheckboxGroupInput
          source='keyword4'
          choices={KEYWORD4}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.keyword4')}
        />
      </Grid>
    </div>
  );
}
const CreateEventTab5 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <RadioButtonGroupInput
          source='trial_type'
          choices={TRIAL_TYPES}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.trial_type')}
        />
        <RadioButtonGroupInput
          source='visit_ok'
          choices={VISIT_OK}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.visit_ok')}
        />
        <TextInput
          source='trial_description'
          label={translate('school_gym_draft.trial_description')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <Grid xs={6} style={{ width: '100%' }} >
          <p>在籍人数*</p>
          {
            ENROLLED_GENERATE.map((d, idx) => {
              return <EnrolledField props={props} dataEnable={props.formData.enrolled || []} dataForm={d} ListAge={props.formData.age} />
            })
          }
        </Grid>
        <TextInput
          source='enroll_description'
          label={translate('school_gym_draft.enroll_description')}
          placeholder={'入力してください…'}
          fullWidth
          multiline={true}
          variant="outlined"
        />
      </Grid>
      <Grid xs={6} style={{ width: '100%' }} >
        <p>募集人数*</p>
        {
          ENROLLED_GENERATE.map((d, idx) => {
            return <EnrolledOpenField props={props} dataEnable={props.formData.recruitment} dataForm={d} ListAge={props.formData.age} />
          })
        }
      </Grid>

    </div>
  );
}
const CreateEventTab6 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <RadioMonthlyFee props={props} />

      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='admission_fee'
          label={translate('school_gym_draft.admission_fee')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='annual_fee'
          label={translate('school_gym_draft.annual_fee')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <GearField props={props} />
        <TextInput
          source='things_to_prepare'
          label={translate('school_gym_draft.things_to_prepare')}
          placeholder={''}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='annual_fee'
          label={translate('school_gym_draft.annual_fee')}
          fullWidth
          multiline={true}
          variant="outlined"

        />
        <TextInput
          source='fee_description'
          label={translate('school_gym_draft.fee_description')}
          //placeholder={placeholder}
          multiline={true}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='initial_fee'
          label={translate('school_gym_draft.initial_fee')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <ReferenceInput
          allowEmpty={true}
          source='created_by'
          reference={'user'}
          disabled={'disabled'}
          fullWidth
          variant="outlined"
          label={translate('school_gym_draft.created_by')}
          filterToQuery={(searchText: any) => ({ ['firstname']: searchText })}>
          <SelectInput
            optionText={'firstname'}
          />
        </ReferenceInput>
      </Grid>
    </div>
  );
}
export default TeamEdit;
