import { gql } from "@apollo/client";
export const SOFT_DELETE_USERS = gql`
mutation MyMutation($list_user_id: [Int!]) {
  update_user(where: {id: {_in: $list_user_id}}, _set: {is_soft_deleted: true}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_GENRE_SPORT_BY_LIST_ID = gql`
query MyQuery($list_genre_sport: [Int!]) {
  genre_sport(where: {id: {_in: $list_genre_sport}}) {
    id
    secondary_name
  }
}
`;
export const GET_ALL_GENRE = gql`
query MyQuery{
  genre(order_by: {id: desc}) {
    id
    name
  }
}
`;
export const GET_ALL_GENRE_SPORT = gql`
query MyQuery{
  genre_sport{
    id
    secondary_name
    genre_id
  }
}
`;
export const DeleteMultiUser = async (apolloClient: any, list_user_id: number[]) => {
  let status = 0;
  try {
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: SOFT_DELETE_USERS,
      variables: {
        list_user_id: list_user_id
      }
    });
    status = 1;
  } catch (e) {
    status = 0
  }
  return status;
}
export const GET_NAME_GENRE_SPORT = async (apolloClient: any, list_genre_sport: number[]) => {
  let list_genre_sport_string = '';
  if (list_genre_sport.length > 0) {
    let { data: { genre_sport } } = await apolloClient.query({
      query: GET_GENRE_SPORT_BY_LIST_ID,
      variables: {
        list_genre_sport: list_genre_sport
      }
    });
    let listSort = [];
    for (let index = 0; index < list_genre_sport.length; index++) {
      const element = list_genre_sport[index];
      const dataFilter = genre_sport.filter((x: any) => x.id === element);
      listSort.push(dataFilter[0]);
    }

    for (let index = 0; index < listSort.length; index++) {
      const element = listSort[index];
      if (index < 5 && index !== 0) {
        list_genre_sport_string = list_genre_sport_string + ',' + element.secondary_name;
      } else {
        list_genre_sport_string = list_genre_sport_string + element.secondary_name;
      }
    }
  }

  return list_genre_sport_string;
}
export const GET_ALL_GENRE_LIST = async (apolloClient: any) => {
    let { data: { genre } } = await apolloClient.query({
      query: GET_ALL_GENRE,
      variables: {

      }
    });
    let list_genre = [];
  for (const item of genre) {
    if(item.id >= 0){
      list_genre.push(item);
    }
  }
  return list_genre;
}
export const GET_ALL_GENRE_SPORT_LIST = async (apolloClient: any) => {
  let { data: { genre_sport } } = await apolloClient.query({
    query: GET_ALL_GENRE_SPORT,
    variables: {
    }
  });
  let list_genre_sport = [];
for (const item of genre_sport) {
  if(item.id >= 0){
    list_genre_sport.push(item);
  }
}
return list_genre_sport;
}
export const RemoveDuplicate = (array: any) => {
  return array.filter((item: any, index: any) => array.indexOf(item) === index);
}