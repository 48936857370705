const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],reference: '',export_name: 'id', nullable: true, editable: false, export: true, import: true },
//{ name: 'billing_id', type: 'reference', reference: 'billing',searchField: 'note',export_name: 'billing_id', nullable: true, editable: false, export: true, import: true },
{ name: 'billing_id', type: 'textinput', numberFormat:true, reference: 'billing',searchField: 'note',export_name: 'billing_id', nullable: false, editable: true, export: true, import: true },
{ name: 'product', type: 'reference', reference: 'team_experiencer', searchField: 'lastname',export_name: 'product', nullable: false, editable: true, export: true, import: true },
{ name: 'unit', type: 'textinput', numberFormat:true, export_name: 'unit', nullable: false, editable: true, export: true, import: true }, 
{ name: 'amount', type: 'textinput', numberFormat:true, export_name: 'amount', nullable: false, editable: true, export: true, import: true }, 
{ name: 'tax', type: 'textinput', numberFormat:true, export_name: 'tax', nullable: false, editable: true, export: true, import: true }, 
{ name: 'percentage', type: 'textinput', numberFormat:true, export_name: 'percentage', nullable: false, editable: true, export: true, import: true }, 
{ name: 'discount', type: 'textinput', numberFormat:true, export_name: 'discount', nullable: false, editable: true, export: true, import: true }, 
{ name: 'stripe_product_id', type: 'textinput', export_name: 'stripe_product_id', nullable: true, editable: true, export: true, import: true }, 
{ name: 'created_at', type: 'datetime', nullable: true, editable: false,export_name: 'created_at', export: true, import: true },
{ name: 'updated_at', type: 'datetime', nullable: true, editable: false,export_name: 'updated_at', export: true, import: true },
{ name: 'created_by', type: 'reference', reference: 'user', searchField: 'lastname',export_name: 'created_by', nullable: false, editable: true, export: true, import: true },

]

export default DATA;