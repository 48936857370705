import * as React from 'react';
import {FC} from 'react';
import {Chip} from '@material-ui/core';
import {FieldProps, Account} from '../../types';
import {useTranslate} from 'react-admin';
import { STATUS } from './dataset';

const StatusField: FC<FieldProps<Account>> = ({ record }) => {
    const translate = useTranslate();    
    var background_color = '';
    var label = '';
    switch(record?.status) {
        case 0:
            background_color = '#FF9900';
            label = STATUS.filter(st => st.id === 0)[0]?.name;
          break;
        case 1:
            background_color = '#3CD4A0';
            label = STATUS.filter(st => st.id === 1)[0]?.name;
          break;
        case 2:
            background_color = '#FF5C93';
            label = STATUS.filter(st => st.id === 2)[0]?.name;
          break;
      }
    return record ? (
        <span>
            <Chip
                label={
                    label
                }
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor:background_color,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    ) : null;
};
export default StatusField;
