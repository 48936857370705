import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useTranslate,
} from 'react-admin';
import { Paper, FormControl, Select, MenuItem, InputLabel, Button, TextField, Grid } from '@material-ui/core';
import { MONTHS, YEARS } from '../billing/dataset';
const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: 15,
    marginBottom: 20

  },
  formControl: {
    paddingRight: 100,
    minWidth: 160,
    paddingTop: 10
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  InputField : {
    paddingTop: 10
  }, 
  title: {
    
  }
}));


const DetailBillingBar = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles();
  const {onChangeBtnSave, isDisable,countAccount, sumAmount} = props;
  const data_currency = new Intl.NumberFormat('en-US').format(sumAmount)
  return <Paper id='paper-toolbar' className={classes.toolbar}>
    <Grid>
      <Grid>
        <ul className='list-total-detail'>
          <li className='list-total-detail-li'>合計値：</li>
          <li className='list-total-detail-li'>件数：</li>
          <li className='list-total-detail-li'>{countAccount}</li>
          <li className='list-total-detail-li'>請求金額合計：</li>
          <li className='list-total-detail-li'>{data_currency} 円</li>


        </ul>
      </Grid>
    </Grid>
  </Paper>
}

export default DetailBillingBar;