import { gql } from "@apollo/client";

export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;
export const FETCH_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    account(where: {id: {_eq: $id}}) {
        id
        contact_email
        created_by
    }
  }
`;
export const APPROVE_TEAM_REVIEW = gql`
mutation MyMutation($list_team_review_id: [Int!]) {
  update_team_review(where: {id: {_in: $list_team_review_id}}, _set: {status:1}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const RECOVER_TEAM_REVIEW = gql`
mutation MyMutation($list_team_review_id: [Int!]) {
  update_team_review(where: {id: {_in: $list_team_review_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const DELETE_TEAM_REVIEW = gql`
mutation MyMutation($list_team_review_id: [Int!]) {
  delete_team_review(where: {id: {_in: $list_team_review_id}}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const DISAPPROVE_TEAM_REIVEW = gql`
mutation MyMutation($list_team_review_id: [Int!]) {
  update_team_review(where: {id: {_in: $list_team_review_id}}, _set: {status:2}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const SEND_MAIL_APPROVE_TEAM_REVIEW = gql`
mutation MyMutation($list_team_review: [Int] ) {
  send_email_approve_team_team_review(action: "APPROVE", list_team_review: $list_team_review, table: "TEAM_REVIEW") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const SEND_MAIL_DISAPPROVE_TEAM_REVIEW = gql`
mutation MyMutation($list_team_review: [Int] ) {
  send_email_approve_team_team_review(action: "DISAPPROVE", list_team_review: $list_team_review, table: "TEAM_REVIEW") {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const GET_TEAM_REVIEW_ACCESS_APPROVE = gql`
query Myquery($list_team_review_id: [Int!]) {
    team_review(where: {id: {_in: $list_team_review_id}, status: {_in: [0]}}) {
      id
      created_by
    }
}
`;
export const GET_TEAM_REVIEW_NOT_ACCESS_APPROVE = gql`
query Myquery($list_team_review_id: [Int!]) {
    team_review(where: {id: {_in: $list_team_review_id}, status: {_nin: [0]}}) {
      id
    }
}
`;
export const INSERT_USER_POINT = gql`
mutation MyMutation($user_id: Int, $amount: Int) {
  insert_user_point(objects: {amount: $amount, user_id: $user_id, point_type_id: 1,created_by: 0}) {
    affected_rows
  }
}
`;
export const DeleteMultiTeamReview = async (apolloClient: any,list_team_review_id: number[]) => {
  let status = 0;
  try{
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DELETE_TEAM_REVIEW,
      variables: {
        list_team_review_id: list_team_review_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}
export const RecoverMultiTeamReview = async (apolloClient: any,list_team_review_id: number[]) => {
  let status = 0;
  try{
    let { data: dataRecoverMulti } = await apolloClient.mutate({
      mutation: RECOVER_TEAM_REVIEW,
      variables: {
        list_team_review_id: list_team_review_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}