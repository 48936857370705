import { downloadCSV } from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import { gql } from "@apollo/client";
import { GetAllAccountUser, GetAllUser } from './gql';
import { CATEGORIES, PUBLISH_STATUS, TRIAL_TYPES } from './dataset';
import moment from 'moment';
interface User {
  firstname: string, 
  lastname: string, 
  phone: string, 

}
const exporter = async (items: any, resource: string, fields: any, translate: any,fetchRelatedRecords: any,apolloClient: any) => {
  var data:any = items
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) =>f.name)
  const dataTeam = await GetAllAccountUser(apolloClient);
  const dataUser = await GetAllUser(apolloClient);
   const data_export = items.map((item: any) => {
      const dataAccountUser = dataTeam.filter((team: any) => team.id === item.id);
      const dataUserName = dataUser.filter((user: any) => user.id === item.created_by);
      return {
        ...item, 
        team_id: dataAccountUser[0].name,
        account_id: item.account_id ? ' ' : 'ユーザー',
        account_lastname: item.account_id ? dataAccountUser[0].account.user.lastname:(dataUserName[0]?dataUserName[0].lastname : '') ,
        account_email:item.account_id ? dataAccountUser[0].account.user.email : (dataUserName[0]?dataUserName[0].email : '') ,
        account_firstname: item.account_id ? dataAccountUser[0].account.user.firstname : (dataUserName[0]?dataUserName[0].firstname : '') ,
        area: dataAccountUser[0].areaByArea.name,
        city: dataAccountUser[0].cityByCity.name,
        pref: dataAccountUser[0].prefByPref.name,
        genre_id: dataAccountUser[0].genre.name,
        genre_sport_id: dataAccountUser[0].genre_sport.secondary_name,
        account_name: item.account_id ? dataAccountUser[0].account.name : '',
        publish_status: GetNamePublishStatus(item.publish_status), 
        category: GetNameCategory(item.category), 
        trial_type: GetNameTrialType(item.trial_type), 
        created:  item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a' ) : item.created,
        approved_at:  item.created ? moment(item.approved_at).format('YYYY-MM-DD h:mm:ss a') : item.approved_at,
        monthly_fee: item.monthly_fee === 0 ? '月謝徴収なし' : '月謝徴収あり'
      }

   });
  
 
  const itemsForExport = data_export.map((item: any) => {
      return fieldName.map((f: any) => item[f])
  });
  
  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if ( val === `${resource}.csv_fields.${name}` ) {
      return translate(`${resource}.${name}`);
    }

    return val;
  }

  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    //encoding: 'UTF-8 BOM',
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

const GetNamePublishStatus = (publish_status: number) => {
  const dataStatus =  PUBLISH_STATUS.filter(x => x.id === publish_status);  
  return dataStatus[0].name;
}
const GetNameCategory = (category: number) => {
  const dataCategory =  CATEGORIES.filter(x => x.id === category);
  if (!dataCategory[0]){
   return '';
  }
  return dataCategory[0].name;
}
const GetNameTrialType = (trial_type: number) => {
  const dataTrialType =  TRIAL_TYPES.filter(x => x.id === trial_type);
  if (!dataTrialType[0]){
   return '';
  }
  return dataTrialType[0].name;
}


