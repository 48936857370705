import * as React from 'react';
import {FC} from 'react';
import {Chip} from '@material-ui/core';
import {FieldProps, Account} from '../../types';
import {useTranslate} from 'react-admin';

const AccountCreateField: FC<FieldProps<Account>> = ({ record }) => {
    const translate = useTranslate();    
    
    var background_color = '';
    var label = '';
    
    if (!record?.account_id) {
        background_color = '#003366';
        label = 'ユーザー';
    }       

    return  record?.account_id ? <></> : (
        <span>
            <Chip
                label={
                    label
                }
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor:background_color,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    );
};
export default AccountCreateField;
