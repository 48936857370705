import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';
import { translate } from 'react-admin';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();

  if (id) {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;
const From = (props: any) => {
  const { editForm } = props;
  const translate = useTranslate();
  var isEditPage = editForm === true ? true : false;
  if (editForm) {
    var { record: { id, contact_status } } = props;
  }
  return <Container>
    <Grid container justify='center' style={{ width: '100%' }} spacing={2}>
      <Grid style={{ width: '50%' }} container spacing={2} justify='center'>
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.team_contact.page_edit" : "menu.team_contact.page_create"} id={editForm ? id : ''} />
          <Separator />
        </Grid >
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }
          if (d.name === 'contact_status' && isEditPage === true && contact_status !== 1) {
            return <></>
          }
          if (d.name === 'account_name' && isEditPage === true) {
            return <Grid item xs={12} key={idx}>
              <ReferenceInput allowEmpty={true} filterToQuery={(searchText: any) => ({ name: searchText })} disabled={'disabled'}
                fullWidth
                variant="outlined" source="team_id" reference="team_list" label={translate('account_admin.account_id')} alwaysOn>
                <AutocompleteInput optionText={'account_name_ref'} />
              </ReferenceInput>
            </Grid>
          }
          return <Grid item xs={12} key={idx}>
            <InputRenderer source='team_contact' data={d} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default From;