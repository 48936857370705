import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  useTranslate,
  useAuthState,
  Loading,
  useRedirect,
  useNotify,
  useGetIdentity,
} from 'react-admin';
import { useApolloClient } from '@apollo/client';
import { DataGrid, GridCellParams } from '@material-ui/data-grid';
//import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { Box, Button } from '@material-ui/core';
import ToolBar, { TypeYear } from './ToolBar';
import { GetDataBilling, GetDataYear, GetPresignedDownload, SendEmailBilling, updateTable } from './gql'
import { makeStyles } from '@material-ui/core/styles';
import { usePermissions } from 'react-admin';
import { PermissionsType } from '../../types';
import { useRefresh } from 'react-admin';
import ResetPassButton from './actionsResetPass';
import DetailBillingBar from './DetailBillingBar';
import ModalEditBilling from './ModalEditBilling';
import axios from 'axios';
import exporter from './exporter';
import moment from 'moment';
import { CountNumberDiffInList } from '../../utils/Utils';
import { useHistory } from 'react-router';
const useStyles = makeStyles((theme) => ({
  DataGrid: {
    marginBottom: 15
  },
  ActionButton: {
    marginRight: 10
  }
}));
const ConfirmPage = () => {

  const { loading, authenticated } = useAuthState();
  const redirect = useRedirect();
  const [data, setData] = useState([] as any);
  const [showDialogReset, setshowDialogReset] = useState(false);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const apolloClient = useApolloClient();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [resetPassword, setResetPassword] = useState<string>('');
  const [confirmResetPassword, setConfirmResetPassword] = useState<string>('');
  const [year, setYear] = useState<number>(0);
  const [month, setMonth] = useState<number>(0);
  const [flagReload, setFlagReload] = useState<number>(0);
  const [accountAddress, setAccountAddess] = useState<string>('');
  const [accountPref, setaccountPref] = useState<string>('');
  const [accountCity, setaccountCity] = useState<string>('');
  const [accountName, setaccountName] = useState<string>('');
  const [countAccount, setCountAccount] = useState<number>(0);
  const [sumAmount, setSumAmount] = useState<number>(0);
  const [listYear, setListYear] = useState<TypeYear[]>([]);
  const history = useHistory();
  const refresh = useRefresh()
  const translate = useTranslate()
  const notify = useNotify();
  const classes = useStyles();
  const { identity, loading: identityLoading } = useGetIdentity();
  const { permissions } = usePermissions();
  const role = permissions?.role;
  const permission = permissions?.list_permission as PermissionsType;
  const [pageSize, setPageSize] = React.useState(10);


  useEffect(() => {
    if (authenticated) {
      history.location.pathname = '/billings';
      refresh()
      fetchYear();
      fetchData(apolloClient);
    }
    //fetchData();
  }, [year, month, accountAddress, accountPref, accountCity, accountName,flagReload])
  const fetchYear = async () => {
    const ListYear = await GetDataYear(apolloClient);
    setListYear(ListYear)
  }
  const reLoadClick = () => {
    let index = flagReload + 1;
    setFlagReload(index)
  }
  const renderActionCell = (params: GridCellParams) => {
    let ExportBillingDetailDisable = false;
    let ExportBillingDetailReceiptDisable = false;
    let ExportBillingStripe = false

    if (!params?.row?.filepath && !params?.row?.filename) {
      ExportBillingDetailDisable = true
    }
    if (!params?.row?.receipt_filepath && !params?.row?.receipt_filename) {
      ExportBillingDetailReceiptDisable = true
    }

    // enable download stripe button
    if(params?.row?.issue_date && 
      params?.row?.paid === '未払い' &&  
      params?.row?.payment_method === 'Stripe' && 
      params?.row?.account_payment_method === 1 && 
      params?.row?.stripe_id){
        ExportBillingStripe = true;
    }
    return <>
    
      <Button
        className={classes.ActionButton}
        variant="contained"
        color="secondary"
        onClick={(e: any) => window.location.href=`/#/edit-billings/${params?.row.id}`}>編集</Button>
      <Box>
      <Button
          className={classes.ActionButton}
          disabled={ !ExportBillingDetailReceiptDisable}
          variant="contained"
          color="primary"
          id={"btn-resetpass-admin"}
          onClick={(e: any) => GenReceiptInvoice(e, params)}
        >
          {translate('領収書生成')}
        </Button>
      </Box>
      {/* <ModalEditBilling/> */}
      {!ExportBillingDetailDisable && <Box>
        <Button
          className={classes.ActionButton}
          //disabled={!confirmed}
          disabled={ExportBillingDetailDisable}
          variant="contained"
          color="primary"
          id={"btn-resetpass-admin"}
          onClick={(e: any) => DownloadInvoice(e, params)}
        >
          {translate('請求書ダウンロード')}
        </Button>

      </Box>
      }
      {!ExportBillingDetailReceiptDisable && <Box className=''>
        <Button
          className={classes.ActionButton}
          //disabled={!confirmed}
          disabled={ExportBillingDetailReceiptDisable}
          variant="contained"
          color="primary"
          id={"btn-resetpass-admin"}
          onClick={(e: any) => DownloadInvoiceReceipt(e, params)}
        >
          {translate('領収書のダウンロード')}
        </Button>

      </Box>
      }
      { ExportBillingStripe && <Box className=''>
        <Button
          className={classes.ActionButton}
          //disabled={!confirmed}
          //disabled={!ExportBillingStripe}
          variant="contained"
          color="primary"
          id={"btn-resetpass-admin"}
          onClick={(e: any) => GenStripeInvoice(e, params)}
        >
          {translate('Stripe決済')}
        </Button>

      </Box>
      }
      
    </>
  }
  const DownloadInvoice = async (event: any, params: GridCellParams) => {
    const admin_id = params?.row?.id as number;
    const billing_id = params?.row?.billing_id as number;

    const filename = params?.row?.filename as string;
    const filepath = params?.row?.filepath as string;

    const dataPreSigned = await GetPresignedDownload(apolloClient, filepath);
    if (dataPreSigned) {
      fetch(dataPreSigned.preUrl)
        .then((res) => { return res.blob(); })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = filename;
          a.click();
        });
    }
    //window.location.href = dataPreSigned.preUrl;
    //setIdResetPass(admin_id)
    //setshowDialogReset(true);
  }
  const GenReceiptInvoice = async (event: any, params: GridCellParams) => {
    const billing_id = params?.row?.billing_id as number;
    const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
    const response = await fetch(`${Domain}api/create-receipt/${billing_id}`);
    if(response) {
      notify(`${translate('common.gen_receipt_success')}`, 'info');
      window.location.reload();
    }
    return response;

  }
  const DownloadInvoiceReceipt = async (event: any, params: GridCellParams) => {
    const admin_id = params?.row?.id as number;
    const billing_id = params?.row?.billing_id as number;

    const filename = params?.row?.receipt_filename as string;
    const filepath = params?.row?.receipt_filepath as string;

    const dataPreSigned = await GetPresignedDownload(apolloClient, filepath);
    if (dataPreSigned) {
      fetch(dataPreSigned.preUrl)
        .then((res) => { return res.blob(); })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = filename;
          a.click();
        });
      //const dataSendEmail = await SendEmailBilling(apolloClient, billing_id);
      //console.log('dataSendEmail', dataSendEmail);

    }
    //window.location.href = dataPreSigned.preUrl;
    //setIdResetPass(admin_id)
    //setshowDialogReset(true);
  }
  const GenStripeInvoice = async (event: any, params: GridCellParams) => {
    const billing_id = params?.row?.billing_id as number;
    const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
    const response = await fetch(`${Domain}api/make-payment-by-id/${billing_id}`);
    if(response) {
      notify(`${translate('common.gen_receipt_success')}`, 'info');
      window.location.reload();
    }
    return response;

  }
  const onClose = () => {
    setshowDialogReset(false);

  }
  const onCloseConfirm = () => {
    setShowConfirmReset(false);
  }
  const handleSelectBoxChange = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'BillingYear') {
      setYear(value);
    } else if (name === 'billingMonth') {
      setMonth(value);
    } else if (name === 'account-address') {
      setAccountAddess(value);
    } else if (name === 'account-pref') {
      setaccountPref(value);
    }
    else if (name === 'account_city') {
      setaccountCity(value);
    }
    else if (name === 'account-name') {
      setaccountName(value);
    }
  }
  const onChangeCreateButton = async () => {


  }
  const onChangeText = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'email-admin') {
      setEmail(value);
    } else if (name === 'password-admin') {
      setPassword(value);
    }
    console.log('email', email);

  }
  const onChangeResetPass = (event: any) => {
    const { target: { value, name } } = event;
    if (name === 'password-reset') {
      setResetPassword(value);
    } else if (name === 'password-reset-confirm') {
      setConfirmResetPassword(value);
    }
  }
  const onConfirmDialog = (event: any) => {
    if (!resetPassword) {
      notify(translate('common.reset_pass_error'), 'warning');
    } else {
      setShowConfirmReset(true);
    }
  }
  const onSubmitConfirm = async (event: any) => {


  }
const BillingColumn = [
    { field: 'id', headerName: 'ID', typename: 'number', width: 80 },
    { field: 'account_name', headerName: '団体', typename: 'string', width: 180, disableColumnMenu: true },
    { field: 'team_experiencer_name', headerName: '入会者', typename: 'string', width: 180, disableColumnMenu: true },
    { field: 'status_updated_at', headerName: 'ステータス変更日', typename: 'string', width: 180, disableColumnMenu: true },
    { field: 'year', headerName: '請求年', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'month', headerName: '請求月', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'paid', headerName: '支払ステータス', typename: 'string',  width: 150, disableColumnMenu: true},
    { field: 'discount', headerName: '割引', typename: 'string', minWidth: 180, disableColumnMenu: true },
    //{ field: 'issue_date', headerName: '請求日', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'paid_date', headerName: '支払日', typename: 'string', width: 120, disableColumnMenu: true },
    { field: 'payment_method', headerName: '支払方法', typename: 'number', width: 200, disableColumnMenu: true },
    //{ field: 'payment_reference', headerName: 'payment_reference', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'billing_detail_product', headerName: '申し込みID', typename: 'string', width: 120, disableColumnMenu: true },
    { field: 'billing_detail_unit', headerName: '個数', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'billing_detail_amount', headerName: '合計', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'tax_amount', headerName: '消費税', typename: 'string', minWidth: 180, disableColumnMenu: true },
    //{ field: 'billing_detail_percentage', headerName: 'billing_detail_percentage', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'billing_detail_discount', headerName: 'ディスカウント', typename: 'string',  width: 150, disableColumnMenu: true },
    { field: 'payment_method_update_from', headerName: '新支払い方法適用日', typename: 'string',  width: 150, disableColumnMenu: true },
    { field: 'issue_date', headerName: '請求日', typename: 'string',  width: 120, disableColumnMenu: true },
    { field: 'account_pref', headerName: '都道府県', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'account_city', headerName: '市区町村', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'account_address', headerName: '住所', typename: 'string', minWidth: 180, disableColumnMenu: true },
    { field: 'due_date', headerName: '支払い期限', typename: 'string',  width: 120, disableColumnMenu: true },
    { field: 'deposit_amount', editable: true, headerName: '入金額', typename: 'string',minWidth: 180, disableColumnMenu: true },
    { field: 'deposit_date', headerName: '入金日', typename: 'date', width: 130, disableColumnMenu: true },
    { field: 'note_billing', headerName: '備考', typename: 'string',  width: 120, disableColumnMenu: true },



    //{ field: 'status', headerName: 'ステータス', typename: 'number', width: 200, disableColumnMenu: true },
    //{ field: 'created', headerName: 'ステータス', typename: 'string', width: 300, disableColumnMenu: true },
    { field: 'action', headerName: '確認', width: 700, renderCell: renderActionCell },
  ]

  const fetchData = async (apolloClient:any) => {   
    for (let index = 0; index < 1; index++) {
    const dataFetch = await GetDataBilling(apolloClient, month, year , accountAddress, accountPref, accountCity, accountName,1,1,'','');        
    const dataConvert = dataFetch.dataBilling.map((bill: any, index: any) => ({ ...bill,status_updated_at: bill.status_updated_at? moment(bill.status_updated_at).format('YYYY-MM-DD') : '',deposit_date: bill.deposit_date? moment(bill.deposit_date).format('YYYY-MM-DD') : '', payment_method: bill.payment_method === 0 ? '銀行振り込み' : 'Stripe', id: bill.billing_detail_id, paid: bill.paid == 0 ? '未払い' : (bill.paid == 1 ? '支払済み' : 'エラー') }))
    setData(dataConvert);
    //const dataCountAccountId = await CountNumberDiffInList(dataConvert);
    const dataCountAccountId = dataFetch.data_count_account;

    setCountAccount(dataCountAccountId);
    setSumAmount(dataFetch.data_total_amount + dataFetch.data_total_tax_amount);
  }
    }
  const onChangeBtnSave = async () => {
    await exporter(data, BillingColumn, 'billing_view', translate);
  };

  // const onChangeValueInvoice = async (params: any) => {
  //   let column = params.field;
  //   let invoice_detail_id = params.id;
  //   let value = params.value;
  //   const checkNotNumber = isNaN(value);
  //   if (checkNotNumber) {
  //     notify(translate('common.must_be_number'), 'warning');
  //   } else {
  //     await updateTable(apolloClient, column, invoice_detail_id, value);
  //     notify(translate('common.update_billing_success'));
  //   }
  // }
  return <Box width='100%' height='55%' maxHeight='600px'>
    <ToolBar
      onChangeBtnSave={onChangeBtnSave}
      isDisable={!permission?.permission_all}
      onSelectBoxChange={handleSelectBoxChange}
      BillingMonth={month}
      BillingYear={year}
      AccountAddress={accountAddress}
      AccountPref={accountPref}
      AccountCity={accountCity}
      AccountName={accountName}
      listYear={listYear}
      reloadPage={reLoadClick}
    />
    <DetailBillingBar countAccount={countAccount} sumAmount={sumAmount} />
    <DataGrid
      rows={data}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize.pageSize)}
      columns={BillingColumn}
      //pageSize={15}
      className={classes.DataGrid}
      pagination
      rowsPerPageOptions= {[5,10,25,50]}
      //onCellValueChange={(params) => onChangeValueInvoice(params)}
    />
    <ResetPassButton
      is_open={showDialogReset}
      is_show_confirm={showConfirmReset}
      onClose={onClose}
      onCloseConfirm={onCloseConfirm}
      onSubmitConfirm={onSubmitConfirm}
      onChangeResetPass={onChangeResetPass}
      resetPassword={resetPassword}
      confirmResetPassword={confirmResetPassword}
      onConfirmDialog={onConfirmDialog}
      isDisable={!permission?.permission_all}
    />
  </Box>


}






export default ConfirmPage;

