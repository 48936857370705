import * as React from 'react';
import {
  Edit,
  FormDataConsumer,
  useTranslate,
} from 'react-admin';
import EditField from '../components/editField';
import { Grid } from '@material-ui/core';
import TabPanel, { a11yProps } from '../components/TabPanel';
import EditHistory from '../edit_history';
import { TabbedForm } from 'react-admin';
import { FormTab } from 'react-admin';
import { TextInput } from 'react-admin';
import InputRenderer from '../../utils/InputRenderer';
import { required } from 'react-admin';
import CreateToolbar from "../components/CreateToolbar";
import { InfoOutlined } from '@material-ui/icons';
import jaLocale from "date-fns/locale/ja";
import { ATTENTION, TYPE, LEVEL, LADIES, AGE_GROUP, IS_GROUP, IS_PREE, DISABILITY, PREF, ACCEPT_CONTACT, STATUS } from './dataset'
import { RadioButtonGroupInput } from 'react-admin';
import { CheckboxGroupInput } from 'react-admin';
import { ReferenceInput } from 'react-admin';
import { SelectInput } from 'react-admin';
import { useForm } from 'react-final-form';
import { useGetIdentity } from 'react-admin';
import { useNotify } from 'react-admin';
import SectionTitle from '../components/SectionTitle';
import { GetAllGenreSport } from './gql';
import { useEffect, useState } from 'react';
import { useDataProvider } from 'ra-core';
import { DateInput } from "../../utils/DatePicker";

const EventEdit = (props: any) => {
  const translate = useTranslate()
  const [value, setValue] = React.useState(0);
  const notify = useNotify();
  const requiredValidate = [required()];
  const { identity, loading: identityLoading } = useGetIdentity();

  if (identityLoading) {
    return <>Loading...</>;
  }
  const onFailure = (error: any) => {
    notify(`Could not create: ${error.message}`, 'warning');
  };
  const defaultValues = () => ({ created_by: identity.id });
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div>

      <TabPanel value={value} index={0}>
        <Edit
          {...props}
          successMessage="common.msg_update_success"
          undoable={false}
          className="formContent"
          title={<EditField titlePage={translate('menu.account.account_edit')} />}>
          <TabbedForm
            className="form"
            redirect="list"
            toolbar={<CreateToolbar />}
            initialValues={defaultValues}
            title={'edit'}
          >
            <FormTab label={translate("event.event_tab_1")} style={{ width: '10%', maxWidth: 'inherit' }} container justify='center' spacing={2} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab1 {...formDataProps} />
                  //初期設定情報
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("event.event_tab_2")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab2 {...formDataProps} />
                  //イベント情報
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("event.event_tab_3")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab3 {...formDataProps} />
                  //イベントのポイント
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("event.event_tab_4")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab4 {...formDataProps} />
                  //参加費用・要項
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("event.event_tab_5")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab5 {...formDataProps} />
                  //主催者情報
                )}
              </FormDataConsumer>
            </FormTab>
            <FormTab label={translate("event.event_tab_6")} style={{ width: '10%', maxWidth: 'inherit' }} >
              <FormDataConsumer>
                {(formDataProps: any) => (
                  <CreateEventTab6 {...formDataProps} />
                  // 注意事項など
                )}
              </FormDataConsumer>
            </FormTab>
          </TabbedForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditHistory {...props} />
      </TabPanel>
    </div>
  );
};
const CreateEventTab1 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <ReferenceInput
          allowEmpty={false}
          source='account_id'
          reference={'account'}
          //disabled={!editable && isEditPage}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.account_id')}
          //filter= {{status: 1}}
          filterToQuery={(searchText: any) => ({ ['name']: searchText })}>
          <SelectInput
            optionText={'name'}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <RadioButtonGroupInput
          source='attention'
          //validate={true}
          choices={ATTENTION}
          fullWidth
          variant="outlined"
          label={translate('event.attention')}
        />
      </Grid>
      <RadioButtonGroupInput
        source='status'
        //validate={true}
        choices={STATUS}
        fullWidth
        variant="outlined"
        label={translate('event.status')}
      />
    </div>
  );
};
const CreateEventTab2 = (props: any) => {
  console.log('props', props)
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [dataGenreSport, setdataGenreSport] = useState<any>([])
  const dataValidDateFrom = form.getState().values.valid_from;
  const dataValidDateto = form.getState().values.valid_to;

  const formData = props
  useEffect(() => {
    (async () => {
      let genre_id = 0;
      if (props.formData.genre_id) {
        genre_id = props.formData.genre_id
      }
      const dataGenreSport = await GetAllGenreSport(dataProvider, genre_id);
      setdataGenreSport(dataGenreSport);
    })()
  }, [props.formData.genre_id]);
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid item xs={4} >
        <DateInput
          source='valid_from'
          //disabled={!editable && isEditPage}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.valid_from')}
          providerOptions={{ locale: jaLocale }}
          options={{
            placeholder: '年/月/日',
            format: 'yyyy/MM/dd',
            ampm: false,
            cancelLabel: translate('common.btn_cancel'),
            okLabel: translate('common.btn_ok'), 
            minDate: dataValidDateFrom

          }}
        />
      </Grid>
      <Grid style={{ marginLeft: 15 }} item xs={4}  >
        <DateInput
          source='valid_to'
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.valid_to')}
          providerOptions={{ locale: jaLocale }}
          options={{
            placeholder: '年/月/日',
            format: 'yyyy/MM/dd',
            ampm: false,
            cancelLabel: translate('common.btn_cancel'),
            okLabel: translate('common.btn_ok'), 
            minDate: dataValidDateFrom
          }}
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='title'
          validate={requiredValidate}
          label={translate('event.title')}
          //placeholder={placeholder}
          fullWidth
          //multiline={}
          variant="outlined"
        />
        <TextInput
          source='sub_title'
          validate={requiredValidate}
          label={translate('event.sub_title')}
          //placeholder={placeholder}
          fullWidth
          //multiline={data.multiline}
          variant="outlined"
        />
      </Grid>
      <RadioButtonGroupInput
        source='type'
        validate={requiredValidate}
        choices={TYPE}
        fullWidth
        variant="outlined"
        label={translate('event.type')}
      />
      <CheckboxGroupInput
        source='level'
        validate={requiredValidate}
        choices={LEVEL}
        fullWidth
        variant="outlined"
        label={translate('event.level')}
      />
      <Grid xs={6} style={{ width: '50%' }} >
        <ReferenceInput
          allowEmpty={false}
          source='genre_id'
          reference={'genre'}
          //disabled={!editable && isEditPage}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.genre_id')}
          //filter= {{status: 1}}
          filterToQuery={(searchText: any) => ({ ['name']: searchText })}>
          <SelectInput
            optionText={'name'}
          />
        </ReferenceInput>
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <SelectInput
          allowEmpty={false}
          source='genre_sport_id'
          reference={'genre_sport'}
          //disabled={!editable && isEditPage}
          choices={dataGenreSport}
          validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.genre_sport_id')}
        >
        </SelectInput>
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='description'
          //validate={requiredValidate}
          label={translate('event.description')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='covid_note'
          //validate={requiredValidate}
          label={translate('event.covid_note')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='comment'
          //validate={requiredValidate}
          label={translate('event.comment')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='video_url'
          //validate={requiredValidate}
          label={translate('event.video_url')}
          //placeholder={translate('event.comment')}
          fullWidth
          //multiline={data.multiline}
          variant="outlined"
        />
      </Grid>

      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image1_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image2_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image3_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image4_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image5_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image6_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image7_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image8_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'image9_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
    </div>
  );
}
const CreateEventTab3 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div style={{ width: '98%' }}>
      <div className="formContent" style={{ width: '98%', marginTop: '15px' }}>
        <Grid xs={6} style={{ width: '50%' }} >
          <TextInput
            source='title1'
            validate={requiredValidate}
            label={translate('event.title1')}
            //placeholder={placeholder}
            fullWidth
            //multiline={}
            variant="outlined"
          />
          <TextInput
            source='body1'
            validate={requiredValidate}
            label={translate('event.body1')}
            //placeholder={placeholder}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid>
        <Grid xs={6} style={{ width: '50%' }}>
          <InputRenderer source='event' data={{ name: 'image10_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
        </Grid>
      </div>
      <div className="formContent" style={{ width: '98%', marginTop: '15px' }}>
        <Grid xs={6} style={{ width: '50%' }} >
          <TextInput
            source='title2'
            label={translate('event.title2')}
            //placeholder={placeholder}
            fullWidth
            //multiline={}
            variant="outlined"
          />
          <TextInput
            source='body2'
            label={translate('event.body2')}
            //placeholder={placeholder}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid>
        <Grid xs={6} style={{ width: '50%' }}>
          <InputRenderer source='event' data={{ name: 'image11_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
        </Grid>
      </div>
      <div className="formContent" style={{ width: '98%', marginTop: '15px' }}>
        <Grid xs={6} style={{ width: '50%' }} >
          <TextInput
            source='title3'
            label={translate('event.title3')}
            fullWidth
            //multiline={}
            variant="outlined"
          />
          <TextInput
            source='body3'
            label={translate('event.body3')}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid>
        <Grid xs={6} style={{ width: '50%' }}>
          <InputRenderer source='event' data={{ name: 'image12_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
        </Grid>
      </div>
      <div className="formContent" style={{ width: '98%', marginTop: '15px' }}>
        <Grid xs={6} style={{ width: '50%' }} >
          <TextInput
            source='title4'
            label={translate('event.title4')}
            //placeholder={placeholder}
            fullWidth
            //multiline={}
            variant="outlined"
          />
          <TextInput
            source='body4'
            label={translate('event.body4')}
            //placeholder={placeholder}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid>
        <Grid xs={6} style={{ width: '50%' }}>
          <InputRenderer source='event' data={{ name: 'image13_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
        </Grid>
      </div>
    </div>
  );
}
const CreateEventTab4 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <RadioButtonGroupInput
          source='is_free'
          choices={IS_PREE}
          //validate={requiredValidate}
          fullWidth
          variant="outlined"
          label={translate('event.is_free')}
        />
        <RadioButtonGroupInput
          source='is_group'
          validate={requiredValidate}
          choices={IS_GROUP}
          fullWidth
          variant="outlined"
          label={translate('event.is_group')}
        />
        <CheckboxGroupInput
          source='age_group'
          choices={AGE_GROUP}
          fullWidth
          variant="outlined"
          label={translate('event.age_group')}
        />
        <RadioButtonGroupInput
          source='disability'
          //validate={requiredValidate}
          choices={DISABILITY}
          fullWidth
          variant="outlined"
          label={translate('event.disability')}
        />
        <RadioButtonGroupInput
          source='ladies'
          choices={LADIES}
          fullWidth
          variant="outlined"
          label={translate('event.ladies')}
        />
        <TextInput
          source='capacity'
          validate={requiredValidate}
          label={translate('event.capacity')}
          //placeholder={placeholder}
          fullWidth
          //multiline={}
          variant="outlined"
        />
        <TextInput
          source='capacity_note'
          label={translate('event.capacity_note')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='application_method'
          label={translate('event.application_method')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='application_method_note'
          label={translate('event.application_method_note')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='payment_method'
          label={translate('event.payment_method')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='fee_note'
          label={translate('event.fee_note')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
        <TextInput
          source='access'
          label={translate('event.access')}
          //placeholder={placeholder}
          fullWidth
          multiline={true}
          variant="outlined"
        />
      </Grid>
    </div>
  );
}
const CreateEventTab5 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='organizer_name'
          validate={requiredValidate}
          label={translate('event.organizer_name')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <Grid xs={6} style={{ maxWidth: '70%' }}>
          <Grid xs={5} style={{ maxWidth: '80%' }} >
            <TextInput
              source='organizer_post_code'
              validate={requiredValidate}
              label={translate('event.organizer_post_code')}
              //placeholder={placeholder}
              fullWidth
              variant="outlined"
            />
            {/* <Button style={{ width: '50%' }} label="click to gen"></Button> */}
          </Grid>
          <SelectInput
            source='organizer_pref'
            validate={requiredValidate}
            label={translate('event.organizer_pref')}
            //placeholder={placeholder}
            choices={PREF}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <TextInput
          source='organizer_city'
          validate={requiredValidate}
          label={translate('event.organizer_city')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='organizer_address1'
          //validate={requiredValidate}
          label={translate('event.organizer_address1')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <TextInput
          source='organizer_address2'
          //validate={requiredValidate}
          label={translate('event.organizer_address2')}
          //placeholder={placeholder}
          fullWidth
          variant="outlined"
        />
        <Grid>
          <TextInput
            source='additional_note'
            label={translate('event.additional_note')}
            //placeholder={placeholder}
            fullWidth
            multiline={true}
            variant="outlined"
          />
        </Grid>
        <Grid>
          <TextInput
            source='admission_fee'
            label={translate('event.admission_fee')}
            //placeholder={placeholder}
            fullWidth
            variant="outlined"
          />
        </Grid>
        <Grid>
          <RadioButtonGroupInput
            source='accept_contact'
            choices={ACCEPT_CONTACT}
            validate={requiredValidate}
            fullWidth
            variant="outlined"
            label={translate('event.accept_contact')}
          />
        </Grid>
      </Grid>
    </div>
  );
}
const CreateEventTab6 = (props: any) => {
  const form = useForm();
  const requiredValidate = [required()];
  const translate = useTranslate();
  const formData = props
  return (
    <div className="formContent" style={{ width: '98%' }}>
      <Grid xs={6} style={{ width: '50%' }} >
        <TextInput
          source='cancel_policy'
          label={translate('event.cancel_policy')}
          //placeholder={placeholder}
          multiline={true}
          fullWidth
          variant="outlined"
        />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'data1_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'data2_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>
      <Grid xs={6} style={{ width: '50%' }}>
        <InputRenderer source='event' data={{ name: 'data3_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true }} />
      </Grid>

    </div>
  );
}
export default EventEdit;
