import * as React from 'react';
import {FC} from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {useTranslate} from 'react-admin';
import {stringify} from 'query-string';

import {Account, Company, FieldProps} from '../../types';

const useStyles = makeStyles({
    icon: { paddingRight: '0.5em' },
    link: {
        display: 'inline-flex',
        alignItems: 'left',
        margin:'0 20px',
    },
});
const stopPropagation = (e: any)=> e.stopPropagation();

const LinkToTeams: FC<FieldProps<Account>> = ({ record }) => {
    const translate = useTranslate();
    const classes = useStyles();
    return record != null ? (
        <Button
            size="small"
            color="primary"
            component={Link}
            onClick={stopPropagation}
            to={{
                pathname: `/team_list`,
                search: stringify({
                    page: 1,
                    perPage: 25,
                    sort: 'id',
                    order: 'DESC',
                    filter: JSON.stringify({ account_id: record.id }),
                }),
            }}
            className={classes.link}
        >
            {translate('account.list_team')}
        </Button>
    ) : null;
};

export default LinkToTeams;
