import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';
import { NumberInput } from 'react-admin';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();

  if (id) {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  } else {
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const From = (props: any) => {
  const translate = useTranslate();
  const { editForm } = props;
  var isEditPage = editForm === true ? true : false;
  if (editForm) {
    var { record: { id } } = props;
  }
  return <Container>

    <Grid container justify='center' style={{ width: '100%' }} spacing={2}>

      <Grid style={{ width: '50%' }} container spacing={2} justify='center'>
        <Grid style={{ width: '100%' }} >
          <SectionTitle label={editForm ? "menu.event_place.page_edit" : "menu.event_place.page_create"} id={editForm ? id : ''} />
          <Separator />
        </Grid >
        {Fields.map((d, idx) => {
          if (d.type === 'hidden') {
            return <></>
          }
          if (d.name === 'created' && isEditPage === false) {
            return <></>
          }
          if (d.name === 'applicants') {

            return <>
              <Grid item xs={12} key={idx} className='note-applicants-grid'>
                <NumberInput
                  source={'applicants'}
                  //disabled={(editable === false) ? 'disabled' : ''}
                  label={translate(`${'event_plan'}.${'applicants'}`)}
                  fullWidth
                  variant="outlined"
                />
                <div className='note-applicants'>
                  
                <p style={{"fontSize": 12}}>※このプランに申込された方を、参加確定ステータスに変更し、参加確定人数が募集人数に達した場合に「募集人数に達しました」と表示され、プランの申込みができなくなります。 </p>
                <p style={{"fontSize": 12}}>※募集人数を0人にすることで、申込受付を停止することができます。</p>
                 
                </div>
              </Grid>
            </>
          }
          return <Grid item xs={12} key={idx}>
            <InputRenderer source='event_plan' data={d} />
          </Grid>
        })}
      </Grid>
    </Grid>
  </Container>;
};

export default From;