import * as React from 'react';
import {Fragment, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Confirm,
    useDeleteMany,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
    use
} from 'react-admin';
import { useDataProvider } from 'react-admin';
import moment from 'moment';

const BulkDeleteButtonAction = (props :any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [user_ids, setUserIds] = useState<number[]>(selectedIds);
    let list_user_delete = selectedIds;
    const {
        resource,
    } = useListContext();

    const [deleteMany, { loading }] = useDeleteMany(
        resource,
        user_ids,
        {
            onSuccess: () => {
                refresh();
                notify(translate('common.msg_delete'));
                unselectAll(resource);
            },
            onFailure: () => notify(translate('common.msg_delete_fail'), 'warning'),
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const UpdateData = async (list_user_id: number[]) => {
        const dataNow =  moment().utcOffset('+0900').format('YYYYMMDD');
        for (const user_id of list_user_id) {
            const {data: dataUser} = await dataProvider.getOne('user', {id: user_id});
            const {data: dataAnswer} = await dataProvider.getOne('answer', {answered_by: user_id});
            const {data: dataQuestion}  = await dataProvider.getOne('question', {user_id: user_id});
            if(dataAnswer || dataQuestion) {
                let splitEmail = dataUser.email.split('_');                
                const dataUpdateUser = await dataProvider.update('user', {
                    id: user_id, 
                    data: {
                        status: 0, 
                        email: splitEmail.length === 3 ? `DELETED_${dataNow}_${splitEmail[2]}` : `DELETED_${dataNow}_${dataUser.email}`, 
                        deleted: true
                    }
                })
                list_user_delete = list_user_delete.filter((x: number) => x !==  user_id);
                setUserIds(list_user_delete);
                console.log('list_user_delete',list_user_delete);
            }
            continue;
        }
    }
    const handleConfirm = async () => {
        await UpdateData(selectedIds);
        deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate('common.btn_delete')} onClick={handleClick} startIcon={<DeleteIcon />} style={{color:'#f44336'}}/>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate('common.msg_title_delete')}
                content={translate('common.msg_delete_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButtonAction;