import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';


const CustomField = (props: any) => {
  const { record } = props;
  if(record.status === 0 || ( record.status === 1 && (!record.lastname || !record.firstname))){
    return <span>無効</span>;
  } else {
    return <span>有効</span>;
  }
}

const CustomReferField = (props: any) => {
  return (
  <>
      <CustomField {...props}/>
  </>)
}

CustomReferField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default CustomReferField;