import React, {useState} from 'react'
import {Box, Button, Card, CardContent, CircularProgress, Typography} from '@material-ui/core'
import {Title, useTranslate} from 'react-admin';
import {useHistory} from 'react-router-dom'
import {renderInput} from '../../utils/renderInput';
import gql from "graphql-tag";
import {Field, withTypes} from "react-final-form";
import {ApolloError, useMutation} from "@apollo/client"
import {refreshSaga, useNotify, useRefresh} from "ra-core";
import {ChangePasswordRequest, ChangePasswordVariables} from "../../generated/changePassword";

const CHANGE_PASSWORD = gql`
    mutation changePasswordAdmin($old_password: String!, $new_password: String!, $confirm_password: String!) {
        change_password_admin(old_password: $old_password, new_password: $new_password,confirm_password: $confirm_password) {
            isSuccess
            status_code
        }
    }
`;

interface FormValues {
    oldPassword: string;
    password: string;
    confirmPassword: string;
}

interface FormValuesError {
    password?: string;
    oldPassword?: string;
    confirmPassword?: string;
}

const { Form } = withTypes<FormValues>();

const ChangePassword = () => {
    const translate = useTranslate();
    const onError = (error: ApolloError) => {
        notify(error.message, 'warning');
    };
    const [changePass] = useMutation<ChangePasswordRequest, ChangePasswordVariables>(CHANGE_PASSWORD, {onError, onCompleted: ()=> {
        history.push('change-password'); 
        }
    });
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const refresh = useRefresh();
    const notify = useNotify();

    const validate = (values: FormValues) => {
        const errors: FormValuesError = {};
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }else if (values.password.length <= 5) {
            errors.password = translate('common.minLenght');
        }
        if (!values.oldPassword) {
            errors.oldPassword = translate('ra.validation.required');
        }
        if (values.password !== values.confirmPassword) {
            errors.confirmPassword = translate('common.msg_confirm_not_match');
        }
        return errors;
    };

    const handleChangePassword = async (values: FormValues) => {
        setLoading(true);
        const {data} = await changePass({
            variables: {
                new_password: values.password,
                old_password: values.oldPassword,
                confirm_password: values.confirmPassword
            },
        });
        if(data?.change_password_admin?.status_code !== 200){
            setLoading(false);
            notify(translate('changePassword.error'), 'warning');
        }else{
            notify(translate('changePassword.msg_complete'), 'info');
            setLoading(false);
            history.goBack(); 
        }
        
    };

    return (
        <Card>
            <Title title={translate('common.configuration')} />
            <CardContent>
                <Box className="form" style={{ width: '50%', margin: 'auto' }}>
                    <Form
                        onSubmit={handleChangePassword}
                        validate={validate}
                        render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} >
                                <SectionTitle label="changePassword.title" />
                                <Separator />
                                <Field
                                    autoFocus
                                    name="oldPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    variant="outlined"
                                    label={translate('changePassword.oldPassword')}
                                    type="password"
                                    disabled={loading}
                                    style={{ marginBottom: 35 }}
                                />
                                <Field
                                    name="password"
                                    // @ts-ignore
                                    component={renderInput}
                                    variant="outlined"
                                    label={translate('changePassword.new_password')}
                                    type="password"
                                    disabled={loading}
                                    style={{ marginBottom: 35 }}
                                />

                                <Field
                                    name="confirmPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    variant="outlined"
                                    label={translate('changePassword.confirm_password')}
                                    type="password"
                                    disabled={loading}
                                    style={{ marginBottom: 35 }}
                                />

                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('changePassword.btn_change_password')}
                                </Button>
                            </form>
                        )}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};


const SectionTitle = ({ label }: { label: string }) => {
    const translate = useTranslate();

    return (
        <Typography variant="h6" gutterBottom>
            {translate(label)}
        </Typography>
    );
};
const Separator = () => <Box pt="1em" />;

export default ChangePassword;
