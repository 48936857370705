import * as React from 'react';
import { FC } from 'react';
import { Chip } from '@material-ui/core';
import { FieldProps, Account } from '../../types';
import { useTranslate } from 'react-admin';

const StatusField: FC<FieldProps<Account>> = ({ record }) => {
    const translate = useTranslate();
    let label = '';
    let background = '';
    switch (record?.status) {
        case 0:
            label = translate('common.valid');
            background = '#3CD4A0'
            break;
        case 1:
            label = translate('common.invalid');
            background = '#FF5C93'
            break;
        case 2:
            label = translate('common.waiting_mail');
            background = '#FFD700'
            break;

        default:
            break;
    }
    return record ? (
        <span>
            <Chip
                label={
                    label
                }
                style={{
                    color: '#fff',
                    height: 16,
                    backgroundColor:background,
                    fontSize: 11,
                    fontWeight: 'bold',
                }}
            />
        </span>

    ) : null;
};
export default StatusField;
