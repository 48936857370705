import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import { GET_ALL_TEAM_ACCOUNT } from './gql';

const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any, apolloClient: any) => {
  const datateam = await GET_ALL_TEAM_ACCOUNT(apolloClient);
  let dataExport: any = items;
  let data: any = dataExport.map((item: any) => {
    const dataFilter = datateam.filter((x: any) => x.id === item.team_contact_id);
    let account_name = '';
    let account_source = '';
    let contact_type = '';
    let team_name = '';
    if (dataFilter[0] && dataFilter[0].team && dataFilter[0].team.account) {
      account_name = dataFilter[0].team.account.name
      account_name = (dataFilter[0].team && dataFilter[0].team.account && dataFilter[0].team.account.name) ? dataFilter[0].team.account.name: ''
      account_source = (dataFilter[0].team && dataFilter[0].team.account && dataFilter[0].team.account.source) ? dataFilter[0].team.account.source: ''
      team_name = dataFilter[0].team.name
    }
    let text_status = '未対応';
    switch (item.status) {
      case 0:
        text_status = "未対応"
        break;
      case 1:
        text_status = "体験中"
        break;
      case 2:
        text_status = "キャンセル"
        break;
      case 3:
        text_status = "入会"
        break;
      case 4:
        text_status = "体験終了"
        break;
      case 5:
        text_status = "辞退"
        break;
      default:
        break;
    }
    let genre_text = '未対応';
    switch (item.gender) {
      case 0:
        genre_text = "不明"
        break;
      case 1:
        genre_text = "男性"
        break;
      case 2:
        genre_text = "女性"
        break;
      case 3:
        genre_text = "未選択"
        break;
      default:
        break;
    }
    const birthday = item.birthday;
    let dob = new Date(birthday);  
    let month_diff = Date.now() - dob.getTime();  
    let age_dt = new Date(month_diff);  
    let year = age_dt.getUTCFullYear(); 
    let age = Math.abs(year - 1970);
    return {
      ...item, 
      account_name: account_name, 
      team_name: team_name,
      contact_type: item.change_from_inquiry_flag === true ? '問合せ' : ( item.contact_type == 2 ? '体験申込み' : (item.contact_type == 3 ? '入会申込み' : '見学申し込み')),
      status: text_status,
      gender: genre_text,
      already_in_sports: item.already_in_sports === true ? '同スポーツの団体に所属している' : '同スポーツの団体に所属していない',
      same_user_id: item.same_user_id === false ? '申込み本人ではない' : '申込み本人',
      account_source: account_source,
      team_contact_id: `${item.lastname} ${item.firstname}`,
      age: age
    }
  })
  await fetchRelatedRecords(data, 'user_id', 'user').then((user: any) => {
    const data_user = data.map((item: any) => (
      {
        ...item,
        user_id: user[item.user_id] ? user[item.user_id].id : '',
        user_phone: user[item.user_id] ? user[item.user_id].phone : '',
        user_email: user[item.user_id] ? user[item.user_id].email : '',
        user_name: user[item.user_id]? user[item.user_id].lastname +  ' ' +user[item.user_id].firstname : ''
      }
      ));
      data = data_user;
  });
  await fetchRelatedRecords(data, 'created_by', 'user').then((user: any) => {
    const data_created_by = data.map((item: any) => (
      {
        ...item,
        created_by: user[item.created_by].lastname + ' ' + user[item.created_by].firstname
      }
    ));
    data = data_created_by;
  });
  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.name)

  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }
    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;


