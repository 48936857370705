export const STATUS = [
    {id: 0, name: "無効"},
    {id: 1, name: "有効"},
    {id: 2, name: "メール認証待ち"}
  ]
const DATA = [
{ name: 'id', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'account_id', type: 'reference', reference: 'account',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'user_id', type: 'reference', reference: 'user',searchField: 'lastname', nullable: false, editable: false, export: false, import: true },
{ name: 'user_id', type: 'reference', reference: 'user',searchField: 'email', nullable: false, editable: false, export: true, import: true },
{ name: 'status', type: 'status',options:STATUS, nullable: false, editable: true, export: true, import: true },
{ name: 'created',type: 'date', nullable: true, editable: false, export: true, import: true },
{ name: 'created_by', numberFormat:true,nullable: true, editable: false, export: true, import: true },
{ name: 'user_comment', type: 'textinput',multiline:true, nullable: true, editable: true, export: true },
{ name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },

]
export default DATA;