import * as React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import Switch from '@material-ui/core/Switch';
import event from '../event';
import { UpdateContactFlag } from './gql';
import { useApolloClient } from '@apollo/client';
import { useNotify } from 'react-admin';

const useStyles = makeStyles({
  icon: { paddingRight: '0.5em' },
  link: {
    display: 'inline-flex',
    alignItems: 'left',
    margin: '0 20px',
  },
});
const stopPropagation = (e: any) => e.stopPropagation();


const ToggleSwitch = (props: any, onChange: any) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { record } = props;
  const [checked, setChecked] = useState(false);
  const apolloClient = useApolloClient();
  const notify = useNotify();
  useEffect(() => {
    setChecked(record.flag_disable);
  }, []);

  const handleChange = async (checked: any, record: any) => {
    const team_contact_id = record.id;
    setChecked(checked);
    const dataUpdateFlag = await UpdateContactFlag(apolloClient, team_contact_id, checked);
    if (dataUpdateFlag === 1) {
      notify(translate('common.msg_update_success'));
    } else if (dataUpdateFlag === 0) {
      notify(translate('common.msg_update_error'), 'warning')
    }

  }
  return <>
    <Switch checked={checked} onChange={(event: any) => {
      handleChange(event.target.checked, record)
    }}></Switch>
  </>
};

export default ToggleSwitch;
