import { ApolloCache, ApolloClient, gql } from "@apollo/client";

export const GET_DATA_ADMIN = gql`
query MyQuery {
  admin(where: {is_deleted: {_eq: false}}) {
    id
    email
    created_by
    created
    permission_all
    permission_create_edit
    permission_approve
    permission_genre
    permission_payment
    permission_view
    refresh_token
    status
    is_deleted
  }
}
`;
export const GET_DATA_ADMIN_DRAFT = gql`
query MyQuery {
  admin(where: {is_deleted: {_eq: true}}) {
    id
    email
    created_by
    created
    permission_all
    permission_create_edit
    permission_approve
    permission_genre
    permission_payment
    permission_view
    refresh_token
    status
    is_deleted
  }
}
`;
export const GET_DATA_ADMIN_CHECK = gql`
query MyQuery($email: String) {
  admin(where: {email: {_eq: $email}}) {
    id
    email
    created_by
    created
    permission_all
    permission_create_edit
    permission_approve
    permission_genre
    permission_payment
    permission_view
    refresh_token
    status
    is_deleted
  }
}
`;


export const CREATE_ADMIN = gql`
mutation MyMutation($email: String!, $password: String!) {
  register_admin(email: $email, password: $password) {
    email
    error_code
    status_code
    error_message
    admin_id
  }
}
`; 
export const DELETE_ADMIN = gql`
mutation MyMutation($id: Int!) {
  update_admin_by_pk(pk_columns: {id: $id}, _set: {is_deleted: true}) {
    id
    is_deleted
  }
}
`; 
export const RESTORE_ADMIN = gql`
mutation MyMutation($id: Int!) {
  update_admin_by_pk(pk_columns: {id: $id}, _set: {is_deleted: false}) {
    id
    is_deleted
  }
}
`; 
export const DELETE_ADMIN_PERMENANTLY = gql`
mutation MyMutation($id: Int!) {
  delete_admin_by_pk( id: $id) {
    id
    is_deleted
  }
}
`; 
export const UPDATE_PERMISSION_ADMIN = gql`
mutation MyMutation($list_admin: [Admin!]!) {
  update_perrmission_admin(list_admin: $list_admin) {
    error_code
    error_message
    status_code
  }
}
`; 
export const GET_ADMIN_BY_ID = gql`
query MyQuery($id: Int!) {
  admin_by_pk(id: $id) {
    id
    email
    created_by
    created
    permission_all
    permission_create_edit
    permission_approve
    permission_genre
    permission_payment
    permission_view
    refresh_token
    status
    is_deleted
  }
}`; 
export const RESET_PASSWORD_ADMIN = gql`
mutation reset_pass($admin_id: Int!, $new_password: String!) {
  reset_password_admin(admin_id: $admin_id,new_password: $new_password) {
    error_code
    error_message
    isSuccess
    status_code
  }
}
`

export const GetDataAdmin = async (apolloClient: any) => {
  let { data: dataAdmin } = await apolloClient.query({
    query: GET_DATA_ADMIN,
    variables: {
    }
  });
  return dataAdmin.admin;
}
export const GetDataAdminDraft = async (apolloClient: any) => {
  let { data: dataAdmin } = await apolloClient.query({
    query: GET_DATA_ADMIN_DRAFT,
    variables: {
    }
  });
  return dataAdmin.admin;
}
export const GetDataAdminById = async (apolloClient: any, admin_id: number) => {
  let { data: dataAdminOne } = await apolloClient.query({
    query: GET_ADMIN_BY_ID,
    variables: {
      id: admin_id
    }
  });
  return dataAdminOne.admin_by_pk;
}
export const RegisterAdmin = async (apolloClient: any, email: string, password: string) => {
  const removeSpaceEmail = email.replace(/\s/g,'');
  let status_code = 0;
  let { data: dataAdmin } = await apolloClient.query({
    query: GET_DATA_ADMIN_CHECK,
    variables: {
      email: removeSpaceEmail
    }
  });
  
  let { data: dataCreateAdmin, errors: errorInsert} = await apolloClient.mutate({
    mutation: CREATE_ADMIN,
    variables: {
      email: removeSpaceEmail, 
      password: password
    }
  });
  status_code = dataCreateAdmin.register_admin.status_code
  if(dataAdmin.admin.length > 0){
    status_code = dataAdmin.admin[0].is_deleted === true ?  204 : 205
  }
  const dataRes = {
    status_code: status_code, 
    admin_id: dataCreateAdmin.register_admin.admin_id
  }
  return dataRes;
}
export const DeleteAdmin = async (ApolloClient: any, admin_id: number) => {
  let {data: dataDelete} = await ApolloClient.mutate({
    mutation: DELETE_ADMIN,
    variables: {
      id: admin_id
    }
  })
  return { id: dataDelete.update_admin_by_pk.id}
}
export const RestoreAdmin = async (ApolloClient: any, admin_id: number) => {
  let {data: dataRestore} = await ApolloClient.mutate({
    mutation: RESTORE_ADMIN,
    variables: {
      id: admin_id
    }
  })
  return { id: dataRestore.update_admin_by_pk.id}
}
export const DeleteAdminPermanently = async (ApolloClient: any, admin_id: number) => {
  let {data: dataDelete} = await ApolloClient.mutate({
    mutation: DELETE_ADMIN_PERMENANTLY,
    variables: {
      id: admin_id
    }
  })
  return { id: dataDelete.delete_admin_by_pk.id}
}
export const UpdatePermissionAdmin = async (ApolloClient: any, list_admin: any) => {
  let {data: dataUpdatePermission} = await ApolloClient.mutate({
    mutation: UPDATE_PERMISSION_ADMIN,
    variables: {
      list_admin: list_admin
    }
  })
  return { status_code: dataUpdatePermission.update_perrmission_admin.status_code}
}
export const ResetPassAdmin = async (ApolloClient: any, admin_id: number, new_password: string) => {
  let {data: dataResetPass} = await ApolloClient.mutate({
    mutation: RESET_PASSWORD_ADMIN,
    variables: {
      admin_id: admin_id,
      new_password: new_password
    }
  })
  return { status: dataResetPass.reset_password_admin.status_code, message: dataResetPass.reset_password_admin.error_message}
}