import { gql } from "@apollo/client";
export const RECOVER_USER = gql`
mutation MyMutation($list_user_id: [Int!]) {
  update_user(where: {id: {_in: $list_user_id}}, _set: {is_soft_deleted: false}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const RecoverMultiUser = async (apolloClient: any,list_user_id: number[]) => {
  let status = 0;
  try{
    let { data: dataRecoverMulti } = await apolloClient.mutate({
      mutation: RECOVER_USER,
      variables: {
        list_user_id: list_user_id
      }
    });
    status = 1;
  }catch(e){
    status = 0
  }
 
  return status;
}