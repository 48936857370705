import * as React from 'react';
import {Chip, Grid} from '@material-ui/core';
import {useTranslate} from 'react-admin';
import { TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import {EnrolledListType,EnrolledType} from './type'


const EnrollOpenField = ({props, dataForm, dataEnable,ListAge }: {props: any,dataForm: EnrolledListType, dataEnable: EnrolledType[], ListAge: string[]} ) =>{
    const form = useForm();
    const translate = useTranslate();
    var isDisable = 'disable';
    var enrolled = form.getState().values.enrolled as EnrolledType[];
    var data = (dataEnable || []).filter(x => x.name === dataForm.name);
    var AgeEnable = ListAge.filter(x => x === dataForm.name);
    if(AgeEnable[0]){
      isDisable = '';
  }
  if(data[0]){
      isDisable = '';
      let dataOpen = data[0].open;
      form.change(`age_open_${dataForm.id}`,dataOpen)
  }
    return  <Grid className='EnrolledOpenForm'>
    <p className={'RowOpenTitle'}>{dataForm.name}</p>
    <TextInput
      source={`age_open_${dataForm.id}`}
      disabled={isDisable}
      label={translate(`team.age_open_${dataForm.id}`)}
      fullWidth
      multiline={true}
      variant="outlined"
      onChange={(event: any) => {
        if(data[0]){
          let objIndex = dataEnable.findIndex((obj => obj.name == dataForm.name));
          dataEnable[objIndex].open = event.target.value;
      }else{
          const current = form.getState().values[`age_${dataForm.id}`]
          let dataPush:EnrolledType = {name:dataForm.name, current: current, open:  event.target.value}
          enrolled.push(dataPush);
      }
      }}
    />
  </Grid>
}
export default EnrollOpenField;
