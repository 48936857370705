// Hook
import {useState} from "react";

function useLocalStorage<T>(key: string, initialValue: any) {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            if(item && !item.startsWith("{")) {
                return item;
            }
            // Parse stored json or if none return initialValue
            if (item) {
                let jsonObj: any = JSON.parse(item);
                return (jsonObj as T);
            } else {
                return initialValue;
            }
        } catch (error) {
            // If error also return initialValue
            console.log(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);

            if (typeof value === 'string') {
                window.localStorage.setItem(key, value);
                return
            }
            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;