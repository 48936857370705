import * as React from 'react';
import {Fragment, useState} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import {
    Button,
    Confirm,
    useListContext,
    useNotify,
    useRefresh,
    useTranslate,
    useUnselectAll,
} from 'react-admin';
import { GetDataTeamReview, hardDeleteMultiTeamReview, softDeleteMultiTeamReview } from './gql';
import { useApolloClient } from "@apollo/client";


const BulkDeleteButtonAction = (props :any) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const { selectedIds } = props;
    const apolloClient = useApolloClient();
    const translate = useTranslate();
    // Get team_review to check publish
    const {
        resource,
    } = useListContext();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const deleteMany = async () => {
        const dataIdsTeamReview = await GetDataTeamReview(apolloClient,selectedIds);        

        const ResponseSoft = await softDeleteMultiTeamReview(apolloClient,dataIdsTeamReview.dataSoftDelete);
        const ResponseHard = await hardDeleteMultiTeamReview(apolloClient,dataIdsTeamReview.dataHardDelete);

        if (ResponseSoft === 1 || ResponseHard === 1) {
            refresh();
            notify(translate('common.msg_delete'));
            unselectAll(resource);
        }else if (ResponseSoft === 0 || ResponseHard === 0) {
            notify(translate('common.msg_delete_fail'), 'warning')
        }
    }
    const handleConfirm = async () => {
        await deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate('common.btn_delete')} onClick={handleClick} startIcon={<DeleteIcon />} style={{color:'#f44336'}}/>
            <Confirm
                isOpen={open}
                //loading={loading}
                title={translate('common.msg_title_delete')}
                content={translate('common.msg_delete_confirm')}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
};

export default BulkDeleteButtonAction;