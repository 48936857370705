const STATUS = [
  {id: 0, name: "下書き"},
  {id: 1, name: "募集中"},
  {id: 2, name: "募集終了"},
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '',options:[],numberFormat:true,reference: '', nullable: true, editable: false, export: true, import: true },
  //{ name: 'genre_id',searchField: '', options: [], type: 'reference',reference: 'genre', nullable: false, editable: true, export: true, import: true },
  { name: 'account_name', type: 'reference', nullable: true, editable: true, export: true, import: true },

  { name: 'team_id', type: 'reference',reference: 'team',searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'publish_start_date', type: 'date',nullable: true, editable: true, export: true, import: true },
  { name: 'publish_end_date', type: 'date',nullable: false, editable: true, export: true, import: true },
  { name: 'genre_id', type: 'reference',reference: 'genre',searchField: 'name', nullable: false, editable: true, export: true, import: true },
  { name: 'sport_genre_id', type: 'reference',reference: 'genre_sport',searchField: 'secondary_name',nullable: false, editable: true, export: true, import: true },
  { name: 'game_date', type: 'date',nullable: true, editable: true, export: true, import: true },
  { name: 'game_start_time', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'game_end_time', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'title', type: 'textinput',nullable: false, editable: true, export: true, import: true },
  { name: 'body', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'post_code', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'area', type: 'reference',reference: 'area',searchField: 'name',nullable: true, editable: true, export: true, import: true },
  { name: 'pref', type: 'reference',reference: 'pref',searchField: 'name',nullable: true, editable: true, export: true, import: true },
  { name: 'city', type: 'reference',reference: 'city',searchField: 'name',nullable: true, editable: true, export: true, import: true },
  { name: 'area_name', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'pref_name', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'city_name', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'address', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'building', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'place', type: 'textinput',nullable: true, editable: true, export: true, import: true },
  { name: 'status', type: 'selectbox',options:STATUS,nullable: false, editable: true, export: true, import: true },
  { name: 'male', type: 'boolean',nullable: true, editable: true, export: true, import: true },
  { name: 'female', type: 'boolean',nullable: true, editable: true, export: true, import: true },
  //{ name: 'age', type: 'textinput',nullable: true, editable: true, export: true, import: true },

  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,nullable: true, editable: false, export: false, import: true },
]

export default DATA;