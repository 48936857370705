import { gql } from "@apollo/client";
export const FETCH_ALL_TEAM = gql`
query MyQuery {
  team {
    id
    account {
      name
    }
  }
}
`;
export const FETCH_ALL_TEAM_BY_TEAM_CONTACT = gql`
query MyQuery {
  team_contact {
    team {
      name
      account {
        name
        source
      }
    }
    id
  }
}
`;
export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;

export const FETCH_DATA_CHAT_TEAM_CONTACT = gql`
query MyQuery($team_experiencer_id: Int) {
  team_experiencer(where: {id: {_eq: $team_experiencer_id}}) {
    team_contact {
      team_contact_messages(  order_by: {created: asc}) {
        message
        id
        created
        viewed
        assigned_team_admin_id
         viewed
      }
      team{
        name
      }
    }
    firstname
    lastname
    user{
      image_url
    }
  }
}`;
export const UPDATE_FLAG_TEAM_EXPERIENCER = gql`
mutation MyMutation($id: Int!, $flag_disable: Boolean) {
  update_team_experiencer_by_pk(pk_columns: {id: $id}, _set: {flag_disable: $flag_disable}) {
    id
  }
}

`;
export const GET_ALL_TEAM_ACCOUNT = async (apolloClient: any) => {
  let { data: { team_contact } } = await apolloClient.query({
    query: FETCH_ALL_TEAM_BY_TEAM_CONTACT,
    variables: {
    }
  });
  //console.log('11111111111',team_contact);
  return team_contact;
}
export const DeleteBulkTeamExperiencer = gql`
mutation MyMutation($list_team_experiencer_id: [Int!]) {
  delete_team_experiencer(where: {id: {_in: $list_team_experiencer_id}}) {
    affected_rows
  }
}
`;
export const DeleteMultiTeamExperiencer = async (apolloClient: any, list_team_experiencer_id: number[]) => {
  let status = 0;
  try {
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: DeleteBulkTeamExperiencer,
      variables: {
        list_team_experiencer_id: list_team_experiencer_id
      }
    });
    status = 1;
  } catch (e) {
    status = 0
  }
  return status;
};
export const UpdateTeamExperiencerFlag = async (apolloClient: any, team_experiencer_id: number, flag_disable: boolean) => {
  let status = 0;
  try {
    let { data: dataDeleteMulti } = await apolloClient.mutate({
      mutation: UPDATE_FLAG_TEAM_EXPERIENCER,
      variables: {
        id: team_experiencer_id,
        flag_disable: flag_disable
      }
    });
    status = 1;
  } catch (e) {
    status = 0;
  }
  return status;
}
export const GetDataChatTeamContact = async (apolloClient: any,team_experiencer_id: number) => {
    let { data: dataMessage } = await apolloClient.mutate({
      mutation: FETCH_DATA_CHAT_TEAM_CONTACT,
      variables: {
        team_experiencer_id: team_experiencer_id
      }
    });
  return dataMessage.team_experiencer[0];
};