import { Button, SaveButton, Toolbar, useTranslate, useNotify, useRedirect } from "react-admin";
import { useHistory } from "react-router";
import * as React from "react";
import { useApolloClient } from "@apollo/client";
import moment from 'moment';
import { useState } from "react";

const UpdateToolbar = (props: any) => {
    const {record} = props;
    const translate = useTranslate();
    const history = useHistory();    
    const apolloClient = useApolloClient();
    const redirect = useRedirect();
    const notify = useNotify();
    const [oldAccountName, setOldAccountName] = useState(record.name);
    const cancelButton = () => {
        history.goBack();
    };
    const onSuccess = () => {
        notify(`${translate('common.msg_add_success')}`, 'info');
        redirect('/data_account');
    };
    const transform = async (data: any) => {
        let dataAccount = {};
        let dataName: any = data.account_name_history;
        const newAccountName = data.name;
        dataAccount = {
            ...data,
            account_name_history: dataName
        }
        const checkName = dataName.filter((x: any) => x.name === newAccountName);
        if (oldAccountName !== newAccountName) {
            dataName = dataName.push({ name: oldAccountName, modified: moment().utcOffset('+0900').format('YYYY-MM-DD HH:mm').toString() })
        }
        dataAccount = {
            ...data,
            sports: !data.sports ? '' : data.sports
        }
        return dataAccount
    };
    return (
        <Toolbar {...props} style={{ display: 'flex', justifyContent: 'center' }} >
            <SaveButton
                transform={transform}
                disabled={props.pristine}
                undoable={false}
                onSuccess={props.onSuccess || onSuccess}
                label={translate('common.btn_update')}
                style={props.pristine ? { color: 'rgba(255, 255, 255, 0.4)', marginRight: '25px' } : { marginRight: '25px' }}
            />
            <Button label={translate('common.btn_cancel')} onClick={cancelButton} variant="contained" size="medium" color="default" />
        </Toolbar>
    );
};
export default UpdateToolbar;