import * as React from "react";
import PropTypes from 'prop-types';
import { 
  ReferenceField,
} from 'react-admin';

const CustomField = (props: any) => {
  const { record } = props;
  const birthday = record.birthday;
  var dob = new Date(birthday);  
  var month_diff = Date.now() - dob.getTime();  
  var age_dt = new Date(month_diff);  
  var year = age_dt.getUTCFullYear(); 
  var age = Math.abs(year - 1970); 
  // return <span>N{record['id'] + ' ' + record['name']}</span>;
  return <span>{age}</span>;
}
const CustomReferFieldFullName = (props: any) => {
  return (
  <>
      <CustomField {...props} />
  </>)
}
CustomReferFieldFullName.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  reference: PropTypes.string,
};

export default CustomReferFieldFullName;