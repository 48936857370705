import * as React from 'react';
import { Chip, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { useForm } from 'react-final-form';
import { EnrolledListType, EnrolledType } from './type'
import { NumberInput } from 'react-admin';
import { TextInput } from 'react-admin';


const RadioMonthlyFee = ({ props }: { props: any }) => {
    const form = useForm();
    const translate = useTranslate();
    var isDisable1 = 'disable';
    var isDisable2 = 'disable';
    var isDisable3 = 'disable';
    var checked_1 = false;
    var checked_2 = false;
    var checked_3 = false;
    let monthly_fee_between = props.formData.monthly_fee_between;
    let monthly_fee = props.formData.monthly_fee; 
    let monthly_fee_from = props.formData.monthly_fee_from;
    let monthly_fee_to = props.formData.monthly_fee_to;
    if (monthly_fee_between === true) {
        if (!props.formData.monthly_fee_to) {
            checked_2 = true;
            isDisable2 = '';
            props.formData.radio_value_2 = monthly_fee_from;
            props.formData.radio_value_3_from = '';
            props.formData.radio_value_3_to = '';
            props.formData.radio_value_1 = '';
        }else{
            checked_3 = true;
            isDisable3 = '';
            props.formData.radio_value_2 = '';
            props.formData.radio_value_3_from = monthly_fee_from;
            props.formData.radio_value_3_to = monthly_fee_to;
        }
        
    }else{
        checked_1 = true;
        isDisable1 = '';
        props.formData.radio_value_1 = monthly_fee_to;
        props.formData.radio_value_3_from = '';
        props.formData.radio_value_3_to = '';
        props.formData.radio_value_2 = '';
    }

    return <Grid className='RadioMonthlyFee'>
        <RadioGroup row aria-label="gender" className='Radio-Group-Fee' name="row-radio-buttons-group">
            <Grid className={'Radio-1'}>
                <FormControlLabel value="一律" checked={checked_1} control={<Radio />} label="一律"
                    onClick={(event: any) => {
                        form.change('monthly_fee_between',false);
                    }}
                />
                <NumberInput
                    source={'radio_value_1'}
                    disabled={isDisable1}
                    label={translate(`team.radio_value_1`)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= {(event:any) => {
                        form.change('monthly_fee_from',event.target.value);
                        form.change('monthly_fee_to',event.target.value);
                        
                    }}
                />
                <p className='amount'>円</p>
            </Grid>
            <Grid className={'Radio-2'}>
                <FormControlLabel value="下限のみ" checked={checked_2} control={<Radio />} label="下限のみ"
                    onClick={(event: any) => {
                        form.change('monthly_fee_between',true);
                        form.change('monthly_fee_to','');
                    }}
                />
                <NumberInput
                    source={'radio_value_2'}
                    disabled={isDisable2}
                    label={translate(`team.radio_value_2`)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= {(event:any) => {
                        form.change('monthly_fee_from',parseInt(event.target.value));
                        form.change('monthly_fee_to',0);
                    }}
                />
                <p className='amount'>円 〜</p>

            </Grid>
            <Grid className={'Radio-3'}>
                <FormControlLabel value="下限・上限あり" checked={checked_3} control={<Radio />} label="下限・上限あり"
                    onClick={(event: any) => {
                        form.change('monthly_fee_between',true);
                        form.change('monthly_fee_to',1);
                    }}
                />
                <NumberInput
                    source={'radio_value_3_from'}
                    disabled={isDisable3}
                    label={translate(` `)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= {(event:any) => {
                        form.change('monthly_fee_from',parseInt(event.target.value));
                    }}
                />
                <span className='SpanBetween'>~</span>
                <NumberInput
                    source={'radio_value_3_to'}
                    disabled={isDisable3}
                    label={translate(` `)}
                    fullWidth
                    min={0}
                    variant="outlined"
                    className ='remove_placeholder'
                    onChange= {(event:any) => {
                        form.change('monthly_fee_to',parseInt(event.target.value));
                    }}
                />
                <p className='amount'>円</p>

            </Grid>
        </RadioGroup>
    </Grid>
}
export default RadioMonthlyFee;
