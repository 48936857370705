import { downloadCSV } from 'react-admin';
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment';
const exporter = async (items: any, resource: string, fields: any, translate: any, fetchRelatedRecords: any) => {
  var data: any = items
  // await fetchRelatedRecords(items, 'question_id', 'question').then((question: any) => {
  //   const data_question = items.map((item: any) => (
  //     {
  //       ...item,
  //       question_id: question[item.question_id].title,
  //     }
  //   ));
  //   data = data_question;
  // });
  // await fetchRelatedRecords(data, 'answered_by', 'user').then((user: any) => {
  //   const data_user = data.map((item: any) => (
  //     {
  //       ...item,
  //       lastname: `${user[item.answered_by].lastname}`,
  //       firstname: `${user[item.answered_by].firstname}`,
  //       created:  item.created ? moment(item.created).format('YYYY-MM-DD h:mm:ss a' ) : item.created,
  //     }
  //   ));
  //   data = data_user;
  // });

  //var fieldName = fields.map((f: any) => f.type === 'reference' ?`${f.reference}_${f.searchField}` : f.name )
  var fieldName = fields.map((f: any) => f.export_name)
  // create header
  const itemsForExport = data.map((item: any) => {
    return fieldName.map((f: any) => item[f])
  });

  const translated = (name: string) => {
    const val = translate(`${resource}.csv_fields.${name}`);
    if (val === `${resource}.csv_fields.${name}`) {
      return translate(`${resource}.${name}`);
    }

    return val;
  }
  const csv = '\uFEFF' + convertToCSV({
    data: itemsForExport,
    encoding: 'CP932',
    fields: fieldName.map((f: string) => translated(f)),
  });
  return downloadCSV(csv, resource);
};

export default exporter;

