export const STATUS = [
    {id: 0, name: "無効"},
    {id: 1, name: "有効"},
    {id: 2, name: "メール認証待ち"}
  ]
  export const PERMISSIONS = [
    {id: "display", name: "display"},
    {id: "edit", name: "edit"},
    {id: "delete", name: "delete"}
  ]
const DATA = [
{ name: 'id', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'team_id', type: 'reference', reference: 'team',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'user_id', type: 'reference', reference: 'user',searchField: 'lastname', nullable: false, editable: false, export: true, import: true },
{ name: 'user_id', type: 'reference', reference: 'user',searchField: 'email', nullable: false, editable: false, export: true, import: true },
{ name: 'status', type: 'status',options:STATUS, nullable: false, editable: true, export: true, import: true },
//{ name: 'permissions', type: 'mutipleChoiceInput',options:PERMISSIONS,nullable: false, editable: false, export: true, import: true },
{ name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
{ name: 'created_by', numberFormat:true,nullable: true, editable: false, export: true, import: true },
{ name: 'note', type: 'textinput', multiline: true, nullable: true, editable: true, export: true, import: true },
]

export default DATA;