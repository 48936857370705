import * as React from 'react';
import { FC, Fragment, useEffect } from 'react';
import {
    BooleanField,
    Filter,
    List,
    TextField,
    usePermissions,
    useTranslate,
    SearchInput,
    ReferenceField
} from 'react-admin';

import ListActions from '../components/actions';
import BulkDeleteButton from './actionsBulkDelete';
import BulkRecoverButton from './actionsBulkRecover';
import exporter from './exporter';
import ListEmpty from '../components/listEmpty';
import { FilterProps, PermissionsType } from "../../types";
import ListPagination from "../components/ListPagination";
import CustomizableDatagrid from '../components/CustomizableDatagrid';
import Fields from './dataset';
import { NumberField } from 'react-admin';
import { SelectField } from 'react-admin';
import ChipFieldJsonb from '../components/ChipFieldJsonb';
import UserNameReferenceField from './ReferenceField';
import UserEmailReferenceField from './ReferenceFieldEmail';
import StatusField from './StatusField';
import { DateField } from 'react-admin';
import { NumberInput } from 'react-admin';
import { useHistory } from 'react-router';
import { ReferenceInput } from 'react-admin';
import { AutocompleteInput } from 'react-admin';


const ExportableFields = Fields.filter(d => d.export);

const UserBulkActionButtons = (props: JSX.IntrinsicAttributes) => {
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    return (
        <Fragment>
            {(role === 'admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkRecoverButton {...props}/>}
            {(role ==='admin') && (permission?.permission_approve === true || permission?.permission_all === true) && <BulkDeleteButton {...props} />}
        </Fragment>
    )
};
const TeamFilter: FC<Omit<FilterProps, 'children'>> = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <NumberInput source="id@_eq" label={translate('user_list.id')} alwaysOn />
            <SearchInput source="note" alwaysOn />
            <ReferenceInput allowEmpty={true} filterToQuery={(searchText: any) => ({ email: searchText })} source="user_id" reference="user" label={translate('account_admin.email')} alwaysOn>
                <AutocompleteInput optionText={'email'}/>
            </ReferenceInput>
        </Filter>
    )
};

const StaffList = (props: any) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const role = permissions?.role;
    const permission = permissions?.list_permission as PermissionsType;
    let edit = (permission?.permission_create_edit == true || permission?.permission_all == true) ? 'edit': '';
    const module = 'team_admin';
    const history = useHistory();
    useEffect( () => {
        history.location.pathname = '/team_admin_trash';
    },[props]);
    if (role ===undefined) {
        return null;
    }
    console.log({ permissions });

    return (
        <List
            {...props}
            filters={<TeamFilter />}
            sort={{ field: 'id', order: 'DESC' }}
            perPage={25}
            bulkActionButtons={<UserBulkActionButtons />}
            filterDefaultValues={{ is_soft_deleted: true }}
            pagination={<ListPagination />}
            exporter={(items: any, fetchRelatedRecords: any) => exporter(items, 'team_admin', ExportableFields, translate, fetchRelatedRecords)}
            actions={<ListActions to='team_admin' />}
            empty={<ListEmpty />}
            title={translate(' ')}
        >
            <CustomizableDatagrid>

                {Fields.map((f, idx) => {
                    if (!Boolean(f)) return <></>
                    const { name, reference, searchField, type, options, numberFormat } = f || {}
                    if (type === 'textinput') {
                        if (numberFormat) {
                            return <NumberField source={name} label={translate(`${module}.${name}`)} />
                        }
                        return <TextField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'reference') {
                        if(reference === 'user'&& searchField === 'lastname'){
                            return  <UserNameReferenceField source="user_id" label={translate('account_admin.user_id')} reference='user' />
                        }
                        if(reference === 'user'&& searchField === 'email'){
                            return  <UserEmailReferenceField source="user_id" label={translate('account_admin.email')} reference='user' />
                        }
                        return <ReferenceField label={translate(`${module}.${name}`)} source={name} reference={reference}>
                            <TextField source={searchField} />
                        </ReferenceField>
                    }

                    if (type === 'checkbox') {
                        return <BooleanField source={name} label={translate(`${module}.${name}`)} />
                    }

                    if (type === 'selectbox') {
                        return <SelectField
                            source={name}
                            choices={options}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'jsonb') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'mutipleChoiceInput') {
                        return <ChipFieldJsonb
                            source={name}
                            label={translate(`${module}.${name}`)}
                        />
                    }
                    if (type === 'status') {
                        return <StatusField source={name} label={translate(`${module}.${name}`)} />
                    }
                    if (type === 'date') {
                        return <DateField showTime={true} source={name} label={translate(`${module}.${name}`)} locales="ja-JP" />
                    }
                    return <></>
                })}
            </CustomizableDatagrid>
        </List>

    );
};

export default StaffList;
