import StatusField from "../email/StatusField";

const ALREADY_IN_SPORTS = [
    { id: false, name: '同スポーツの団体に所属していない' },
    { id: true, name: '同スポーツの団体に所属している' }
]

const SAME_USER_ID = [
    { id: false, name: '申込み本人ではない' },
    { id: true, name: '申込み本人' }
]
const GENDER = [
    { id: 0, name: '不明' },
    { id: 1, name: '男性' },
    { id: 2, name: '女性' },
    { id: 3, name: '未選択'}

]
const STATUS = [
    { id: 0, name: '未対応' },
    { id: 1, name: '対応中' },
    { id: 2, name: '参加確定' },
    { id: 3, name: 'キャンセル' },
    { id: 4, name: '辞退' }
]


const DATA = [
{ name: 'id', type: 'textinput', searchField: '', options:[], reference: '', nullable: true, editable: false, export: true, import: true },
{ name: 'event_id', type: 'reference', reference: 'event',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'account_name',  export: true },
{ name: 'user_id', type: 'textinput', nullable: true, editable: false, export: true, import: true },
{ name: 'user_name',  type: 'hidden', export: true },
{ name: 'user_phone', type: 'hidden',  export: true },
{ name: 'user_email', type: 'hidden',  export: true },
{ name: 'event_contact_id', type: 'reference',reference: 'event_contact',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'same_user_id', type: 'status',options: SAME_USER_ID, nullable: false, editable: true, export: true, import: true },
{ name: 'event_plan_id', type: 'reference', reference: 'event_plan',searchField: 'name', nullable: false, editable: false, export: true, import: true },
{ name: 'paid', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'status', type: 'status', options:STATUS, nullable: false, editable: true, export: true, import: true },
{ name: 'status_updated_at', type: 'date',nullable: false, editable: true, export: true, import: true },
{ name: 'lastname', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'firstname', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'lastname_kana', type: 'textinput', nullable: false, editable: true, export: true, import: true },
{ name: 'firstname_kana', type: 'textinput', nullable: false, editable: true, export: true, import: true },

{ name: 'gender', type: 'status',options: GENDER, nullable: false, editable: true, export: true, import: true },
{ name: 'birthday', type: 'date', nullable: false, editable: false,  export: true, import: true },
{ name: 'age', type: 'age',  export: true, },
{ name: 'already_in_sports', type: 'status',options: ALREADY_IN_SPORTS, nullable: false, editable: true, export: true, import: true },
{ name: 'created', type: 'date', nullable: false, editable: true, export: true, import: true },
{ name: 'created_by', type: 'textinput', nullable: false, editable: false, export: true, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true, editable: true, export: true, import: true },

]

export default DATA;