import * as React from 'react';
import { FC } from 'react';
import { FieldProps } from '../../types';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { useApolloClient } from '@apollo/client';
import {
  useTranslate,
  useNotify,
} from 'react-admin';
import { GetDataBillingById, GetPresignedDownload, InsertTokenInvoice, SendEmailBilling } from '../billing_view/gql';
import { makeid } from '../../utils/Utils';
import ActionsUpdateInoivce from './actionsUpdateInoivce';

const useStyles = makeStyles((theme) => ({
  DataGrid: {
    marginBottom: 15
  },
  ActionButton: {
    marginRight: 10
  }
}));

const ActionsField: FC<FieldProps<any>> = ({ record, basePath, resource }) => {
  const apolloClient = useApolloClient();
  const translate = useTranslate()
  const notify = useNotify();
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const { id, billing_detail_amount, tax_amount,due_date, paid, payment_method, deposit_amount, filepath, receipt_filepath, receipt_filename, filename,amount_after_discount,tax_amount_after_discount,billing_detail_tax, billing_detail_discount,issue_date,year,month} = record;
  const classes = useStyles();
  let ExportBillingDetailDisable = false;
  let ExportBillingDetailReceiptDisable = false;
  let ExportBillingStripe = false;
  
  const GenReceiptInvoice = async (record: any) => {
    const billing_id = record.billing_id as number;
    const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
    const response = await fetch(`${Domain}api/create-receipt/${billing_id}`);
    if (response) {
      notify(`${translate('common.gen_receipt_success')}`, 'info');
      window.location.reload();
    }
    return response;

  }
  const DownloadInvoice = async (record: any) => {
    const admin_id = record.id as number;
    const billing_id = record.billing_id as number;

    const filename = record.filename as string;
    const filepath = record.filepath as string;

    const dataPreSigned = await GetPresignedDownload(apolloClient, filepath);
    if (dataPreSigned) {
      fetch(dataPreSigned.preUrl)
        .then((res) => { return res.blob(); })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = filename;
          a.click();
        });
      // const dataSendEmail = await SendEmailBilling(apolloClient, billing_id);
      // console.log('dataSendEmail', dataSendEmail);

    }
    //window.location.href = dataPreSigned.preUrl;
    //setIdResetPass(admin_id)
    //setshowDialogReset(true);
  }
  const GenStripeInvoice = async (record: any) => {
    const billing_id = record.billing_id as number;
    const Domain = process.env.REACT_APP_DOMAIN_CREATE_INVOICE;
    const response = await fetch(`${Domain}/api/create-invoice-biling-id/${billing_id}/`);
    if (response) {
      notify(`${translate('common.gen_receipt_success')}`, 'info');
      window.location.reload();
    }
    return response;

  }
  const UpdateInvoice = async (record: any) => {
    setIsOpen(true)
  }
  const handleCloseClick = () => {
    setIsOpen(false)

  }
  function delay(ms: any) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  if (!filepath && !filename) {
    ExportBillingDetailDisable = true
  }
  if (!receipt_filepath && !receipt_filename) {
    ExportBillingDetailReceiptDisable = true
  }
  if (record.issue_date &&
    record.paid === '未払い' &&
    record.payment_method === 'Stripe' &&
    record.account_payment_method === 1 &&
    record.stripe_id) {
    ExportBillingStripe = true;
  }
  return record ? (
    <span>
      <Grid className='grid-action-billing'>
        <Button
          className={classes.ActionButton}
          variant="contained"
          color="secondary"
          onClick={async (e: any) => {
            const dataFetch = await GetDataBillingById(apolloClient, id);
            const paid_date = dataFetch.dataBilling[0].paid_date;
            window.location.href = `
            /#/edit-billings?id=${id}&billing_detail_amount=${billing_detail_amount}&tax_amount=${tax_amount}&due_date=${due_date}&paid=${paid}&payment_method=${payment_method}&deposit_amount=${deposit_amount}&paid_date=${paid_date}&discount=${billing_detail_discount}&amount_after_discount=${amount_after_discount}&tax_amount_after_discount=${tax_amount_after_discount}&issue_date=${issue_date}&month=${month}&year=${year}`
            //window.location.reload();
          }}>編集</Button>
        <Box>
          <Button
            className={classes.ActionButton}
            disabled={!ExportBillingDetailReceiptDisable}
            variant="contained"
            color="primary"
            id={"btn-resetpass-admin"}
            onClick={(e: any) => GenReceiptInvoice(record)}
          >
            領収書生成
          </Button>
        </Box>
        {!ExportBillingDetailDisable && <Box>
          <Button
            className={classes.ActionButton}
            //disabled={!confirmed}
            disabled={ExportBillingDetailDisable}
            variant="contained"
            color="primary"
            id={"btn-resetpass-admin"}
            onClick={(e: any) => DownloadInvoice(record)}
          >
            {translate('請求書ダウンロード')}
          </Button>
        </Box>
        }
        {!ExportBillingDetailDisable && <Box>
          <Button
            className={classes.ActionButton}
            //disabled={!confirmed}

            disabled={ExportBillingDetailDisable}
            variant="contained"
            color="primary"
            id={"btn-resetpass-admin"}
            onClick={(e: any) => UpdateInvoice(record)}
          >
            {loading && <CircularProgress
              size={18}
              style={{ marginLeft: '-10px', color: 'lightgrey' }}
            // thickness={2}
            />}
            {translate('請求書再生成')}
          </Button>
        </Box>
        }
        {ExportBillingStripe && <Box className=''>
          <Button
            className={classes.ActionButton}
            //disabled={!confirmed}
            //disabled={!ExportBillingStripe}
            variant="contained"
            color="primary"
            id={"btn-resetpass-admin"}
            onClick={(e: any) => GenStripeInvoice(record)}
          >
            {translate('Stripe決済')}
          </Button>

        </Box>
        }
      </Grid>
      <ActionsUpdateInoivce data={record} handleCloseClick={handleCloseClick} isOpen={isOpen}></ActionsUpdateInoivce>
    </span>
  ) : null;
}

export default ActionsField;


