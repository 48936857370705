const STATUS = [
{id: 0, name: "終了"},
{id: 1, name: "作成中"},
{id: 2, name: "公開待ち"},
{id: 3, name: "公開中"},
{id: 4, name: "受付停止中"},
]
const CATEGORY = [
    {id: 0, name: "全般"},
    {id: 1, name: "スポーツジャンル＞スポーツ"}
    ]
const DATA = [
{ name: 'id', type: 'textinput',searchField: '',options:[],reference: '',export_name: 'id', nullable: true, editable: false, export: true, import: true },
{ name: 'user_id', type: 'textinput', nullable: false, editable: false,export_name: 'user_id', export: true, import: true },
{ name: 'user_id', type: 'reference', reference: 'user', searchField: 'lastname',export_name: 'lastname', nullable: false, editable: false, export: true, import: true },
{ name: 'user_id', type: 'reference', reference: 'user', searchField: 'firstname',export_name: 'firstname', nullable: false, editable: false, export: true, import: true }, 
{ name: 'nickname', type: 'textinput', nullable: false, editable: true,export_name: 'nickname', export: true, import: true },
{ name: 'category', type: 'status',options: CATEGORY, nullable: false,export_name: 'category', editable: true, export: false, import: true },
{ name: 'title', type: 'textinput', nullable: false, editable: true,export_name: 'title', export: true, import: true },
{ name: 'question', type: 'textinput',multiline: true, nullable: false,export_name: 'question', editable: true, export: true, import: true },
{ name: 'approved', type: 'textinput', nullable: true, editable: false,export_name: 'approved', export: true, import: true },
//{ name: 'approved_by', type: 'textinput', nullable: true, editable: true, export: true, import: true },
{ name: 'violation', type: 'textinput', nullable: false, editable: true,export_name: 'violation', export: true, import: true },

{ name: 'genre_id', type: 'reference', reference: 'genre',searchField: 'name', nullable: true,export_name: 'genre_id', editable: true, export: true, import: true },
{ name: 'genre_sport_id',type: 'reference', reference: 'genre_sport',searchField: 'secondary_name',export_name: 'genre_sport_id', nullable: true, editable: true, export: true, import: true },
{ name: 'created', type: 'textinput', nullable: true, editable: false,export_name: 'created', export: true, import: true },
{ name: 'created_by', type: 'hidden', defaultvalue: 0, numberFormat:true,nullable: true,export_name: 'created_by', editable: false, export: false, import: true },
{ name: 'note', type: 'textinput',multiline: true, nullable: true,export_name: 'note', editable: true, export: true, import: true },

]

export default DATA;