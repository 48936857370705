import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  useTranslate,
  useNotify
} from 'react-admin';
import { Button } from '@material-ui/core';

import { useApolloClient } from "@apollo/client";
import {
  DISAPPROVE_TEAM_REIVEW, GET_TEAM_REVIEW_NOT_ACCESS_APPROVE, GET_TEAM_REVIEW_ACCESS_APPROVE, SEND_MAIL_DISAPPROVE_TEAM_REVIEW
} from './gql';
import 'moment/locale/ja';
import { useRefresh } from 'react-admin';
import { useUnselectAll } from 'react-admin';
import jwt_decode from "jwt-decode";
import { RemoveCircle } from '@material-ui/icons';
import { Confirm } from 'react-admin';

const ButtonDisapprove = (props: any) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const apolloClient = useApolloClient();
  const [open, setOpen] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const token = localStorage.getItem('token') as string;
  var decoded = jwt_decode(token) as any;
  var dataDisapprove: number[] = [];
  const { selectedIds } = props;
  const totalCount: number = selectedIds ? selectedIds.length : 0;

  const DisapproveTeamReview = async (team_review_id: number[]) => {
    let { data: dataNotAccess } = await apolloClient.query({
      query: GET_TEAM_REVIEW_NOT_ACCESS_APPROVE,
      variables: {
        list_team_review_id: team_review_id
      }
    });
    let { data: dataAccess } = await apolloClient.query({
      query: GET_TEAM_REVIEW_ACCESS_APPROVE,
      variables: {
        list_team_review_id: team_review_id
      }
    });
    if (dataAccess.team_review.length > 0) {
      //setCountSuccess(dataAccess.team_review.length);
      dataAccess.team_review.map((x: any) => {
        dataDisapprove.push(x.id);
      })

    } else {
      return {
        countSuccess: 0,
        countFail: dataNotAccess.team_review.length
      };
    }
    //setCountFail(dataNotAccess.team_review.length);
    const user_id = decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"]
    const { data } = await apolloClient.mutate({
      mutation: DISAPPROVE_TEAM_REIVEW,
      variables: {
        list_team_review_id: dataDisapprove
      }
    });
    let dataReturn = {
      countFail: dataNotAccess.team_review.length,
      countSuccess: dataAccess.team_review.length,
    }
    console.log('dataApproveSendMail', dataDisapprove);
    //send mail
    const { data: dataSendMail } = await apolloClient.mutate({
      mutation: SEND_MAIL_DISAPPROVE_TEAM_REVIEW,
      variables: {
        list_team_review: dataDisapprove
      }
    });
    console.log('dataSendMail', dataSendMail);
    return dataReturn;
  }
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = async () => {
    const data = await DisapproveTeamReview(selectedIds);
    if (data.countSuccess > 0) {
      //notify(translate('common.msg_approved'))
      //notify(`${data.countSuccess}非承認にしました。, ${data.countFail} 承認エラーが発生しました。`)
      notify(`非承認にしました。`)
      unselectAll('team_review');
      refresh();
    } else {
      notify(translate(` ${data.countFail} チームが承認されませんでした`), 'warning')
      setOpen(false)

    }
  }



  return (
    <Fragment>
      <Button
        disabled={isConverting || props.disabled}
        //variant="contained"
        startIcon={<RemoveCircle />}
        color="secondary"
        onClick={handleClick}>
        {translate('team_review.disapprove')}

      </Button>
      <Confirm
        isOpen={open}
        title={translate('team.msg_title_disapprove')}
        content={translate('team.msg_disapprove_message')}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
        cancel={translate('common.cancel_text_disapprove_team')}
        confirm={translate('common.confirm_text_disapprove_team')}
      />
    </Fragment>
  )
}

ButtonDisapprove.propTypes = {
  selectedIds: PropTypes.array.isRequired,
}

ButtonDisapprove.defaultProps = {
  selectedIds: []
}

export default ButtonDisapprove;