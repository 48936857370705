import { gql } from "@apollo/client";
export const FETCH_RESELLERS = gql`
  query GetResellers($ids: [Int!]) {
    reseller(where: {id: {_in: $ids}}) {
      id
      account_name
      account_number
      account_type
      zip_code
      other
      address
      bank_branch_code
      bank_branch_name
      bank_code
      bank_name
      name
    }
  }
`;
export const FETCH_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    account(where: {id: {_eq: $id}}) {
        id
        contact_email
        created_by
    }
  }
`;
export const APPROVE_TEAM = gql`
mutation MyMutation($list_team_id: [Int!],$varified_by: Int) {
  update_team(where: {id: {_in: $list_team_id}}, _set: {publish_status:2,varified_by:$varified_by}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_TEAM_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
    team_draft(where: {id: {_in: $list_team_id}, publish_status: {_in: [1]}}) {
      id
    }
}
`;
export const GET_TEAM_NOT_ACCESS_APPROVE = gql`
query Myquery($list_team_id: [Int!]) {
  team_draft(where: {id: {_in: $list_team_id}, publish_status: {_nin: [1]}}) {
      id
    }
}
`;
export const COPY_DATA_TEAM_DRAFT_TO_TEAM = gql`
mutation MyMutation($team_draft_id: Int!,
  $team_id: Int,
  $account_id: Int,
  $achievements: String,
  $admission_fee: Int,
  $age: jsonb,
  $annual_fee: Int,
  $appeal_body: String,
  $appeal_title: String,
  $area: Int,
  $attention: Int,
  $category: Int,
  $city: Int,
  $created: timestamp,
  $created_by: Int,
  $disability: Int,
  $enroll_description: String,
  $enrolled: jsonb,
  $fee_description: String,
  $for_parents: String,
  $gear: jsonb,
  $genre_id: Int,
  $genre_other: String,
  $genre_sport_id: Int,
  $genre_sport_other: String,
  $image1_url: String,
  $image2_url: String,
  $image3_url: String,
  $image4_url: String,
  $image5_url: String,
  $image6_url: String,
  $image7_url: String,
  $initial_fee: Int,
  $keyword: jsonb,
  $keyword1: jsonb,
  $keyword2: jsonb,
  $keyword3: jsonb,
  $keyword4: jsonb,
  $ladies: Int,
  $logo_url: String,
  $monthly_fee: Int,
  $monthly_fee_between: Boolean,
  $monthly_fee_from: Int,
  $monthly_fee_to: Int,
  $name: String,
  $name_kana: String,
  $note: String,
  $philosophy: String,
  $practice_datetime: String,
  $practice_days: jsonb,
  $pref: Int,
  $president_name: String,
  $preview: jsonb,
  $publish_status: Int,
  $style: jsonb,
  $team_connection: Boolean,
  $things_to_prepare: String,
  $trial_description: String ,
  $trial_type: Int,
  $varified: timestamp,
  $varified_by: Int,
  $video_url: String,
  $show_account_info: Boolean,
  $team_connection_message: String,
  $recruitment: jsonb,
  $payable_rate: Int,
  $request_date: timestamp,
  $visit_ok: Boolean) {
    update_team(where: {id: {_eq: $team_id}},
     _set: {account_id: $account_id,
     achievements: $achievements,
     admission_fee: $admission_fee,
     age: $age,
     annual_fee: $annual_fee,
     appeal_body: $appeal_body,
     appeal_title: $appeal_title,
     area: $area,
     attention: $attention,
     category: $category,
     city: $city,
     created: $created,
     disability: $disability,
     enroll_description: $enroll_description,
     enrolled: $enrolled,
     recruitment: $recruitment,
     fee_description: $fee_description,
     for_parents: $for_parents,
     gear: $gear,
     genre_id: $genre_id,
     genre_other: $genre_other,
     genre_sport_id: $genre_sport_id,
     genre_sport_other: $genre_sport_other,
     image1_url: $image1_url,
     image2_url: $image2_url,
     image3_url: $image3_url,
     image4_url: $image4_url,
     image5_url: $image5_url,
     image6_url: $image6_url,
     image7_url: $image7_url,
     initial_fee: $initial_fee,
     keyword: $keyword,
     keyword1: $keyword1,
     keyword2: $keyword2,
     keyword3: $keyword3,
     keyword4: $keyword4,
     ladies: $ladies,
     logo_url: $logo_url,
     monthly_fee: $monthly_fee,
     monthly_fee_between: $monthly_fee_between,
     monthly_fee_from: $monthly_fee_from,
     monthly_fee_to: $monthly_fee_to,
     name: $name,
     name_kana: $name_kana,
     note: $note,
     philosophy: $philosophy,
     practice_datetime: $practice_datetime,
     practice_days: $practice_days,
     pref: $pref,
     president_name: $president_name,
     preview: $preview,
     style: $style,
     publish_status: $publish_status,
     team_connection: $team_connection,
     things_to_prepare: $things_to_prepare,
     trial_description: $trial_description,
     trial_type: $trial_type,
     varified: $varified,
     varified_by: $varified_by,
     video_url: $video_url,
     visit_ok: $visit_ok,
     show_account_info: $show_account_info,
     team_connection_message: $team_connection_message,
     payable_rate: $payable_rate,
     request_date: $request_date,
     created_by: $created_by}) {
      affected_rows
    }
    delete_team_draft_by_pk(id: $team_draft_id) {
      id
    }
    delete_team_place(where: {team_id: {_eq: $team_id}}) {
      affected_rows
    }
    delete_team_staff(where: {team_id: {_eq: $team_id}}) {
      affected_rows
    }
  }
  `;
export const GET_DATA_TEAM_DRAFT = gql`
query MyQuery($team_draft_id: Int) {
  team_draft(where: {id: {_eq: $team_draft_id}}) {
    id
    account_id
    achievements
    admission_fee
    age
    annual_fee
    appeal_body
    appeal_title
    area
    attention
    category
    city
    created
    created_by
    disability
    enroll_description
    enrolled
    recruitment
    fee_description
    for_parents
    gear
    genre_id
    genre_other
    genre_sport_id
    genre_sport_other
    image1_url
    image2_url
    image3_url
    image4_url
    image5_url
    image6_url
    image7_url
    initial_fee
    keyword
    keyword1
    keyword2
    keyword3
    keyword4
    ladies
    logo_url
    monthly_fee
    monthly_fee_between
    monthly_fee_from
    monthly_fee_to
    name
    name_kana
    note
    philosophy
    practice_datetime
    practice_days
    pref
    president_name
    preview
    preview_key
    publish_status
    style
    team_connection
    things_to_prepare
    trial_description
    trial_type
    varified
    varified_by
    video_url
    visit_ok
    team_id
    show_account_info
    team_connection_message
    payable_rate
    request_date
    team{
      request_date
    }
    team_staff_drafts {
      comment
      created_by
      email
      image_url
      name
      note
      user_id
      title 
    }
    team_place_drafts {
      account_place_id
      Is_required
      order
    }
  }
}
`;
export interface team_place_insert_input {
  account_place_id: number,
  team_id: number
}
export interface team_staff_insert_input {
  comment: string,
  created_by: number,
  email: string,
  image_url: string,
  name: string,
  note: string
}

export const COPY_PLACE = gql`
mutation MyMutation($dataPlace: [team_place_insert_input!]!) {
  insert_team_place(objects: $dataPlace) {
    affected_rows
  }
}
`;
export const COPY_STAFF = gql`
mutation MyMutation($dataStaff: [team_staff_insert_input!]!) {
  insert_team_staff(objects: $dataStaff) {
    affected_rows
  }
}
`;
export const SEND_MAIL_APPROVE = gql`
mutation MyMutation($team_draft_id: Int) {
  send_email_approve_team_draft(team_draft_id: $team_draft_id) {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const GET_TEAM_DRAFT_ACCESS_APPROVE = gql`
query Myquery($list_team_draft_id: [Int!]) {
    team_draft(where: {id: {_in: $list_team_draft_id}, publish_status: {_in: [1]}}) {
      id
      account_id
      created_by 
    }
}
`;

export const GET_TEAM_DRAFT_NOT_ACCESS_APPROVE = gql`
query Myquery($list_team_draft_id: [Int!]) {
    team_draft(where: {id: {_in: $list_team_draft_id}, publish_status: {_nin: [1]}}) {
      id
    }
}
`;
export const DISAPPROVE_TEAM_DRAFT = gql`
mutation MyMutation($list_team_draft_id: [Int!]) {
  update_team_draft(where: {id: {_in: $list_team_draft_id}}, _set: {publish_status:2}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_TEAM_BY_TEAM_DRAFT_ID = gql`
query MyQuery($selectedIds: [Int!] ) {
  team_draft(where: {id: {_in: $selectedIds}, account_id: {_is_null: true}, publish_status: {_eq: 1}, team: {publish_status: {_eq: 2}}}) {
    id
    name
    team_id
  }
}
`;
export const SEND_EMAIL_APPROVE_TEAM_REVIEW = gql`
mutation MyMutation($table: String ="TEAM" , $list_team: [Int], $action: String = "APPROVE") {
  send_email_approve_team_team_review(action: $action, list_team: $list_team, table: $table) {
    error_code
    error_message
    send_success
    status_code
  }
 }
`;
export const SendMailApprove = async (apolloClient: any, team_draft_id: number) => {
  const { data: DataSendMailApprove } = await apolloClient.mutate({
    mutation: SEND_MAIL_APPROVE,
    variables: {
      team_draft_id: team_draft_id
    }
  });
  return DataSendMailApprove;
}
export const CopyTeamPlaceStaff = async (apolloClient: any, dataPlaces: any, dataStaffs: any, team_id: number) => {
  const ListTeamStaff: team_staff_insert_input[] = [];
  const ListTeamplace: team_place_insert_input[] = [];
  for (let index = 0; index < dataStaffs.length; index++) {
    const element = dataStaffs[index];
    let dataStaffPush = {
      comment: element.comment,
      created_by: element.created_by,
      email: element.email,
      image_url: element.image_url,
      name: element.name,
      note: element.note,
      team_id: team_id,
      user_id: element.user_id,
      title: element.title
    }
    ListTeamStaff.push(dataStaffPush);
  }
  for (let index = 0; index < dataPlaces.length; index++) {
    const element = dataPlaces[index];
    let dataPlace = {
      account_place_id: element.account_place_id,
      Is_required: element.Is_required,
      team_id: team_id,
      order: element.order
    }
    ListTeamplace.push(dataPlace);
  }
  const { data: DataPlace } = await apolloClient.mutate({
    mutation: COPY_PLACE,
    variables: {
      dataPlace: ListTeamplace
    }
  });
  const { data: DataStaff } = await apolloClient.mutate({
    mutation: COPY_STAFF,
    variables: {
      dataStaff: ListTeamStaff
    }
  });
  return { DataPlace, DataStaff };
}
export const ExecuteCopyTeam = async (dataTeam: any, apolloClient: any, varified_by: number) => {
  const { data: DataCopy } = await apolloClient.mutate({
    mutation: COPY_DATA_TEAM_DRAFT_TO_TEAM,
    variables: {
      team_draft_id: dataTeam.id,
      team_id: dataTeam.team_id,
      // team_place_draft_id: dataTeam.team_place_drafts[0].id,
      // team_staff_draft_id: dataTeam.team_staff_drafts[0].id,
      account_id: dataTeam.account_id,
      achievements: dataTeam.achievements,
      admission_fee: dataTeam.admission_fee,
      age: dataTeam.age,
      annual_fee: dataTeam.annual_fee,
      appeal_body: dataTeam.appeal_body,
      appeal_title: dataTeam.appeal_title,
      area: dataTeam.area,
      attention: dataTeam.attention,
      category: dataTeam.category,
      city: dataTeam.city,
      created: dataTeam.created,
      created_by: dataTeam.created_by,
      disability: dataTeam.disability,
      enroll_description: dataTeam.enroll_description,
      enrolled: dataTeam.enrolled,
      recruitment: dataTeam.recruitment,
      fee_description: dataTeam.fee_description,
      for_parents: dataTeam.for_parents,
      gear: dataTeam.gear,
      genre_id: dataTeam.genre_id,
      genre_other: dataTeam.genre_other,
      genre_sport_id: dataTeam.genre_sport_id,
      genre_sport_other: dataTeam.genre_sport_other,
      image1_url: dataTeam.image1_url,
      image2_url: dataTeam.image2_url,
      image3_url: dataTeam.image3_url,
      image4_url: dataTeam.image4_url,
      image5_url: dataTeam.image5_url,
      image6_url: dataTeam.image6_url,
      image7_url: dataTeam.image7_url,
      initial_fee: dataTeam.initial_fee,
      keyword: dataTeam.keyword,
      keyword1: dataTeam.keyword1,
      keyword2: dataTeam.keyword2,
      keyword3: dataTeam.keyword3,
      keyword4: dataTeam.keyword4,
      ladies: dataTeam.ladies,
      logo_url: dataTeam.logo_url,
      monthly_fee: dataTeam.monthly_fee,
      monthly_fee_between: dataTeam.monthly_fee_between,
      monthly_fee_from: dataTeam.monthly_fee_from,
      monthly_fee_to: dataTeam.monthly_fee_to,
      name: dataTeam.name,
      name_kana: dataTeam.name_kana,
      note: dataTeam.note,
      philosophy: dataTeam.philosophy,
      practice_datetime: dataTeam.practice_datetime,
      practice_days: dataTeam.practice_days,
      pref: dataTeam.pref,
      president_name: dataTeam.president_name,
      preview: dataTeam.preview,
      preview_key: dataTeam.preview_key,
      publish_status: 2,
      style: dataTeam.style,
      team_connection: dataTeam.team_connection,
      things_to_prepare: dataTeam.things_to_prepare,
      trial_description: dataTeam.trial_description,
      trial_type: dataTeam.trial_type,
      varified: dataTeam.varified,
      video_url: dataTeam.video_url,
      visit_ok: dataTeam.visit_ok,
      varified_by: varified_by,
      show_account_info: dataTeam.show_account_info,
      team_connection_message: dataTeam.team_connection_message,
      payable_rate: dataTeam.payable_rate,
      request_date: dataTeam.account_id ? dataTeam.team.request_date : dataTeam.request_date,
    }
  });
  return DataCopy;
}
export const GetAllGenreSport = async (dataProvider: any, genre_id: number) => {
  let data_genre_sport = await dataProvider.getList("genre_sport", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: { genre_id: genre_id },
  });
  let ListData = [];
  const { data } = data_genre_sport;
  for (let item = 0; item < data.length; item++) {
    ListData.push({ id: data[item].id, name: data[item].secondary_name })
  }
  return ListData;
};
// fetch data json
export const fetchJson = async (link: string) => {
  try {
    const response = await fetch(link);
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    console.error(error);
  }
};
export const GetArea = async () => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`) || [];
  const ListArea = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    ListArea.push({ id: dataAllArea[are].id, name: dataAllArea[are].name });
  }
  return ListArea;
};
export const GetPref = async (area_id: Number) => {
  const dataAllArea = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/all.json`) || [];
  const ListPref = [];
  for (var are = 0; are < dataAllArea.length; are++) {
    const listPrefByArea = dataAllArea[are].pref;
    if (dataAllArea[are].id === area_id) {
      for (var pref = 0; pref < listPrefByArea.length; pref++) {
        ListPref.push({ id: listPrefByArea[pref].id, name: listPrefByArea[pref].name })
      }
      return ListPref;
    }
  }
};
export const GetCity = async (pref_id: Number) => {
  const dataAllCityByPref = await fetchJson(`${process.env.REACT_APP_LOOKUP_ENDPOINT}/area/${pref_id}.json`) || [];
  const ListCity = [];
  for (var city = 0; city < dataAllCityByPref.length; city++) {
    ListCity.push({ id: dataAllCityByPref[city].id, name: dataAllCityByPref[city].city });
  }
  return ListCity;
};
export const SendEmailApproveTeamDraftEndUser = async (apolloClient: any, team_draft_ids: number[]) => {
  const { data: dataTeamByTeamDraftId } = await apolloClient.query({
    query: GET_TEAM_BY_TEAM_DRAFT_ID,
    fetchPolicy: 'network-only',
    variables: {
      selectedIds: team_draft_ids
    }
  });

  const ListTeamId = dataTeamByTeamDraftId.team_draft.map((x: any) => x.team_id);
  console.log('ListTeamId', ListTeamId);

  let { data: dataSendEmailApproveTeam } = await apolloClient.mutate({
    mutation: SEND_EMAIL_APPROVE_TEAM_REVIEW,
    variables: {
      list_team: ListTeamId
    }
  });
  return dataSendEmailApproveTeam
}