import { gql } from "@apollo/client";
import { number } from "react-admin";

export interface event_place_insert_input {
  event_id: number,
  name: string,
  area: number,
  pref: number,
  city: number,
  venue_post_code: string,
  venue_pref: string,
  venue_city: string,
  venue_address1: string,
  venue_address2: string,
  venue_line_cd1: number,
  venue_station_cd1: number,
  venue_walk1: string,
  venue_line_cd2: number,
  venue_station_cd2: number,
  venue_walk2: string,
  venue_line_cd3: number,
  venue_station_cd3: number,
  venue_walk3: string,
  created_by: number,
  note: string,
  date_from: Date,
  date_to: Date,
  time: string,
  time_description: string,
  area_name: string,
  pref_name: string,
  city_name: string,
  venue_line1: string,
  venue_station1: string,
  venue_line2: string,
  venue_station2: string,
  venue_line3: string,
  venue_station3: string
}
export interface event_plan_insert_input {
  event_id: number,
  created_by: number,
  name: string,
  description: string,
  price: number,
  applicants: number,
  note: string,
}
export const UPLOAD_FILE_S3 = gql`
mutation MyMutation($binary: String!, $filename: String!, $foldername: String) {
  upload_image(binary: $binary, filename: $filename, foldername: $foldername) {
    error_code
    error_message
    image_url
    status_code
  }
}
`;

export const APPROVE_EVENT = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:2}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GET_EVENT_APPROVE_3 = gql`
query MyQuery($list_event_id: Int!, $date_now: date) {
  event_draft(where: {valid_from: {_gt: $date_now}, id: {_eq: $list_event_id}}) {
    id
    valid_from
  }
}
`;
export const GET_EVENT_APPROVE_4 = gql`
query MyQuery($list_event_id: Int! , $date_now: date) {
  event_draft(where: {valid_from: {_lte: $date_now}, id: {_eq: $list_event_id}, valid_to: {_gte: $date_now}}) {
    id
    valid_from
  }
}
`;
export const GET_EVENT_APPROVE_ERROR = gql`
query MyQuery($list_event_id: Int!, $date_now: date) {
  event_draft(where: {id: {_eq: $list_event_id}, valid_to: {_lt: $date_now}}) {
    id
    valid_from
  }
}
`;
export const APPROVE_EVENT_WAITING = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const APPROVE_EVENT_OPENNING = gql`
mutation MyMutation($list_event_id: [Int!]) {
  update_event(where: {id: {_in: $list_event_id}}, _set: {status:4}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const COPY_EVENT_PLAN = gql`
mutation MyMutation($dataPlan: [event_plan_insert_input!]!) {
  insert_event_plan(objects: $dataPlan) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const UPDATE_EVENT_PLAN = gql`
mutation MyMutation($event_plan_id: Int!, $name: String, $note: String, $price: Int, $event_id: Int,$description: String,$created_by: Int,$applicants: Int) {
  update_event_plan_by_pk(pk_columns: {id: $event_plan_id}, _set: {name: $name, note: $note, price: $price, event_id: $event_id, description: $description, created_by: $created_by, applicants: $applicants}) {
    id
  }
}
`;
export const DELETE_EVENT_PLAN = gql`
mutation MyMutation($list_event_plan_id: [Int!]) {
  delete_event_plan(where: {id: {_in: $list_event_plan_id}}) {
    affected_rows
  }
}
`;
export const COPY_EVENT_PLACE = gql`
mutation MyMutation($dataEventPlace: [event_place_insert_input!]!) {
  insert_event_place(objects: $dataEventPlace) {
    affected_rows
  }
}
`;
export const GET_DATA_EVENT_DRAFT = gql`
query MyQuery($event_id: Int) {
  event_draft(where: {id: {_eq: $event_id}}) {
    id
    accept_contact
    access
    account_id
    additional_note
    admission_fee
    age_group
    application_method
    application_method_note
    area
    area_name
    attention
    body1
    body2
    body3
    body4
    cancel_policy
    capacity
    capacity_note
    city
    city_name
    comment
    covid_note
    created
    created_by
    data1_url
    data2_url
    data3_url
    date_from
    date_to
    description
    disability
    fee_note
    genre_id
    genre_other
    genre_sport_id
    genre_sport_other
    image10_url
    image11_url
    image12_url
    image13_url
    image1_url
    image2_url
    image3_url
    image4_url
    image5_url
    image6_url
    image7_url
    image8_url
    image9_url
    is_free
    is_group
    ladies
    level
    name
    note
    organizer_address1
    organizer_address2
    organizer_city
    organizer_name
    organizer_post_code
    organizer_pref
    payment_method
    point
    pref
    pref_name
    preview
    preview_key
    schedule_time
    status
    sub_title
    time
    time_description
    title
    title1
    title2
    title3
    title4
    type
    valid_from
    valid_to
    venue_address1
    venue_address2
    venue_city
    venue_line1
    venue_line2
    venue_line3
    venue_line_cd1
    venue_line_cd2
    venue_line_cd3
    venue_post_code
    venue_pref
    venue_station1
    venue_station2
    venue_station3
    venue_station_cd1
    venue_station_cd2
    venue_station_cd3
    venue_walk1
    venue_walk2
    venue_walk3
    video_url
    event_id
    event {
      event_plans {
        id
      }
    }
    event_place_drafts {
      area
      area_name
      city
      city_name
      created
      created_by
      date_from
      date_to
      event_draft_id
      id
      name
      note
      pref
      pref_name
      time
      time_description
      venue_address1
      venue_address2
      venue_city
      venue_line1
      venue_line2
      venue_line3
      venue_line_cd1
      venue_line_cd2
      venue_line_cd3
      venue_post_code
      venue_pref
      venue_station1
      venue_station2
      venue_station3
      venue_station_cd1
      venue_station_cd2
      venue_station_cd3
      venue_walk1
      venue_walk2
      venue_walk3
    }
    event_plan_drafts {
      applicants
      created_by
      description
      event_draft_id
      event_plan_id
      id
      name
      note
      price
    }
  }
}
`;
export const COPY_DATA_EVENT_DRAFT_TO_EVENT = gql`
mutation MyMutation($event_draft_id: Int!,
   $event_id: Int,
   $account_id: Int,
   $accept_contact: Boolean,
   $access: String,
   $additional_note: String,
   $video_url: String,
   $venue_walk3: String,
   $venue_walk2: String,
   $venue_walk1: String,
   $venue_station_cd3: Int ,
   $venue_station_cd2: Int ,
   $venue_station_cd1: Int ,
   $venue_station3: String,
   $venue_station2: String,
   $venue_station1: String,
   $venue_pref: String,
   $venue_post_code: String,
   $venue_line_cd3: Int ,
   $venue_line_cd2: Int ,
   $venue_line_cd1: Int ,
   $venue_line3: String,
   $venue_line2: String,
   $venue_line1: String,
   $venue_city: String,
   $venue_address2: String,
   $venue_address1: String,
   $valid_to: date,
   $valid_from: date,
   $type: Int ,
   $title: String,
   $title4: String,
   $title3: String,
   $title2: String,
   $title1: String,
   $time_description: String,
   $time: String,
   $sub_title: String,
   $status: Int ,
   $schedule_time: String,
   $preview: jsonb,
   $pref_name: String,
   $pref: Int ,
   $point: String,
   $payment_method: String,
   $organizer_pref: String,
   $organizer_post_code: String,
   $organizer_name: String,
   $organizer_city: String,
   $organizer_address2: String,
   $organizer_address1: String,
   $note: String,
   $name: String,
   $level: jsonb,
   $ladies: Int ,
   $is_group: Int ,
   $is_free: Boolean = false,
   $image9_url: String,
   $image8_url: String,
   $image7_url: String,
   $image6_url: String,
   $image5_url: String,
   $image4_url: String,
   $image3_url: String,
   $image2_url: String,
   $image1_url: String,
   $image13_url: String,
   $image12_url: String,
   $image11_url: String,
   $image10_url: String,
   $genre_sport_other: String,
   $genre_sport_id: Int ,
   $genre_other: String,
   $genre_id: Int ,
   $fee_note: String,
   $disability: Int ,
   $description: String,
   $date_to: date,
   $date_from: date,
   $data3_url: String,
   $data2_url: String,
   $data1_url: String,
   $created_by: Int ,
   $created: timestamp,
   $covid_note: String,
   $comment: String,
   $city_name: String,
   $city: Int ,
   $capacity_note: String,
   $capacity: String,
   $cancel_policy: String,
   $body4: String,
   $body3: String,
   $body2: String,
   $body1: String,
   $attention: Int ,
   $area_name: String,
   $area: Int ,
   $application_method_note: String,
   $application_method: String,
   $age_group: jsonb,
   $admission_fee: String
  ) {
    update_event(where: {id: {_eq: $event_id}},
     _set: {
      account_id:$account_id,
      accept_contact: $accept_contact,
      access: $access,
      additional_note: $additional_note,
      video_url: $video_url,
      venue_walk3: $venue_walk3,
      venue_walk2: $venue_walk2,
      venue_walk1: $venue_walk1,
      venue_station_cd3: $venue_station_cd3,
      venue_station_cd2: $venue_station_cd2,
      venue_station_cd1: $venue_station_cd1,
      venue_station3: $venue_station3,
      venue_station2: $venue_station2,
      venue_station1: $venue_station1,
      venue_pref: $venue_pref,
      venue_post_code: $venue_post_code,
      venue_line_cd3: $venue_line_cd3,
      venue_line_cd2: $venue_line_cd2,
      venue_line_cd1: $venue_line_cd1,
      venue_line3: $venue_line3,
      venue_line2: $venue_line2,
      venue_line1: $venue_line1,
      venue_city: $venue_city,
      venue_address2: $venue_address2,
      venue_address1: $venue_address1,
      valid_to: $valid_to,
      valid_from: $valid_from,
      type: $type,
      title: $title,
      title4: $title4,
      title3: $title3,
      title2: $title2,
      title1: $title1,
      time_description: $time_description,
      time: $time,
      sub_title: $sub_title,
      status: $status,
      schedule_time: $schedule_time,
      preview: $preview,
      pref_name: $pref_name,
      pref: $pref,
      point: $point,
      payment_method: $payment_method,
      organizer_pref: $organizer_pref,
      organizer_post_code: $organizer_post_code,
      organizer_name: $organizer_name,
      organizer_city: $organizer_city,
      organizer_address2: $organizer_address2,
      organizer_address1: $organizer_address1,
      note: $note,
      name: $name,
      level: $level,
      ladies: $ladies,
      is_group: $is_group,
      is_free: $is_free,
      image9_url: $image9_url,
      image8_url: $image8_url,
      image7_url: $image7_url,
      image6_url: $image6_url,
      image5_url: $image5_url,
      image4_url: $image4_url,
      image3_url: $image3_url,
      image2_url: $image2_url,
      image1_url: $image1_url,
      image13_url: $image13_url,
      image12_url: $image12_url,
      image11_url: $image11_url,
      image10_url: $image10_url,
      genre_sport_other: $genre_sport_other,
      genre_sport_id: $genre_sport_id,
      genre_other: $genre_other,
      genre_id: $genre_id,
      fee_note: $fee_note,
      disability: $disability,
      description: $description,
      date_to: $date_to,
      date_from: $date_from,
      data3_url: $data3_url,
      data2_url: $data2_url,
      data1_url: $data1_url,
      created_by: $created_by,
      covid_note: $covid_note,
      comment: $comment,
      city_name: $city_name,
      city: $city,
      capacity_note: $capacity_note,
      capacity: $capacity,
      cancel_policy: $cancel_policy,
      body4: $body4,
      body3: $body3,
      body2: $body2,
      body1: $body1,
      attention: $attention,
      area_name: $area_name,
      area: $area,
      application_method_note: $application_method_note,
      application_method: $application_method,
      age_group: $age_group,
      admission_fee: $admission_fee
      }) {
      affected_rows
    }
    delete_event_draft_by_pk(id: $event_draft_id) {
      id
    }
    delete_event_place(where: {event_id: {_eq: $event_id}}) {
      affected_rows
    }
  }
  `;
  export const SEND_MAIL_APPROVE = gql`
mutation MyMutation($event_id: Int) {
  send_email_approve_request_event_draft(event_id: $event_id) {
    error_code
    error_message
    send_success
    status_code
  }
}
`;
export const GET_EVENT_DRAFT_ACCESS_APPROVE = gql`
query Myquery($list_event_draft_id: [Int!]) {
    event_draft(where: {id: {_in: $list_event_draft_id}, status: {_in: [2]}}) {
      id
      account_id
      created_by 
    }
}
`;

export const GET_EVENT_DRAFT_NOT_ACCESS_APPROVE = gql`
query Myquery($list_event_draft_id: [Int!]) {
    event_draft(where: {id: {_in: $list_event_draft_id}, status: {_nin: [2]}}) {
      id
    }
}
`;
export const DISAPPROVE_EVENT_DRAFT = gql`
mutation MyMutation($list_event_draft_id: [Int!]) {
  update_event_draft(where: {id: {_in: $list_event_draft_id}}, _set: {status:3}) {
    affected_rows
    returning {
      id
    }
  }
}
`;
export const GetDataEvent = async (apolloClient: any, event_id: number) => {
  let { data: dataEventDraft } = await apolloClient.query({
    query: GET_DATA_EVENT_DRAFT,
    variables: {
      event_id: event_id
    }
  });
  return dataEventDraft.event_draft[0];

}
export const SendMailApprove = async (apolloClient: any, event_id: number) => {
  const { data: DataSendMailApprove } = await apolloClient.mutate({
    mutation: SEND_MAIL_APPROVE,
    variables: {
      event_id: event_id
    }
  });
  return DataSendMailApprove;
}

export const ExecuteCopyEvent = async (dataEvent: any, apolloClient: any, status: number) => {
  const { data: DataCopy } = await apolloClient.mutate({
    mutation: COPY_DATA_EVENT_DRAFT_TO_EVENT,
    variables: {
      event_draft_id: dataEvent.id,
      event_id: dataEvent.event_id,
      account_id: dataEvent.account_id,
      accept_contact: dataEvent.accept_contact,
      access: dataEvent.access,
      additional_note: dataEvent.additional_note,
      video_url: dataEvent.video_url,
      venue_walk3: dataEvent.venue_walk3,
      venue_walk2: dataEvent.venue_walk2,
      venue_walk1: dataEvent.venue_walk1,
      venue_station_cd3: dataEvent.venue_station_cd3,
      venue_station_cd2: dataEvent.venue_station_cd2,
      venue_station_cd1: dataEvent.venue_station_cd1,
      venue_station3: dataEvent.venue_station3,
      venue_station2: dataEvent.venue_station2,
      venue_station1: dataEvent.venue_station1,
      venue_pref: dataEvent.venue_pref,
      venue_post_code: dataEvent.venue_post_code,
      venue_line_cd3: dataEvent.venue_line_cd3,
      venue_line_cd2: dataEvent.venue_line_cd2,
      venue_line_cd1: dataEvent.venue_line_cd1,
      venue_line3: dataEvent.venue_line3,
      venue_line2: dataEvent.venue_line2,
      venue_line1: dataEvent.venue_line1,
      venue_city: dataEvent.venue_city,
      venue_address2: dataEvent.venue_address2,
      venue_address1: dataEvent.venue_address1,
      valid_to: dataEvent.valid_to,
      valid_from: dataEvent.valid_from,
      type: dataEvent.type,
      title:dataEvent.title,
      title3: dataEvent.title3,
      title2: dataEvent.title2,
      title1: dataEvent.title1,
      title4: dataEvent.title4,
      time_description: dataEvent.time_description,
      time: dataEvent.time,
      sub_title: dataEvent.sub_title,
      status: status,
      schedule_time: dataEvent.schedule_time,
      preview: dataEvent.preview,
      pref_name: dataEvent.pref_name,
      pref: dataEvent.pref,
      point: dataEvent.point,
      payment_method: dataEvent.payment_method,
      organizer_pref: dataEvent.organizer_pref,
      organizer_post_code: dataEvent.organizer_post_code,
      organizer_name: dataEvent.organizer_name,
      organizer_city: dataEvent.organizer_city,
      organizer_address2: dataEvent.organizer_address2,
      organizer_address1: dataEvent.organizer_address1,
      note: dataEvent.note,
      name: dataEvent.name,
      level: dataEvent.level,
      ladies: dataEvent.ladies,
      is_group: dataEvent.is_group,
      is_free: dataEvent.is_free,
      image9_url: dataEvent.image9_url,
      image8_url: dataEvent.image8_url,
      image7_url: dataEvent.image7_url,
      image6_url: dataEvent.image6_url,
      image5_url: dataEvent.image5_url,
      image4_url: dataEvent.image4_url,
      image3_url: dataEvent.image3_url,
      image2_url: dataEvent.image2_url,
      image1_url: dataEvent.image1_url,
      image13_url: dataEvent.image13_url,
      image12_url: dataEvent.image12_url,
      image11_url: dataEvent.image11_url,
      image10_url: dataEvent.image10_url,
      genre_sport_other: dataEvent.genre_sport_other,
      genre_sport_id: dataEvent.genre_sport_id,
      genre_other: dataEvent.genre_other,
      genre_id: dataEvent.genre_id,
      fee_note: dataEvent.fee_note,
      disability: dataEvent.disability,
      description: dataEvent.description,
      date_to: dataEvent.date_to,
      date_from: dataEvent.date_from,
      data3_url: dataEvent.data3_url,
      data2_url: dataEvent.data2_url,
      data1_url: dataEvent.data1_url,
      created_by: dataEvent.created_by,
      created: dataEvent.created,
      covid_note: dataEvent.covid_note,
      comment: dataEvent.comment,
      city_name: dataEvent.city_name,
      city: dataEvent.city,
      capacity_note: dataEvent.capacity_note,
      capacity: dataEvent.capacity,
      cancel_policy: dataEvent.cancel_policy,
      body4: dataEvent.body4,
      body3: dataEvent.body3,
      body2: dataEvent.body2,
      body1: dataEvent.body1,
      attention: dataEvent.attention,
      area_name: dataEvent.area_name,
      area: dataEvent.area,
      application_method_note: dataEvent.application_method_note,
      application_method: dataEvent.application_method,
      age_group: dataEvent.age_group,
      admission_fee: dataEvent.admission_fee
    }
  });
  return DataCopy;
}
export const CopyEventPlanPlace = async (apolloClient: any, dataPlaces: any, dataPlans: any, dataPlanOld: any, event_id: number) => {
  const ListEventPlace: event_place_insert_input[] = [];
  const ListEventPlan: event_plan_insert_input[] = [];
  const listEventPlanIdUpdate  = dataPlans.filter((x: any) => x.event_plan_id !== null);
  const listEventPlanIdCreate  = dataPlans.filter((x: any) => !x.event_plan_id);
  let listEventPlanIdDelete = [];
  const listEventIdOld: number[] = [];
  const listEventIdUpdate: number[] = [];

  //console.log('11111111111listEventPlanIdUpdate',listEventPlanIdUpdate);
  //console.log('11111111111listlistEventPlanIdCreate',listEventPlanIdCreate);
  //console.log('11111111111listdataPlanOld',dataPlanOld);

  for (let index = 0; index < listEventPlanIdUpdate.length; index++) {
    const element = listEventPlanIdUpdate[index];
    listEventIdUpdate.push(element.event_plan_id);
  }
  for (let index = 0; index < dataPlanOld.length; index++) {
    const element = dataPlanOld[index];
    listEventIdOld.push(element.id);
  }
  // console.log('11111111111listEventIdOldt',listEventIdOld);
  // console.log('11111111111listlistEventIdUpdate',listEventIdUpdate);


  listEventPlanIdDelete =  listEventIdOld.filter((x:any) =>  !listEventIdUpdate.includes(x))
  //console.log('11111111111element',listEventPlanIdDelete);
  
  // get list id event_plan delete
  const { data: DataPlanDelete } = await apolloClient.mutate({
    mutation: DELETE_EVENT_PLAN,
    variables: {
      list_event_plan_id: listEventPlanIdDelete
    }
  });
  //console.log('11111111111DataPlanDelete',DataPlanDelete);
  if(listEventPlanIdCreate.length > 0){
    for (let index = 0; index < listEventPlanIdCreate.length; index++) {
      const element = listEventPlanIdCreate[index];
      let dataPlanCreate = {
        event_id: event_id,
        created_by: element.created_by,
        name: element.name,
        description: element.description,
        price: element.price,
        applicants: element.applicants,
        note: element.note
      }
      ListEventPlan.push(dataPlanCreate);
    }
    const { data: DataPlan } = await apolloClient.mutate({
      mutation: COPY_EVENT_PLAN,
      variables: {
        dataPlan: ListEventPlan
      }
    });

  }
  if(listEventPlanIdUpdate.length > 0){
    for (let index = 0; index < listEventPlanIdUpdate.length; index++) {
      const element = listEventPlanIdUpdate[index];
      
        let dataPlanUpdate = {
          event_id: event_id,
          created_by: element.created_by,
          name: element.name,
          description: element.description,
          price: element.price,
          applicants: element.applicants,
          note: element.note,
          event_plan_id: element.event_plan_id
        }
        const { data: DataPlanUpdate } = await apolloClient.mutate({
          mutation: UPDATE_EVENT_PLAN,
          variables: dataPlanUpdate
        });
        //console.log('11111111111DataPlanUpdate',DataPlanUpdate);
    }
  }
 
  //console.log('11111111111listEventPlanIdDelete',listEventPlanIdDelete);


 
  
  for (let index = 0; index < dataPlaces.length; index++) {
    const element = dataPlaces[index];
    let dataPlace = {
      event_id: event_id,
      name: element.name,
      area: element.area,
      pref: element.pref,
      city: element.city,
      venue_post_code: element.venue_post_code,
      venue_pref: element.venue_pref,
      venue_city: element.venue_city,
      venue_address1: element.venue_address1,
      venue_address2: element.venue_address2,
      venue_line_cd1: element.venue_line_cd1,
      venue_station_cd1: element.venue_station_cd1,
      venue_walk1: element.venue_walk1,
      venue_line_cd2: element.venue_line_cd2,
      venue_station_cd2: element.venue_station_cd2,
      venue_walk2: element.venue_walk2,
      venue_line_cd3: element.venue_line_cd3,
      venue_station_cd3: element.venue_station_cd3,
      venue_walk3: element.venue_walk3,
      created_by: element.created_by,
      note: element.note,
      date_from: element.date_from,
      date_to: element.date_to,
      time: element.time,
      time_description: element.time_description,
      area_name: element.area_name,
      pref_name: element.pref_name,
      city_name: element.city_name,
      venue_line1: element.venue_line1,
      venue_station1: element.venue_station1,
      venue_line2: element.venue_line2,
      venue_station2: element.venue_station2,
      venue_line3: element.venue_line3,
      venue_station3: element.venue_station3,
    }
    ListEventPlace.push(dataPlace);
  }

  //console.log('11111111111ListEventPlace',ListEventPlace);
  
  const { data: DataPlace } = await apolloClient.mutate({
    mutation: COPY_EVENT_PLACE,
    variables: {
      dataEventPlace: ListEventPlace
    }
  });
  return { DataPlace };
}
export const GetEventApproveStatus3 = async (apolloClient: any, event_id: number, dateNow: string) => {

  let { data: dataEventStatus3 } = await apolloClient.query({
    query: GET_EVENT_APPROVE_3,
    variables: {
      list_event_id: event_id,
      date_now: dateNow
    }
  });
  return dataEventStatus3.event_draft;
}
export const GetEventApproveStatus4 = async (apolloClient: any, event_id: number, dateNow: string) => {
  let { data: dataEventStatus4 } = await apolloClient.query({
    query: GET_EVENT_APPROVE_4,
    variables: {
      list_event_id: event_id,
      date_now: dateNow
    }
  });

  return dataEventStatus4.event_draft;
}
export const GetEventApproveError = async (apolloClient: any, event_id: number, dateNow: string) => {
  let { data: dataEventError } = await apolloClient.query({
    query: GET_EVENT_APPROVE_ERROR,
    variables: {
      list_event_id: event_id,
      date_now: dateNow
    }
  });
  return dataEventError.event_draft;
}
export const GetAllGenreSport = async (dataProvider: any, genre_id: number) => {
  let data_genre_sport = await dataProvider.getList("genre_sport", {
    pagination: { page: 1, perPage: 10000 },
    sort: { field: "id", order: "ASC" },
    filter: { genre_id: genre_id },
  });
  let ListData = [];
  const { data } = data_genre_sport;
  for (let item = 0; item < data.length; item++) {
    ListData.push({ id: data[item].id, name: data[item].secondary_name })
  }
  return ListData;
};
