import defaultTheme from './default'
import {createMuiTheme} from '@material-ui/core'
import Image from '../delete.png'; 
export const darkTheme = {
    palette: {
        secondary: {
            main: '#90caf9',
        },
        
        type: 'dark',
    },
    shape: {
        borderRadius: 3,
    },
    overrides: {
        MuiInputLabel : {
            animated : {
                background:'#424242 !important'
            }
        },
        RaCreate:{
            root:{
                backgroundColor:'transparent !important',
                boxShadow: 'none !important',
                padding: '0 !important',
            }
        },
        RaEdit : {
            root:{
                backgroundColor:'transparent !important',
                boxShadow: 'none !important',
                padding: '0 !important',
            }
        },
        RaSidebar: {
            drawerPaper: {
                padingTop:'30px',
                order:-1,
            },
            root: {
                float:'left !important'
            }
        },        
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #079D3E',
            },
            icon:{
                minWidth:'20px',
                marginRight:'3px'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                backgroundClip: 'padding-box',
                              
            },
        },
        MuiButton: {
            contained: {
                boxShadow: 'none',
                '&$disabled': {
                    color: '#FFF',
                },
            },          
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        RaLayout: {
            root: {
                height:'100%',
                width: '100%',
                display:'block',
                zIndex: 1,
                position: 'relative',
                minWidth: 'auto',
                minHeight: '100vh',
                flexDirection: 'column',
            },
            content : {
                marginLeft:'20px',
                marginTop: '30px',
                float:'left !important',
                width:'70%',
               
            }, 
            appFrame: {
                height:'100%'
            },
            contentWithSidebar: {
                width: '100%',
            }

        },
        MuiDrawer: {
            docked: {
                paddingTop:'30px',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)',
                padding: '14px 7px 14px 7px !important',
            },
            head: {
                fontSize: '0.95rem',
            },
            body: {
                fontSize: '0.95rem',
            },
            paddingCheckbox: {
                padding: '0 0 0 15px',
            },
        },     
        MuiTypography :{
            colorPrimary:{
                color: '#FFF'
            }
        },
        RaListToolbar :{
            toolbar: {
                backgroundColor: '#424242',
                borderRadius: '6px',
                padding: '15px !important',
                marginBottom: '20px',
                boxShadow:
                    '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#424242',
                },      
                MuiButtonBase: {
                    root:{
                        backgroundColor:'red'
                    }
                }             
            }
        },
        RaList: {
            main:{
                backgroundColor: '#424242',
                borderRadius: '6px',
                padding: '15px',
                boxShadow:
                    '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#424242',
                },                 
            },
            content: {
                overflow: 'hidden',
                overflowX:'auto',
            }
        },
        MuiMenuItem:{
            root:{
                paddingTop: '10px',
                paddingBottom: '10px',
            }
        },
        RaToolbar: {
            root:{
                backgroundColor: 'none',
            },
            toolbar: {
                backgroundColor: 'none',
                textAlign : 'center',
            }
        },      
        
        MuiSnackbarContent: {
            root:{
                backgroundColor:'rgb(60, 212, 160)'
            },  
        },
        MuiOutlinedInput: {
            inputMultiline: {
                height:'auto !important',
                minHeight: ' 150px !important'
            }            
        },
        RaDatagrid: {
            // table:{
            //     tableLayout:'fixed',
            // },
            headerCell:{
                whiteSpace:'nowrap',
            },
            rowCell: {
                whiteSpace:'nowrap',
            }
        },
        RaShow: {
            root: {
                overflowX:'auto',
            }
        },
        RaSimpleFormIterator: {
            root:{
                display:'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',

            },
            line: {
                width: '48%',
                borderBottom:'none',
            },
            index: {
                display:'none'
            }
        },
        RaTopToolbar :{
            root: {
                width: '500px',
            }
        }
    },    

};

export const lightTheme = {
    palette: {
        primary: {
            main: '#079D3E',
        },
        secondary: {
            light: '#5f5fc4',
            main: '#283593',
            dark: '#001064',
            contrastText: '#fff',
        },
        background: {
            default: '#FAFAFA',
        },
    },
    
    shape: {
        borderRadius: 3,
    },
    overrides: {
        RaMenuItemLink: {
            root: {
                borderLeft: '3px solid #fff',
            },
            active: {
                borderLeft: '3px solid #079D3E',
                background:'#f5f5f5'
            },
            icon:{
                minWidth:'20px',
                marginRight:'3px'
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
            root: {
                backgroundClip: 'padding-box',
                              
            },
            card: {
                boxShadow: 'none'
            }
        },
        MuiButton: {
            contained: {
                backgroundColor: '#fff',
                color: '#079D3E',
                boxShadow: 'none',
            },          
        },
        MuiCard: {
            root : {
                boxShadow: 'none'
            }
        },
        RaCreate: {
            card:  {
                boxShadow: 'none'
            }
        },
        MuiAppBar: {
            root:{
                borderBotom:'none'
            },
            colorSecondary: {
                color: '#FFF',
                backgroundColor: '#079D3E',
                // boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: 'transparent',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
            },
        },
        RaLayout: {
            root: {
                height:'100vh',
                width: '100%',
                display:'flex',
                zIndex: 1,
                position: 'relative',
                minWidth: 'auto',
                minHeight: '100vh',
                flexDirection: 'column',
            },
            content : {
                marginLeft:'10px',
                marginTop: '10px',
                float:'left !important',
                width:'70%',
                // overflow: 'hidden'
            }, 
            appFrame: {
                height:'100vh'
            },
            contentWithSidebar: {
                width: '100%',
            }

        },
        MuiDrawer: {
            docked: {
                backgroundColor: '#FFF',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                paddingTop:'30px',
            },
            paper: {
                // width: '320px'
            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(224, 224, 224, .5)',
                padding: '5px 5px 5px 5px !important',
            },
            head: {
                fontSize: '0.95rem',
            },
            body: {
                fontSize: '0.95rem',
            },
            paddingCheckbox: {
                padding: '0 0 0 15px',
            },
        },     
        MuiTypography :{
            colorPrimary:{
                color: '#4A4A4A'
            }
        },
        RaListToolbar :{
            toolbar: {
                backgroundColor: '#FFF',
                borderRadius: '6px',
                padding: '7px !important',
                marginBottom: '10px',
                boxShadow:
                    '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#FFF',
                },      
                MuiButtonBase: {
                    root:{
                        backgroundColor:'#079D3E'
                    }
                }             
            }
        },
        RaList: {
            main:{
                backgroundColor: '#FFF',
                borderRadius: '6px',
                padding: '10px',
                boxShadow:
                    '0px 1px 8px rgba(0, 0, 0, 0.103475), 0px 3px 3px rgba(0, 0, 0, 0.0988309), 0px 3px 4px rgba(0, 0, 0, 0.10301) !important',
                '&::-webkit-scrollbar': {
                    width: '0.4em',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#FFF',
                },                 
            },
            content: {
                overflow: 'hidden',
                overflowX:'auto',
            }
        },
        MuiMenuItem:{
            root:{
                paddingTop: '10px',
                paddingBottom: '10px',
            }
        },
        RaToolbar: {
            root:{
                backgroundColor: 'none',
            },
            toolbar: {
                backgroundColor: 'none',
                textAlign : 'center',
            }
        },      
        RaSidebar: {
            drawerPaper: {
                maxWidth:'320px',
                order:-1,
            },
            root: {
                float:'left !important'
            }
        },
        MuiSnackbarContent: {
            root:{
                backgroundColor:'rgb(60, 212, 160)'
            },  
        },
        MuiOutlinedInput: {
            inputMultiline: {
                height:'auto !important',
                minHeight: ' 150px !important'
            }            
        },
        RaDatagrid: {
            // table:{
            //     tableLayout:'fixed',
            // },
            headerCell:{
                whiteSpace:'nowrap',
            },
            rowCell: {
                whiteSpace:'nowrap',
            }
        },
        RaShow: {
            root: {
                overflowX:'auto',
            }
        },
        RaSimpleFormIterator: {
            root:{
                display:'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',

            },
            line: {
                width: '48%',
                borderBottom:'none',
            },
            index: {
                display:'none'
            }
        },
        RaTopToolbar :{
            root: {
                width: '500px',
            }
        },
        RaFileInputPreview: {
            removeButton: {
                display: 'none',
            },
            removeIcon: {
                display: 'none',
            }
        },
        RaLabeled: {
            // value: {
            //     display:'flex',
            //     height:'100px',
            //     position: 'relative',
            // }
        },
        RaImageInput: {
            removeButton: {
                top: '0 !important',
                right: '0 !important',
            }
        },
    },
};
export default {
    default: createMuiTheme({ ...defaultTheme, ...lightTheme }),

}