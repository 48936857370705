import * as React from 'react';
import {
  useTranslate,
} from 'react-admin';
import { Box, Typography, Grid, Container } from '@material-ui/core';
import InputRenderer from '../../utils/InputRenderer';
import Fields from './dataset';

const SectionTitle = ({ label, id }: { label: string, id: number }) => {
  const translate = useTranslate();
  if(id){
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}ID {id} の編集
      </Typography>
    );
  }else{
    return (
      <Typography variant="h6" gutterBottom>
        {translate(label)}新規登録
      </Typography>
    );
  }
};

const Separator = () => <Box pt="1em" />;

const NFrom = (props: any) => {
  const { editForm } = props;
  if(editForm){
    var {record: {id}} = props;
  }
  var isEditPage = editForm === true ? true : false;
  return <Container style={{justifyContent: 'center'}}>
    <Grid  style={{ width: '100%'}}  container justify='center' spacing={2}> 
      <Grid  style={{ width: '50%'}} container justify='center' spacing={2} >
      <Grid  style={{ width: '100%'}} >
      <SectionTitle  label={editForm ? "menu.team_review.edit_page" : "menu.team_review.add_page"} id = {editForm? id : ''}  />
      <Separator />
      </Grid>
      {Fields.map((d, idx) => {
        if (d.type === 'hidden') {
          return <></>
        }
        if (d.name === 'created' && isEditPage === false) {
          return <></>
        }
        return <Grid item xs={12} key={idx}>
          <InputRenderer source='team_review' data={d} />
        </Grid>
      })}
      </Grid>
    </Grid>
  </Container>;
};

export default NFrom;