export const STATUS = [
  {id: 0, name: "終了"},
  {id: 1, name: "作成中"},
  {id: 2, name: "申請中"},
  {id: 3, name: "公開予定"},
  {id: 4, name: "公開中"},
  {id: 5, name: "受付停止中"},
  {id: 6, name: "非承認"},
  {id: 7, name: "非公開"},
]
export const TYPE = [
  {id: 1, name: "大会"},
  {id: 2, name: "イベント"},
  {id: 3, name: "教室"},
  {id: 4, name: "講習会・セミナー"},
  {id: 5, name: "講演会"},
  {id: 6, name: "ツアー・合宿"}
]
export const IS_PREE = [
  {id: true, name: "無料"},
  {id: false, name: "有料"}
]
export const IS_GROUP = [
  {id: 1, name: "個人"},
  {id: 2, name: "団体"},
  {id: 3, name: "個人団体こちらでも"}
]
export const AGE_GROUP = [
  {id: '3歳以下', name: "3歳以下"},
  {id: '幼児', name: "幼児"},
  {id: '小学生', name: "小学生"},
  {id: '中学生', name: "中学生"},
  {id: '高校生', name: "高校生"},
  {id: '大学生・専門学生', name: "大学生・専門学生"},
  {id: '18歳以上', name: "18歳以上"},
  {id: '30歳以上', name: "30歳以上"},
  {id: '40歳以上', name: "40歳以上"},
  {id: '50歳以上', name: "50歳以上"},
  {id: '60歳以上', name: "60歳以上"},
  {id: '年齢不問', name: "年齢不問"},
]
export const ATTENTION = [
  {id: 0, name: "オススメ掲載しない"},
  {id: 1, name: "オススメ掲載する"},
]
export const DISABILITY = [
  {id: 1, name: "はい"},
  {id: 2, name: "いいえ"},
]
export const LADIES = [
  {id: 1, name: "レディースのみ"},
  {id: 2, name: "レディース歓迎"},
]
export const LEVEL = [
  {id: '初心者向け', name: "初心者向け"},
  {id: '初心者歓迎', name: "初心者歓迎"},
  {id: '中級者向け', name: "中級者向け"},
  {id: '上級者向け', name: "上級者向け"},
  {id: 'レベル不問', name: "レベル不問"},
]
export const PREF = [
  {id: '北海道', name: "北海道"},
  {id: '青森県', name: "青森県"},
  {id: '岩手県', name: "岩手県"},
  {id: '宮城県', name: "宮城県"},
  {id: '秋田県', name: "秋田県"},
  {id: '山形県', name: "山形県"},
  {id: '福島県', name: "福島県"},
  {id: '東京都', name: "東京都"},
  {id: '神奈川県', name: "神奈川県"},
  {id: '埼玉県', name: "埼玉県"},
  {id: '千葉県', name: "千葉県"},
  {id: '茨城県', name: "茨城県"},
  {id: '栃木県', name: "栃木県"},
  {id: '群馬県', name: "群馬県"},
  {id: '山梨県', name: "山梨県"},
  {id: '長野県', name: "長野県"},
  {id: '新潟県', name: "新潟県"},
  {id: '富山県', name: "富山県"},
  {id: '石川県', name: "石川県"},
  {id: '福井県', name: "福井県"},
  {id: '岐阜県', name: "岐阜県"},
  {id: '静岡県', name: "静岡県"},
  {id: '愛知県', name: "愛知県"},
  {id: '三重県', name: "三重県"},
  {id: '大阪府', name: "大阪府"},
  {id: '兵庫県', name: "兵庫県"},
  {id: '京都府', name: "京都府"},
  {id: '滋賀県', name: "滋賀県"},
  {id: '奈良県', name: "奈良県"},
  {id: '和歌山県', name: "和歌山県"},
  {id: '岡山県', name: "岡山県"},
  {id: '広島県', name: "広島県"},
  {id: '鳥取県', name: "鳥取県"},
  {id: '島根県', name: "島根県"},
  {id: '山口県', name: "山口県"},
  {id: '香川県', name: "香川県"},
  {id: '徳島県', name: "徳島県"},
  {id: '愛媛県', name: "愛媛県"},
  {id: '高知県', name: "高知県"},
  {id: '福岡県', name: "福岡県"},
  {id: '佐賀県', name: "佐賀県"},
  {id: '長崎県', name: "長崎県"},
  {id: '熊本県', name: "熊本県"},
  {id: '大分県', name: "大分県"},
  {id: '宮崎県', name: "宮崎県"},
  {id: '鹿児島県', name: "鹿児島県"},
  {id: '沖縄県', name: "沖縄県"},
]
export const ACCEPT_CONTACT = [
  {id: true, name: "受け付けない"},
  {id: false, name: "受け付ける"}
]
const DATA = [
  { name: 'id', type: 'textinput',searchField: '', nullable: true, editable: false, export: true, import: true },
  { name: 'genre_id',searchField: 'name', type: 'reference',reference: 'genre', nullable: false, editable: true, export: true, import: true },
  { name: 'account_id',searchField: 'name', type: 'reference',reference: 'account', nullable: false, editable: true, export: true, import: true },
  { name: 'valid_from', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'valid_to', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'date_from', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'date_to', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'title', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'attention', type: 'status',options: ATTENTION, nullable: false, editable: true, export: true, import: true },
  { name: 'status', type: 'status',options: STATUS, nullable: false, editable: true, export: true, import: true },
  { name: 'sub_title', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'type', type: 'radio',options: TYPE, nullable: false, editable: true, export: true, import: true },
  { name: 'level', type: 'checkboxinput',options:LEVEL , nullable: false, editable: true, export: true, import: true },
  { name: 'video_url', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'image1_url', type: 'imagefield', nullable: false, editable: true, export: true, import: true },
  { name: 'image2_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image3_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image4_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image5_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image6_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image7_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image8_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'image9_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'access', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'capacity', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'capacity_note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'is_group', type: 'status',options:IS_GROUP, nullable: false, editable: true, export: true, import: true },
   { name: 'genre_sport_id', type: 'textinput',reference: 'genre_sport', nullable: false, editable: true, export: true, import: true },
  { name: 'description', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'point', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'covid_note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'image10_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'title1', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'body1', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'image11_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'title2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'body2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'image12_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'title3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'body3', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'image13_url', type: 'imagefield', nullable: true, editable: true, export: true, import: true },
  { name: 'title4', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'body4', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'organizer_name', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'organizer_pref', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'organizer_city', type: 'textinput', nullable: false, editable: true, export: true, import: true },
  { name: 'organizer_address1', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'organizer_address2', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'additional_note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'application_method', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'application_method_note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'is_free', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'admission_fee', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'payment_method', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'fee_note', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'cancel_policy', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'data1_url', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'data2_url', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'data3_url', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  
  { name: 'created', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'request_date', type: 'date', nullable: true, editable: false, export: true, import: true },
  { name: 'approved_at', type: 'date', nullable: false, editable: true, export: true, import: true },
  { name: 'attention', type: 'radio',options:ATTENTION, nullable: false, editable: true, export: true, import: true },
  { name: 'schedule_time', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'organizer_post_code', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'comment', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  { name: 'age_group', type: 'jsonb', nullable: true, editable: true, export: true, import: true },
  { name: 'disability', type: 'textinput',numberFormat: true, nullable: true, editable: true, export: true, import: true },
  { name: 'ladies', type: 'textinput', nullable: true,numberFormat: true, editable: true, export: true, import: true },
  //{ name: 'preview_key', type: 'textinput', nullable: true, editable: true, export: true, import: true },
  //{ name: 'preview', type: 'jsonb', nullable: false, editable: true, export: true, import: true },
  
  { name: 'accept_contact', type: 'boolean', nullable: false, editable: true, export: true, import: true },
  { name: 'genre_other', type: 'textinput', nullable: true,editable: true, export: true, import: true },
  { name: 'genre_sport_other', type: 'textinput', nullable: true,editable: true, export: true, import: true },
  { name: 'note', type: 'textinput', nullable: true,editable: true, export: true, import: true },


]

export default DATA;